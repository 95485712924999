//TODO: Review if required in MonoRepo
const borderProps = [
  "border",
  "borderWidth",
  "borderStyle",
  "borderColor",
  "borderTop",
  "borderTopWidth",
  "borderTopStyle",
  "borderTopColor",
  "borderRight",
  "borderRightWidth",
  "borderRightStyle",
  "borderRightColor",
  "borderBottom",
  "borderBottomWidth",
  "borderBottomStyle",
  "borderBottomColor",
  "borderLeft",
  "borderLeftWidth",
  "borderLeftStyle",
  "borderLeftColor",
  "borderX",
  "borderY",
]

const marginProps = [
  "m",
  "margin",
  "mt",
  "marginTop",
  "mr",
  "marginRight",
  "mb",
  "marginBottom",
  "ml",
  "marginLeft",
  "mx",
  "my",
]

const paddingProps = [
  "p",
  "padding",
  "pt",
  "paddingTop",
  "pr",
  "paddingRight",
  "pb",
  "paddingBottom",
  "pl",
  "paddingLeft",
  "px",
  "py",
]

const sizeProps = ["size", "w", "h", "minW", "maxW", "minH", "maxH"]

// add optional filter param to only return matching subset of props: e.g. ("Left") to only get borderLeft props, or () to get all
export const withFilter = (propNames: string[]) => (filter?: string) =>
  propNames.filter((propName) => propName.includes(filter ?? ""))

export const styleProps = {
  border: withFilter(borderProps),
  padding: withFilter(paddingProps),
  margin: withFilter(marginProps),
  size: withFilter(sizeProps),
  spacing: withFilter([...marginProps, ...paddingProps]),
}
