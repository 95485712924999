import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

import { MenuLayout } from "common/components"
import { CustomersIcon, SalesOrdersIcon } from "common/theme"
import { route } from "features/app/routes"

const SalesView: FC = () => {
  const { t } = useTranslation()

  const cards = [
    { link: route.customers.path, title: t("customer_other"), icon: CustomersIcon },
    { link: route.salesOrders.path, title: t("salesOrders.salesOrdersTitle"), icon: SalesOrdersIcon },
    // { link: route.salesChannels.path, title: "SalesView Channels", icon: SalesChannelsIcon },
    // { link: route.shipment.path, title: "Shipment Methods", icon: ShipmentIcon },
    // { link: route.invoice.path, title: "Invoice", icon: InvoiceIcon },
    // { link: route.payment.path, title: "Payment", icon: PaymentIcon },
    // { link: route.creditNote.path, title: "Credit Note", icon: CreditIcon },
  ]

  return <MenuLayout cards={cards} pageTitle={t("sales")} />
}

export { SalesView }
