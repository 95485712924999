import { Image, Tag, Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useTable } from "react-table"

import { Category, ProductAPI, api } from "api"
import { Pagination, Table, TableItemMenu } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { DefaultImageIcon } from "common/theme"

type ProductsTableProps = {
  loading: boolean
  onOpen: () => void
  products: ProductAPI[]
  refetch: (top: number, skip: number) => void
  setProduct: Dispatch<SetStateAction<ProductAPI>>
  pageCount: number
  itemsPerPage: number
  search: string
}

const ProductsTable: FC<ProductsTableProps> = ({
  loading,
  onOpen,
  setProduct,
  products,
  refetch,
  pageCount,
  itemsPerPage,
  search,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: productCategories } = api.useProductCategories(TENANCY_ID)

  const NameCell: FC<{ product: ProductAPI }> = ({ product }) => (
    <TableItemMenu
      deleteClick={() => {
        setProduct(product)
        onOpen()
      }}
      editClick={() => navigate(product.id)}
      value={product.name}
      isShowEdit
      onClick={() => navigate(`info/${product.id}`)}
    />
  )

  const columnData: Column<ProductAPI>[] = [
    {
      Header: t("image") as string,
      accessor: "images",
      Cell: ({ row }) =>
        row.original.mainImage ? (
          <Image boxSize="50px" src={row.original.mainImage.thumbnailUrl} />
        ) : (
          <DefaultImageIcon fontSize={50} />
        ),
    },
    {
      Header: t("name") as string,
      accessor: "name",
      Cell: ({ row }) => <NameCell product={row.original} />,
    },
    {
      Header: t("product.category") as string,
      accessor: "productCategoryId",
      Cell: ({ row }) => {
        const productCategory = productCategories?.value?.find(
          (item) => item.id === row.original.productCategoryId
        ) as Category

        return <Text>{productCategory?.name || ""}</Text>
      },
    },
    {
      Header: t("product.inventoryType") as string,
      accessor: "inventoryType",
      Cell: ({ row }) => <Text>{row.original.inventoryType}</Text>,
    },
    {
      Header: t("product.stockLevel") as string,
      accessor: "totalStock",
    },
    {
      Header: t("status_label") as string,
      accessor: "status",
      Cell: ({ row }) => (
        <Tag
          background={row.original.status === "Active" ? "success.02" : "warning.02"}
          borderColor={row.original.status === "Active" ? "success.03" : "warning.03"}
          borderRadius={2}
          color="text.01"
          fontSize={12}
        >
          {t(`statuses.${row.original.status}`)}
        </Tag>
      ),
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setPageSize,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, [productCategories]),
      data: useMemo(() => products, [products]),
      initialState: {
        pageSize: itemsPerPage,
      },
      manualPagination: true,
      pageCount,
    },
    usePagination
  )

  useEffect(() => {
    gotoPage(0)
    refetch(pageSize, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])
  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<ProductAPI>): React.ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()} _hover={{ background: "#aee5ff" }}>
        {row.cells.map((cell) => (
          <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} data-testid="productsTable" width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR
            bg="white"
            color="#36495E"
            fontSize="lg"
            textTransform="none"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => (
              <Table.TH _first={{ width: "10%" }} {...column.getHeaderProps()} textTransform="capitalize">
                {column.render("Header")}
              </Table.TH>
            ))}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()} textAlign="left">
        {loading ? (
          <Table.Skeleton columnWidths={[16, 24, 24, 32, 24, 16]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={(event) => {
                setPageSize(event)
                refetch(event, 0)
              }}
              totalPages={pageOptions.length}
              onPageChange={(pageIndex) => {
                gotoPage(pageIndex)
                refetch(pageSize, pageIndex * pageSize)
              }}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { ProductsTable }
