import { Button, Flex, Grid, GridItem, Text } from "@chakra-ui/react"
import { FC, useContext } from "react"
import { useNavigate } from "react-router-dom"

import { symbolPosition } from "common/utils/currency.utils"
import { ProductsContext } from "features/inShop/product/ProductState/context"

const RemainingInfo: FC = () => {
  const navigate = useNavigate()
  const { state } = useContext(ProductsContext)
  const { order } = state.makeOrder

  const buttonStyles = {
    borderRadius: 10,
    color: "white",
    fontSize: 24,
  }

  return (
    <Grid
      data-testid="orderInfo"
      style={{
        background: "#007abd",
        minHeight: "200px",
        width: "100%",
        color: "white",
        fontWeight: "bold",
        padding: "10px 0",
      }}
      templateColumns="2fr 3fr"
    >
      <GridItem
        style={{
          fontSize: 30,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingLeft: 50,
          letterSpacing: 1.5,
        }}
      >
        <Flex justify="space-between" mb={2}>
          <Text>Subtotal</Text>
          <Text>{symbolPosition(order.subtotal)}</Text>
        </Flex>
        <Flex justify="space-between" mb={2}>
          <Text>Discount</Text>
          <Text>{symbolPosition(order.discount)}</Text>
        </Flex>
        <Flex justify="space-between" mb={2}>
          <Text>IVA</Text>
          <Text>{symbolPosition(order.tax)}</Text>
        </Flex>
        <Flex justify="space-between">
          <Text>Total</Text>
          <Text>{symbolPosition(order.total)}</Text>
        </Flex>
        <Flex justify="space-between" mb={2}>
          <Text>Remaining</Text>
          <Text>{symbolPosition(Number((order.total - order.deposit).toFixed(2)))}</Text>
        </Flex>
      </GridItem>
      <GridItem
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 0",
        }}
      >
        <Button
          background="#149a45"
          display="flex"
          flexDirection="column"
          height="5rem"
          px="4rem"
          style={buttonStyles}
          onClick={() => navigate("/remainingPayment")}
        >
          <Text>Proceed to Payment</Text>
        </Button>
      </GridItem>
    </Grid>
  )
}

export { RemainingInfo }
