import { arSa } from "./translations/arSa"
import { en } from "./translations/en"
import { esEs } from "./translations/esEs"
import { frFr } from "./translations/frFr"
// import { ruRu } from "./translations/ruRu"
import { getSystemLanguageLocale } from "common/systemLanguageStorage"

const systemLanguageLocale = getSystemLanguageLocale()

export const i18nInitOptions = {
  resources: {
    en,
    ar: arSa,
    fr: frFr,
    es: esEs,
    // ru: ruRu,
  },
  lng: systemLanguageLocale ?? "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: (value: string, format: string | undefined) => {
      if (!value || !format) return value
      if (format === "uppercase") return value.toLocaleUpperCase()
      if (format === "lowercase") return value.toLocaleLowerCase()

      return value
    },
  },
}
