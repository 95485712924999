import { Button, Flex, Heading, Stack, useTheme } from "@chakra-ui/react"
import { AddIcon, FilterIcon } from "@iac/components.icons.react"
import { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { StockPageTable } from "./StockPageTable"
import { CatalogProduct, mockData } from "api"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { route } from "features/app/routes"
import { SearchForProducts } from "features/inventory/products/searchForProducts/SearchForProducts"

const StockPage: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { colors } = useTheme()
  const [search, setSearch] = useState("")
  const [product, setProduct] = useState<CatalogProduct>(mockData.initCatalogProduct)

  const stockPerOrders = [
    {
      id: "47bf7d96-5535-42e9-86a8-8b6ba1dc9e3d",
      deliveryDate: "16/05/2021",
      originalStockLevel: 4500,
      costPrice: 0.63,
      rrPrice: 0.72,
      stockOnHand: 1008,
      status: "arrived",
    },
    {
      id: "b6ffd901-c360-43ee-afff-e9d1e93d90f7",
      deliveryDate: "08/04/2021",
      originalStockLevel: 3500,
      costPrice: 0.65,
      rrPrice: 0.72,
      stockOnHand: 0,
      status: "confirmed",
    },
    {
      id: "30c1368b-150a-4b59-beb1-ec7b52bbd308",
      deliveryDate: "10/05/2021",
      originalStockLevel: 3690,
      costPrice: 0.53,
      rrPrice: 0.72,
      stockOnHand: 360,
      status: "ordered",
    },
    {
      id: "6df09f80-9d85-4242-b1fb-cf51989d86b6",
      deliveryDate: "16/06/2021",
      originalStockLevel: 4000,
      costPrice: 0.66,
      rrPrice: 0.72,
      stockOnHand: 500,
      status: "confirmed",
    },
  ]

  useEffect(() => {
    setSearch(search)
    const { id } = params
    if (id) {
      const findProduct = mockData.catalogProduct.catalogProducts.find((item) => item.id === id)
      findProduct && setProduct(findProduct)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack data-testid="stockPagePerOrderContainer">
      <PageHeader
        buttonTitle="Back"
        title={product.name}
        variant="secondary"
        width="150px"
        onClick={() => navigate(`${route.productInfo.path}/${product.id}`)}
      />
      <Flex direction="column" p="0 1rem">
        <Heading color={colors.brand.dark} data-testid="tableTitle" mb={6} size="md">
          Stock per order
        </Heading>
        <Flex direction="column" width="100%">
          <Flex align="center" justify="space-between" mb={6} width="70%">
            <Flex width="80%">
              <SearchForProducts titleButton="Search" onSearch={setSearch} />
            </Flex>
            <FilterIcon fontSize={30} />
          </Flex>
          <StockPageTable orders={stockPerOrders} />
        </Flex>
      </Flex>
      <Flex align="flex-start" direction="column" p="2rem 1rem 0">
        <Button mb={4} mr={6} variant="primary" onClick={() => navigate(route.newOrder.path)}>
          <AddIcon border={`1px solid ${colors.white}`} borderRadius="100%" mr={3} /> Add New Order
        </Button>
        <Button
          borderColor={colors.primary.blue}
          color={colors.primary.blue}
          variant="outline"
          onClick={() => navigate(`${route.productStockPerVariant.path}/${product.id}`)}
        >
          View Stock per Variants
        </Button>
      </Flex>
    </Stack>
  )
}

export { StockPage }
