import { Box, Button, Fade, Flex, Grid, Image, Text, useDisclosure } from "@chakra-ui/react"
import { CheckmarkCircleIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import React, { useState } from "react"

import { SelectedProduct } from "./AssociatedProductsControl"
import { FormButtons } from "common/components"
// import { CheckmarkCircleIcon, DefaultImageIcon } from "@iac/components.icons.react"

type Props = {
  onClose: () => void
  onDone: () => void
  products: SelectedProduct[]
  deleteProducts: (ids: string[]) => void
}

const SelectedProducts = ({ onClose, onDone, products, deleteProducts }: Props) => {
  const { t } = useTranslation()
  const [productsToDeleteIds, setProductsToDeleteIds] = useState<string[]>([])
  const { isOpen, onOpen, onClose: onHideButton } = useDisclosure()

  return (
    <Flex direction="column" width="100%">
      <Text color="info.01" fontSize="14px">
        {t("inventory.product.associatedProducts.clickOnAssociatedProductToDelete")}
      </Text>
      <Flex maxH="250px" mb="14px" mt="12px" overflowY="scroll" wrap="wrap">
        {products.map((product) => (
          <Box key={product.id} mb="10px" mr="18px" position="relative">
            {productsToDeleteIds.includes(product.id) && (
              <CheckmarkCircleIcon color="danger.01" height="14px " position="absolute" right="2px" top="2px" />
            )}
            <Grid
              _hover={{ borderColor: "info-01" }}
              alignItems="center"
              border="1px solid #CBCBCB"
              borderRadius="5px"
              boxShadow="0px 1px 2px rgba(55, 99, 122, 0.2)"
              boxSize="100px"
              data-testid="products-to-delete-line"
              gridTemplateRows="2fr 1fr"
              justifyItems="center"
              onClick={() =>
                setProductsToDeleteIds((ids) =>
                  ids.includes(product.id) ? ids.filter((id) => id !== product.id) : [...ids, product.id]
                )
              }
            >
              {product.images?.length ? (
                <Image borderRadius="5px" boxSize="48px" src={product.images[0].thumbnailUrl} />
              ) : (
                <CheckmarkCircleIcon />
                // <DefaultImageIcon fontSize={50} />
              )}
              <Text fontSize="10px">{product.name.length > 15 ? product.name.slice(0, 15) + "..." : product.name}</Text>
            </Grid>
          </Box>
        ))}
      </Flex>
      <Flex justify="space-between" width="100%">
        <FormButtons
          buttonTestIdSuffix="-associated-modal"
          cancel={() => onClose()}
          done={() => {
            onDone()
            onClose()
          }}
          padding="0"
        />
        <Fade in={isOpen} transition={{ exit: { duration: 0.2 }, enter: { duration: 0 } }}>
          <Button
            _hover={{ backgroundColor: "#56C568" }}
            backgroundColor="#56C568"
            color="white"
            data-testid="associated-products-deleted-button"
            mr="16px"
            width="90px"
          >
            {t("inventory.product.associatedProducts.deleted")}
          </Button>
        </Fade>
        {!!productsToDeleteIds.length && (
          <Button
            _hover={{ backgroundColor: "HoverDanger" }}
            backgroundColor="danger.01"
            color="white"
            data-testid="delete-products-button"
            mr="16px"
            width="90px"
            onClick={() => {
              deleteProducts(productsToDeleteIds)
              onOpen()
              setProductsToDeleteIds([])
              setTimeout(() => onHideButton(), 500)
            }}
          >
            {t("delete")}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export { SelectedProducts }
