import { Flex } from "@chakra-ui/react"
import { EditIcon, RemoveIcon } from "@iac/components.icons.react"
import { Dispatch, FC, SetStateAction, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useTable } from "react-table"

import { ShipmentMethod } from "api"
import { Pagination, Table } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE } from "common/constants"
import { route } from "features/app/routes"

type ShipmentMethodsTableProps = {
  loading: boolean
  onOpen: () => void
  shipmentMethods: ShipmentMethod[]
  setShipmentMethod: Dispatch<SetStateAction<ShipmentMethod>>
}

const ShipmentMethodsTable: FC<ShipmentMethodsTableProps> = ({
  setShipmentMethod,
  shipmentMethods,
  onOpen,
  loading,
}) => {
  const navigate = useNavigate()
  const columnData: Column<ShipmentMethod>[] = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      id: "buttons",
      // @ts-ignore
      Cell: ({ row }) => (
        <Flex align="center" justify="flex-end">
          <EditIcon
            _hover={{ cursor: "pointer", transform: "scale(1.3)" }}
            color="#007abd"
            fontSize={20}
            transition=".3s"
            onClick={() => navigate(`${route.shipment.path}/${row.original.id}`)}
          />
          <RemoveIcon
            _hover={{ cursor: "pointer", transform: "scale(1.3)" }}
            color="black"
            marginLeft="1.5rem"
            transition=".3s"
            onClick={() => {
              setShipmentMethod(row.original)
              onOpen()
            }}
          />
        </Flex>
      ),
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, []),
      data: useMemo(() => shipmentMethods, [shipmentMethods]),
    },
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<ShipmentMethod>): React.ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} data-testid="suppliersTable" width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR bg="white" color="#36495E" fontSize="lg" {...headerGroup.getHeaderGroupProps()}>
            <Table.TH width="50%">Name</Table.TH>
            <Table.TH width="40%">Description</Table.TH>
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()} textAlign="left">
        {loading ? (
          <Table.Skeleton columnWidths={[32, 16, 32, 64]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              setPageSize={() => {}}
              totalPages={pageOptions.length}
              onPageChange={gotoPage}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { ShipmentMethodsTable }
