import { Input, Select } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, MutableRefObject, useEffect } from "react"
import { useForm } from "react-hook-form"

import { useValidationSchemes } from "../utils/validationSchemеs"
import { api } from "api"
import { ProductAPI } from "api/generated/models/ProductAPI"
import { FormField } from "common/components"
import { COUNTRY_ID } from "common/constants"
import { formStyles } from "common/theme"

type TaxInfoProps = {
  productAPI: ProductAPI
  onIsValidChange: (isValid: boolean) => void
  getValuesRef: MutableRefObject<() => Partial<ProductAPI>>
}

type ProductFormValues = {
  hsCode: string
  sellingTaxCategoryId: string
}

const TaxInfo: FC<TaxInfoProps> = ({ productAPI, getValuesRef, onIsValidChange }) => {
  const { t } = useTranslation()
  const { taxInfoSchema } = useValidationSchemes()

  const { data: taxCategories } = api.useTaxCategories(COUNTRY_ID)

  const {
    formState: { errors, isValid },
    getValues,
    register,
    reset,
    setValue,
  } = useForm<ProductFormValues>({
    resolver: zodResolver(taxInfoSchema),
    mode: "all",
    defaultValues: { hsCode: productAPI.hsCode, sellingTaxCategoryId: productAPI.sellingTaxCategoryId },
  })
  const formFieldProps = { errors, register }

  useEffect(() => {
    getValuesRef.current = () => getValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    onIsValidChange(isValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])
  useEffect(() => {
    if (productAPI.sellingTaxCategoryId)
      setValue("sellingTaxCategoryId", productAPI.sellingTaxCategoryId, { shouldValidate: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productAPI.sellingTaxCategoryId])

  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxCategories])

  const selectComponent = (
    <>
      <option value="" disabled>
        {t("product.placeholder.select")}
      </option>
      {taxCategories?.value?.map((taxCategory) => (
        <option key={taxCategory.id} value={taxCategory.id}>
          {taxCategory.name}
        </option>
      ))}
    </>
  )

  return (
    <>
      <FormField<ProductFormValues>
        label={t("product.hsCode")}
        name="hsCode"
        {...formFieldProps}
        stackProps={formStyles.control}
      >
        <Input data-testid="product-hs-code-field" placeholder={t("product.placeholder.customHSCode")} />
      </FormField>
      <FormField<ProductFormValues>
        label={t("product.salesTaxCategory")}
        name="sellingTaxCategoryId"
        isRequired
        {...formFieldProps}
        stackProps={{ ...formStyles.control, marginBottom: 2 }}
      >
        <Select data-testid="product-tax-category-select">{selectComponent}</Select>
      </FormField>
    </>
  )
}

export { TaxInfo }
