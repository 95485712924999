import { Flex, GridItem, Text } from "@chakra-ui/react"
import { FC } from "react"

import { Order } from "api"
import { symbolPosition } from "common/utils/currency.utils"

type InShopInfoProps = {
  order: Order
}

export const InShopInfo: FC<InShopInfoProps> = ({ order }) => (
  <GridItem
    data-testid="inShopInfo"
    style={{
      fontSize: 30,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: 50,
      letterSpacing: 1.5,
    }}
  >
    <Flex justify="space-between" mb={2}>
      <Text>Subtotal</Text>
      <Text data-testid="subtotal">{symbolPosition(order.subtotal)}</Text>
    </Flex>
    <Flex justify="space-between" mb={2}>
      <Text>IVA</Text>
      <Text data-testid="tax">{symbolPosition(order.tax)}</Text>
    </Flex>
    <Flex justify="space-between" mb={2}>
      <Text>Discount</Text>
      <Text data-testid="discount">{symbolPosition(order.discount)}</Text>
    </Flex>
    <Flex justify="space-between" mb={2}>
      <Text>Total</Text>
      <Text data-testid="total">{symbolPosition(order.total)}</Text>
    </Flex>
    <Flex justify="space-between" mb={2}>
      <Text>Deposit</Text>
      <Text data-testid="deposit">{symbolPosition(order.deposit)}</Text>
    </Flex>
    <Flex justify="space-between" mb={2}>
      <Text>Remaining</Text>
      <Text data-testid="remaining">{symbolPosition(Number((order.total - order.deposit).toFixed(2)))}</Text>
    </Flex>
  </GridItem>
)
