import { MenuItem, Tag, Text } from "@chakra-ui/react"
// import { CloseCircleIcon, PrintIcon } from "@iac/components.icons.react"
import { CloseCircleIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { format, parseISO } from "date-fns"
import { Dispatch, FC, ReactNode, SetStateAction, useContext, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useTable } from "react-table"

import { Order, SalesOrderStatus, StoreFront, api } from "api"
import { NotImplemented, Pagination, Table, TableItemMenu } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { ProductsContext } from "features/inShop/product/ProductState/context"

type CustomerOrdersTableProps = {
  loading: boolean
  onOpen: () => void
  orders: Order[]
  refetch: (top: number, skip: number) => void
  setOrder: Dispatch<SetStateAction<Order>>
  pageCount: number
  itemsPerPage: number
}

const CustomerOrdersTable: FC<CustomerOrdersTableProps> = ({
  loading,
  orders,
  setOrder,
  onOpen,
  refetch,
  pageCount,
  itemsPerPage,
}) => {
  const formatDate = "dd/MM/yyyy kk:MM"
  const {
    state: {
      globalEntities: {
        tenancyCurrency: {
          currency: { symbol },
        },
      },
    },
  } = useContext(ProductsContext)
  const navigate = useNavigate()
  const { data: storeFronts } = api.useStoreFronts(TENANCY_ID)
  const { t } = useTranslation()

  const NameCell: FC<{ order: Order }> = ({ order }) => (
    <TableItemMenu
      // editClick={() => navigate(`/sales/customers/${order.customerId}/${order.id}`)}
      editClick={() => {}}
      isShowDelete={false}
      value={String(order.orderNumber)}
      onClick={() => navigate(`salesOrder/${order.id}`)}
    >
      <NotImplemented>
        {/*<MenuItem icon={<PrintIcon fontSize={20} />}>*/}
        <MenuItem>
          <Text fontSize={16} ml={6}>
            {t("printReceipt")}
          </Text>
        </MenuItem>
      </NotImplemented>
      <MenuItem
        color="danger-01"
        icon={<CloseCircleIcon fontSize={20} />}
        onClick={() => {
          setOrder(order)
          onOpen()
        }}
      >
        <Text fontSize={16} ml={6}>
          {t("cancel")}
        </Text>
      </MenuItem>
    </TableItemMenu>
  )

  const columnData: Column<Order>[] = [
    {
      Header: t("orderNumber") as string,
      accessor: "id",
      Cell: ({ row }) => <NameCell order={row.original} />,
    },
    {
      Header: t("orderDate") as string,
      accessor: "orderDate",
      Cell: ({ row }) => (
        <Text>{row.original.orderDate ? format(parseISO(row.original.orderDate), formatDate) : "N/A"}</Text>
      ),
    },
    {
      Header: t("orderValue") as string,
      accessor: "total",
      Cell: ({ row }) => <Text>{`${row.original.total} ${symbol}`}</Text>,
    },
    {
      Header: t("deliveryDate") as string,
      accessor: "deliveryDate",
      Cell: ({ row }) => (
        <Text>{row.original.deliveryDate ? format(parseISO(row.original.deliveryDate), formatDate) : "N/A"}</Text>
      ),
    },
    {
      Header: t("storeFront") as string,
      accessor: "storeFrontId",
      Cell: ({
        row: {
          original: { storeFrontId },
        },
      }) => {
        const storeFront = storeFronts?.value?.find((item) => item.id === storeFrontId) as StoreFront

        return <Text>{storeFront?.name}</Text>
      },
    },
    {
      Header: t("status_label") as string,
      accessor: "status",
      Cell: ({ row }) => (
        <Tag
          background={
            row.original.status === SalesOrderStatus.PAID
              ? "success.02"
              : row.original.status === SalesOrderStatus.CANCELED
              ? "danger.02"
              : "warning.02"
          }
          borderColor={
            row.original.status === SalesOrderStatus.PAID
              ? "success.03"
              : row.original.status === SalesOrderStatus.CANCELED
              ? "danger.03"
              : "warning.03"
          }
          borderRadius={2}
          color="text.01"
          fontSize={12}
        >
          {t(`salesOrderStatus.${row.original.status}`)}
        </Tag>
      ),
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, [storeFronts, symbol]),
      data: useMemo(() => orders, [orders]),
      initialState: {
        pageSize: itemsPerPage,
      },
      manualPagination: true,
      pageCount,
    },
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<Order>): ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} data-testid="customer-orders-table" width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR bg="white" color="#36495E" fontSize="lg" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Table.TH textTransform="capitalize" {...column.getHeaderProps()}>
                {column.render("Header")}
              </Table.TH>
            ))}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {loading ? (
          <Table.Skeleton columnWidths={[16, 24, 24, 32, 24, 16]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={(event) => {
                setPageSize(event)
                refetch(event, 0)
              }}
              totalPages={pageOptions.length}
              onPageChange={gotoPage}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
    /* eslint-enable react/jsx-key */
  )
}

export { CustomerOrdersTable }
