import { request as __request } from "../core/request"
import { ProductModel } from "../models/ProductModel"
import { trackPromise } from "react-promise-tracker"

export class InShopService {
  /**
   * Search Products.
   * ## Requires:
   * ### searchProducts.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async searchProductsGetAsync({
    tenancyId,
    storeFrontId,
    productName,
    searchByStoreFront,
    filterOptions,
    select,
    expand,
  }: {
    tenancyId: string
    storeFrontId: string
    productName: string
    searchByStoreFront?: boolean
    filterOptions: {
      includeOutOfStock: boolean
      includeInactive: boolean
      isBarcode?: boolean
    }
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<ProductModel>
  }> {
    if (!storeFrontId || !productName) {
      return null as any
    }

    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/search?term=${productName}
        &includeOutOfStock=${filterOptions.includeOutOfStock}&status=${filterOptions.includeInactive ? "" : "active"}${
          filterOptions.isBarcode ? `&isBarcode=${filterOptions.isBarcode}` : ""
        }${searchByStoreFront ? `&storefrontId=${storeFrontId}` : ""}`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }
}
