import { request as __request } from "../core/request";
import { TaxCategory } from "../models/TaxCategory";
import { trackPromise } from "react-promise-tracker";

export class TaxCategoriesService {

  /**
   * Gets all Tax Categories.
   * ## Requires:
   * ### taxCategories.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async taxCategoriesGetAllAsync({
    select,
    expand,
    parentId,
  }: {
    parentId: number,
    /** Limits the properties returned in the result. **/
    select?: string,
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string,
  }): Promise<{
    readonly '@odata.context'?: string,
    readonly value?: Array<TaxCategory>,
  }> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/countries/${parentId}/isa/taxcategories`,
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Gets Tax Category.
   * ## Requires:
   * ### taxCategories.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async taxCategoriesGetAsync({
    id,
    parentId,
    select,
    expand,
  }: {
    parentId: number,
    id: string,
    /** Limits the properties returned in the result. **/
    select?: string,
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string,
  }): Promise<{
    readonly '@odata.context'?: string,
  } & TaxCategory> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/countries/${parentId}/isa/taxcategories/${id}`,
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }
}