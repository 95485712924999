import { Button, Flex, Heading, Textarea, VStack } from "@chakra-ui/react"
import { ChangeEvent, FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { v4 as uuid } from "uuid"

import { initShipmentMethod } from "../ShipmentMethods.view"
import { ShipmentMethod } from "api"
import { CommonFormControl } from "common/components/CommonFormControl/CommonFormControl"
import { route } from "features/app/routes"

const ShipmentMethodsChanges: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [shipmentMethod, setShipmentMethod] = useState<ShipmentMethod>(initShipmentMethod)

  const setValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name, value } = event.currentTarget
    setShipmentMethod({ ...shipmentMethod, [name]: value })
  }

  const changesShipment = (): void => {
    if (params.id) {
      const shipments: ShipmentMethod[] = JSON.parse(localStorage.getItem("shipments") as string)
      const changedMethods = shipments.map((item) => (item.id === params.id ? shipmentMethod : item))
      localStorage.setItem("shipments", JSON.stringify(changedMethods))

      return navigate(route.shipment.path)
    }
    const object = {
      ...shipmentMethod,
      id: uuid(),
    }
    const shipments: ShipmentMethod[] = JSON.parse(localStorage.getItem("shipments") as string)
    localStorage.setItem("shipments", JSON.stringify([...shipments, object]))

    return navigate(route.shipment.path)
  }

  useEffect(() => {
    const { id } = params
    if (id) {
      const shipments: ShipmentMethod[] = JSON.parse(localStorage.getItem("shipments") as string)
      const findShipment = shipments.find((item) => item.id === id)
      findShipment && setShipmentMethod(findShipment)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <VStack align="start" marginBottom="3rem" padding="2rem 1rem 0">
      <Heading color="#36495E" marginBottom="3rem" size="lg">
        {params.id ? "Edit Shipment Method" : "Add New Shipping Method"}
      </Heading>
      <Flex direction="column" width="100%">
        <CommonFormControl label="Name" name="name" value={shipmentMethod.name} onChange={setValue} />
        <CommonFormControl label="Description">
          <Textarea
            name="description"
            placeholder="Write Description"
            value={shipmentMethod.description}
            width="60%"
            onChange={setValue}
          >
            {shipmentMethod.description}
          </Textarea>
        </CommonFormControl>
      </Flex>
      <Flex align="center" justify="space-between" p="0 3rem 0 1rem" width="70%">
        <Button
          borderColor="#007abd"
          color="#007abd"
          variant="outline"
          width={150}
          onClick={() => navigate(route.shipment.path)}
        >
          Cancel
        </Button>
        <Button variant="primary" width={150} onClick={changesShipment}>
          Done
        </Button>
      </Flex>
    </VStack>
  )
}

export { ShipmentMethodsChanges }
