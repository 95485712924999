import { Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react"
import { FC, useContext, useEffect, useMemo, useState } from "react"

import { RefundInfo } from "./RefundInfo/RefundInfo"
import { RefundProductDetails } from "./RefundProductDetails/RefundProductDetails"
import { RefundProductItem } from "./RefundProductItem/RefundProductItem"
import { Types } from "../inShop/product/ProductState/reducers"
import { ProductModel, mockData } from "api"
import { ProductsContext } from "features/inShop/product/ProductState/context"

export const RefundView: FC = () => {
  const { state, dispatch } = useContext(ProductsContext)
  const { order } = state.makeOrder
  const [products, setProducts] = useState(order.orderLines)

  useEffect(() => {
    dispatch({ type: Types.SetOpenRefund, payload: { openRefundProduct: false } })
    dispatch({ type: Types.SetRefundOrder, payload: { refundOrder: mockData.initOrder } })
    dispatch({ type: Types.SetRefundTotal, payload: { total: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useMemo(() => setProducts(order.orderLines), [order])

  return (
    <Flex flex={1} paddingTop="1.25rem">
      <Flex align="center" direction="column" flex={1}>
        <Heading color="#36495E" size="lg">
          Refund
        </Heading>
        <Grid
          //align="center"
          style={{
            background: "transparent",
            padding: "8px 30px 8px 15px",
            color: "#36495E",
            fontWeight: "bold",
            fontSize: 24,
            width: "100%",
          }}
          templateColumns="4fr 1fr 1fr 1fr 1fr"
        >
          <GridItem paddingLeft="1rem" textAlign="left">
            <Text>Product</Text>
          </GridItem>
          <GridItem alignItems="center">
            <Text>QTY</Text>
          </GridItem>
          <GridItem>
            <Text>Paid</Text>
          </GridItem>
          <GridItem>
            <Text>Remaining</Text>
          </GridItem>
          <GridItem>
            <Text>Due</Text>
          </GridItem>
        </Grid>
        <Flex align="flex-start" direction="column" flex={1} width="100%">
          {products.map((item: ProductModel) => (
            <RefundProductItem key={item.id} object={item} />
          ))}
        </Flex>
        <RefundInfo />
      </Flex>
      {state.bool.openRefundProduct && <RefundProductDetails />}
    </Flex>
  )
}
