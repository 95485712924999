import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

import { MenuLayout } from "common/components"
import { SuppliersIcon } from "common/theme"
import { route } from "features/app/routes"

const PurchasesView: FC = () => {
  const { t } = useTranslation()

  const cards = [
    { link: route.suppliers.path, title: t("supplier"), icon: SuppliersIcon },
    // { link: route.purchaseOrder.path, title: "Purchase Order", icon: PurchaseOrderIcon },
    // { link: route.supplierCredit.path, title: "Supplier Credit", icon: CreditIcon },
  ]

  return <MenuLayout cards={cards} pageTitle={t("purchase")} />
}

export { PurchasesView }
