/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class HealthService {

    /**
     * Get Health
     * Provides details on the health of the API
     * @returns string Success
     * @throws ApiError
     */
    public static async healthGet(): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/api/health`,
            errors: {
                503: `Service Unavailable`,
            },
        });
        return result.body;
    }

}