import { cond } from "ramda"

import { isProvider } from "../customers/utils"
import { Admin, AdminStatuses, Entity, EntityTypes, TenancyLink } from "api"
import { AdminRoles } from "api/generated/models/AdminRoles"
import { CustomerType, toCustomerType } from "features/customers"

export type AdminType = "owner" | "tenancy" | "partner" | "external"

export type AdminDetails = Admin & {
  owner?: Entity
  isSuggested?: boolean
  isNew?: boolean
  isExternal?: boolean
  status?: AdminStatuses
  avatarUrl?: string
  tenancyLinks?: TenancyLink[]
}

export const emptyAdmin: Admin = {
  id: undefined,
  role: undefined,
  name: "",
  email: "",
}

export const emptyAdminDetails: AdminDetails = {
  ...emptyAdmin,
  isNew: true,
  owner: undefined,
}

export const adminRoles = Object.values(AdminRoles)

export const customerSpecificRole: { [customerType in CustomerType]: AdminRoles } = {
  root: AdminRoles.IACADMINISTRATOR,
  distributor: AdminRoles.DISTRIBUTOR_ADMINISTRATOR,
  partner: AdminRoles.PARTNER_ADMINISTRATOR,
  consumer: AdminRoles.CUSTOMER_ADMINISTRATOR,
}

const customerSpecificRoles = Object.values(customerSpecificRole)

export const generalRoles = adminRoles.filter((role) => !customerSpecificRoles.includes(role))

export const isCustomerSpecificRole = (role: AdminRoles) => !generalRoles.includes(role)

export const adminRolesForCustomer = (customerType: CustomerType | EntityTypes): AdminRoles[] => {
  const customerRoles =
    customerSpecificRole[customerType as CustomerType] ??
    customerSpecificRole[toCustomerType(customerType) as CustomerType]

  return [customerRoles, ...generalRoles]
}

export const adminRolesForAdmin = (admin?: Admin, entity?: Entity | null) => {
  if (!admin || !entity) return []
  const isDefaultTenancy = true // always true as we're only supporting 1 entity to 1 tenancy for now
  const isEntityAdmin = admin.entityId === entity?.id
  const allowPartnerManagerRole = isDefaultTenancy && isEntityAdmin && isProvider(entity)

  return adminRolesForCustomer(entity?.entityType as EntityTypes).filter((role) =>
    role === AdminRoles.PARTNER_MANAGER ? allowPartnerManagerRole : true
  )
}

export const updateAdminRolesForCustomer = (customerType: CustomerType, admins: AdminDetails[]): AdminDetails[] =>
  admins.map((admin) => {
    const role = cond([
      [
        () => admin.role === "PartnerManager",
        () => (customerType === "consumer" ? "CustomerAdministrator" : admin.role) as AdminRoles,
      ],
      [() => isCustomerSpecificRole(admin.role as AdminRoles), () => customerSpecificRole[customerType]],
      [() => true, () => admin.role],
    ])() as AdminRoles

    return { ...admin, role }
  })
