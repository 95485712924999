import { Flex, IconButton, IconButtonProps, Select, Stack, StackProps, Text } from "@chakra-ui/react"
import { CaretDownSolidIcon, ChevronLeftIcon, ChevronRightIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { clamp, range } from "ramda"
import { FC } from "react"

import { DEFAULT_ITEMS_PER_PAGE, ITEMS_PER_PAGE_SCALES } from "common/constants"

export type PaginationProps = StackProps & {
  currentPage: number
  totalPages: number
  variant?: string
  pageSize?: number
  rowCount?: number
  setPageSize: (pageSize: number) => void
  onPageChange: (newPage: number) => void
}

const buttonStyles = (type: "next" | "previous", isActive: boolean): Partial<IconButtonProps> => ({
  isDisabled: !isActive,
  size: "md",
  transition: "unset",
  variant: "ghost",
  icon: type === "next" ? <ChevronRightIcon /> : <ChevronLeftIcon />,
})

export const Pagination: FC<PaginationProps> = ({
  variant,
  currentPage,
  totalPages,
  pageSize = DEFAULT_ITEMS_PER_PAGE,
  rowCount = 0,
  setPageSize,
  onPageChange,
  ...stackProps
}) => {
  const { t } = useTranslation()

  const hasPreviousPage = currentPage > 0
  const hasNextPage = currentPage < totalPages - 1

  const clamped = clamp(0, totalPages - 1)

  const rowMin = currentPage * pageSize + 1
  const rowMax = Math.min((currentPage + 1) * pageSize, rowCount)

  const borderedStyle = {
    borderLeftWidth: "1px",
    borderLeftColor: "ui.02",
    borderStyle: "solid",
  }

  return (
    <Flex alignItems="stretch" bg="transparent" color="text.01" direction="row" {...stackProps} height="tableRow">
      <Flex alignItems="center" px={4}>
        {t("pagination.itemsPerPage")}
        <Select
          height="auto"
          icon={<CaretDownSolidIcon />}
          size="sm"
          value={pageSize}
          variant="plain"
          width="fit-content"
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
        >
          {ITEMS_PER_PAGE_SCALES.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex alignItems="center" {...borderedStyle} flexGrow={1} px={4}>
        {rowCount > 0 && (
          <>
            {rowMin}-{rowMax} of {rowCount} items
          </>
        )}
      </Flex>
      <Flex alignItems="center" {...borderedStyle} direction="row" px={4}>
        <Select
          height="auto"
          icon={<CaretDownSolidIcon />}
          size="sm"
          value={currentPage}
          variant="plain"
          width="fit-content"
          onChange={({ target }) => onPageChange(clamped(parseInt(target.value, 10)))}
        >
          {range(0, totalPages).map((pageNumber) => (
            <option key={pageNumber} value={pageNumber}>
              {pageNumber + 1}
            </option>
          ))}
        </Select>
        <Text whiteSpace="nowrap">{t("pagination.ofTotal", { count: totalPages })}</Text>
      </Flex>
      <Stack align="center" direction="row" px={1} spacing={1} {...borderedStyle}>
        <IconButton
          {...buttonStyles("previous", hasPreviousPage)}
          aria-label={t("previous")}
          onClick={() => onPageChange(clamped(currentPage - 1))}
        />
        <IconButton
          {...buttonStyles("next", hasNextPage)}
          aria-label={t("next")}
          onClick={() => onPageChange(clamped(currentPage + 1))}
        />
      </Stack>
    </Flex>
  )
}
