import { Tag, Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, ReactNode, SetStateAction, useContext, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useTable } from "react-table"

import { Category, Country, Supplier, api } from "api"
import { Pagination, Table } from "common/components"
import { TableItemMenu } from "common/components/TableItemMenu/TableItemMenu"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { ProductsContext } from "features/inShop/product/ProductState/context"

type SuppliersTableProps = {
  loading: boolean
  onOpen: () => void
  suppliers: Supplier[]
  refetch: (top: number, skip: number) => void
  setSupplier: Dispatch<SetStateAction<Supplier>>
  itemsPerPage: number
}

const SuppliersTable: FC<SuppliersTableProps> = ({
  suppliers,
  setSupplier,
  loading,
  onOpen,
  refetch,
  itemsPerPage,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    state: {
      globalEntities: { countries },
    },
  } = useContext(ProductsContext)

  const { data: productCategories } = api.useProductCategories(TENANCY_ID)

  const NameCell: FC<{ supplier: Supplier }> = ({ supplier }) => (
    <TableItemMenu
      deleteClick={() => {
        setSupplier(supplier)
        onOpen()
      }}
      editClick={() => navigate(supplier.id)}
      value={supplier.name}
      width="60%"
    />
  )

  const columnData: Column<Supplier>[] = [
    {
      Header: t("name") as string,
      accessor: "name",
      Cell: ({ row }) => <NameCell supplier={row.original} />,
    },
    {
      Header: t("companyInfo.companyName") as string,
      accessor: "companyName",
    },
    {
      Header: t("country") as string,
      accessor: "countryId",
      Cell: ({ row }) => {
        const country = countries.find((item) => item?.id?.toString() === row.original.countryId.toString()) as Country

        return <Text>{country?.name}</Text>
      },
    },
    {
      Header: t("default_product_category") as string,
      accessor: "defaultProductCategoryId",
      Cell: ({ row }) => {
        const category = productCategories?.value?.find(
          (item) => item.id === row.original.defaultProductCategoryId
        ) as Category

        return <Text>{category?.name || ""}</Text>
      },
    },
    {
      Header: t("pending_orders") as string,
    },
    {
      Header: t("status_label") as string,
      accessor: "status",
      Cell: ({ row }) => (
        <Tag
          background={row.original.status === "Active" ? "success.02" : "warning.02"}
          borderColor={row.original.status === "Active" ? "success.03" : "warning.03"}
          borderRadius={2}
          color="text.01"
          fontSize={12}
        >
          {t(`statuses.${row.original.status}`)}
        </Tag>
      ),
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setPageSize,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, [productCategories, countries]),
      data: useMemo(() => suppliers, [suppliers]),
      initialState: {
        pageSize: itemsPerPage,
      },
    },
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<Supplier>): ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} data-testid="suppliersTable" width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR bg="white" color="#36495E" fontSize="lg" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Table.TH {...column.getHeaderProps()} textTransform="initial">
                {column.render("Header")}
              </Table.TH>
            ))}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()} textAlign="left">
        {loading ? (
          <Table.Skeleton columnWidths={[32, 16, 32, 64]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={(event) => {
                setPageSize(event)
                refetch(event, 0)
              }}
              totalPages={pageOptions.length}
              onPageChange={gotoPage}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { SuppliersTable }
