import { Flex, Switch, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { whereEq } from "ramda"
import { FC, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { LanguageChangesModal, ModalType } from "./LanguageChangesModal"
import { TenancyLanguage, api, mockData } from "api"
import { FormButtons } from "common/components"
import { CommonFormControl } from "common/components/CommonFormControl/CommonFormControl"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { TENANCY_ID } from "common/constants"
import { setSystemLanguageLocale } from "common/systemLanguageStorage"
import { route } from "features/app/routes"

const LanguageChanges: FC = () => {
  const params = useParams()
  const { t, i18n } = useTranslation()

  const navigate = useNavigate()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [name, setName] = useState<string | undefined>("")
  const [modalType, setModalType] = useState<ModalType>(ModalType.isDefault)
  const [tenancyLanguage, setTenancyLanguage] = useState<TenancyLanguage>(mockData.initTenancyLanguage)
  const { data: tenancyLanguages } = api.useTenancyLanguages(TENANCY_ID, { expand: "language" })
  const initialLanguage = useMemo(
    () =>
      tenancyLanguages?.value
        ? (tenancyLanguages?.value?.find((item) => item.languageId === params.id) as TenancyLanguage)
        : mockData.initTenancyLanguage,
    [params.id, tenancyLanguages]
  )
  const updateTenancyLanguage = api.useUpdateTenancyLanguageMutation(
    {
      onSuccess: (language) => {
        if (language.isSystem) {
          const locale = initialLanguage.language.locale
          i18n.changeLanguage(locale)
          setSystemLanguageLocale(locale)
        }
        navigate(route.languages.path)
      },
    },
    TENANCY_ID,
    params.id || ""
  )

  const editLanguage = (): void => {
    const object = {
      isSystem: tenancyLanguage.isSystem,
      isEnabled: tenancyLanguage.isEnabled,
      isDefault: tenancyLanguage.isDefault,
    }
    params.id && updateTenancyLanguage.mutate(object)
  }

  useEffect(() => {
    setTenancyLanguage(initialLanguage)
    setName(initialLanguage?.language?.name)
  }, [initialLanguage])

  const openModal = (type: ModalType) => {
    setModalType(type)
    onOpen()
  }

  const setOption = (value: boolean) =>
    modalType === ModalType.isDefault
      ? setTenancyLanguage({ ...tenancyLanguage, isDefault: value })
      : setTenancyLanguage({ ...tenancyLanguage, isSystem: value })

  return (
    <>
      <PageHeader title={params.id ? t("editItem", { name }) : t("addNewLanguage")} />
      <Flex direction="column" pl={20} pr={4} py={4} width="100%">
        <CommonFormControl label={t("languages.languageChanges.enableLanguage")}>
          <Switch
            data-testid="language-enable-button"
            isChecked={tenancyLanguage.isEnabled || tenancyLanguage.isSystem || tenancyLanguage.isDefault}
            isDisabled={initialLanguage.isSystem || initialLanguage.isDefault}
            onChange={(event) => setTenancyLanguage({ ...tenancyLanguage, isEnabled: event.currentTarget.checked })}
          />
        </CommonFormControl>
        <CommonFormControl label={t("languages.languageChanges.makeSystemLanguage")}>
          <Switch
            data-testid="make-system-language-button"
            isChecked={tenancyLanguage.isSystem}
            isDisabled={initialLanguage.isSystem}
            onChange={(event) =>
              event.currentTarget.checked
                ? openModal(ModalType.isSystem)
                : setTenancyLanguage({ ...tenancyLanguage, isSystem: event.currentTarget.checked })
            }
          />
        </CommonFormControl>
        <CommonFormControl label={t("languages.languageChanges.makeDefaultLanguage")}>
          <Switch
            data-testid="make-default-language-button"
            isChecked={tenancyLanguage.isDefault}
            isDisabled={initialLanguage.isDefault}
            onChange={(event) =>
              event.currentTarget.checked
                ? openModal(ModalType.isDefault)
                : setTenancyLanguage({ ...tenancyLanguage, isDefault: event.currentTarget.checked })
            }
          />
        </CommonFormControl>

        <FormButtons
          cancel={() => navigate(route.languages.path)}
          disabled={whereEq({ ...initialLanguage })(tenancyLanguage)}
          done={editLanguage}
        />
      </Flex>
      <LanguageChangesModal
        isOpen={isOpen}
        modalType={modalType}
        name={tenancyLanguage?.language?.name}
        onClose={onClose}
        onSubmit={setOption}
      />
    </>
  )
}

export { LanguageChanges }
