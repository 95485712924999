import { FormControl, FormLabel, Select } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { ChangeEvent, FC } from "react"

type FormStringSelectProps = {
  label: string
  name: string | null
  selectWidth?: string
  controlWidth?: string
  value?: string | number | null
  data?: { id: string | number; name: string | null | undefined }[]
  stringData?: string[]
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
}

export const FormStringSelect: FC<FormStringSelectProps> = ({
  label,
  controlWidth = "60%",
  selectWidth = "60%",
  name = "",
  value,
  onChange,
  data,
  stringData,
}) => {
  const { t } = useTranslation()

  const formStyles = {
    control: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      width: controlWidth,
      marginBottom: 20,
    },
    label: {
      marginRight: 0,
      marginBottom: 8,
    },
  }

  return (
    <FormControl style={{ ...formStyles.control, flexDirection: "column" }}>
      <FormLabel style={formStyles.label}>{label}</FormLabel>
      <Select
        background="white"
        name={name || ""}
        placeholder={t("select")}
        value={value || ""}
        width={selectWidth}
        onChange={onChange}
      >
        {stringData
          ? stringData.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))
          : data?.map(({ name, id }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
      </Select>
    </FormControl>
  )
}
