import { produce } from "immer"
import { createContext, useContext } from "react"

import { commands } from "./commands"
import { Command } from "features/commands"

export type CommandDispatcher = (action: CommandStateAction) => void

export type Action = ({ dispatch }: { dispatch: CommandDispatcher }) => void

export type Mode = "command" | "subcommand" | "input"

export type AckbarState = {
  mode: Mode
  input: string
  instruction: string
}

export type CommandState = AckbarState & {
  commands: Command[]
}

export const initState: CommandState = {
  mode: "command",
  input: "",
  instruction: "",
  commands,
}

export const commandReducer = (state: CommandState, action: CommandStateAction) => {
  const handlers: { [key in typeof action.type]: () => CommandState } = {
    ResetState: () => produce(state, () => initState),
    ResetCommands: () =>
      produce(state, (draft) => {
        draft.commands = initState.commands
      }),
    SetState: () => produce(state, (draft) => ({ ...draft, ...(action as SetState).state })),
    SetInput: () =>
      produce(state, (draft) => {
        draft.input = (action as SetInput).input
      }),
    SetCommands: () =>
      produce(state, (draft) => {
        draft.commands = (action as SetCommands).commands
      }),
  }

  return handlers[action.type]()
}

type CommandContextType = {
  state: CommandState
  dispatch: CommandDispatcher
}

const CommandContext = createContext<CommandContextType | null>(null)

const useCommandState = (): CommandContextType | null => useContext(CommandContext)

type ResetState = { type: "ResetState" }
type ResetCommands = { type: "ResetCommands" }
type SetState = { type: "SetState"; state: Partial<CommandState> }
type SetInput = { type: "SetInput"; input: string }
type SetCommands = { type: "SetCommands"; commands: Command[] }

type CommandStateAction = ResetState | ResetCommands | SetState | SetInput | SetCommands

export type { CommandContextType, CommandStateAction }

export { useCommandState, CommandContext }
