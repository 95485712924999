import { Box, BoxProps, useTheme } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, ReactNode } from "react"

export type Status = "Accepted" | "Pending" | "Rejected" | "Error"

const useGetIcon = () => {
  const { colors } = useTheme()

  const icons: { [key in Status]: ReactNode } = {
    Accepted: (
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.90062 11.1651L11.713 6.35276L10.2373 4.87706L6.90062 8.21374L5.35276 6.66588L3.87706 8.14158L6.90062 11.1651Z"
        fill={colors.success["01"]}
        fillRule="evenodd"
      />
    ),
    Pending: (
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9.04348 8.61126V4.12422H6.95652V9.4757L9.49817 12.0174L10.9739 10.5417L9.04348 8.61126Z"
        fill={colors.gray[500]}
        fillRule="evenodd"
      />
    ),
    Rejected: (
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.52174 9.04348H11.4783V6.95652H4.52174V9.04348Z"
        fill={colors.error["01"]}
        fillRule="evenodd"
      />
    ),
    Error: (
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9.74662 11.1726L8.04969 9.4757L6.35276 11.1726L4.87706 9.69693L6.57399 8L4.87706 6.30307L6.35276 4.82737L8.04969 6.5243L9.74662 4.82737L11.2223 6.30307L9.52539 8L11.2223 9.69693L9.74662 11.1726Z"
        fill={colors.error["01"]}
        fillRule="evenodd"
      />
    ),
  }

  return { icons }
}

type Props = BoxProps & {
  status: Status
  size?: BoxProps["width"]
}

export const StatusIcon: FC<Props> = ({ status, size = 1, ...boxProps }) => {
  const { t } = useTranslation()
  const { icons } = useGetIcon()

  return (
    <Box bg="white" borderRadius="100%" width={size} {...boxProps} data-status={status} data-testid="status-icon">
      <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <title>{t("invitation", { status: t(`statusIcon.${status}`) })}</title>
        {icons[status]}
      </svg>
    </Box>
  )
}
