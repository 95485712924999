import React from "react"

import { RouteConfigObject } from "./routes"
import { PurchaseOrderView, SupplierChange, SupplierCreditView, SuppliersView } from "../purchases"

export const purchasesRoutes: RouteConfigObject = {
  purchaseOrder: {
    path: "/purchase/purchaseOrder",
    name: "purchase.purchaseOrder",
    element: <PurchaseOrderView />,
  },
  supplierCredit: {
    path: "/purchase/supplierCredit",
    name: "purchase.supplierCredit",
    element: <SupplierCreditView />,
  },
  suppliers: {
    path: "/purchase/suppliers",
    name: "purchase.suppliers",
    element: <SuppliersView />,
  },
  addSupplier: {
    path: "/purchase/suppliers/addSupplier",
    name: "purchase.addSupplier",
    element: <SupplierChange />,
  },
  editSupplier: {
    path: "/purchase/suppliers/:id",
    name: "purchase.editSupplier",
    element: <SupplierChange />,
  },
}
