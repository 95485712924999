/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Tenancy } from '../models/Tenancy';
import type { TenancyMetadata } from '../models/TenancyMetadata';
import { request as __request } from '../core/request';

export class TenanciesService {

    /**
     * Gets all Tenancies.
     * ## Requires:
     * ### tenancies.Read
     * @returns any Success
     * @throws ApiError
     */
    public static async tenanciesGetAllAsync({
        select,
        expand,
        filter,
        orderby,
        top,
        skip,
        count = false,
    }: {
        /** Limits the properties returned in the result. **/
        select?: string,
        /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
        expand?: string,
        /** Restricts the set of items returned. For the allowed functions please refer to the Bifrost Swagger document https://bifrost.iamcloud.net/api/swagger/index.html **/
        filter?: string,
        /** Specifies the order in which items are returned. The maximum number of expressions is 5. **/
        orderby?: string,
        /** Limits the number of items returned from a collection. **/
        top?: number,
        /** Excludes the specified number of items of the queried collection from the result. **/
        skip?: number,
        /** Indicates whether the total count of items within a collection are returned in the result. **/
        count?: boolean,
    }): Promise<{
        readonly '@odata.context'?: string,
        readonly '@odata.count'?: number,
        readonly value?: Array<Tenancy>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/tenancies`,
            query: {
                '$select': select,
                '$expand': expand,
                '$filter': filter,
                '$orderby': orderby,
                '$top': top,
                '$skip': skip,
                '$count': count,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Create Tenancy.
     * ## Requires:
     * ### tenancies.Create
     * @returns any Created
     * @throws ApiError
     */
    public static async tenanciesPostTenancyAsync({
        requestBody,
    }: {
        requestBody?: Tenancy,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/tenancies`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get Tenancy.
     * ## Requires:
     * ### tenancies.Read in root permissions.
     * @returns any Success
     * @throws ApiError
     */
    public static async tenanciesGetAsync({
        id,
        select,
        expand,
    }: {
        id: string,
        /** Limits the properties returned in the result. **/
        select?: string,
        /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
        expand?: string,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Tenancy)> {
        const result = await __request({
            method: 'GET',
            path: `/api/tenancies/${id}`,
            query: {
                '$select': select,
                '$expand': expand,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update Tenancy.
     * ## Requires:
     * ### tenancies.Update
     * @returns any Success
     * @throws ApiError
     */
    public static async tenanciesPatchAsync({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: Tenancy,
    }): Promise<any> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/tenancies/${id}`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Gets the Tenancy metadata.
     * @returns any Success
     * @throws ApiError
     */
    public static async tenanciesGetMetadataAsync({
        id,
    }: {
        id: string,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & TenancyMetadata)> {
        const result = await __request({
            method: 'GET',
            path: `/api/tenancies/${id}/metadata`,
            errors: {
                404: `Not Found`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}