import { Variant } from "../generated"

export const initVariant: Variant = {
  id: "",
  attributes: [],
  receiptDisplayName: "",
  sku: "",
  supplierSku: "",
  supplierBarcode: "",
  image: "",
  stock: "",
  availableStockPerStoreFront: [],
  retailPrice: [],
  status: "",
  notes: "",
}
