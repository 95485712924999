import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js"
import { FC, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"

import { AuthError } from "./AuthError"
import { SetupAdmin } from "./SetupAdmin/SetupAdmin"
import { clearTenancyLinksAccepted, tenancyLinksAccepted } from "./SetupAdmin/storage.utils"
import { Loading } from "common/components"
import { useAuth } from "features/auth"

const LoginCallback: FC = () => {
  const { isAuthenticated, isLoading, user, error, signoutRedirect, signinSilent } = useAuth()
  const appInsights = useAppInsightsContext()
  const trackLogin = useTrackEvent(appInsights, "Iris: Login", {})
  const [isRetrying, setIsRetrying] = useState(false)

  /**
   * Some notes to the next developer that comes here hunting auth bugs:
   * querySessionStatus fires off a load more requests, but errors are
   * still just a string of the stack clearStaleState makes no difference
   * Everything I try to do only gives us a string of the stack.
   *
   * Adding an error boundary didn't work, I think because they won't catch
   * async errors (network requests).
   *
   * We need to treat all errors the same, whether it's 500 or 404, because
   * all we get is a string of the stack, no way to determine what the error was
   * events.addSilentRenewError doesn't actually get called when a silent renew
   * error happens
   */

  useEffect(() => {
    trackLogin(true)
  }, [trackLogin])

  const handleRetry = () => {
    setIsRetrying(true)
    signinSilent({
      onError: () => setIsRetrying(false),
      onSuccess: () => setIsRetrying(false),
    })
  }

  const handleSignout = () => {
    signoutRedirect()
  }

  // `isLoading` stays true when we have an error from `useAuth`.
  // To give the user some feedback, we will show this fake loading
  // spinner whilst retrying for a few seconds
  if (isRetrying) {
    return <Loading />
  }

  if (error) {
    return <AuthError onRetry={handleRetry} onSignout={handleSignout} />
  }

  if (isLoading || !isAuthenticated) {
    return <Loading />
  }

  // check in session storage whether tenancy links have been accepted
  return tenancyLinksAccepted() ? (
    <Navigate to={typeof user?.state === "string" ? user?.state : "/"} replace />
  ) : (
    <SetupAdmin />
  )
}

const LogoutCallback: FC = () => {
  const { isLoading, isAuthenticated } = useAuth()
  const appInsights = useAppInsightsContext()
  const trackLogout = useTrackEvent(appInsights, "Iris: Logout", {})

  useEffect(() => {
    trackLogout(true)
  }, [trackLogout])

  clearTenancyLinksAccepted()

  return isLoading || isAuthenticated ? <Loading /> : <Navigate to="/" />
}

const SilentRefreshCallback: FC = () => {
  const appInsights = useAppInsightsContext()
  const trackExpiry = useTrackEvent(appInsights, "Iris: Auth token expired", {})

  useEffect(() => {
    trackExpiry(true)
  }, [trackExpiry])

  return null
}

// Decision Document: https://dev.azure.com/iacplatform/IAM%20Cloud%20Core%20Platform/_wiki/wikis/Core%20UI/97/0002-handling-ids-errors
const SilentLogoutCallback: FC = () => null

export { LoginCallback, LogoutCallback, SilentRefreshCallback, SilentLogoutCallback }
