import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react"
import { FC, useContext, useEffect, useState } from "react"

import { Order, mockData } from "api"
import { symbolPosition } from "common/utils/currency.utils"
import { generateOrder } from "features/inShop/inShopUtils"
import { ProductsContext } from "features/inShop/product/ProductState/context"

type PaymentType = {
  type: string
}

const Payment: FC<PaymentType> = ({ type }) => {
  const { state } = useContext(ProductsContext)
  const [order, setOrder] = useState<Order>(mockData.initOrder)

  const infoStyles = {
    marginBottom: "1rem",
    alignItems: "center",
    justifyContent: "space-between",
  }

  const generation = (order: Order): Order => {
    const generatedOrder = generateOrder(order.orderLines, order, state)
    const info = {
      subtotal: generatedOrder.subtotal,
      tax: generatedOrder.tax,
      discount: generatedOrder.discount,
      total: generatedOrder.total,
      deposit: generatedOrder.deposit,
      remaining: Number((generatedOrder.total - generatedOrder.deposit).toFixed(2)),
    }

    return { ...order, ...info }
  }

  useEffect(() => {
    switch (type) {
      case "exchange":
        const exchangeOrder = generation(state.exchange.exchangeOrder)

        return setOrder(exchangeOrder)
      case "remaining":
        const remainingOrder = generation(state.remaining.remainingOrder)

        return setOrder(remainingOrder)
      case "refund":
        const refundOrder = generation(state.refund.refundOrder)

        return setOrder(refundOrder)
      default:
        return setOrder(mockData.initOrder)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  return (
    <GridItem background="complimentary.blue" color="white" display="flex" flexDirection="column">
      <Flex direction="column" flex={1} fontSize={22} fontWeight="bold" padding="2rem">
        {state.makeOrder.order.orderLines.map((item) => (
          <Grid key={item.id} mb={3} templateColumns="4fr 2fr 2fr">
            <GridItem>{item.name}</GridItem>
            <GridItem>{item.quantity}</GridItem>
            <GridItem textAlign="right">
              {symbolPosition(Number((item.quantity * item.retailPrice).toFixed(2)))}
            </GridItem>
          </Grid>
        ))}
      </Flex>
      <Box fontSize={25} fontWeight="bold" padding="0 0 3rem 2rem" width="90%">
        <Flex style={infoStyles}>
          <Text>Subtotal</Text>
          <Text>{symbolPosition(order.subtotal)}</Text>
        </Flex>
        <Flex style={infoStyles}>
          <Text>IVA</Text>
          <Text>{symbolPosition(order.tax)}</Text>
        </Flex>
        <Flex style={infoStyles}>
          <Text>Discount</Text>
          <Text>{symbolPosition(order.discount)}</Text>
        </Flex>
        <Flex style={infoStyles}>
          <Text>Total</Text>
          <Text>{symbolPosition(order.total)}</Text>
        </Flex>
        <Flex style={infoStyles}>
          <Text>Deposit</Text>
          <Text>{symbolPosition(order.deposit)}</Text>
        </Flex>
        <Flex style={infoStyles}>
          <Text>Remaining</Text>
          <Text>{symbolPosition(Number((order.total - order.deposit).toFixed(2)))}</Text>
        </Flex>
      </Box>
    </GridItem>
  )
}

export { Payment }
