import { Button, SimpleGrid } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { AnimateSharedLayout, motion } from "framer-motion"
import { FC } from "react"

import { AddCustomerAdminCard } from "./AddCustomerAdminCard"
import { MAX_ADMIN_COUNT, useAddCustomerActions, useAddCustomerState } from "./AddCustomerContext"
import { SectionHeader } from "common/components"
import { AdminCardPlaceholder } from "features/customers"

const AnimatedGrid = motion(SimpleGrid)

export const AddCustomerAdmins: FC = () => {
  const { t } = useTranslation()
  const state = useAddCustomerState()
  const actions = useAddCustomerActions()

  const adminCount = state.admins.length > 0 ? ` (${state.admins.length}/${MAX_ADMIN_COUNT})` : ""

  const addAdminButton = (
    <Button
      children={`${t("Add new admin")}${adminCount}`}
      isDisabled={!state.canAddAdmin}
      variant="tertiary"
      onClick={actions.addNewAdmin}
    />
  )

  return (
    <SectionHeader header={addAdminButton} title={`${state.customerTypeLabel} ${t("admin_other")}`}>
      <AnimateSharedLayout>
        <AnimatedGrid gap={4} templateColumns="repeat(auto-fill, minmax(340px, 1fr))" layout>
          {state.admins.map((admin) => (
            <motion.div key={admin.id} layout>
              <AddCustomerAdminCard
                admin={admin}
                editStatus={state.adminToEdit?.id === admin.id ? "editing-this" : "read-only"}
              />
            </motion.div>
          ))}
          {state.adminToEdit?.id === "" && (
            <motion.div key="newAdmin" layout>
              <AddCustomerAdminCard admin={state.adminToEdit!} editStatus="editing-this" />
            </motion.div>
          )}
          <motion.div key="placeholder" layout>
            <AdminCardPlaceholder
              hasAdmins={state.admins.length > 0 || state.adminToEdit !== null}
              isDisabled={!state.canAddAdmin}
              onClick={actions.addNewAdmin}
            />
          </motion.div>
        </AnimatedGrid>
      </AnimateSharedLayout>
    </SectionHeader>
  )
}
