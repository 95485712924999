import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { ApiError, Order, SalesOrdersService } from "../generated"
import { ODataOptions } from "../handmade"

export const useSalesOrders = (tenancyId: UUID, options?: ODataOptions, customerId?: UUID) => {
  const filter = customerId ? `customerId eq ${customerId}` : undefined

  return useQuery(
    ["salesOrders", options],
    () => SalesOrdersService.salesOrderGetAllAsync({ tenancyId, ...options, filter }),
    {
      enabled: !!tenancyId,
    }
  )
}

export const useSalesOrder = (tenancyId: UUID, id: UUID, options?: ODataOptions) =>
  useQuery(
    ["salesOrders", tenancyId, id, options],
    () => SalesOrdersService.salesOrderGetAsync({ tenancyId, id, ...options }),
    {
      enabled: !!tenancyId || !!id,
    }
  )

export const useCreateSalesOrderMutation = (
  { onSuccess }: { onSuccess: (data: Partial<Order>) => void },
  { onError }: { onError: (error: ApiError) => void },
  tenancyId: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (order: {
      customerId?: string
      storeFrontId: string
      currencyId: string
      orderDate: string
      status: string
      subtotal: number
      tax: number
      deposit: number
      discount: number
      discountType: string
      paymentType: string
      total: number
      orderLines: {
        productVariantId: string
        subtotal: number
        tax: number
        quantity: number
        discount: number
        deposit: number
        discountType: string
        total: number
        deliveryDate: string
        notes: string
      }[]
    }) => SalesOrdersService.salesOrderPostAsync({ tenancyId, requestBody: order as any }),
    {
      onSuccess: (data: Order) => {
        queryClient.invalidateQueries(["salesOrders"])
        onSuccess(data)
      },
      onError: (error: ApiError) => onError(error),
    }
  )
}

export const useUpdateSalesOrderMutation = ({ onSuccess }: { onSuccess: () => void }, tenancyId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (order: { orderLines: { id: UUID; notes: string }[] }) =>
      SalesOrdersService.salesOrderPatchAsync({
        tenancyId,
        id,
        requestBody: order as any,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["salesOrders"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useDeleteSalesOrderMutation = ({ onSuccess }: { onSuccess: () => void }, tenancyId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(() => SalesOrdersService.salesOrderDeleteAsync({ tenancyId, id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["salesOrders"])
      onSuccess()
    },
    onError: () => {},
  })
}
