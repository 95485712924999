import { Box, Flex, Input, Stack, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as z from "zod"

import { TenancyLanguage, VariantTypeOption, api, mockData } from "api"
import { CommonFormControl, FormButtons, FormField, PageHeader } from "common/components"
import { TENANCY_ID } from "common/constants"
import { formStyles } from "common/theme"
import { numbersNlettersNspecialCharacters } from "common/validation"
import { route } from "features/app/routes"

type FormValues = {
  name: string
  description: string
  variantOptionValues: string
}

const VariantOptionChanges: FC = () => {
  const params = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const [option, setOption] = useState<string>("")
  const [options, setOptions] = useState<string[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [variantOption, setVariantOption] = useState<VariantTypeOption>(mockData.initVariantOption)
  const { data } = api.useVariantOption(TENANCY_ID, params.id || "")
  const { data: tenancyLanguages } = api.useTenancyLanguages(TENANCY_ID)
  const { data: optionValues } = api.useVariantOptionValues(TENANCY_ID, params.id || "")
  const createVariantOption = api.useCreateVariantOptionMutation(
    { onSuccess: () => navigate(route.variantOptions.path) },
    TENANCY_ID
  )

  const updateVariantOption = api.useUpdateVariantOptionMutation(
    { onSuccess: () => navigate(route.variantOptions.path) },
    TENANCY_ID,
    params.id || ""
  )

  const schema = z.object({
    name: z
      .string()
      .max(15)
      .nonempty({ message: t("validation.required", { field: "name" }) })
      .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") }),
    description: z
      .string()
      .max(200)
      .nonempty({ message: t("validation.required", { field: "description" }) })
      .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") }),
  })

  const {
    formState: { errors, isDirty, isValid },
    getValues,
    register,
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: "all",
    defaultValues: {
      name: "",
      description: "",
    },
  })

  const isInvalid = !isDirty || !isValid
  const formFieldProps = { errors, register }

  const variantOptionChanges = (): void => {
    const formValues = getValues()
    const defaultLanguage = tenancyLanguages?.value?.find(
      (item: TenancyLanguage) => item.isDefault && item.isEnabled
    ) as TenancyLanguage
    const values = options.map((item) => ({
      translations: [
        {
          languageId: defaultLanguage.languageId,
          value: item,
        },
      ],
    }))
    const option = {
      variantOptionValues: !params.id ? values : undefined,
      translations: [
        {
          languageId: defaultLanguage.languageId,
          value: JSON.stringify({
            Name: formValues.name,
            Description: formValues.description,
          }),
        },
      ],
    }
    params.id ? updateVariantOption.mutate(option) : createVariantOption.mutate(option)
  }

  useEffect(() => {
    if (data && optionValues && params.id) {
      setVariantOption(data)
      setName(data.name)
      const options = optionValues?.value?.map((item) => item.value) as string[]
      setOptions(options)
      setValue("name", data.name)
      setValue("description", data.description)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, params.id, optionValues])

  return (
    <>
      <PageHeader title={params.id ? `Edit ${name}` : t("variant_option.add_new_option")} />
      <Flex direction="column" pl={20} pr={4} width="100%">
        <FormField<FormValues>
          label={t("variant_option.option_name")}
          name="name"
          isRequired
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Input
            data-testid="option-name-field"
            maxLength={15}
            placeholder={t("variant_option.placeholder.option_name")}
          />
        </FormField>
        <FormField<FormValues>
          label={t("description")}
          name="description"
          isRequired
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Input data-testid="option-description-field" maxLength={200} placeholder={t("description_placeholder")} />
        </FormField>
        <CommonFormControl controlWidth="40%" label={t("variant_option.option_values")}>
          <Box flex={1} pos="relative" width="100%">
            <Input
              data-testid="enter-option-values-field"
              disabled={!!params.id}
              name="options"
              placeholder={t("variant_option.placeholder.option_values")}
              value={option}
              onChange={(event) => setOption(event.currentTarget.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setOptions([...options, event.currentTarget.value])
                  setOption("")
                }
              }}
            />
            <Box pos="absolute" right=".5rem" top={0}>
              <Stack direction="row" display="flex" flexWrap="wrap" pos="relative" spacing={2} shouldWrapChildren>
                {options.map((item) => (
                  <Tag key={item} my={2}>
                    <TagLabel>{item}</TagLabel>
                    <TagCloseButton
                      onClick={() => !params.id && setOptions(options.filter((option) => option !== item))}
                    />
                  </Tag>
                ))}
              </Stack>
            </Box>
          </Box>
        </CommonFormControl>

        <FormButtons
          cancel={() => navigate(route.variantOptions.path)}
          disabled={isInvalid}
          done={variantOptionChanges}
        />
      </Flex>
    </>
  )
}

export { VariantOptionChanges }
