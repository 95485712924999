import { Box, Image } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, ReactNode, SetStateAction, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useTable } from "react-table"

import { VariantOptionValue } from "api"
import { Pagination, Table } from "common/components"
import { TableItemMenu } from "common/components/TableItemMenu/TableItemMenu"
import { DEFAULT_ITEMS_PER_PAGE } from "common/constants"
import { DefaultImageIcon } from "common/theme"
import { route } from "features/app/routes"

type OptionValuesTableProps = {
  onOpen: () => void
  optionValues: VariantOptionValue[]
  isLoading?: boolean
  refetch: (top: number, skip: number) => void
  setOptionValue: Dispatch<SetStateAction<VariantOptionValue>>
  itemsPerPage: number
}

const OptionValuesTable: FC<OptionValuesTableProps> = ({
  onOpen,
  refetch,
  optionValues,
  isLoading,
  setOptionValue,
  itemsPerPage,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const ValueCell: FC<{ value: VariantOptionValue }> = ({ value }) => (
    <TableItemMenu
      deleteClick={() => {
        setOptionValue(value)
        onOpen()
      }}
      editClick={() =>
        navigate(`${route.variantOptions.path}/${value.variantOptionId}/variantOptionValues/${value.id}`)
      }
      value={value.value}
      width="30%"
    />
  )

  const columnData: Column<VariantOptionValue>[] = [
    {
      Header: t("variant_option.image_or_photo") as string,
      // @ts-ignore
      Cell: ({ row }) =>
        row.original.image ? (
          <Image height="40px" src={row.original.image.thumbnailUrl} width="40px" />
        ) : row.original.color ? (
          <Box background={row.original.color} height="40px" width="40px" />
        ) : (
          <DefaultImageIcon fontSize={40} />
        ),
    },
    {
      Header: t("name") as string,
      accessor: "value",
      Cell: ({ row }) => <ValueCell value={row.original} />,
    },
    {
      Header: t("status_label") as string,
      // accessor: "status",
      // Cell: ({ row }) => (
      //   <Tag fontSize={12} style={row.original.status === "Active" ? statusStyles.active : statusStyles.inactive}>
      //     {row.original.status}
      //   </Tag>
      // ),
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setPageSize,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, []),
      data: useMemo(() => optionValues, [optionValues]),
      initialState: {
        pageSize: itemsPerPage,
      },
    },
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<VariantOptionValue>): ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()} _hover={{ background: "#e3dcde" }}>
        {row.cells.map((cell) => (
          <Table.TD {...cell.getCellProps()} _first={{ padding: "0.25rem 2rem" }} textAlign="left">
            {cell.render("Cell")}
          </Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR
            bg="white"
            color="#36495E"
            fontSize="lg"
            textTransform="capitalize"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => (
              <Table.TH
                _first={{ width: "40%" }}
                _last={{ width: "10%", textAlign: "left" }}
                textTransform="none"
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </Table.TH>
            ))}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()} textAlign="center">
        {isLoading ? (
          <Table.Skeleton columnWidths={[32, 16, 32, 64]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={(event) => {
                setPageSize(event)
                refetch(event, 0)
              }}
              totalPages={pageOptions.length}
              onPageChange={gotoPage}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { OptionValuesTable }
