import { AuthProviderProps } from "react-oidc-context"

import { REACT_APP_OIDC_AUTHORITY, REACT_APP_OIDC_CLIENT_ID, REACT_APP_OIDC_SCOPE } from "environment"

const origin = window.location.origin

const OIDC_SESSION_STORAGE_KEY = `oidc.user:${REACT_APP_OIDC_AUTHORITY}:${REACT_APP_OIDC_CLIENT_ID}`

const OIDC_PATH = {
  LOGIN_CALLBACK: "auth-callback",
  LOGOUT_CALLBACK: "logout",
  SILENT_REFRESH: "silent-refresh",
  SILENT_REFRESH_CALLBACK: "silent-refresh",
}

const AUTH_PROVIDER_CONFIG: AuthProviderProps = {
  authority: REACT_APP_OIDC_AUTHORITY,
  client_id: REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: `${origin}/${OIDC_PATH.LOGIN_CALLBACK}`,
  post_logout_redirect_uri: `${origin}/${OIDC_PATH.LOGOUT_CALLBACK}`,
  silent_redirect_uri: `${origin}/${OIDC_PATH.SILENT_REFRESH}`,
  loadUserInfo: true,
  response_type: "code",
  scope: REACT_APP_OIDC_SCOPE,
}

export { OIDC_SESSION_STORAGE_KEY, AUTH_PROVIDER_CONFIG, OIDC_PATH }
