import { api } from "api"
import { TENANCY_ID } from "common/constants"

const getAssociatedAndLinkedProducts = (productId: string, variantId: string) => {
  const { data: associatedProductsData } = api.useProductAssociatedProducts(TENANCY_ID, productId)
  const { data: crossLinkedProductsData } = api.useProductCrossLinkedProducts(TENANCY_ID, productId)

  const { data: variantAssociatedProductsData } = api.useProductVariantAssociatedProducts(
    TENANCY_ID,
    productId,
    variantId
  )
  const { data: variantCrossLinkedProductsData } = api.useProductVariantCrossLinkedProducts(
    TENANCY_ID,
    productId,
    variantId
  )

  return variantId
    ? { associatedProductsData: variantAssociatedProductsData, crossLinkedProductsData: variantCrossLinkedProductsData }
    : { associatedProductsData, crossLinkedProductsData }
}

export { getAssociatedAndLinkedProducts }
