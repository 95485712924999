const TENANCY_LINKS_ACCEPTED = "tenancyLinksAccepted"

export const tenancyLinksAccepted = () => window.sessionStorage && window.sessionStorage.getItem(TENANCY_LINKS_ACCEPTED)

export const markTenancyLinksAccepted = () =>
  window.sessionStorage && window.sessionStorage.setItem(TENANCY_LINKS_ACCEPTED, "true")

export const clearTenancyLinksAccepted = () =>
  window.sessionStorage &&
  window.sessionStorage.getItem(TENANCY_LINKS_ACCEPTED) &&
  window.sessionStorage.removeItem(TENANCY_LINKS_ACCEPTED)
