import { Button, Flex, Image, Text, useDisclosure } from "@chakra-ui/react"
import { AddIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { ProductVariantsTable } from "./ProductVariantsTable"
import { Category, Country, ProductAPI, ProductVariant, Supplier, UnitOfMeasure, api, mockData } from "api"
import { ConfirmModal, PageHeader, PageLayout } from "common/components"
import { TENANCY_ID } from "common/constants"
import { route } from "features/app/routes"
import { ProductsContext } from "features/inShop/product/ProductState/context"

// eslint-disable-next-line sonarjs/cognitive-complexity
const ProductInfo = () => {
  const params = useParams()
  const navigate = useNavigate()
  const {
    state: {
      globalEntities: {
        tenancyCurrency: {
          currency: { symbol },
        },
      },
    },
  } = useContext(ProductsContext)
  const { t } = useTranslation()
  const { onOpen, isOpen, onClose } = useDisclosure()
  const [search, setSearch] = useState("")
  const [supplier, setSupplier] = useState<Supplier>(mockData.initSupplier)
  const [productVariants, setProductVariants] = useState<ProductVariant[]>()
  const [product, setProduct] = useState<ProductAPI>(mockData.initProductAPI)
  const [productCategoryName, setProductCategoryName] = useState("")
  const [variant, setVariant] = useState<ProductVariant>(mockData.initProductVariant)
  const [countryOfOrigin, setCountryOfOrigin] = useState<Country>(mockData.initCountry)
  const [unitOfMeasure, setUnitOfMeasure] = useState<UnitOfMeasure>(mockData.initUnitOfMeasure)

  const { data: suppliers } = api.useSuppliers(TENANCY_ID)
  const { data: productCategories } = api.useProductCategories(TENANCY_ID)
  const { data: productData } = api.useProduct(TENANCY_ID, params.productId || "")
  const { data: productVariantsData } = api.useProductVariants(TENANCY_ID, params.productId || "")
  const {
    state: {
      globalEntities: { countries },
    },
  } = useContext(ProductsContext)

  const { data: unitOfMeasures } = api.useUnitOfMeasures(TENANCY_ID)
  const deleteVariantMutation = api.useDeleteProductVariantMutation(
    { onSuccess: () => onClose() },
    product.id,
    TENANCY_ID,
    variant.id
  )

  const deleteVariant = (): void => deleteVariantMutation.mutate()

  useEffect(() => {
    if (params.productId && productData) {
      setProduct(productData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData])

  useEffect(() => {
    if (suppliers) {
      const supplier = suppliers?.find((item) => item.id === product.supplierId) as Supplier
      setSupplier(supplier)
    }
  }, [suppliers, product])

  useEffect(() => {
    if (unitOfMeasures?.value) {
      const unitOfMeasure = unitOfMeasures?.value?.find((item) => item.id === product.unitOfMeasureId) as UnitOfMeasure
      setUnitOfMeasure(unitOfMeasure)
    }
  }, [unitOfMeasures, product])

  useEffect(() => {
    if (countries?.length) {
      const country = countries.find((item) => item.id?.toString() === product.countryOfOriginId.toString()) as Country
      setCountryOfOrigin(country)
    }
  }, [countries, product])

  useEffect(() => {
    if (productCategories?.value) {
      const productCategory = productCategories?.value?.find(
        (item) => item.id === product.productCategoryId
      ) as Category
      setProductCategoryName(productCategory?.name)
    }
  }, [productCategories, product])

  useEffect(() => {
    if (productVariantsData?.value) {
      setProductVariants(productVariantsData?.value || [])
    }
  }, [productVariantsData, product])

  useEffect(() => {
    if (search.length === 0) {
      setProductVariants(productVariantsData?.value)
    } else {
      const findVariants = productVariantsData?.value?.filter((item: ProductVariant) =>
        item.receiptDisplayName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      setProductVariants(findVariants)
    }
  }, [search, productVariantsData])

  return (
    <>
      <PageHeader
        buttonTitle={t("editItem", { name: t("product.productInfo") })}
        isAddIconVisible={false}
        link={route.products.path}
        subtitle={product.name}
        title={t("product.product")}
        isArrow
        isEditIconVisible
        onClick={() => navigate(`/inventory/products/${product.id}`)}
      />
      <Flex fontSize={14} pl={20} pr={10} py={4} width="100%">
        <Flex direction="column" width="50%">
          {product.mainImage?.thumbnailUrl && (
            <Flex mb={4}>
              <Image boxSize="140px" src={product.mainImage.thumbnailUrl} />
            </Flex>
          )}
          <Flex align="center" mb={4}>
            <Text fontWeight="bold" width="30%">
              {t("price")}
            </Text>
            <Text width="60%">
              {product.retailPrice
                ? `${product.retailPrice.toFixed(2)} ${symbol}`
                : t("product.differentPricePerProductVariant")}
            </Text>
          </Flex>
          <Flex align="center" mb={4}>
            <Text fontWeight="bold" width="30%">
              {t("product.totalStock")}
            </Text>
            <Text width="60%">{product.totalStock}</Text>
          </Flex>
          <Flex align="center" mb={6}>
            <Text fontWeight="bold" width="30%">
              {t("product.inventoryType")}
            </Text>
            <Text width="60%">{product.inventoryType}</Text>
          </Flex>
        </Flex>
        <Flex direction="column" width="50%">
          <Flex align="center" mb={4}>
            <Text fontWeight="bold" width="30%">
              {t("product.shortName")}
            </Text>
            <Text width="60%">{product.shortName}</Text>
          </Flex>
          <Flex align="center" mb={4}>
            <Text fontWeight="bold" width="30%">
              {t("product.description")}
            </Text>
            <Text width="60%">{product.description}</Text>
          </Flex>
          <Flex align="center" mb={4}>
            <Text fontWeight="bold" width="30%">
              {t("product.supplier")}
            </Text>
            <Text width="60%">{supplier?.name}</Text>
          </Flex>
          <Flex align="center" mb={4}>
            <Text fontWeight="bold" width="30%">
              {t("product.supplierSKU")}
            </Text>
            <Text width="60%">{product.supplierSku}</Text>
          </Flex>
          <Flex align="center" mb={4}>
            <Text fontWeight="bold" width="30%">
              {t("product.countryOfOrigin")}
            </Text>
            <Text width="60%">{countryOfOrigin?.name}</Text>
          </Flex>
          <Flex align="center" mb={4}>
            <Text fontWeight="bold" width="30%">
              {t("product.category")}
            </Text>
            <Text width="60%">{productCategoryName}</Text>
          </Flex>
          <Flex align="center" mb={4}>
            <Text fontWeight="bold" width="30%">
              {t("product.unitOfMeasure")}
            </Text>
            <Text width="60%">{unitOfMeasure?.name}</Text>
          </Flex>
        </Flex>
      </Flex>
      <PageLayout
        confirmModal={
          <ConfirmModal
            isOpen={isOpen}
            name={variant.receiptDisplayName || "this"}
            type="variant"
            isDelete
            onClick={deleteVariant}
            onClose={onClose}
          />
        }
        setSearch={setSearch}
        table={
          <ProductVariantsTable
            setVariant={setVariant}
            symbol={symbol || ""}
            variants={productVariants || []}
            onOpen={onOpen}
          />
        }
      />
      <Flex pl={20} pr={10} py={4}>
        <Button data-testid="add-variant-button" variant="tertiary" onClick={() => navigate("addVariant")}>
          <AddIcon border="1px solid white" borderRadius="100%" mr={3} /> {t("product.add", { name: t("variant") })}
        </Button>
        <Button
          data-testid="go-to-stock-page-button"
          display="none"
          ml={6}
          variant="tertiary"
          onClick={() => navigate(`${route.productStockPage.path}/${product.id}`)}
        >
          Go to stock page &gt;
        </Button>
      </Flex>
    </>
  )
}

export { ProductInfo }
