import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

import { AutoAccept } from "./AutoAccept"
import { ErrorSplash } from "../ErrorSplash"
import { Loading } from "common/components"
import { useAuth } from "features/auth"

const SetupAdmin: FC = () => {
  const { t } = useTranslation()
  const { signinSilent, signoutRedirect, tenancyLinks, isLoading } = useAuth()

  if (isLoading) {
    return <Loading />
  }

  return tenancyLinks.length ? (
    <AutoAccept tenancyLinks={tenancyLinks} />
  ) : (
    <ErrorSplash
      emitRetry={signinSilent}
      emitSignout={signoutRedirect}
      errorMessage={t("You do not appear to have any linked tenancies.")}
    />
  )
}

export { SetupAdmin }
