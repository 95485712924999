import { Box, forwardRef } from "@chakra-ui/react"
import { FormRichSelect, FormRichSelectProps } from "@iac/components.form"
import { useTranslation } from "@iac/translations.i18n-instance"
import React, { useMemo } from "react"

import { api } from "api"

export const FormCountrySelect = forwardRef<Omit<FormRichSelectProps, "options">, "div">((props, ref) => {
  const { t } = useTranslation()
  const { data: countriesData } = api.useSupportedCountries({ top: 500 })
  const countriesSelectOptions = useMemo(
    () =>
      countriesData?.value?.map((c) => ({
        label: c.name || "",
        value: c.id || "",
        icon: c.flagUrl ?? undefined,
      })) ?? [],
    [countriesData]
  )

  return (
    <Box data-testid="country-select" ref={ref} width="100%">
      <FormRichSelect
        {...props}
        filterPlaceholder="Filter Countries"
        modalTitle="Select your Country"
        options={countriesSelectOptions}
        placeholder={t("select")}
        isWithIcons
      />
    </Box>
  )
})
