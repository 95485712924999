import { SearchIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react"
// import { CalendarIcon } from "common/theme"
import { format } from "date-fns"
import { ChangeEvent, FC, useContext, useEffect, useState } from "react"
// import DayPickerInput from "react-day-picker/DayPickerInput"

import { ProductModel, mockData } from "api"
import { InventoryTypes } from "common/inventoryTypes"
import { globalCurrency, symbolPosition } from "common/utils/currency.utils"
import { generateOrder } from "features/inShop/inShopUtils"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

type AddProductModalProps = {
  isOpen: boolean
  onClose: () => void
}

const AddProductModal: FC<AddProductModalProps> = ({ isOpen, onClose }) => {
  const { state, dispatch } = useContext(ProductsContext)
  // const { generateOrder } = useProductFunctionsContext()
  const [searchingProduct, setSearchingProduct] = useState("")
  const [chooseProduct, setChooseProduct] = useState<ProductModel>({
    ...mockData.initProduct,
    deliveryDate: format(Date.now(), "MM-dd-yyyy"),
  })
  const [typeDiscount, setTypeDiscount] = useState(mockData.initProduct.discountType)
  const [result, setResult] = useState<ProductModel[]>([])
  // const formatDate = "MM/dd/yyyy"
  const discountTypes = [
    { name: globalCurrency.localName, value: globalCurrency.symbol },
    { name: "percent", value: "%" },
  ]

  const formControlStyles = {
    formControl: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      margin: "5px 0",
    },
    label: {
      marginBottom: 0,
      fontSize: 22,
    },
    input: {
      maxWidth: "40%",
      borderRadius: 5,
      fontSize: 22,
    },
  }

  const addProduct = (): void => {
    const prod = {
      ...chooseProduct,
      count: +chooseProduct.quantity,
      discount: +chooseProduct.discount,
      deposit: +chooseProduct.deposit,
      typeDiscount,
    }
    const exchangeOrder = {
      ...state.exchange.exchangeOrder,
      products: [...state.exchange.exchangeOrder.orderLines, prod],
    }
    const makeOrder = generateOrder([...state.makeOrder.order.orderLines, prod], mockData.initOrder, state)
    dispatch({ type: Types.SetExchangeOrder, payload: { exchangeOrder } })
    dispatch({ type: Types.SetOrder, payload: { order: makeOrder } })
    onClose()
  }

  const getProducts = (target: EventTarget & HTMLInputElement): void => {
    setSearchingProduct(target.value)

    const results: ProductModel[] = mockData.product.products.filter((item) =>
      item.name.toLocaleLowerCase().includes(target.value.toLocaleLowerCase())
    )

    return setResult(results)
  }

  const addingProduct = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.currentTarget
    setChooseProduct({
      ...chooseProduct,
      [name]: value,
    })
  }

  const choose = (product: ProductModel): void => {
    setChooseProduct(product)
    setTypeDiscount(product.discountType)
    setSearchingProduct("")
  }

  // const chooseDate = (day: Date): void => {
  //   setChooseProduct({
  //     ...chooseProduct,
  //     deliveryDate: format(day, formatDate),
  //   })
  // }

  useEffect(() => {
    if (!isOpen) {
      setChooseProduct(mockData.initProduct)
      setResult([])
      setSearchingProduct("")
    }
  }, [isOpen])

  useEffect(() => {
    if (searchingProduct.length === 0) {
      setResult([])
    }
  }, [searchingProduct])

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} motionPreset="slideInRight" isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="40rem">
        <ModalHeader>Add product</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize={22}>
          <Stack data-testid="productSearch" flex={1} position="relative">
            <InputGroup>
              <InputLeftElement children={<SearchIcon color="#b1aeaf" />} ml={3} pointerEvents="none" />
              <Input
                aria-label="product-input"
                bg="white"
                borderColor="#b1aeaf"
                borderRadius={result.length ? "20px 20px 0 0" : 20}
                pl={50}
                placeholder="Search product name or SKU"
                value={searchingProduct}
                autoFocus
                onChange={({ target }) => getProducts(target)}
              />
            </InputGroup>
            {result.length && (
              <Box
                background="white"
                border="1px solid #b1aeaf"
                position="absolute"
                py={2}
                top="2rem"
                width="100%"
                zIndex={1}
              >
                {result.map((item) => (
                  <Flex
                    key={item.id}
                    _hover={{ background: "#e3dcde", cursor: "pointer" }}
                    px={4}
                    py={2}
                    onClick={() => choose(item)}
                  >
                    {item.name}
                  </Flex>
                ))}
              </Box>
            )}
          </Stack>
          <Flex direction="column" mt={6}>
            <Flex style={{ ...formControlStyles.formControl, marginTop: 0 }}>
              <Text fontWeight="bold">Product:</Text>
              <Text fontWeight="bold">{chooseProduct.name}</Text>
            </Flex>
            <Flex style={{ ...formControlStyles.formControl }}>
              <Text fontWeight="bold">Price:</Text>
              <Text fontWeight="bold">{symbolPosition(chooseProduct.retailPrice)}</Text>
            </Flex>
            <FormControl style={formControlStyles.formControl}>
              <FormLabel style={{ ...formControlStyles.label, fontWeight: "bold" }}>
                Count {chooseProduct.inventoryType === InventoryTypes.Stocked && `(${chooseProduct.stock} available)`} :
              </FormLabel>
              <Input
                name="count"
                style={{ ...formControlStyles.input, textAlign: "right" }}
                value={chooseProduct.quantity}
                onChange={addingProduct}
              />
            </FormControl>
            <FormControl style={formControlStyles.formControl}>
              <FormLabel style={{ ...formControlStyles.label, fontWeight: "bold" }}>Discount:</FormLabel>
              <InputGroup border="1px solid #cac5c6" borderRadius={5} maxWidth="40%" zIndex={0}>
                <InputLeftElement
                  children={
                    <Select
                      style={{
                        background: "#007abd",
                        paddingLeft: 10,
                      }}
                      value={typeDiscount}
                      onChange={(event) => setTypeDiscount(event.currentTarget.value)}
                    >
                      {discountTypes.map(({ name, value }) => (
                        <option key={name} value={name}>
                          {value}
                        </option>
                      ))}
                    </Select>
                  }
                  className="currencies"
                  color="white"
                  width="55px"
                />
                <Input
                  height="2.5rem"
                  name="discount"
                  placeholder="0.00"
                  style={{
                    ...formControlStyles.input,
                    maxWidth: "100%",
                    textAlign: "right",
                    background: "transparent",
                  }}
                  type="number"
                  value={chooseProduct.discount}
                  variant="filled"
                  onChange={addingProduct}
                />
              </InputGroup>
            </FormControl>
            <FormControl style={formControlStyles.formControl}>
              <FormLabel style={{ ...formControlStyles.label, fontWeight: "bold" }}>Deposit:</FormLabel>
              <Input
                name="deposit"
                style={{ ...formControlStyles.input, textAlign: "right" }}
                value={chooseProduct.deposit}
                onChange={addingProduct}
              />
            </FormControl>
            <FormControl style={formControlStyles.formControl}>
              <FormLabel style={{ ...formControlStyles.label, fontWeight: "bold" }}>Delivery Date:</FormLabel>
              <InputGroup style={formControlStyles.input}>
                <InputLeftElement
                  // children={<CalendarIcon color="#656464" />}
                  fontSize={24}
                  pointerEvents="none"
                  zIndex={0}
                />
                {/*<DayPickerInput*/}
                {/*  format={formatDate}*/}
                {/*  placeholder="MM/DD/YYYY"*/}
                {/*  style={{*/}
                {/*    border: "1px solid #b1aeaf",*/}
                {/*    borderRadius: 5,*/}
                {/*  }}*/}
                {/*  value={chooseProduct.deliveryDate}*/}
                {/*  onDayChange={chooseDate}*/}
                {/*/>*/}
              </InputGroup>
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button fontSize={18} height="2rem" variant="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button
            style={{
              height: "2em",
              fontSize: 18,
              marginLeft: 20,
              background: "#b656464",
              color: "white",
              borderRadius: 10,
            }}
            onClick={addProduct}
          >
            Add product
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export { AddProductModal }
