import { Button, ComponentWithAs, Heading, IconProps, SimpleGrid, Stack, Text } from "@chakra-ui/react"
import { LiftShiftIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

import { NotImplemented } from "common/components"
import {
  FavoriteIcon,
  InventoryIcon,
  OrdersIcon,
  PurchasesIcon,
  SalesIcon,
  SettingsIcon,
  SynchronizeIcon,
} from "common/theme"
import { route } from "features/app/routes"

const AppMenuCatalogue: FC<{
  goToRoute: (route: string) => () => void
  activeMenu: "inShop" | "main"
}> = ({ goToRoute, activeMenu }) => {
  const { t } = useTranslation()

  const MainCatalogue = (
    <Stack spacing={6}>
      <Heading as="h4" color="text.01" size="md">
        {t("Catalogue")}
      </Heading>
      <SimpleGrid columns={3} gap={2} overflow="visible">
        <AppMenuCatalogueCard
          buttonTestId="inventory-bttn"
          Icon={InventoryIcon}
          title={t("inventory_title")}
          onClick={goToRoute(route.inventory.path)}
        />
        <AppMenuCatalogueCard
          buttonTestId="sales-bttn"
          Icon={SalesIcon}
          title={t("sale_other")}
          onClick={goToRoute(route.sales.path)}
        />
        <AppMenuCatalogueCard
          buttonTestId="purchases-bttn"
          Icon={PurchasesIcon}
          title={t("purchase")}
          onClick={goToRoute(route.purchases.path)}
        />
        <NotImplemented>
          <AppMenuCatalogueCard
            buttonTestId="integration-bttn"
            Icon={LiftShiftIcon}
            title={t("integration")}
            onClick={goToRoute("#lift-and-shift")}
          />
        </NotImplemented>
        <AppMenuCatalogueCard
          buttonTestId="settings-bttn"
          Icon={SettingsIcon}
          title={t("settings_string")}
          onClick={goToRoute(route.settings.path)}
        />
      </SimpleGrid>
    </Stack>
  )

  const InShopCatalogue = (
    <NotImplemented>
      <Stack spacing={6}>
        <Heading as="h4" color="text.01">
          {t("Catalogue")}
        </Heading>
        <SimpleGrid columns={3} gap={2} overflow="visible">
          <AppMenuCatalogueCard Icon={OrdersIcon} title={t("orders")} onClick={goToRoute("#orders")} />
          <AppMenuCatalogueCard Icon={FavoriteIcon} title={t("favourites")} onClick={goToRoute("favourites")} />
          <AppMenuCatalogueCard Icon={SynchronizeIcon} title={t("synchronize")} onClick={goToRoute("#synchronize")} />
        </SimpleGrid>
      </Stack>
    </NotImplemented>
  )

  return activeMenu === "main" ? MainCatalogue : InShopCatalogue
}

type AppMenuCatalogueCardProps = {
  title: string
  Icon: ComponentWithAs<"svg", IconProps>
  onClick: () => void
  buttonTestId?: string
}

const AppMenuCatalogueCard: FC<AppMenuCatalogueCardProps> = ({ title, Icon, onClick, buttonTestId }) => (
  <Stack
    _hover={{ boxShadow: "link-box", cursor: "pointer" }}
    align="center"
    background="ui.01"
    borderRadius={4}
    boxShadow="link-box"
    boxSize="91px"
    color="link.01"
    justify="center"
    onClick={onClick}
  >
    <Icon boxSize="30px" />
    <Button color="text.01" data-testid={buttonTestId} variant="link">
      <Text
        fontSize="xs"
        fontWeight="normal"
        lineHeight={3}
        overflowWrap="break-word"
        textAlign="center"
        whiteSpace="break-spaces"
      >
        {title}
      </Text>
    </Button>
  </Stack>
)

export { AppMenuCatalogue }
