import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { TenancyCurrenciesService } from "../generated"
import { ODataOptions } from "../handmade"

export const useTenancyCurrencies = (parentId: UUID, options?: ODataOptions) =>
  useQuery(["tenancyCurrencies"], () => TenancyCurrenciesService.tenancyCurrenciesGetAllAsync({ parentId, ...options }))

export const useTenancyCurrency = (parentId: UUID, id: UUID, options: ODataOptions) =>
  useQuery(["tenancyCurrencies", id], () =>
    TenancyCurrenciesService.tenancyCurrenciesGetAsync({ parentId, id, ...options })
  )

export const useCreateTenancyCurrencyMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (tenancyCurrency: { rate?: number; isDefault: boolean; currencyId: string }) =>
      TenancyCurrenciesService.tenancyCurrenciesPostAsync({ parentId, requestBody: tenancyCurrency as any }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tenancyCurrencies"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useUpdateTenancyCurrencyMutation = (
  { onSuccess }: { onSuccess: () => void },
  parentId: UUID,
  id: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (tenancyCurrency: { rate?: number; isDefault: boolean }) =>
      TenancyCurrenciesService.tenancyCurrenciesPatchAsync({ parentId, id, requestBody: tenancyCurrency as any }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tenancyCurrencies"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useDeleteTenancyCurrencyMutation = (
  { onSuccess }: { onSuccess: () => void },
  parentId: UUID,
  id: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(() => TenancyCurrenciesService.tenancyCurrenciesDeleteAsync({ parentId, id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["tenancyCurrencies"])
      onSuccess()
    },
    onError: () => {},
  })
}
