import { Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react"
import { FC, useContext, useEffect, useMemo, useState } from "react"

import { RemainingProductItem } from "./RemainigProductItem/RemainingProductItem"
import { RemainingInfo } from "./RemainingInfo/RemainingInfo"
import { RemainingProductDetails } from "./RemainingProductDetails/RemainingProductDetails"
import { ProductModel } from "api"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

const RemainingView: FC = () => {
  const { state, dispatch } = useContext(ProductsContext)
  const { order } = state.makeOrder
  const [products, setProducts] = useState(order.orderLines)

  useEffect(() => {
    dispatch({ type: Types.SetOpenRemaining, payload: { openRemainingProduct: false } })
    setProducts(state.remaining.remainingOrder.orderLines)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useMemo(() => setProducts(order.orderLines), [order])

  return (
    <Flex flex={1} paddingTop="1.25rem">
      <Flex align="center" direction="column" flex={1}>
        <Heading color="#36495E" size="lg">
          Deposit
        </Heading>
        <Grid
          //align="center"
          style={{
            background: "transparent",
            padding: "8px 30px 8px 15px",
            color: "#36495E",
            fontWeight: "bold",
            fontSize: 24,
            width: "100%",
          }}
          templateColumns="4fr 1fr 1fr 1fr 1fr"
        >
          <GridItem paddingLeft="1rem" textAlign="left">
            <Text>Product</Text>
          </GridItem>
          <GridItem alignItems="center">
            <Text>QTY</Text>
          </GridItem>
          <GridItem>
            <Text>Paid</Text>
          </GridItem>
          <GridItem>
            <Text>Remaining</Text>
          </GridItem>
          <GridItem>
            <Text>Due</Text>
          </GridItem>
        </Grid>
        <Flex align="flex-start" direction="column" flex={1} width="100%">
          {products.map((item: ProductModel) => (
            <RemainingProductItem key={item.id} object={item} />
          ))}
        </Flex>
        <RemainingInfo />
      </Flex>
      {state.bool.openRemainingProduct && <RemainingProductDetails />}
    </Flex>
  )
}

export { RemainingView }
