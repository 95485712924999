import { Flex, Tag, Text } from "@chakra-ui/react"
import { DownIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, SetStateAction, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useSortBy, useTable } from "react-table"

import { ProductVariant } from "api"
import { Pagination, Table, TableItemMenu } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"

type ProductVariantsTableProps = {
  symbol: string
  onOpen: () => void
  setVariant: Dispatch<SetStateAction<ProductVariant>>
  variants: ProductVariant[]
}

const PRODUCT_VARIANTS_TABLE = "PRODUCT_VARIANTS_TABLE"

const ProductVariantsTable: FC<ProductVariantsTableProps> = ({ symbol, variants, setVariant, onOpen }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [storedPageSize, setStoredPageSize] = useStoredPageSize(PRODUCT_VARIANTS_TABLE)

  const NameCell: FC<{ variant: ProductVariant }> = ({ variant }) => (
    <TableItemMenu
      deleteClick={() => {
        setVariant(variant)
        onOpen()
      }}
      editClick={() => navigate(variant.id)}
      value={variant.receiptDisplayName}
      width="50%"
    />
  )

  const columnData: Column<ProductVariant>[] = [
    {
      id: "variant",
      Header: t("name") as string,
      accessor: "receiptDisplayName",
      Cell: ({ row }) => <NameCell variant={row.original} />,
    },
    {
      Header: t("product.sku") as string,
      accessor: "sku",
      disableSortBy: true,
    },
    {
      Header: t("product.price") as string,
      disableSortBy: true,
      accessor: "storeFronts",
      Cell: ({ row }) => <Text>{`${row.original.storeFronts[0].retailPrice.toFixed(2)} ${symbol}`}</Text>,
    },
    {
      Header: t("product.stockLevel") as string,
      accessor: "stock",
      disableSortBy: true,
    },
    {
      Header: t("product.status") as string,
      accessor: "status",
      Cell: ({ row }) => (
        <Tag
          background={row.original.status === "Active" ? "success.02" : "warning.02"}
          borderColor={row.original.status === "Active" ? "success.03" : "warning.03"}
          borderRadius={2}
          color="text.01"
          fontSize={12}
        >
          {t(`statuses.${row.original.status}`)}
        </Tag>
      ),
      disableSortBy: true,
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setPageSize,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, [symbol]),
      data: useMemo(() => variants, [variants]),
      initialState: {
        pageSize: storedPageSize ?? DEFAULT_ITEMS_PER_PAGE,
      },
    },
    useSortBy,
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<ProductVariant>): React.ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => {
          if (cell.column.Header === "SKU") {
            return (
              <Table.TD textAlign="left" {...cell.getCellProps()}>
                {cell.render("Cell")}
              </Table.TD>
            )
          }

          return (
            <Table.TD _first={{ textAlign: "left" }} textAlign="right" {...cell.getCellProps()}>
              {cell.render("Cell")}
            </Table.TD>
          )
        })}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR bg="white" color="#36495E" fontSize="lg" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              if (column.Header === "SKU") {
                return (
                  <Table.TH textAlign="left" {...headerGroup.getHeaderGroupProps()}>
                    {column.render("Header")}
                  </Table.TH>
                )
              }

              return (
                <Table.TH
                  textAlign="right"
                  {...column.getHeaderProps()}
                  _first={{ textAlign: "left", width: "25%" }}
                  textTransform="none"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <Flex align="center" justify={column.disableSortBy ? "flex-end" : "flex-start"}>
                    {column.render("Header")}
                    <Text display={column.isSorted ? "block" : "none"} marginLeft="1rem">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <DownIcon />
                        ) : (
                          <DownIcon transform="rotate(180deg)" />
                        )
                      ) : (
                        ""
                      )}
                    </Text>
                  </Flex>
                </Table.TH>
              )
            })}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()} textAlign="left">
        {page.map(renderRow)}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={(size) => {
                setPageSize(size)
                setStoredPageSize(size)
              }}
              totalPages={pageOptions.length}
              onPageChange={gotoPage}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { ProductVariantsTable }
