import { ProductStateAction } from "./context"
import { Country, Order, ProductModel, TenancyCurrency, TenancyLanguage } from "api"

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export enum Types {
  SetOrder = "SET_ORDER",
  SetOrderProduct = "SET_ORDER_PRODUCT",
  SetOrderType = "SET_ORDER_TYPE",
  OpenDetails = "OPEN_DETAILS",
  IsUpdate = "IS_UPDATE",
  IsPayment = "IS_PAYMENT",
  IsPaymentCash = "IS_PAYMENT_CASH",
  HideFields = "HIDE_FIELDS",
  ShowCustomerDetail = "SHOW_CUSTOMER_DETAIL",
  SetRefundOrder = "SET_REFUND",
  SetRefundTotal = "SET_REFUND_TOTAL",
  SetRefundProduct = "SET_REFUND_PRODUCT",
  SetOpenRefund = "SET_OPEN_REFUND",
  SetOpenExchange = "SET_OPEN_EXCHANGE",
  SetOpenRemaining = "SET_OPEN_REMAINING",
  IsOrderChanged = "IS_ORDER_CHANGED",
  SetExchangeOrder = "SET_EXCHANGE_ORDER",
  SetExchangeProduct = "SET_EXCHANGE_PRODUCT",
  SetRemainingOrder = "SET_REMAINING_ORDER",
  SetRemainingProduct = "SET_REMAINING_PRODUCT",
  SetTenancyCurrency = "SET_TENANCY_CURRENCY",
  SetStoreFrontId = "SET_STORE_FRONT_ID",
  SetCountries = "SET_COUNTRIES",
  SetTenancyCurrencies = "SET_TENANCY_CURRENCIES",
  SetTenancyLanguages = "SET_TENANCY_LANGUAGES",
  SetTenancyLanguage = "SET_TENANCY_LANGUAGE",
}

type OrderType = {
  order: Order
  orderProduct: ProductModel
  typeOrder: string
}

type ExchangeOrderType = {
  exchangeOrder: Order
  exchangeProduct: ProductModel
}

type RemainingType = {
  remainingOrder: Order
  remainingProduct: ProductModel
}

type BoolType = {
  openDetails: boolean
  isUpdate: boolean
  isPayment: boolean
  isPaymentCash: boolean
  hideFields: boolean
  showCustomerDetail: boolean
  isOrderChanged: boolean
  openRefundProduct: boolean
  openExchangeProduct: boolean
  openRemainingProduct: boolean
}

type RefundType = {
  refundOrder: Order
  refundOrderProduct: ProductModel
  total: number
}

type GlobalEntitiesType = {
  tenancyCurrency: TenancyCurrency
  tenancyLanguage: TenancyLanguage
  tenancyCurrencies: TenancyCurrency[]
  tenancyLanguages: TenancyLanguage[]
  storeFrontId: string
  countries: Country[]
}

type GlobalEntitiesPayload = {
  [Types.SetTenancyCurrency]: {
    tenancyCurrency: TenancyCurrency
  }
  [Types.SetTenancyLanguage]: {
    tenancyLanguage: TenancyLanguage
  }
  [Types.SetTenancyCurrencies]: {
    tenancyCurrencies: TenancyCurrency[]
  }
  [Types.SetTenancyLanguages]: {
    tenancyLanguages: TenancyLanguage[]
  }
  [Types.SetStoreFrontId]: {
    storeFrontId: string
  }
  [Types.SetCountries]: {
    countries: Country[]
  }
}

type RefundPayload = {
  [Types.SetRefundOrder]: {
    refundOrder: Order
  }
  [Types.SetRefundTotal]: {
    total: number
  }
  [Types.SetRefundProduct]: {
    refundOrderProduct: ProductModel
  }
}

type ExchangePayload = {
  [Types.SetExchangeOrder]: {
    exchangeOrder: Order
  }
  [Types.SetExchangeProduct]: {
    exchangeProduct: ProductModel
  }
}

type RemainingPayload = {
  [Types.SetRemainingOrder]: {
    remainingOrder: Order
  }
  [Types.SetRemainingProduct]: {
    remainingProduct: ProductModel
  }
}

type MakeOrderPayload = {
  [Types.SetOrder]: {
    order: Order
  }
  [Types.SetOrderProduct]: {
    orderProduct: ProductModel
  }
  [Types.SetOrderType]: {
    typeOrder: string
  }
}

type BoolPayload = {
  [Types.OpenDetails]: {
    openDetails: boolean
  }
  [Types.IsUpdate]: {
    isUpdate: boolean
  }
  [Types.IsPayment]: {
    isPayment: boolean
  }
  [Types.IsPaymentCash]: {
    isPaymentCash: boolean
  }
  [Types.HideFields]: {
    hideFields: boolean
  }
  [Types.ShowCustomerDetail]: {
    showCustomerDetail: boolean
  }
  [Types.SetOpenRefund]: {
    openRefundProduct: boolean
  }
  [Types.SetOpenExchange]: {
    openExchangeProduct: boolean
  }
  [Types.SetOpenRemaining]: {
    openRemainingProduct: boolean
  }
  [Types.IsOrderChanged]: {
    isOrderChanged: boolean
  }
}

export type GlobalEntitiesActions = ActionMap<GlobalEntitiesPayload>[keyof ActionMap<GlobalEntitiesPayload>]

export type MakeOrderActions = ActionMap<MakeOrderPayload>[keyof ActionMap<MakeOrderPayload>]

export type BoolActions = ActionMap<BoolPayload>[keyof ActionMap<BoolPayload>]

export type RefundActions = ActionMap<RefundPayload>[keyof ActionMap<RefundPayload>]

export type ExchangeActions = ActionMap<ExchangePayload>[keyof ActionMap<ExchangePayload>]

export type RemainingActions = ActionMap<RemainingPayload>[keyof ActionMap<RemainingPayload>]

export const makeOrderReducer = (state: OrderType, action: ProductStateAction): OrderType => {
  switch (action.type) {
    case Types.SetOrder:
      return {
        ...state,
        order: action.payload.order,
      }
    case Types.SetOrderProduct:
      return {
        ...state,
        orderProduct: action.payload.orderProduct,
      }
    case Types.SetOrderType:
      return {
        ...state,
        typeOrder: action.payload.typeOrder,
      }
    default:
      return state
  }
}

export const globalEntitiesReducer = (state: GlobalEntitiesType, action: ProductStateAction): GlobalEntitiesType => {
  switch (action.type) {
    case Types.SetTenancyCurrency:
      return {
        ...state,
        tenancyCurrency: action.payload.tenancyCurrency,
      }
    case Types.SetTenancyLanguage:
      return {
        ...state,
        tenancyLanguage: action.payload.tenancyLanguage,
      }
    case Types.SetTenancyCurrencies:
      return {
        ...state,
        tenancyCurrencies: action.payload.tenancyCurrencies,
      }
    case Types.SetTenancyLanguages:
      return {
        ...state,
        tenancyLanguages: action.payload.tenancyLanguages,
      }
    case Types.SetStoreFrontId:
      return {
        ...state,
        storeFrontId: action.payload.storeFrontId,
      }
    case Types.SetCountries:
      return {
        ...state,
        countries: action.payload.countries,
      }
    default:
      return state
  }
}

export const refundReducer = (state: RefundType, action: ProductStateAction): RefundType => {
  switch (action.type) {
    case Types.SetRefundOrder:
      return {
        ...state,
        refundOrder: action.payload.refundOrder,
      }
    case Types.SetRefundProduct:
      return {
        ...state,
        refundOrderProduct: action.payload.refundOrderProduct,
      }
    case Types.SetRefundTotal:
      return {
        ...state,
        total: action.payload.total,
      }
    default:
      return state
  }
}

export const exchangeReducer = (state: ExchangeOrderType, action: ProductStateAction): ExchangeOrderType => {
  switch (action.type) {
    case Types.SetExchangeOrder:
      return {
        ...state,
        exchangeOrder: action.payload.exchangeOrder,
      }
    case Types.SetExchangeProduct:
      return {
        ...state,
        exchangeProduct: action.payload.exchangeProduct,
      }
    default:
      return state
  }
}

export const remainingReducer = (state: RemainingType, action: ProductStateAction): RemainingType => {
  switch (action.type) {
    case Types.SetRemainingOrder:
      return {
        ...state,
        remainingOrder: action.payload.remainingOrder,
      }
    case Types.SetRemainingProduct:
      return {
        ...state,
        remainingProduct: action.payload.remainingProduct,
      }
    default:
      return state
  }
}

export const boolReducer = (state: BoolType, action: ProductStateAction): BoolType => {
  switch (action.type) {
    case Types.OpenDetails:
      return {
        ...state,
        openDetails: action.payload.openDetails,
      }
    case Types.IsUpdate:
      return {
        ...state,
        isUpdate: action.payload.isUpdate,
      }
    case Types.IsPayment:
      return {
        ...state,
        isPayment: action.payload.isPayment,
      }
    case Types.IsPaymentCash:
      return {
        ...state,
        isPaymentCash: action.payload.isPaymentCash,
      }
    case Types.HideFields:
      return {
        ...state,
        hideFields: action.payload.hideFields,
      }
    case Types.ShowCustomerDetail:
      return {
        ...state,
        showCustomerDetail: action.payload.showCustomerDetail,
      }
    case Types.IsOrderChanged:
      return {
        ...state,
        isOrderChanged: action.payload.isOrderChanged,
      }
    case Types.SetOpenRefund:
      return {
        ...state,
        openRefundProduct: action.payload.openRefundProduct,
      }
    case Types.SetOpenExchange:
      return {
        ...state,
        openExchangeProduct: action.payload.openExchangeProduct,
      }
    case Types.SetOpenRemaining:
      return {
        ...state,
        openRemainingProduct: action.payload.openRemainingProduct,
      }
    default:
      return state
  }
}
