import { ThemeConfig } from "@chakra-ui/react"
import { multiply, range } from "ramda"

// Force light-mode until dark mode is fully supported
export const irisThemeConfig: ThemeConfig = {
  cssVarPrefix: "iam-cloud-theme",
  initialColorMode: "light",
  useSystemColorMode: false,
}

const percentages: number[] = range(0, 10).map(multiply(0.1))
const lighten = percentages.map((opacity) => `rgba(255,255,255,${opacity})`)
const darken = percentages.map((opacity) => `rgba(0,0,0,${opacity})`)

const scrollbarStyles = {
  "*::-webkit-scrollbar": {
    width: "8px",
    borderRadius: "8px",
    backgroundColor: `rgba(0, 0, 0, 0.05)`,
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: `rgba(0, 0, 0, 0.05)`,
  },
}

const colors = {
  //TODO: used on admin card header - to MonoRepo
  admin: { owner: "#F6F0D5", tenancy: "#D8EFF3", partner: "#D9E7F2", external: "#DDDDEE" },
  //TODO: missed from design system - to MonoRepo
  customer: {
    customer: {
      border: "#4c9cda",
      background: "#d2e8fb",
    },
  },
  lighten,
  darken,
}

const styles = {
  global: {
    body: {
      "&.body": { bg: "ui.background", color: "text.01" },
      ...scrollbarStyles,
    },
  },
}

export const themeOptions = { styles, colors }
