import { Modal } from "@chakra-ui/react"
import { FC, useState } from "react"

import { ImagesSelectModalLayout } from "./ImagesSelectModalLayout"
import { ImagesUploadedByIds } from "./ImagesUploadedByIds"
import { ImagesUploadedByImageType } from "./ImagesUploadedByImageType"
import { UploadPhotosPanel } from "./UplodaPhotosPanel"
import { ImageType, ImagesService, LibraryImage } from "api"
import { TENANCY_ID } from "common/constants"

type ImagesSelectModalProps = {
  isOpen: boolean
  onClose: () => void
  onDone: (images: string[]) => void
  imageType?: ImageType
  isMultiple: boolean
  libraryImagesIds?: UUID[]
}

const ImagesSelectModal: FC<ImagesSelectModalProps> = ({
  isOpen,
  onClose,
  onDone,
  imageType,
  isMultiple,
  libraryImagesIds,
}) => {
  const [imagesToUpload, setImagesToUpload] = useState<string[]>([])
  const [selectedLibraryImages, setSelectedLibraryImages] = useState<Set<LibraryImage>>(new Set())

  const clearAndCloseModal = () => {
    setSelectedLibraryImages(new Set<LibraryImage>())
    setImagesToUpload([])
    onClose()
  }

  const toggleSelectedLibraryImages = (image: LibraryImage) => {
    if (!isMultiple) setImagesToUpload([])
    const newSelectedLibraryImages = new Set(isMultiple ? selectedLibraryImages : new Set<LibraryImage>())
    if (newSelectedLibraryImages.has(image)) newSelectedLibraryImages.delete(image)
    else newSelectedLibraryImages.add(image)
    setSelectedLibraryImages(newSelectedLibraryImages)
  }

  const submit = async () => {
    const promises = imagesToUpload.map(async (base64) => {
      const blob = await (await fetch(base64)).blob()

      return ImagesService.imagesPostAsync({ tenancyId: TENANCY_ID, imageType, file: blob })
    })
    const uploadedImages = await Promise.all(promises)
    onDone([...uploadedImages.map((image) => image.id), ...Array.from(selectedLibraryImages).map((image) => image.id)])
    clearAndCloseModal()
  }

  return (
    <Modal isOpen={isOpen} scrollBehavior="inside" size="3xl" isCentered onClose={onClose}>
      <ImagesSelectModalLayout
        libraryPanel={
          libraryImagesIds ? (
            <ImagesUploadedByIds
              libraryImagesIds={libraryImagesIds}
              selectedImages={selectedLibraryImages}
              onImageClick={toggleSelectedLibraryImages}
            />
          ) : (
            imageType && (
              <ImagesUploadedByImageType
                imageType={imageType}
                selectedImages={selectedLibraryImages}
                onImageClick={toggleSelectedLibraryImages}
              />
            )
          )
        }
        uploadPanel={
          <UploadPhotosPanel
            clearLibrary={() => setSelectedLibraryImages(new Set<LibraryImage>())}
            deleteImage={(imageIndex) => setImagesToUpload((prev) => prev.filter((image, i) => i !== imageIndex))}
            isMultiple={isMultiple}
            uploadedImages={imagesToUpload}
            onImageUploaded={(img) => setImagesToUpload((prev) => (isMultiple ? [...prev, img] : [img]))}
          />
        }
        onClose={clearAndCloseModal}
        onDone={submit}
      />
    </Modal>
  )
}

export type { ImagesSelectModalProps }

export { ImagesSelectModal }
