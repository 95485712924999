import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Stack,
  UseDisclosureReturn,
} from "@chakra-ui/react"
import { LeftIcon } from "@iac/components.icons.react"
import { IamCloudLogo } from "@iac/components.logos.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { AppMenuCatalogue, AppMenuLink } from "./components"
import { AppMenuProducts } from "./components/AppMenuProducts"
import { NotImplemented, VideoPlayer } from "common/components"
import { isInShopAccessible } from "isInShopAccessible"

enum Nav {
  MAIN = "main",
  INSHOP = "inShop",
}

type NavProps = {
  navTarget: Nav
  emitSetActiveNav: (navTarget: Nav) => void
}

const AppMenu: FC<UseDisclosureReturn> = (disclosureProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [activeMenu, setActiveMenu] = useState(Nav.INSHOP)

  const goToRoute = (route: string) => () => {
    navigate(route)
    disclosureProps.onClose()
  }

  useEffect(() => {
    if (location.pathname.includes("/inShop")) {
      setActiveMenu(Nav.INSHOP)
    } else {
      setActiveMenu(Nav.MAIN)
    }
  }, [location.pathname])

  return (
    <Drawer placement="left" size="appMenu" {...disclosureProps}>
      <DrawerOverlay />
      <DrawerContent bg="ui.01" pt={1}>
        <DrawerCloseButton color="interactive.05" />
        <DrawerHeader alignItems="center" display="flex" p={3}>
          <Box w={14}>
            <IamCloudLogo as={Icon} height="20px" width="auto" />
          </Box>
        </DrawerHeader>
        <DrawerBody mt={4} p={3}>
          <Stack align="flex-start" as="nav" spacing={10}>
            {(isInShopAccessible || location.pathname.includes("/inShop")) && (
              <AppMenuProducts activeMenu={activeMenu} goToRoute={goToRoute} />
            )}
            <AppMenuCatalogue activeMenu={activeMenu} goToRoute={goToRoute} />

            <NotImplemented>
              <AppMenuLink icon={<LeftIcon />} iconPlacement="left" to="" onClick={() => {}}>
                {t("backTo", { location: "irisMenu" })}
              </AppMenuLink>
            </NotImplemented>
          </Stack>

          <NotImplemented>
            <VideoPlayer playerTitle={t("IAM Cloud Support")} videoId="u2sS-lxL-AA" />
          </NotImplemented>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export type { NavProps }

export { AppMenu }
