import { Category } from "../generated"

export const categories = [
  {
    id: "c3395c34-50a5-45c1-ac14-0152f318ce5a",
    tenancyId: "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55",
    parentCategoryId: "",
    taxCategoryId: "169f8946-797e-41b0-a643-1cb62e61fb31",
    name: "Accesorios Fiesta",
    description: "Products de fiesta",
    imageId: "",
    status: "Inactive",
    translations: [],
    image: { id: "", url: "", thumbnailUrl: "" },
  } as Category,
  {
    id: "ade9a09b-0967-4ca2-ae08-a68bdf6cb8c2",
    taxCategoryId: "f5094c51-3e22-42b8-9f63-b0c8618a120e",
    tenancyId: "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55",
    parentCategoryId: "",
    name: "Chuches",
    description: "Chocolates, salados",
    imageId: "",
    status: "Active",
    translations: [],
    image: { id: "", url: "", thumbnailUrl: "" },
  } as Category,
  {
    id: "e01eb027-6038-42b6-bf17-e9b9131956f5",
    taxCategoryId: "169f8946-797e-41b0-a643-1cb62e61fb31",
    tenancyId: "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55",
    parentCategoryId: "",
    name: "Disfraces",
    description: "Disfruat en las fiestas",
    imageId: "",
    status: "Active",
    translations: [],
    image: { id: "", url: "", thumbnailUrl: "" },
  } as Category,
  {
    id: "1e1c3015-d9a4-4e12-8af9-241a056167d7",
    taxCategoryId: "169f8946-797e-41b0-a643-1cb62e61fb31",
    tenancyId: "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55",
    parentCategoryId: "",
    name: "Globos",
    description: "Balloons",
    imageId: "",
    status: "Active",
    translations: [],
    image: { id: "", url: "", thumbnailUrl: "" },
  } as Category,
  {
    id: "41a564e2-552b-4ec3-8249-26f9ba3480b0",
    taxCategoryId: "169f8946-797e-41b0-a643-1cb62e61fb31",
    tenancyId: "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55",
    parentCategoryId: "",
    name: "Regalos",
    description: "Wrap you gift in a creating",
    imageId: "",
    status: "Active",
    translations: [],
    image: { id: "", url: "", thumbnailUrl: "" },
  } as Category,
  {
    id: "46a06f1a-ae6b-49f1-a099-bdf519fefa99",
    taxCategoryId: "169f8946-797e-41b0-a643-1cb62e61fb31",
    tenancyId: "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55",
    parentCategoryId: "",
    name: "Velas",
    description: "Birthday cake are not the",
    imageId: "",
    status: "Active",
    translations: [],
    image: { id: "", url: "", thumbnailUrl: "" },
  },
  {
    id: "261c4e18-3d14-46b7-8e6a-ef082f724ae6",
    taxCategoryId: "169f8946-797e-41b0-a643-1cb62e61fb31",
    tenancyId: "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55",
    parentCategoryId: "",
    name: "Pinata",
    description: "Every party needs one",
    imageId: "",
    status: "Active",
    translations: [],
    image: { id: "", url: "", thumbnailUrl: "" },
  } as Category,
]
