import { request as __request } from "../core/request"
import { Order } from "../models/Order"
import { trackPromise } from "react-promise-tracker"

export class SalesOrdersService {
  /**
   * Gets all SalesView Orders.
   * ## Requires:
   * ### salesOrder.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async salesOrderGetAllAsync({
    tenancyId,
    select,
    expand,
    orderby,
    top,
    skip,
    count = false,
    filter,
  }: {
    tenancyId: string
    filter?: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
    /** Specifies the order in which items are returned. The maximum number of expressions is 5. **/
    orderby?: string
    /** Limits the number of items returned from a collection. **/
    top?: number
    /** Excludes the specified number of items of the queried collection from the result. **/
    skip?: number
    /** Indicates whether the total count of items within a collection are returned in the result. **/
    count?: boolean
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<Order>
    readonly count: number
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/salesorders`,
        query: {
          $select: select,
          $expand: expand,
          $filter: filter,
          $top: top,
          $skip: skip,
          $count: count,
          $orderby: orderby,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return { ...result.body, count: result.body["@odata.count"] }
  }

  /**
   * Gets SalesView Order.
   * ## Requires:
   * ### salesOrder.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async salesOrderGetAsync({
    tenancyId,
    id,
    select,
    expand,
  }: {
    tenancyId: string
    id: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & Order
  > {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/salesorders/${id}`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Create SalesView Order.
   * ## Requires:
   * ### salesOrder.Create
   * @returns any Success
   * @throws ApiError
   */
  public static async salesOrderPostAsync({
    tenancyId,
    requestBody,
  }: {
    tenancyId: string
    requestBody: Partial<Order>
  }): Promise<
    {
      readonly "@odata.context"?: string
      body?: Order
    } & Order
  > {
    requestBody.id = undefined
    requestBody.orderNumber = undefined
    requestBody.tenancyId = undefined
    requestBody.sellerName = undefined
    requestBody.deliveryDate = undefined
    requestBody.notes = undefined
    const result = await trackPromise(
      __request({
        method: "POST",
        path: `/api/tenancies/${tenancyId}/isa/salesorders`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Update SearchingProduct.
   * ## Requires:
   * ### products.Update
   * @returns any Success
   * @throws ApiError
   */
  public static async salesOrderPatchAsync({
    tenancyId,
    id,
    requestBody,
  }: {
    tenancyId: string
    id: string
    requestBody: Partial<Order>
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & Order
  > {
    requestBody.id = undefined
    const result = await trackPromise(
      __request({
        method: "PATCH",
        path: `/api/tenancies/${tenancyId}/isa/salesorders/${id}`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          409: `Conflict`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Delete the SearchingProduct.
   * ## Requires:
   * ### products.Delete
   * @returns any Success
   * @throws ApiError
   */
  public static async salesOrderDeleteAsync({ tenancyId, id }: { tenancyId: string; id: string }): Promise<
    {
      readonly "@odata.context"?: string
    } & Order
  > {
    const result = await trackPromise(
      __request({
        method: "DELETE",
        path: `/api/tenancies/${tenancyId}/isa/salesorders/${id}`,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }
}
