import { Button, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

enum ModalType {
  isDefault,
  isSystem,
}

type LanguageChangesModalProps = {
  isOpen: boolean
  onClose: () => void
  name: string | undefined
  onSubmit: (value: boolean) => void
  modalType: ModalType
}

const LanguageChangesModal: FC<LanguageChangesModalProps> = ({ onClose, isOpen, name, onSubmit, modalType }) => {
  const { t } = useTranslation()
  const setValue = (value: boolean) => {
    onSubmit(value)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} size="3xl" isCentered onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          {modalType === ModalType.isDefault
            ? t("languages.languageChanges.modal.default.question", { name })
            : t("languages.languageChanges.modal.system.question", { name })}
        </ModalHeader>
        <ModalCloseButton
          borderColor="text.01"
          borderRadius={50}
          borderWidth={1}
          data-testid="close-button"
          size="sm"
        />
        <ModalFooter alignItems="center" display="flex" justifyContent="flex-start">
          <Button data-testid="system-language-yes-button" mr={6} variant="primary" onClick={() => setValue(true)}>
            {modalType === ModalType.isDefault
              ? t("languages.languageChanges.modal.default.yes")
              : t("languages.languageChanges.modal.system.yes")}
          </Button>
          <Button data-testid="system-language-no-button" variant="secondary" onClick={() => setValue(false)}>
            {modalType === ModalType.isDefault
              ? t("languages.languageChanges.modal.default.no")
              : t("languages.languageChanges.modal.system.no")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export type { LanguageChangesModalProps }

export { ModalType, LanguageChangesModal }
