import { Flex, Input, Select, Switch } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import { isValidPhoneNumber } from "libphonenumber-js"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as z from "zod"

import { Supplier, api, mockData } from "api"
import { FormButtons, FormCountrySelect, FormField, FormPhoneNumberInput, FormRichSelect } from "common/components"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { COUNTRY_ID, TENANCY_ID } from "common/constants"
import { Statuses } from "common/statuses"
import { formStyles } from "common/theme"
import {
  emailValidation,
  numbersNletters,
  numbersNlettersNdash,
  numbersNlettersNspecialCharacters,
  onlyLetters,
} from "common/validation"
import { route } from "features/app/routes"

type FormValues = {
  name: string
  email: string
  phone: string
  companyName: string
  taxRegistrationNumber: string
  addressLine1: string
  addressLine2: string
  city: string
  region: string
  postCode: string
  countryId: string
  defaultProductCountryOfOriginId: string
  defaultProductCategoryId: string
  buyingTaxCategoryId: string
  priceIncludesTax: boolean
  status: string
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const SupplierChange = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [name, setName] = useState("")
  const [isNext, setIsNext] = useState(false)
  const [isNextFields, setIsNextFields] = useState(false)
  const [supplier, setSupplier] = useState<Supplier>(mockData.initSupplier)

  const { data: taxCategories } = api.useTaxCategories(COUNTRY_ID)
  const { data: productCategories } = api.useProductCategories(TENANCY_ID)
  const { data: supplierData } = api.useSupplier(TENANCY_ID, params.id || "")
  const { data: tenancyData } = api.useTenancy(TENANCY_ID)

  const createSupplierMutation = api.useCreateSupplierMutation(
    {
      onSuccess: () => navigate(route.suppliers.path),
    },
    TENANCY_ID
  )
  const updateSupplierMutation = api.useUpdateSupplierMutation(
    {
      onSuccess: () => navigate(route.suppliers.path),
    },
    TENANCY_ID,
    params.id || ""
  )

  const statuses: string[] = [Statuses.Active, Statuses.Inactive]

  const schema = z.object({
    name: z
      .string()
      .max(15)
      .nonempty({ message: t("validation.required", { field: "name" }) })
      .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") }),
    email: z
      .string()
      .nonempty({ message: t("validation.required", { field: "email" }) })
      .regex(emailValidation, { message: t("validation.email") }),
    phone: z.string().refine((val) => isValidPhoneNumber(val), {
      message: t("validation.phone"),
    }),
    companyName: z
      .string()
      .nonempty({ message: t("validation.required", { field: "company name" }) })
      .regex(numbersNletters, { message: t("validation.lettersNnumbers") }),
    taxRegistrationNumber: z
      .string()
      .nonempty({ message: t("validation.required", { field: "tax registration number" }) }),
    countryId: z.string().nonempty({ message: t("validation.select", { field: "country" }) }),
    addressLine1: z
      .string()
      .nonempty({ message: t("validation.required", { field: "address line 1" }) })
      .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") }),
    addressLine2: z.string(),
    city: z
      .string()
      .nonempty({ message: t("validation.required", { field: "city" }) })
      .regex(onlyLetters, { message: t("validation.letters") }),
    region: z
      .string()
      .nonempty({ message: t("validation.required", { field: "region" }) })
      .regex(onlyLetters, { message: t("validation.letters") }),
    postCode: z
      .string()
      .nonempty({ message: t("validation.required", { field: "post code" }) })
      .max(10)
      .regex(numbersNlettersNdash, { message: t("validation.lettersNnumnersNdash") }),
    defaultProductCountryOfOriginId: z.string().optional(),
    defaultProductCategoryId: z
      .string()
      .nonempty({ message: t("validation.select", { field: t("sales.default_product_category") }) }),
    buyingTaxCategoryId: z.string().optional(),
    priceIncludesTax: z.boolean().optional(),
    applyResellerTax: z.boolean().optional(),
    status: z.string().nonempty({ message: t("validation.select", { field: "status" }) }),
  })

  const {
    formState: { errors, isValid, isDirty },
    getValues,
    setValue,
    register,
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: "all",
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      companyName: "",
      taxRegistrationNumber: "",
      countryId: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      region: "",
      postCode: "",
      defaultProductCountryOfOriginId: "",
      defaultProductCategoryId: "",
      buyingTaxCategoryId: "",
      status: "",
    },
  })

  const forNext = watch([
    "name",
    "email",
    "phone",
    "companyName",
    "taxRegistrationNumber",
    "countryId",
    "addressLine1",
    "city",
    "region",
    "postCode",
  ])
  const watchPhone = watch("phone")
  const watchCountryId = watch("countryId")
  const watchDefaultProductCountryOfOriginId = watch("defaultProductCountryOfOriginId")
  const watchDefaultProductCategoryId = watch("defaultProductCategoryId")
  const isInvalid = !isValid || !isDirty
  const formFieldProps = { errors, register }

  useEffect(() => {
    if (tenancyData?.countryId && !params.id) {
      setValue("countryId", String(tenancyData?.countryId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenancyData])

  const changesSupplier = (): void => {
    const {
      name,
      email,
      phone,
      companyName,
      taxRegistrationNumber,
      countryId,
      addressLine1,
      addressLine2,
      city,
      region,
      postCode,
      defaultProductCountryOfOriginId,
      defaultProductCategoryId,
      buyingTaxCategoryId,
      priceIncludesTax,
      status,
    } = getValues()
    const address = JSON.stringify({
      addressLine1,
      addressLine2,
      city,
      region,
      postCode,
    })
    const data = {
      name,
      email,
      phone: phone.replace(/ /g, ""),
      companyName,
      taxRegistrationNumber,
      countryId: +countryId,
      address,
      defaultProductCountryOfOriginId: +defaultProductCountryOfOriginId,
      defaultProductCategoryId,
      buyingTaxCategoryId,
      priceIncludesTax,
      status,
    }

    if (params.id) {
      updateSupplierMutation.mutate(data)
    } else {
      createSupplierMutation.mutate(data)
    }
  }

  useEffect(() => {
    const { name, email, phone, companyName, taxRegistrationNumber, countryId, addressLine1, city, region, postCode } =
      errors
    const isValidFields =
      [name, email, phone, companyName, taxRegistrationNumber, countryId, addressLine1, city, region, postCode].every(
        (item) => !item?.message
      ) && forNext.every((item) => item?.length)
    setIsNext(isValidFields)
  }, [errors, forNext])

  useEffect(() => {
    if (params.id && supplierData) {
      const address = JSON.parse(supplierData.address)
      setSupplier(supplierData)
      setName(supplierData.name)
      setValue("name", supplierData.name)
      setValue("email", supplierData.email)
      setValue("phone", supplierData.phone)
      setValue("companyName", supplierData.companyName)
      setValue("taxRegistrationNumber", supplierData.taxRegistrationNumber || "")
      setValue("countryId", String(supplierData.countryId))
      setValue("addressLine1", address.addressLine1 || "")
      setValue("addressLine2", address.addressLine2 || "")
      setValue("city", address.city || "")
      setValue("region", address.region || "")
      setValue("postCode", address.postCode || "")
      setValue("defaultProductCountryOfOriginId", String(supplierData.defaultProductCountryOfOriginId))
      setValue("defaultProductCategoryId", supplierData.defaultProductCategoryId)
      setValue("buyingTaxCategoryId", supplierData.buyingTaxCategoryId)
      setValue("priceIncludesTax", supplierData.priceIncludesTax)
      setValue("status", supplierData.status)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, supplierData])

  return (
    <>
      <PageHeader
        link={route.suppliers.path}
        title={params.id ? `${t("editItem", { name })} ${t("supplier")}` : t("addNew", { item: "supplier" })}
        isArrow
      />
      <Flex direction="column" pl={20} pr={4} py={4} width="100%">
        {!isNextFields ? (
          <>
            <FormField<FormValues>
              label={t("name")}
              name="name"
              isRequired
              {...formFieldProps}
              stackProps={{ ...formStyles.control, width: "40%" }}
            >
              <Input data-testid="supplier-name-field" maxLength={15} placeholder={t("placeholder.name")} />
            </FormField>
            <FormField<FormValues>
              label={t("email")}
              name="email"
              isRequired
              {...formFieldProps}
              stackProps={formStyles.control}
            >
              <Input data-testid="supplier-email-field" />
            </FormField>
            <FormField<FormValues>
              errors={errors}
              label={t("phone")}
              name="phone"
              stackProps={formStyles.control}
              isRequired
            >
              <FormPhoneNumberInput
                dataTestId="supplier-phone-field"
                value={watchPhone}
                onChange={(val) => setValue("phone", val, { shouldDirty: true, shouldValidate: true })}
              />
            </FormField>
            <FormField<FormValues>
              label={t("companyInfo.companyName")}
              name="companyName"
              isRequired
              {...formFieldProps}
              stackProps={{ ...formStyles.control, width: "40%" }}
            >
              <Input data-testid="supplier-company-name-field" placeholder={t("companyName_placeholder")} />
            </FormField>
            <FormField<FormValues>
              label={t("tax_registration_number")}
              name="taxRegistrationNumber"
              isRequired
              {...formFieldProps}
              stackProps={{ ...formStyles.control, width: "40%" }}
            >
              <Input data-testid="supplier-tax-registration-number-field" />
            </FormField>
            <FormField<FormValues>
              errors={errors}
              label={t("country")}
              name="countryId"
              stackProps={{ ...formStyles.control }}
              isRequired
            >
              <FormCountrySelect
                data-testid="supplier-country-select"
                value={watchCountryId}
                onChange={(val) => setValue("countryId", val.toString(), { shouldDirty: true, shouldValidate: true })}
              />
            </FormField>
            {watchCountryId?.length !== 0 && (
              <>
                <FormField<FormValues>
                  label={t("addressLine", { number: 1 })}
                  name="addressLine1"
                  isRequired
                  {...formFieldProps}
                  stackProps={{ ...formStyles.control, width: "40%" }}
                >
                  <Input data-testid="supplier-address-line1-field" />
                </FormField>
                <FormField<FormValues>
                  label={t("addressLine", { number: 2 })}
                  name="addressLine2"
                  {...formFieldProps}
                  stackProps={{ ...formStyles.control, width: "40%" }}
                >
                  <Input data-testid="supplier-address-line2-field" />
                </FormField>
                <FormField<FormValues>
                  label={t("city")}
                  name="city"
                  isRequired
                  {...formFieldProps}
                  stackProps={formStyles.control}
                >
                  <Input data-testid="supplier-city-field" />
                </FormField>
                <FormField<FormValues>
                  label={t("region_label")}
                  name="region"
                  isRequired
                  {...formFieldProps}
                  stackProps={formStyles.control}
                >
                  <Input data-testid="supplier-region-field" />
                </FormField>
                <FormField<FormValues>
                  label={t("post_code")}
                  name="postCode"
                  isRequired
                  {...formFieldProps}
                  stackProps={formStyles.control}
                >
                  <Input data-testid="supplier-post-code-field" />
                </FormField>
                <FormButtons
                  cancel={() => navigate(route.suppliers.path)}
                  disabled={!isNext}
                  done={() => setIsNextFields(true)}
                  titleDone={t("next")}
                />
              </>
            )}
          </>
        ) : (
          <>
            <FormField<FormValues>
              errors={errors}
              label={t("default_product_country_of_origin")}
              name="defaultProductCountryOfOriginId"
              stackProps={{ ...formStyles.control }}
            >
              <FormCountrySelect
                data-testid="supplier-default-product-country-select"
                value={watchDefaultProductCountryOfOriginId}
                onChange={(val) =>
                  setValue("defaultProductCountryOfOriginId", val.toString(), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
              />
            </FormField>
            <FormField<FormValues>
              errors={errors}
              label={t("default_product_category")}
              name="defaultProductCategoryId"
              stackProps={formStyles.control}
              isRequired
            >
              <FormRichSelect
                data-testid="supplier-default-product-category-select"
                options={
                  productCategories?.value?.map((category) => ({
                    label: category.name,
                    value: String(category.id),
                  })) ?? []
                }
                value={watchDefaultProductCategoryId}
                onChange={(val) =>
                  setValue("defaultProductCategoryId", val, { shouldDirty: true, shouldValidate: true })
                }
              />
            </FormField>
            <FormField<FormValues>
              label={t("tax_category")}
              name="buyingTaxCategoryId"
              isRequired
              {...formFieldProps}
              stackProps={formStyles.control}
            >
              <Select data-testid="supplier-tax-category-select">
                <option value="" disabled>
                  Select
                </option>
                {taxCategories?.value?.map((tax) => (
                  <option key={tax.id} value={tax.id}>
                    {tax.name}
                  </option>
                ))}
              </Select>
            </FormField>
            <FormField<FormValues>
              label={t("price_includes_tax")}
              name="priceIncludesTax"
              {...formFieldProps}
              stackProps={formStyles.control}
            >
              <Switch
                data-testid="supplier-price-includes-tax-switch"
                defaultChecked={supplier.priceIncludesTax}
                size="md"
                onChange={(event) => setSupplier({ ...supplier, priceIncludesTax: event.currentTarget.checked })}
              />
            </FormField>
            <FormField<FormValues>
              label={t("status_label")}
              name="status"
              isRequired
              {...formFieldProps}
              stackProps={formStyles.control}
            >
              <Select data-testid="supplier-status-select">
                <option value="" disabled>
                  {t("select")}
                </option>
                {statuses.map((item) => (
                  <option key={item} value={item}>
                    {t(`statuses.${item}`)}
                  </option>
                ))}
              </Select>
            </FormField>
            <FormButtons
              cancel={() => setIsNextFields(false)}
              disabled={isInvalid}
              done={changesSupplier}
              titleCancel={t("back")}
            />
          </>
        )}
      </Flex>
    </>
  )
}

export { SupplierChange }
