import { Flex, Input, Select } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { ColorPicker } from "@iac/components.picker"
import { useTranslation } from "@iac/translations.i18n-instance"
import { colord } from "colord"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as z from "zod"

import { ImageType, TenancyLanguage, VariantTypeOption, api } from "api"
import { CommonFormControl, FormButtons, FormField, ImagesControlSingle, PageHeader } from "common/components"
import { TENANCY_ID } from "common/constants"
import { formStyles } from "common/theme"
import { numbersNlettersNspecialCharacters } from "common/validation"
import { route } from "features/app/routes"

type FormValues = {
  variantOptionId: string
  value: string
  color: string
  imageId: string
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const OptionValueChanges = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [name, setName] = useState("")
  const [variantOptionId, setVariantOptionId] = useState("")

  const { data: variantOptions } = api.useVariantOptions(TENANCY_ID)
  const { data: tenancyLanguages } = api.useTenancyLanguages(TENANCY_ID)
  const { data: variantOptionValueData } = api.useVariantOptionValue(TENANCY_ID, params.optionId || "", params.id || "")

  const createVariantOptionValue = api.useCreateVariantOptionValueMutation(
    {
      onSuccess: () => navigate(route.variantOptions.path),
    },
    TENANCY_ID,
    variantOptionId
  )
  const updateVariantOptionValue = api.useUpdateVariantOptionValueMutation(
    {
      onSuccess: () => navigate(route.variantOptions.path),
    },
    TENANCY_ID,
    params.optionId || "",
    params.id || ""
  )

  const schema = z.object({
    variantOptionId: z.string().nonempty({ message: t("validation.select", { field: "variant option" }) }),
    value: z
      .string()
      .max(15)
      .nonempty({ message: t("validation.required", { field: "name" }) })
      .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") }),
    color: z.string().optional(),
    imageId: z.string().optional(),
  })

  const {
    formState: { errors, isDirty, isValid },
    getValues,
    setValue,
    watch,
    reset,
    register,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: "all",
    defaultValues: {
      variantOptionId: "",
      value: "",
      color: "",
      imageId: "",
    },
  })
  const watchColor = watch("color")
  const watchImage = watch("imageId")
  const watchVariantOptionId = watch("variantOptionId")
  const isInvalid = !isDirty || !isValid
  const formFieldProps = { errors, register }

  useEffect(() => {
    setVariantOptionId(watchVariantOptionId)
  }, [watchVariantOptionId])

  useEffect(() => {
    if (watchImage) setValue("color", "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchImage])

  useEffect(() => {
    if (watchColor) setValue("imageId", "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchColor])

  useEffect(() => {
    if (params.optionId && params.id && variantOptionValueData) {
      setName(variantOptionValueData.value)
      reset({
        variantOptionId: variantOptionValueData.variantOptionId,
        value: variantOptionValueData.value,
        imageId: variantOptionValueData.imageId || "",
        color: variantOptionValueData.color || "",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantOptionValueData, params.id, params.optionId])

  const changesValueOption = (): void => {
    const { value, color, imageId } = getValues()
    const defaultLanguage = tenancyLanguages?.value?.find(
      (item: TenancyLanguage) => item.isDefault && item.isEnabled
    ) as TenancyLanguage
    const valueOption = {
      color: color || null,
      imageId: imageId || null,
      translations: [
        {
          languageId: defaultLanguage.languageId,
          value,
        },
      ],
    }
    if (params.id) {
      updateVariantOptionValue.mutate(valueOption)
    } else {
      createVariantOptionValue.mutate(valueOption)
    }
  }

  return (
    <>
      <PageHeader title={params.id ? t("variant_option.edit_value", { name }) : t("variant_option.add_new_value")} />
      <Flex direction="column" pl={20} pr={4} width="100%">
        <FormField<FormValues>
          label={t("variant_option.value_option")}
          name="variantOptionId"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Select data-testid="option-value-select">
            <option value="" disabled>
              {t("select")}
            </option>
            {variantOptions?.value?.map((item: VariantTypeOption) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField<FormValues>
          label={t("variant_option.option_name")}
          name="value"
          isRequired
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Input data-testid="option-name-field" maxLength={200} />
        </FormField>

        <CommonFormControl controlWidth="100%">
          <Flex align="center" justify="flex-start">
            <ImagesControlSingle
              imageId={watchImage}
              imageType={ImageType.VariantOptionValue}
              onChange={(imageId) => {
                setValue("imageId", imageId ?? null, { shouldDirty: true })
              }}
            />
          </Flex>
        </CommonFormControl>

        <FormField<FormValues>
          label={t("variant_option.option_colour")}
          name="color"
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <ColorPicker
            {...register("color")}
            color={colord(watchColor)}
            setColor={(event) => {
              setValue("color", event.toHex(), { shouldDirty: true })
            }}
          />
        </FormField>

        <FormButtons
          cancel={() => navigate(route.variantOptions.path)}
          disabled={isInvalid}
          done={changesValueOption}
        />
      </Flex>
    </>
  )
}

export { OptionValueChanges }
