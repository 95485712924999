import { Button, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"
import { useNavigate } from "react-router-dom"

import { route } from "features/app/routes"

type ScannedBarcodeProps = {
  isOpen: boolean
  onClose: () => void
  sCode: string
}

export const BarcodeNotFoundModal: FC<ScannedBarcodeProps> = ({ isOpen, onClose, sCode }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} size="2xl" isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="Text01">{t("scannedBarcode.message")}</ModalHeader>
        <ModalCloseButton borderColor="black" borderRadius="50%" borderWidth={1} color="black" size="sm" />
        <ModalFooter alignItems="center" display="flex" justifyContent="flex-start" mt="2rem">
          <Button
            mr={6}
            variant="primary"
            onClick={() => {
              navigate(route.addVariantForProductViaScanner.path)
              onClose()
            }}
          >
            {t("scannedBarcode.addAsVariant")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              navigate(`${route.addProduct.path}/${sCode}`)
              onClose()
            }}
          >
            {t("scannedBarcode.addAsProduct")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
