import { pipe, propOr, tryCatch } from "ramda"

import { OpenAPI } from "api"
import { REACT_APP_API_URL, REACT_APP_BIFROST_URL } from "environment"
import { OIDC_SESSION_STORAGE_KEY } from "features/auth"

// TODO - Refactor. I've not written this code, only moved it
const configureOpenAPI = () => {
  const getToken = (): string =>
    pipe(
      propOr("{}", OIDC_SESSION_STORAGE_KEY),
      tryCatch(JSON.parse, () => ({})),
      propOr("", "access_token")
    )(sessionStorage) as string

  // eslint-disable-next-line
  OpenAPI.BASE = REACT_APP_API_URL

  // eslint-disable-next-line
  OpenAPI.BASE_BIFROST = REACT_APP_BIFROST_URL

  // eslint-disable-next-line
  OpenAPI.TOKEN = () =>
    new Promise<string>((resolve) => {
      resolve(getToken())
    })
}

export { configureOpenAPI }
