const FEATURE_FLAG = {
  cdmPhase2: "cdmPhase2",
} as const

const ALL_FEATURE_FLAGS = Object.values(FEATURE_FLAG)

type FeatureFlags = {
  [key in keyof typeof FEATURE_FLAG]: boolean
}

export { FEATURE_FLAG, ALL_FEATURE_FLAGS }

export type { FeatureFlags }
