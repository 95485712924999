import { Box, Flex } from "@chakra-ui/react"
import { FC } from "react"

import { PageHeader } from "common/components/PageHeader/PageHeader"
import { Search } from "features/inventory/search/Search"

const PaymentView: FC = () => (
  <Box p={4}>
    <PageHeader buttonTitle="Add New Payment" title="Payments" onClick={() => {}} />
    <Flex data-testid="payments" direction="column" p={4}>
      <Search placeholder="Search for payments" titleButton="Search payment" onSearch={() => {}} />
    </Flex>
  </Box>
)

export { PaymentView }
