import { Box, Flex, Tag, Text } from "@chakra-ui/react"
import { EditIcon, RemoveIcon } from "@iac/components.icons.react"
import { FC, useMemo } from "react"
import { Column, Row, usePagination, useTable } from "react-table"

import { Pagination, Table } from "common/components"
import { symbolPosition } from "common/utils/currency.utils"

type StockPerOrder = {
  id: string
  deliveryDate: string
  originalStockLevel: number
  costPrice: number
  rrPrice: number
  stockOnHand: number
  status: string
}

type StockPageTableProps = {
  orders: StockPerOrder[]
}

const StockPageTable: FC<StockPageTableProps> = ({ orders }) => {
  const styles = {
    ordered: {
      background: "#fad0b6",
      color: "text.01",
      borderWidth: 1,
      borderColor: "#e57533",
    },
    active: {
      background: "#aee5ff",
      color: "text.01",
      borderWidth: 1,
      borderColor: "#0073b3",
    },
    success: {
      background: "#b8f6cf",
      color: "text.01",
      borderWidth: 1,
      border: "#1fc65a",
    },
  }

  const getStatus = (status: string) => {
    switch (status) {
      case "ordered":
        return styles.ordered
      case "confirmed":
        return styles.active
      case "arrived":
        return styles.success
    }
  }

  const columnData: Column<StockPerOrder>[] = [
    {
      Header: "Delivery Date",
      accessor: "deliveryDate",
    },
    {
      Header: "Original Stock Level",
      accessor: "originalStockLevel",
    },
    {
      Header: "Cost Price",
      accessor: "costPrice",
      Cell: ({ row }) => <Text>{symbolPosition(row.original.costPrice)}</Text>,
    },
    {
      Header: "RR Price",
      accessor: "rrPrice",
      Cell: ({ row }) => <Text>{symbolPosition(row.original.rrPrice)}</Text>,
    },
    {
      Header: "Stock on Hand",
      accessor: "stockOnHand",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <Tag borderRadius={2} fontSize={14} style={getStatus(row.original.status)} textTransform="capitalize">
          {row.original.status}
        </Tag>
      ),
    },
    {
      id: "buttons",
      // @ts-ignore
      Cell: ({ row }) => (
        <Flex align="center" justify="flex-end">
          <EditIcon
            _hover={{ cursor: "pointer", transform: "scale(1.3)" }}
            color="#007abd"
            fontSize={20}
            transition=".3s"
          />
          <RemoveIcon
            _hover={{ cursor: "pointer", transform: "scale(1.3)" }}
            color="black"
            marginLeft="1.5rem"
            transition=".3s"
          />
        </Flex>
      ),
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, []),
      data: useMemo(() => orders, [orders]),
    },
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<StockPerOrder>): React.ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Table.TD {...cell.getCellProps()} textAlign="right" textTransform="capitalize">
            {cell.render("Cell")}
          </Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Box position="relative" width="100%">
      <Table {...getTableProps()} width="100%">
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.TR
              bg="white"
              color="#36495E"
              fontSize="lg"
              textTransform="capitalize"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <Table.TH {...column.getHeaderProps()} textAlign="right">
                  {column.render("Header")}
                </Table.TH>
              ))}
            </Table.TR>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>{page.map(renderRow)}</Table.Body>
        <Table.Footer>
          <Table.TR bg="#fafafa">
            <Table.TD colSpan={columnData.length}>
              <Pagination
                currentPage={pageIndex}
                setPageSize={() => {}}
                totalPages={pageOptions.length}
                onPageChange={gotoPage}
              />
            </Table.TD>
          </Table.TR>
        </Table.Footer>
      </Table>
    </Box>
    /* eslint-enable react/jsx-key */
  )
}

export { StockPageTable }
