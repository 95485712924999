import { Box, Avatar as ChakraAvatar, AvatarProps as ChakraAvatarProps, keyframes } from "@chakra-ui/react"
import { FC } from "react"

const pulse = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

type AvatarSize = "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "full"

// Chakra avatar sizes: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/avatar.ts#L85
const avatarSizesToThemeSizes: { [key in AvatarSize]: number | string } = {
  "2xs": 4,
  xs: 6,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 24,
  "2xl": 32,
  full: "100%",
}

const defaultAvatarSize = avatarSizesToThemeSizes.md

const getSize = (size: AvatarSize) => {
  if (!size || !avatarSizesToThemeSizes[size]) {
    return defaultAvatarSize
  }

  return avatarSizesToThemeSizes[size]
}

type Props = ChakraAvatarProps & { isPulsing?: boolean; pulseColor?: string }

const Avatar: FC<Props> = ({ isPulsing, pulseColor, size, ...props }) => {
  const pulseAnimation = `${pulse} 1.5s infinite alternate ease-in-out`
  const avatarPulseStartingSize = getSize(size as AvatarSize) || defaultAvatarSize

  return (
    <Box pos="relative">
      {isPulsing && (
        <Box
          animation={pulseAnimation}
          bgColor={pulseColor}
          borderRadius="999rem"
          height={avatarPulseStartingSize}
          pos="absolute"
          transform="scale(1.2)"
          transformOrigin="center"
          width={avatarPulseStartingSize}
        />
      )}
      <ChakraAvatar size={size} {...props} />
    </Box>
  )
}

export { Avatar }

export type { Props as AvatarProps }
