import { Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, ReactNode, SetStateAction, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useTable } from "react-table"

import { Customer } from "api"
import { Pagination, Table, TableItemMenu } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE } from "common/constants"

type CustomerTableProps = {
  loading: boolean
  onOpen: () => void
  customers: Customer[]
  refetch: (top: number, skip: number) => void
  setCustomer: Dispatch<SetStateAction<Customer>>
  pageCount: number
  itemsPerPage: number
}

const CustomerTable: FC<CustomerTableProps> = ({
  loading,
  onOpen,
  setCustomer,
  customers,
  refetch,
  pageCount,
  itemsPerPage,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const NameCell: FC<{ customer: Customer }> = ({ customer }) => (
    <TableItemMenu
      deleteClick={() => {
        setCustomer(customer)
        onOpen()
      }}
      editClick={() => navigate(customer.id)}
      value={customer.name}
      isShowEdit
      onClick={() => navigate(`info/${customer.id}`)}
    />
  )

  const columnData: Column<Customer>[] = [
    {
      Header: t("name") as string,
      accessor: "name",
      Cell: ({ row }) => <NameCell customer={row.original} />,
    },
    {
      Header: t("email") as string,
      accessor: "email",
      Cell: ({ row: { original: customer } }) => <Text>{customer.email}</Text>,
    },
    {
      Header: t("mobileNumber") as string,
      // @ts-ignore
      Cell: ({ row: { original: customer } }) => <Text>{customer.phone}</Text>,
    },
    {
      Header: t("pendingOrders") as string,
      // @ts-ignore
      Cell: ({ row: { original: customer } }) => <Text>{customer.salesOrders?.length || 0}</Text>,
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setPageSize,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, []),
      data: useMemo(() => customers, [customers]),
      initialState: {
        pageSize: itemsPerPage,
      },
      manualPagination: true,
      pageCount,
    },
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<Customer>): ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()} _hover={{ cursor: "pointer", background: "#aee5ff" }}>
        {row.cells.map((cell) => {
          if (cell.column.Header === "Name") {
            return <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
          }

          return <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
        })}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} data-testid="customers-table" width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR bg="white" color="#36495E" fontSize="lg" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Table.TH textTransform="capitalize" {...column.getHeaderProps()}>
                {column.render("Header")}
              </Table.TH>
            ))}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {loading ? (
          <Table.Skeleton columnWidths={[32, 16, 32, 64]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={(event) => {
                setPageSize(event)
                refetch(event, 0)
              }}
              totalPages={pageOptions.length}
              onPageChange={(pageIndex) => {
                gotoPage(pageIndex)
                refetch(pageSize, pageIndex * pageSize)
              }}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { CustomerTable }
