const LONG_FORM_ELEMENT_WIDTH = "582px"

const SHORT_FORM_ELEMENT_WIDTH = "255px"

const formStyles = {
  control: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: SHORT_FORM_ELEMENT_WIDTH,
    marginBottom: 30,
  },
  label: {
    display: "flex",
    margin: "0 0 .5rem 0",
  },
  input: {
    width: "100%",
  },
}

export { LONG_FORM_ELEMENT_WIDTH, SHORT_FORM_ELEMENT_WIDTH, formStyles }
