import { initCurrency } from "./initCurrency"

export const initTenancyCurrency = {
  id: "",
  currencyId: "",
  parentId: "",
  rate: 0,
  isDefault: false,
  currency: {
    ...initCurrency,
  },
}
