import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  UseDisclosureProps,
  useDisclosure,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useRef } from "react"
import { useAuth } from "react-oidc-context"
import { useNavigate } from "react-router-dom"

import { NotImplemented } from "common/components"
import { useManagedCustomer } from "features/customers"

const linkButtonProps = {
  borderRadius: "xs",
  color: "link.01",
  fontSize: "xs",
  variant: "link",
}

export const ProfileMenu: FC<UseDisclosureProps> = (props) => {
  const { t } = useTranslation()
  const disclosureProps = useDisclosure(props)
  const focusRef = useRef(null)

  const navigate = useNavigate()
  const goToRoute = (route: string) => () => {
    navigate(route)
    disclosureProps.onClose()
  }

  const { isAuthenticated, signoutRedirect } = useAuth()
  // const logOut = useLogOut()
  const handleRedirect = () => signoutRedirect()

  const { customerType } = useManagedCustomer()

  const { user } = useAuth()
  const email = user?.profile.email
  const username = user?.profile.name

  const showUser = username && isAuthenticated

  return (
    <Flex align="center" borderRadius="full" color="gray.500" fontSize="sm">
      {showUser && (
        <Popover arrowShadowColor="none" initialFocusRef={focusRef} {...disclosureProps}>
          <PopoverTrigger>
            <div role="group">
              <Button
                _active={{ bg: "none" }}
                _focus={{ outline: 0 }}
                _focusVisible={{ outline: 1, boxShadow: "outline" }}
                _hover={{ bg: "none", color: "black" }}
                bg="none"
                color="interactive.05"
                h="fit-content"
                mx={0}
                p={1}
              >
                <Avatar
                  _groupHover={{ boxShadow: "nav" }}
                  bg={`customer.${customerType}.avatar`}
                  color="white"
                  data-testid="ProfileMenu__avatar--main"
                  name={username}
                  size="xs"
                />
                <ChevronDownIcon boxSize={3} ml={1} />
              </Button>
            </div>
          </PopoverTrigger>
          <PopoverContent
            _focus={{ border: "none" }}
            bg="white"
            border="none"
            boxShadow="modal"
            fontSize="xs"
            mr={2}
            p={0}
            w={80}
          >
            <PopoverArrow boxShadow="modal" />
            <HStack px={4} py={5}>
              <Avatar
                bg={`customer.${customerType}.avatar`}
                boxSize={16}
                color="white"
                data-testid="ProfileMenu__avatar--menu"
                mr={2}
                name={username}
                size="lg"
              />
              <Stack spacing={0}>
                <Heading color="text.01" fontSize="md" fontWeight="bold" textTransform="capitalize">
                  {username}
                </Heading>
                <Text color="text.02">{email}</Text>
                {isAuthenticated && (
                  <Box pt={2}>
                    <Button {...linkButtonProps} ref={focusRef} onClick={goToRoute("/profile")}>
                      {t("profileMenu.myProfile")}
                    </Button>
                  </Box>
                )}
              </Stack>
            </HStack>
            <NotImplemented>
              <Divider color="ui.07" />
              <Stack align="flex-start" px={4} py={5} spacing={5}>
                <Button {...linkButtonProps} onClick={goToRoute("#organisation-settings")}>
                  {t("profileMenu.organisationSettings")}
                </Button>
                <Button {...linkButtonProps} onClick={goToRoute("#legal-settings")}>
                  {t("profileMenu.legalSettings")}
                </Button>
              </Stack>
            </NotImplemented>
            <Divider color="ui.07" />
            <Box px={4} py={5}>
              <Button {...linkButtonProps} onClick={handleRedirect}>
                {t("profileMenu.signOut")}
              </Button>
            </Box>
          </PopoverContent>
        </Popover>
      )}
    </Flex>
  )
}
