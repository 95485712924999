import constate from "constate"
import { useMemo, useState } from "react"

import { CustomerType, EMPTY_ENTITY, customersOf, toCustomerType } from "../customer.utils"
import { Entity, EntityTypes, api } from "api"
import { AdminRoles } from "api/generated/models/AdminRoles"
import { useManageCustomerAdmin } from "api/hooks"
import { adminRolesForCustomer } from "features/admins"

type UseManageCustomerReturn = {
  userCustomerId: UUID
  managedCustomerId: UUID | null
  enableManagedCustomer: (id: UUID) => void
  disableManagedCustomer: () => void
  isManagingCustomer: () => boolean
}

export type ManageCustomerProviderProps = { customerId?: UUID } | undefined

const useManageCustomer = ({ customerId = "" }: ManageCustomerProviderProps = {}): UseManageCustomerReturn => {
  const { data } = useManageCustomerAdmin(true)
  const userEntityId = data?.entityId ? data?.entityId : ""

  const [managedCustomerId, setManagedCustomerId] = useState<UUID>(customerId)

  return useMemo(() => {
    const enableManagedCustomer = (id: UUID) => setManagedCustomerId(id)
    const disableManagedCustomer = () => setManagedCustomerId("")

    return {
      userCustomerId: userEntityId,
      managedCustomerId: managedCustomerId ? managedCustomerId : userEntityId,
      enableManagedCustomer,
      disableManagedCustomer,
      isManagingCustomer: () => !!managedCustomerId,
    }
  }, [managedCustomerId, userEntityId])
}

/* These are used before the user is authenticated, some api calls might fail */
export const [ManageCustomerProvider, useManageCustomerContext] = constate(useManageCustomer)

const withCustomerHelpers = (entity: Entity = EMPTY_ENTITY): UseCustomerReturn => {
  const customerType = toCustomerType(entity.entityType ?? EntityTypes.CUSTOMER)

  return {
    ...entity,
    entity: entity ?? {},
    customerType,
    childCustomerTypes: customersOf(customerType) ?? [],
    adminRoles: adminRolesForCustomer(customerType) ?? [],
  }
}

type UseCustomerReturn = Entity & {
  entity: Entity
  customerType: CustomerType
  childCustomerTypes: CustomerType[]
  adminRoles: AdminRoles[]
}

export const useManagedCustomer = (): UseCustomerReturn => {
  const { managedCustomerId } = useManageCustomerContext()
  const { data: entity } = api.useEntity({ id: managedCustomerId ?? "" })

  return withCustomerHelpers(entity ?? undefined)
}

export const useUserCustomer = () => {
  const { userCustomerId } = useManageCustomerContext()
  const { data: entity } = api.useEntity({ id: userCustomerId ?? "" })

  return withCustomerHelpers(entity ?? undefined)
}
