import { Box, Button, Icon, Text } from "@chakra-ui/react"
import { IamCloudLogo } from "@iac/components.logos.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect } from "react"
import { Outlet } from "react-router-dom"

import { useAuth } from "../useAuthWrapper"
import { useAdmin, useEntityOfUser, useManageCustomerAdmin, useTenancyPermissionsForCurrentUser } from "api/hooks"
import { Loading } from "common/components"

export const PrivateRoutes: FC = () => {
  const { t } = useTranslation()
  const { cloudVaultId, activeNavigator, isAuthenticated, isInitialLoading: isLoadingAuth, signinRedirect } = useAuth()
  const { isInitialLoading: isLoadingManageCustomerAdmin, refetch: fetchManageCustomerAdmin } =
    useManageCustomerAdmin(true)
  const { isInitialLoading: isLoadingEntityOfUser, refetch: fetchEntityOfUser } = useEntityOfUser()
  const adminId = cloudVaultId ? (cloudVaultId as UUID) : ""
  const { isInitialLoading: isLoadingAdminWithTenancyLinks, refetch: fetchUseAdminWithTenancyLinks } = useAdmin({
    id: adminId,
    expand: "tenancyLinks",
  })

  useEffect(() => {
    if (isAuthenticated) {
      fetchManageCustomerAdmin()
      fetchEntityOfUser()
      fetchUseAdminWithTenancyLinks()
    }
  }, [isAuthenticated, fetchManageCustomerAdmin, fetchEntityOfUser, fetchUseAdminWithTenancyLinks])

  const isLoading =
    isLoadingAuth || isLoadingManageCustomerAdmin || isLoadingEntityOfUser || isLoadingAdminWithTenancyLinks

  const showLoadingState = isLoading || activeNavigator === "signoutRedirect" || activeNavigator === "signinRedirect"

  if (showLoadingState) return <Loading />

  if (isAuthenticated) return <Outlet />

  const handleRedirect = () => signinRedirect()

  return (
    <Box as="main" maxW={176} mx="auto" my={28} p={6} w="full">
      <Box borderRadius="lg" p={6} shadow="modal">
        <IamCloudLogo as={Icon} height="44px" width="140px" />
        <Text mt={4} variant="heading-03">
          {t("landing.signIn.instruction")}
        </Text>
        <Button h={10} mt={14} px={4} size="small" onClick={handleRedirect}>
          {t("landing.signIn.button")}
        </Button>
      </Box>
    </Box>
  )
}
