import type { StoreFront } from "../models/StoreFront"
import { request as __request } from "../core/request"
import { trackPromise } from "react-promise-tracker"

export class StoreFrontsService {
  /**
   * Gets all Store Fronts.
   * ## Requires:
   * ### storeFronts.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async storeFrontsGetAllAsync({
    select,
    expand,
    top,
    skip,
    count = false,
    parentId,
    filter,
  }: {
    parentId: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
    /** Limits the number of items returned from a collection. **/
    top?: number
    /** Excludes the specified number of items of the queried collection from the result. **/
    skip?: number
    /** Indicates whether the total count of items within a collection are returned in the result. **/
    count?: boolean
    /** Restricts the set of items returned. For the allowed functions please refer to the Bifrost Swagger document https://bifrost.iamcloud.net/api/swagger/index.html **/
    filter?: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<StoreFront>
    readonly count: number
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${parentId}/isa/storefronts`,
        query: {
          $select: select,
          $expand: expand,
          $top: top,
          $skip: skip,
          $count: count,
          $filter: filter,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return { ...result.body, count: result.body["@odata.count"] }
  }

  /**
   * Create Store Front.
   * ## Requires:
   * ### storeFronts.Create
   * @returns any Success
   * @throws ApiError
   */
  public static async storeFrontsPostAsync({
    parentId,
    requestBody,
  }: {
    parentId: string
    requestBody?: StoreFront
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & StoreFront
  > {
    const result = await trackPromise(
      __request({
        method: "POST",
        path: `/api/tenancies/${parentId}/isa/storefronts`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Gets Store Front.
   * ## Requires:
   * ### storeFronts.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async storeFrontsGetAsync({
    id,
    parentId,
    select,
    expand,
  }: {
    parentId: string
    id: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & StoreFront
  > {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${parentId}/isa/storefronts/${id}`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Update Store Front.
   * ## Requires:
   * ### storeFronts.Update
   * @returns any Success
   * @throws ApiError
   */
  public static async storeFrontsPatchAsync({
    parentId,
    id,
    requestBody,
  }: {
    parentId: string
    id: string
    requestBody?: StoreFront
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & StoreFront
  > {
    const result = await trackPromise(
      __request({
        method: "PATCH",
        path: `/api/tenancies/${parentId}/isa/storefronts/${id}`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          409: `Conflict`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Delete the Store Front.
   * ## Requires:
   * ### storeFronts.Delete
   * @returns any Success
   * @throws ApiError
   */
  public static async storeFrontsDeleteAsync({ id, parentId }: { id: string; parentId: string }): Promise<
    {
      readonly "@odata.context"?: string
    } & StoreFront
  > {
    const result = await trackPromise(
      __request({
        method: "DELETE",
        path: `/api/tenancies/${parentId}/isa/storefronts/${id}`,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }
}
