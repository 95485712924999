import { useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import { CurrenciesTable } from "./currenciesTable/CurrenciesTable"
import { TenancyCurrency, api, mockData } from "api"
import { ConfirmModal, PageHeader, PageLayout } from "common/components"
import { TENANCY_ID } from "common/constants"
import { route } from "features/app/routes"

const CurrenciesView: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [symbol, setSymbol] = useState("")
  const [tenancyCurrencies, setTenancyCurrencies] = useState<TenancyCurrency[] | undefined>([])
  const [tenancyCurrency, setTenancyCurrency] = useState<TenancyCurrency>(mockData.initTenancyCurrency)
  const { data, isLoading } = api.useTenancyCurrencies(TENANCY_ID, { expand: "currency" })
  const { data: allCurrencies } = api.useCurrencies()
  const areNewCurrenciesAvailable = useMemo(
    () => allCurrencies?.value?.some(({ id }) => data?.value?.every(({ currencyId }) => currencyId !== id)),
    [allCurrencies, data]
  )
  const deleteTenancyCurrency = api.useDeleteTenancyCurrencyMutation(
    { onSuccess: () => onClose() },
    TENANCY_ID,
    tenancyCurrency.currencyId
  )

  const deleteCurrency = (): void => deleteTenancyCurrency.mutate(undefined, undefined)

  useEffect(() => {
    if (data?.value) {
      setTenancyCurrencies(data?.value)
      const currency = data?.value?.find((item) => item.isDefault) as TenancyCurrency
      setSymbol(currency.currency.symbol || "")
    }
  }, [data])

  useEffect(() => {
    if (search.length === 0) {
      setTenancyCurrencies(data?.value)
    } else {
      const currencies = data?.value?.filter((item) =>
        item.currency.localName?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      setTenancyCurrencies(currencies)
    }
  }, [data?.value, search])

  return (
    <>
      <PageHeader
        buttonTitle={t("currencies.addNewCurrency")}
        isAddButtonVisible={areNewCurrenciesAvailable}
        link="/settings"
        title={t("currencies.currency_other")}
        isArrow
        onClick={() => navigate(route.addCurrency.path)}
      />
      <PageLayout
        confirmModal={
          <ConfirmModal
            isOpen={isOpen}
            name={tenancyCurrency?.currency?.localName}
            type="currency"
            onClick={deleteCurrency}
            onClose={onClose}
          />
        }
        setSearch={setSearch}
        table={
          <CurrenciesTable
            loading={isLoading}
            setTenancyCurrency={setTenancyCurrency}
            symbol={symbol}
            tenancyCurrencies={tenancyCurrencies || []}
            onOpen={onOpen}
          />
        }
      />
    </>
  )
}

export { CurrenciesView }
