import { Box, Flex } from "@chakra-ui/react"
import { FC } from "react"

import { PageHeader } from "common/components/PageHeader/PageHeader"
import { Search } from "features/inventory/search/Search"

const CreditNoteView: FC = () => (
  <Box p={4}>
    <PageHeader buttonTitle="Add New Credit" title="Credit Notes" onClick={() => {}} />
    <Flex data-testid="creditNotes" direction="column" p={4}>
      <Search placeholder="Search for credit notes" titleButton="Search credit notes" onSearch={() => {}} />
    </Flex>
  </Box>
)

export { CreditNoteView }
