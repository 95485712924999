import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { VariantOptionValue, VariantOptionValuesService } from "../generated"
import { ODataOptions } from "../handmade"

export const useVariantOptionValues = (tenancyId: UUID, parentId: UUID, options?: ODataOptions) =>
  useQuery(
    ["variantOptionValues", options],
    () =>
      VariantOptionValuesService.variantOptionValuesGetAllAsync({ tenancyId, parentId, expand: "image", ...options }),
    { enabled: !!tenancyId && !!parentId }
  )

export const useVariantOptionValue = (tenancyId: UUID, parentId: UUID, id: UUID) =>
  useQuery(
    ["variantOptionValue"],
    () => VariantOptionValuesService.variantOptionValuesGetAsync({ id, tenancyId, parentId }),
    { enabled: !!id }
  )

export const useCreateVariantOptionValueMutation = (
  { onSuccess }: { onSuccess: () => void },
  tenancyId: UUID,
  parentId: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (variantOptionValue: Partial<VariantOptionValue>) =>
      VariantOptionValuesService.variantOptionValuesPostAsync({
        tenancyId,
        parentId,
        requestBody: variantOptionValue as any,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["variantOptionValues"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useUpdateVariantOptionValueMutation = (
  { onSuccess }: { onSuccess: () => void },
  tenancyId: UUID,
  parentId: UUID,
  id: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (variantOptionValue: Partial<VariantOptionValue>) =>
      VariantOptionValuesService.variantOptionsPatchAsync({
        tenancyId,
        parentId,
        id,
        requestBody: variantOptionValue as any,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["variantOptionValues"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useDeleteVariantOptionValueMutation = (
  { onSuccess }: { onSuccess: () => void },
  tenancyId: UUID,
  parentId: UUID,
  id: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(() => VariantOptionValuesService.variantOptionValuesDeleteAsync({ id, tenancyId, parentId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["variantOptionValues"])
      onSuccess()
    },
    onError: () => {},
  })
}
