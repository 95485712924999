import React from "react"

import { RouteConfigObject } from "./routes"
import {
  CompanyInfo,
  CurrenciesView,
  CurrencyChanges,
  LanguageChanges,
  LanguagesView,
  PhysicalStoreChanges,
  StoreFrontsView,
  VirtualStoreChanges,
} from "../settings"
import { TroubleshootingView } from "../settings/troubleshooting/Troubleshooting.view"

export const settingsRoutes: RouteConfigObject = {
  companyInfo: {
    path: "/settings/companyInfo",
    name: "settings.company",
    element: <CompanyInfo />,
  },
  storeFronts: {
    path: "/settings/storeFronts",
    name: "settings.storeFronts",
    element: <StoreFrontsView />,
  },
  addVirtualStore: {
    path: "/settings/storeFronts/addStore/Virtual",
    name: "storeFronts.addVirtualStore",
    element: <VirtualStoreChanges />,
  },
  addPhysicalStore: {
    path: "/settings/storeFronts/addStore/Physical",
    name: "storeFronts.addPhysicalStore",
    element: <PhysicalStoreChanges />,
  },
  editVirtualStore: {
    path: "/settings/storeFronts/Virtual/:id",
    name: "storeFronts.editVirtualStore",
    element: <VirtualStoreChanges />,
  },
  editPhysicalStore: {
    path: "/settings/storeFronts/Physical/:id",
    name: "storeFronts.editPhysicalStore",
    element: <PhysicalStoreChanges />,
  },
  languages: {
    path: "/settings/languages",
    name: "settings.languages",
    element: <LanguagesView />,
  },
  addLanguage: {
    path: "/settings/languages/addLanguage",
    name: "languages.addLanguage",
    element: <LanguageChanges />,
  },
  editLanguage: {
    path: "/settings/languages/:id",
    name: "languages.editLanguage",
    element: <LanguageChanges />,
  },
  currencies: {
    path: "/settings/currencies",
    name: "settings.currencies",
    element: <CurrenciesView />,
  },
  addCurrency: {
    path: "/settings/currencies/addCurrency",
    name: "currencies.addCurrency",
    element: <CurrencyChanges />,
  },
  editCurrency: {
    path: "/settings/currencies/:id",
    name: "currencies.editCurrency",
    element: <CurrencyChanges />,
  },
  troubleshooting: {
    path: "/settings/troubleshooting",
    name: "settings.troubleshooting",
    element: <TroubleshootingView />,
  },
}
