import { useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { CustomerOrdersTable } from "./CustomerOrdersTable"
import { Customer, Order, api, mockData } from "api"
import { PageHeader, PageLayout } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"
import { route } from "features/app/routes"

const CUSTOMER_ORDERS_TABLE = "CUSTOMER_ORDERS_TABLE"

const CustomerInfo: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { onOpen } = useDisclosure()
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useState("")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [order, setOrder] = useState<Order>(mockData.initOrder)
  const [orders, setOrders] = useState<Order[] | undefined>([])
  const [customer, setCustomer] = useState<Customer>(mockData.initCustomer)
  const [ordersCount, setOrdersCount] = useState(1)
  const { data: customerEntity } = api.useCustomerEntity(TENANCY_ID, params.id || "")

  const [storedPageSize, setStoredPageSize] = useStoredPageSize(CUSTOMER_ORDERS_TABLE)

  const [options, setOptions] = useState({
    top: storedPageSize || DEFAULT_ITEMS_PER_PAGE,
    skip: 0,
  })

  const { data, isLoading } = api.useSalesOrders(
    TENANCY_ID,
    {
      top: options.top,
      skip: options.skip,
      count: true,
      orderby: "orderDate desc",
    },
    params.id
  )

  const refetchSalesOrders = async (top: number, skip: number) => {
    setStoredPageSize(top)
    setOptions({
      top,
      skip,
    })
  }

  useEffect(() => {
    if (params.id && customerEntity) {
      setCustomer(customerEntity)
    }
  }, [customerEntity, params.id])

  useEffect(() => {
    if (data?.value) {
      setOrders(data.value)
      setOrdersCount(data.count)
    }
  }, [params.id, data])

  return (
    <>
      <PageHeader
        buttonTitle={t("editCustomer")}
        isAddIconVisible={false}
        link={route.customers.path}
        subtitle={customer.name}
        title={t("customer")}
        isArrow
        isEditIconVisible
        onClick={() => navigate(`/sales/customers/${customer.id}`)}
      />
      <PageLayout
        setSearch={setSearch}
        table={
          <CustomerOrdersTable
            itemsPerPage={options.top}
            loading={isLoading}
            orders={orders || []}
            pageCount={Math.ceil(ordersCount / options.top)}
            refetch={refetchSalesOrders}
            setOrder={setOrder}
            onOpen={onOpen}
          />
        }
      />
    </>
  )
}

export { CustomerInfo }
