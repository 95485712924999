import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { AddIcon, ChevronDownIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import React from "react"
import { Link } from "react-router-dom"

import { route } from "features/app/routes"

enum StoreTypes {
  Physical = "Physical",
  Virtual = "Virtual",
}

const StoryTypeButton = () => {
  const { t } = useTranslation()

  return (
    <Menu>
      <MenuButton
        as={Button}
        data-testid="add-store-front-bttn"
        fontWeight="normal"
        leftIcon={<AddIcon />}
        rightIcon={<ChevronDownIcon />}
        variant="primary"
      >
        {t("storeFronts.addNewStoreFront")}
      </MenuButton>
      <MenuList>
        <Link key="physical" data-testid="physical-bttn" to={route.addPhysicalStore.path}>
          <MenuItem key="physical">{t("storeFronts.physical")}</MenuItem>
        </Link>
        <Link key="virtual" data-testid="virtual-bttn" to={route.addVirtualStore.path}>
          <MenuItem key="virtual">{t("storeFronts.virtual")}</MenuItem>
        </Link>
      </MenuList>
    </Menu>
  )
}

export { StoreTypes, StoryTypeButton }
