import { FC } from "react"
import { useParams } from "react-router-dom"

import { CustomerChanges } from "./CustomerChanges"

const CustomerChangesPage: FC = () => {
  const params = useParams()

  return <CustomerChanges customerId={params.id} />
}

export { CustomerChangesPage }
