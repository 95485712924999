import { Box, Button, Flex, Input } from "@chakra-ui/react"
import { AddIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import React, { useRef, useState } from "react"
import { FieldArrayWithId, UseFormRegister } from "react-hook-form"

import { ProductVariantFormValues } from "../ChangeProductVariant"
import { StoreFront } from "api"
import { FormRichSelect } from "common/components"
import { LONG_FORM_ELEMENT_WIDTH } from "common/theme"

type AvailableStockControlProps = {
  fields: FieldArrayWithId<ProductVariantFormValues, "storeFronts">[]
  storeFronts: StoreFront[]
  register: UseFormRegister<ProductVariantFormValues>
  onAddNewStore: (id: string, stock: string) => void
}

export const AvailableStockControl = ({ fields, storeFronts, register, onAddNewStore }: AvailableStockControlProps) => {
  const { t } = useTranslation()
  const [isAddNew, setIsAddNew] = useState(false)
  const [newStoreId, setNewStoreId] = useState("")
  const [stock, setStock] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)

  const selectedStoresIds = fields.map(({ storeFrontId }) => storeFrontId)
  const storeFrontsOptions =
    storeFronts
      .filter(({ id }) => !selectedStoresIds.includes(id))
      .map((store: StoreFront) => ({ label: store.name, value: String(store.id) })) ?? []

  const onSave = () => {
    onAddNewStore(newStoreId, stock)
    setNewStoreId("")
    setStock("")
    setIsAddNew(false)
  }
  const NewInput = () => (
    <Flex mb={4} width="fit-content">
      <Flex>
        <Box mr={4} w="283px">
          <FormRichSelect options={storeFrontsOptions} value={newStoreId} onChange={(id) => setNewStoreId(id)} />
        </Box>
        {newStoreId && (
          <Input
            maxLength={9}
            placeholder="1"
            ref={inputRef}
            type="number"
            value={stock}
            w="283px"
            autoFocus
            onChange={(e) => setStock(e.target.value)}
          />
        )}
      </Flex>
      {newStoreId && (
        <Button alignSelf="end" disabled={!stock} fontSize="xs" h={6} ml={4} variant="primary" onClick={onSave}>
          {t("save")}
        </Button>
      )}
    </Flex>
  )

  return (
    <Flex direction="column" w={LONG_FORM_ELEMENT_WIDTH}>
      {fields.map((field, index) => {
        const store = storeFronts.find((store) => store.id === field.storeFrontId) as StoreFront

        return (
          <Flex key={field.id} mb={4} width="100%">
            <Input mr={4} value={store?.name || ""} disabled />
            <Input
              data-testid="variant-available-stock-store-field"
              maxLength={9}
              placeholder="1"
              {...register(`storeFronts.${index}.stock` as const, {
                valueAsNumber: true,
                required: true,
              })}
            />
          </Flex>
        )
      })}
      {isAddNew && <NewInput />}
      {!isAddNew && (
        <Button fontSize="xs" h={6} variant="primary" w="fit-content" onClick={() => setIsAddNew(true)}>
          <AddIcon mr={2} /> {t("product.addStoreFront")}
        </Button>
      )}
    </Flex>
  )
}
