import { useTranslation } from "@iac/translations.i18n-instance"

import { AdminTenancyPermission, Entity } from "api"
import { useEntityOfUser, useTenancyPermissionsForCurrentUser } from "api/hooks"
import { CENTRAL_COMMAND } from "features/app/appNames"
import { isProvider } from "features/customers/utils"

const hasCentralCommandApp = (userEntity: Entity | undefined, tenancies: AdminTenancyPermission[]) =>
  // TODO: Check this rule still applies.
  isProvider(userEntity) || tenancies.length > 1

const useCentralCommandApp = () => {
  const { t } = useTranslation()
  const { data: userEntity } = useEntityOfUser()
  const { data: tenancies, isLoading } = useTenancyPermissionsForCurrentUser()

  return {
    appName: t(CENTRAL_COMMAND),
    hasApp: !isLoading && hasCentralCommandApp(userEntity, tenancies),
    isLoading,
  }
}

export { useCentralCommandApp }
