export const shippingTerms = [
  { id: "EXW", name: "Ex Works" },
  { id: "FCA", name: "Free Carrier" },
  { id: "CPT", name: "Carriage Paid to" },
  { id: "CIP", name: "Carriage Insurance Paid To" },
  { id: "DAP", name: "Delivered At Place" },
  { id: "DPU", name: "Delivered at Place Unloaded" },
  { id: "DDP", name: "Delivered Duty Paid" },
  { id: "FAS", name: "Free Alongside Ship" },
  { id: "FOB", name: "Free on Board" },
  { id: "CFR", name: "Cost and Freight" },
  { id: "CIF", name: "Cost Insurance and Freight" },
]
