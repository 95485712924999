import { Navigate } from "react-router-dom"

import { useEntityOfUser, useTenancy } from "api/hooks"
import { Loading } from "common/components"
import { isMobileDevice } from "isInShopAccessible"

export const LandingPageRoute = () => {
  const { data: entityOfUser, isLoading: isEntityLoading } = useEntityOfUser()
  const tenancyId = entityOfUser?.defaultTenancyId ?? ""

  const { isLoading: isTenancyLoading } = useTenancy(tenancyId)

  if (isTenancyLoading || isEntityLoading) {
    return <Loading />
  }

  return <Navigate to={isMobileDevice ? "/inShop" : "/inventory"} replace />
}
