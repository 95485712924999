import { Tag, Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useTable } from "react-table"

import { StoreTypes } from "../storeTypeButton/StoryTypeButton"
import { StoreFront } from "api"
import { Pagination, Table, TableItemMenu } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE } from "common/constants"

type StoreFrontsTableProps = {
  loading: boolean
  onOpen: () => void
  storeFronts: StoreFront[]
  refetch: (top: number, skip: number) => void
  setStoreFront: Dispatch<SetStateAction<StoreFront>>
  pageCount: number
  itemsPerPage: number
  search: string
}

type FullAddress = {
  addressLine1: string
  addressLine2: string
  city: string
  region: string
  postCode: string
}

const StoreFrontsTable: FC<StoreFrontsTableProps> = ({
  storeFronts = [],
  setStoreFront,
  onOpen,
  loading,
  refetch,
  pageCount,
  itemsPerPage,
  search,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const NameCell: FC<{ store: StoreFront }> = ({ store }) => (
    <TableItemMenu
      deleteClick={() => {
        setStoreFront(store)
        onOpen()
      }}
      editClick={() =>
        store.type === StoreTypes.Physical ? navigate(`Physical/${store.id}`) : navigate(`Virtual/${store.id}`)
      }
      value={store.name}
    />
  )

  const parseAddress = (address: string): string => {
    if (address) {
      const parsedAddress: FullAddress = address && JSON.parse(address)
      const { addressLine1, addressLine2, city, region, postCode } = parsedAddress

      return `${addressLine1}, ${addressLine2}, ${city}, ${region}, ${postCode}`
    }

    return ""
  }

  const columnData: Column<StoreFront>[] = [
    {
      Header: t("name") as string,
      accessor: "name",
      Cell: ({ row }) => <NameCell store={row.original} />,
    },
    {
      Header: t("storeFronts.type") as string,
      accessor: "type",
      Cell: ({ row }) => <Text>{row.original.type}</Text>,
    },
    {
      Header: t("address") as string,
      // @ts-ignore
      Cell: ({ row }) => (
        <Text>
          {row.original.type === StoreTypes.Virtual ? row.original.address : parseAddress(row.original.address)}
        </Text>
      ),
    },
    {
      Header: t("status_label") as string,
      accessor: "status",
      Cell: ({ row }) => (
        <Tag
          background={row.original.status === "Active" ? "success.02" : "warning.02"}
          borderColor={row.original.status === "Active" ? "success.03" : "warning.03"}
          borderRadius={2}
          color="text.01"
          fontSize={12}
        >
          {t(`statuses.${row.original.status}`)}
        </Tag>
      ),
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setPageSize,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, []),
      data: useMemo(() => storeFronts, [storeFronts]),
      initialState: {
        pageSize: itemsPerPage,
      },
      manualPagination: true,
      pageCount,
    },
    usePagination
  )

  useEffect(() => {
    gotoPage(0)
    refetch(pageSize, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<StoreFront>): ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} data-testid="store-fronts-table" width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR bg="white" color="#36495E" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Table.TH {...column.getHeaderProps()} _first={{ width: "20%" }} textTransform="capitalize">
                {column.render("Header")}
              </Table.TH>
            ))}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()} textAlign="left">
        {loading ? (
          <Table.Skeleton columnWidths={[32, 16, 32, 64]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={(event) => {
                setPageSize(event)
                refetch(event, 0)
              }}
              totalPages={pageOptions.length}
              onPageChange={(pageIndex) => {
                gotoPage(pageIndex)
                refetch(pageSize, pageIndex * pageSize)
              }}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { StoreFrontsTable }
