import { useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { SuppliersTable } from "./suppliersTable/SuppiersTable"
import { Supplier, api, mockData } from "api"
import { ConfirmModal, PageHeader, PageLayout } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"
import { route } from "features/app/routes"

const SUPPLIERS_TABLE = "SUPPLIERS_TABLE"

const SuppliersView: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [search, setSearch] = useState("")
  const [supplier, setSupplier] = useState<Supplier>(mockData.initSupplier)
  const [suppliers, setSuppliers] = useState<Supplier[] | undefined>([])
  const deleteSupplierMutation = api.useDeleteSupplierMutation({ onSuccess: () => onClose() }, TENANCY_ID, supplier.id)
  const [storedPageSize, setStoredPageSize] = useStoredPageSize(SUPPLIERS_TABLE)

  const [options, setOptions] = useState({
    skip: 0,
    top: storedPageSize ?? DEFAULT_ITEMS_PER_PAGE,
  })

  const { data, isLoading } = api.useSuppliers(TENANCY_ID, {
    top: options.top,
    skip: options.skip,
    count: true,
  })

  const refetchSuppliers = async (top: number, skip: number) => {
    setOptions({
      ...options,
      top,
      skip,
    })
    setStoredPageSize(top)
  }

  const deleteSupplier = (): void => deleteSupplierMutation.mutate()

  useEffect(() => {
    if (data && data.length > 0) {
      setSuppliers(data)
    }
  }, [data])

  useEffect(() => {
    if (data.length > 0) {
      if (search.length === 0) {
        setSuppliers(data)
      } else {
        const findSupplier = data?.filter((item: Supplier) =>
          item.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        )
        setSuppliers(findSupplier)
      }
    }
  }, [data, search])

  return (
    <>
      <PageHeader
        buttonTitle={t("addNewSupplier")}
        link="/purchase"
        title={t("suppliers_other")}
        isArrow
        onClick={() => navigate(route.addSupplier.path)}
      />
      <PageLayout
        confirmModal={
          <ConfirmModal
            isOpen={isOpen}
            name={supplier.name || ""}
            type="supplier"
            isDelete
            onClick={deleteSupplier}
            onClose={onClose}
          />
        }
        setSearch={setSearch}
        table={
          <SuppliersTable
            itemsPerPage={options.top}
            loading={isLoading}
            refetch={refetchSuppliers}
            setSupplier={setSupplier}
            suppliers={suppliers || []}
            onOpen={onOpen}
          />
        }
      />
    </>
  )
}

export { SuppliersView }
