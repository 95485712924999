import { Box, Button, Flex, Input } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { AddIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { GroupBase, OptionBase, Select } from "chakra-react-select"
import { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"

import { useValidationSchemes } from "../utils/validationSchemеs"
import { ProductAPI, VariantTypeOption, api } from "api"
import { FormField } from "common/components"
import { CommonFormControl } from "common/components/CommonFormControl/CommonFormControl"
import { TENANCY_ID } from "common/constants"
import { SHORT_FORM_ELEMENT_WIDTH, formStyles } from "common/theme"

type SelectOption = {
  label: string
  value: string
} & OptionBase

type InitialStockProps = {
  productAPI: ProductAPI
  onIsValidChange: (isValid: boolean) => void
  getValuesRef: MutableRefObject<() => Partial<ProductAPI>>
  variantsState: [
    {
      id: UUID

      name: string
      optionValues: { id: string; value: string }[]
    }[],
    Dispatch<
      SetStateAction<
        {
          id: UUID
          name: string
          optionValues: { id: string; value: string }[]
        }[]
      >
    >
  ]
}

type ProductFormValues = {
  initialStock: string
  initialCost: string
  carryingCost: string | null
  retailPrice: string
}

const DEFAULT_VARIANT_NAME = "Default"

const InitialStock: FC<InitialStockProps> = ({ onIsValidChange, getValuesRef, productAPI, variantsState }) => {
  const { t } = useTranslation()
  const { initialStockSchema } = useValidationSchemes()

  const [variants, setVariants] = variantsState

  const [variantsMap, setVariantsMap] = useState<VariantTypeOption[]>([
    {
      id: "",
      tenancyId: "",
      name: "",
      description: "",
      variantOptionValues: [],
      translations: [],
    },
  ])

  const [productStoreFronts, setProductStoreFronts] = useState<{ label: string; value: string }[]>([])
  const { data: variantOptionsData } = api.useVariantOptions(TENANCY_ID, { expand: "variantoptionvalues" })
  const { data: storeFrontsData } = api.useStoreFronts(TENANCY_ID, { filter: `status ne 'Inactive'` })

  const variantsSelectOptions = useMemo(
    () => variantOptionsData?.value?.map(({ id, name }) => ({ label: name, value: id })),
    [variantOptionsData]
  )

  useEffect(() => {
    if (productAPI.storeFronts.length) {
      setProductStoreFronts(
        productAPI.storeFronts
          .map((item) => {
            const store = storeFrontsData?.value?.find((store) => store.id === item.storeFrontId)

            return { label: store ? store.name : "not found", value: item.storeFrontId }
          })
          .filter((store) => store.label !== "not found")
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFrontsData])

  const {
    formState: { errors, isValid },
    getValues,
    register,
  } = useForm<ProductFormValues>({
    resolver: zodResolver(initialStockSchema),
    mode: "all",
    defaultValues: {
      initialStock: "",
      initialCost: "",
      carryingCost: null,
      retailPrice: "",
    },
  })
  const formFieldProps = { errors, register }

  const isInvalid =
    !isValid || !productStoreFronts[0] || variants.map((variant) => variant.optionValues).flat().length === 0

  useEffect(() => {
    onIsValidChange(!isInvalid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalid])

  useEffect(() => {
    getValuesRef.current = () => {
      const object = getValues()
      const storeFronts = productStoreFronts.map((item) => ({ storeFrontId: item.value, stock: 0 }))

      return {
        ...object,
        initialStock: +object.initialStock,
        initialCost: +object.initialCost,
        carryingCost: object.carryingCost ? +object.carryingCost : null,
        retailPrice: +object.retailPrice,
        storeFronts,
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productStoreFronts])

  const setVariantOption = (id: string, index: number): void => {
    const variant = variantOptionsData?.value?.find((item) => item.id === id) as VariantTypeOption
    if (variant) {
      const mappedVariants = variantsMap.map((mapVariant, i) =>
        i === index && mapVariant.id !== variant.id ? variant : mapVariant
      )
      setVariantsMap(mappedVariants)
      setVariants((variants) =>
        variants.filter((variant) => mappedVariants.some((findVariant) => findVariant.id === variant.id))
      )
    }
  }

  const onSelectedItemsChange = (selectedItems: readonly SelectOption[], option: VariantTypeOption): void => {
    if (selectedItems) {
      const optionFind = variants.find((item) => item.id === option.id)
      if (optionFind) {
        const replaced = variants.map((item) => {
          if (item.id === option.id) {
            return {
              id: item.id,
              name: item.name,
              optionValues: selectedItems.map((item) => ({ id: item.value, value: item.label })),
            }
          }

          return item
        })
        setVariants(replaced)
      } else {
        setVariants([
          ...variants,
          {
            id: option.id,
            name: option.name,
            optionValues: selectedItems.map((item) => ({ id: item.value, value: item.label })),
          },
        ])
      }
    }
  }

  useEffect(() => {
    if (variantOptionsData?.value && (variantsMap[0].name === "" || variantsMap[0].name === DEFAULT_VARIANT_NAME)) {
      const defaultVariant = variantOptionsData?.value?.find(
        (item) => item.name === DEFAULT_VARIANT_NAME
      ) as VariantTypeOption
      if (defaultVariant) {
        if (variantsMap.length > 1 || variantsMap[0].name === "") setVariantsMap([defaultVariant])
        const defaultVariantOptionValue = {
          label: defaultVariant.variantOptionValues[0].value,
          value: defaultVariant.variantOptionValues[0].id,
        }
        onSelectedItemsChange([defaultVariantOptionValue], defaultVariant)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantOptionsData, variantsMap])

  return (
    <>
      <FormField<ProductFormValues>
        label={t("product.initialStock")}
        name="initialStock"
        isRequired
        {...formFieldProps}
        stackProps={formStyles.control}
      >
        <Input data-testid="product-initial-stock-field" placeholder="1" />
      </FormField>
      <FormField<ProductFormValues>
        label={t("product.initialCostOfGoods")}
        name="initialCost"
        isRequired
        {...formFieldProps}
        stackProps={formStyles.control}
      >
        <Input data-testid="product-initial-cost-field" placeholder="1" />
      </FormField>
      <FormField<ProductFormValues>
        label={t("product.carryingCost")}
        name="carryingCost"
        {...formFieldProps}
        stackProps={formStyles.control}
      >
        <Input data-testid="product-carrying-cost-field" placeholder="1" />
      </FormField>
      <FormField<ProductFormValues>
        label={t("product.rrp")}
        name="retailPrice"
        isRequired
        {...formFieldProps}
        stackProps={formStyles.control}
      >
        <Input data-testid="product-rrp-field" placeholder="1" />
      </FormField>
      <CommonFormControl {...formStyles.control} label={t("product.variants")} isRequired>
        <Flex align="flex-start" direction="column" justify="center" width={SHORT_FORM_ELEMENT_WIDTH}>
          {variantsMap.map((variant, index) => (
            <Flex
              key={variant.id}
              data-testid="product-variants-select"
              direction="column"
              justify="center"
              marginBottom={3}
              width="100%"
            >
              <Select
                menuPlacement="top"
                options={
                  variantsSelectOptions?.filter((opt) => {
                    const isOptionSelected = variantsMap.find((v) => v.id === opt.value)

                    return index > 0 ? !(isOptionSelected || opt.label === DEFAULT_VARIANT_NAME) : !isOptionSelected
                  }) || []
                }
                value={variantsSelectOptions?.find((opt) => (opt as any).value === variantsMap[index].id)}
                onChange={(option) => {
                  if (option) setVariantOption(option.value, index)
                }}
              />
              {variant.id && variant.name !== DEFAULT_VARIANT_NAME && (
                <Box data-testid="product-option-select" flex={1} pos="relative" py={3} width="100%">
                  <Select<SelectOption, true, GroupBase<SelectOption>>
                    isClearable={false}
                    menuPlacement="top"
                    options={variant.variantOptionValues?.map((item: { value: string; id: string }) => ({
                      label: item.value,
                      value: item.id,
                    }))}
                    placeholder={t("product.writeDownTheVariant")}
                    isMulti
                    onChange={(selectedItems) => onSelectedItemsChange(selectedItems, variant)}
                  />
                </Box>
              )}
            </Flex>
          ))}
          <Button
            data-testid="product-add-more-variants-button"
            disabled={variantsMap[variantsMap.length - 1].id === "" || variantsMap[0].name === DEFAULT_VARIANT_NAME}
            variant="primary"
            onClick={() => {
              if (variantsMap.length === variantOptionsData?.value?.length) return
              setVariantsMap([
                ...variantsMap,
                {
                  id: "",
                  tenancyId: "",
                  name: "",
                  description: "",
                  translations: [],
                  variantOptionValues: [],
                },
              ])
            }}
          >
            <AddIcon mr={3} />
            {t("product.addMore")}
          </Button>
        </Flex>
      </CommonFormControl>
      <CommonFormControl {...formStyles.control} label={t("product.storeFront")} isRequired>
        <Box data-testid="product-store-fronts-button" width={SHORT_FORM_ELEMENT_WIDTH}>
          <Select<SelectOption, true, GroupBase<SelectOption>>
            isClearable={false}
            menuPlacement="top"
            options={storeFrontsData?.value?.map((store) => ({ label: store.name, value: store.id }))}
            placeholder={t("select")}
            value={productStoreFronts}
            isMulti
            onChange={(selectedItems) =>
              setProductStoreFronts(selectedItems.map((item) => ({ label: item.label, value: item.value })))
            }
          />
        </Box>
      </CommonFormControl>
    </>
  )
}

export { InitialStock }
