import { FormControl, FormLabel, Select } from "@chakra-ui/react"
import { ChangeEvent, FC } from "react"

export type FormSelectProps = {
  label: string
  name: string | null
  selectWidth?: string
  controlWidth?: string
  value: { id: string; name: string } | null
  data?: { id: string; name: string | undefined }[]
  stringData?: string[]
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  isRequired?: boolean
}

export const FormSelect: FC<FormSelectProps> = ({
  label,
  controlWidth = "20%",
  selectWidth = "100%",
  name = "",
  value,
  onChange,
  data,
  stringData,
  isRequired = false,
}) => {
  const formStyles = {
    control: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      width: controlWidth,
      marginBottom: 30,
    },
    label: {
      margin: "0 0 .5rem 0",
    },
  }

  return (
    <FormControl isRequired={isRequired} style={{ ...formStyles.control, flexDirection: "column" }}>
      <FormLabel style={formStyles.label}>{label}</FormLabel>
      <Select
        name={name || ""}
        placeholder="Select a type"
        value={value?.id || ""}
        width={selectWidth}
        onChange={onChange}
      >
        {stringData
          ? stringData.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))
          : data?.map(({ name, id }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
      </Select>
    </FormControl>
  )
}
