export const buttonStyle = (isLongThan750: boolean) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "text.10",
  background: "customer.consumer.border",
  fontSize: 20,
  height: isLongThan750 ? "76px" : "3rem",
  borderRadius: 10,
  cursor: "pointer",
  fontWeight: "normal",
  boxShadow: "temp",
})

export const sizes = {
  sm: {
    width: 55,
  },
  md: {
    width: 73,
  },
  lg: {
    width: 110,
  },
}
