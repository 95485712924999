import { InventoryTypes } from "../inventoryTypes"

export const getAvailability = (type: InventoryTypes): string => {
  switch (type) {
    case InventoryTypes.Stocked:
      return InventoryTypes.Stocked
    case InventoryTypes.Artisanal:
      return InventoryTypes.Artisanal
    case InventoryTypes.OnRequest:
      return InventoryTypes.OnRequest
    default:
      return ""
  }
}
