import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react"

import { SelectedProduct } from "./AssociatedProductsControl"
import { SelectedProducts } from "./SelectedProducts"
import { api } from "api"
import { FoundProductsList } from "common/components"
import { SearchPanel } from "common/components/SearchPanel/SearchPanel"
import { TENANCY_ID } from "common/constants"
import { ProductsContext } from "features/inShop/product/ProductState/context"

type Props = {
  isOpen: boolean
  onClose: () => void
  isCrossLinked: boolean
  onDone: (products: SelectedProduct[], variants: SelectedProduct[]) => void
  preselectedProducts: SelectedProduct[]
  preselectedVariants: SelectedProduct[]
}

const MAX_ASSOCIATED_PRODUCTS_NUMBER = 5
const MAX_CROSS_LINKED_PRODUCTS_NUMBER = 20

const AssociatedProductsModal: FC<Props> = ({
  isOpen,
  onClose,
  isCrossLinked,
  onDone,
  preselectedProducts,
  preselectedVariants,
}) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState("")
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(preselectedProducts)
  const [selectedVariants, setSelectedVariants] = useState<SelectedProduct[]>(preselectedVariants)
  const maxProductsNumber = isCrossLinked ? MAX_CROSS_LINKED_PRODUCTS_NUMBER : MAX_ASSOCIATED_PRODUCTS_NUMBER
  const {
    state: {
      globalEntities: { storeFrontId },
    },
  } = useContext(ProductsContext)
  const { data: searchingProducts } = api.useSearchProducts(TENANCY_ID, storeFrontId, search)

  useEffect(() => {
    setSelectedProducts(preselectedProducts)
    setSelectedVariants(preselectedVariants)
  }, [preselectedProducts, preselectedVariants, isOpen])

  const setSelectedProductsOrVariants = (
    product: SelectedProduct,
    setState: Dispatch<SetStateAction<SelectedProduct[]>>
  ) =>
    setState((prev) =>
      [...selectedProducts, ...selectedVariants].length >= maxProductsNumber || prev.some((p) => p.id === product.id)
        ? prev
        : [...prev, product]
    )

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      motionPreset="slideInBottom"
      scrollBehavior="inside"
      size="2xl"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent maxHeight="calc(100% - 9rem)" p={4} pb={0} top="0rem">
        <ModalHeader pb={0} px={4}>
          {isCrossLinked
            ? t("product.associatedProducts.searchProductToCrossLink")
            : t("product.associatedProducts.searchProductToAssociate")}
          <Text color="info-01" fontSize="14px" fontWeight="normal" height="22px" mb="-22px" mt="15px">
            {!!searchingProducts?.value?.length &&
              t("product.associatedProducts.doubleClickOnProductOrVariantToBeAdded")}
          </Text>

          <SearchPanel
            buttonVariant="primary"
            paddingLeft={0}
            placeholder={t("product.associatedProducts.searchProductOrVariant")}
            width="85%"
            onSearch={(s) => setSearch(s)}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px={4}>
          <Box width="442px">
            <FoundProductsList
              products={searchingProducts?.value}
              onProductDoubleClick={(product) => setSelectedProductsOrVariants(product, setSelectedProducts)}
              onVariantDoubleClick={(variant) => setSelectedProductsOrVariants(variant, setSelectedVariants)}
            />
          </Box>
        </ModalBody>
        <ModalFooter
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          pb={8}
          pl={4}
          pr={0}
          pt={1}
          //scroll="auto"
        >
          {(!!preselectedProducts.length ||
            !!preselectedVariants.length ||
            !!selectedProducts.length ||
            !!selectedVariants.length) && (
            <SelectedProducts
              deleteProducts={(ids) => {
                setSelectedProducts((products) => products.filter((product) => !ids.includes(product.id)))
                setSelectedVariants((variants) => variants.filter((variant) => !ids.includes(variant.id)))
              }}
              products={[...selectedProducts, ...selectedVariants]}
              onClose={onClose}
              onDone={() => {
                onDone(selectedProducts, selectedVariants)
                setSearch("")
              }}
            />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export { AssociatedProductsModal }
