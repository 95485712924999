import { Button, Flex, Input, Select, Switch, Textarea, VStack, useDisclosure } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as z from "zod"

import { FullSyncModal } from "./FullSyncModal"
import { GenerateKeyModal } from "./GenerateKeyModal"
import { HydrateStoreFrontModal } from "./HydrateStoreFrontModal"
import { FormValuesVirtual, WebsiteTypes, getVirtualModel } from "./StoreFrontUtils"
import { StoreTypes } from "../storeTypeButton/StoryTypeButton"
import { api } from "api"
import { FormField } from "common/components"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { TENANCY_ID } from "common/constants"
import { Statuses } from "common/statuses"
import { formStyles } from "common/theme"
import { numbersNlettersNspecialCharacters, webAddress } from "common/validation"
import { route } from "features/app/routes"
import { ProductsContext } from "features/inShop/product/ProductState/context"

const VirtualStoreChanges = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    state: {
      globalEntities: { tenancyCurrencies, tenancyLanguages },
    },
  } = useContext(ProductsContext)
  const [name, setName] = useState<string | undefined>("")
  const { onOpen: onHydrateOpen, onClose: onHydrateClose, isOpen: isHydrateOpen } = useDisclosure()
  const { onOpen: onGenerateOpen, onClose: onGenerateClose, isOpen: isGenerateOpen } = useDisclosure()
  const { onOpen: onFullSyncOpen, onClose: onFullSyncClose, isOpen: isFullSyncOpen } = useDisclosure()
  const { data: storeFront } = api.useStoreFront(TENANCY_ID, params.id || "")
  const createStoreFrontMutation = api.useCreateStoreFrontsMutation(
    {
      onSuccess: () => navigate(route.storeFronts.path),
    },
    TENANCY_ID
  )
  const updateStoreFrontMutation = api.useUpdateStoreFrontsMutation(
    {
      onSuccess: () => navigate(route.storeFronts.path),
    },
    TENANCY_ID,
    params.id || ""
  )

  const websiteTypes: string[] = [WebsiteTypes.WooCommerce]
  const statuses: string[] = [Statuses.Active, Statuses.Inactive]

  const schema = z.object({
    name: z
      .string()
      .max(30)
      .nonempty({ message: t("validation.required", { field: "name" }) })
      .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") }),
    description: z.string().optional(),
    type: z.string().nonempty({ message: t("validation.select", { field: "store type" }) }),
    address: z
      .string()
      .nonempty({ message: t("validation.required", { field: "web address" }) })
      .regex(webAddress, {
        message: t("settings.storeFronts.websiteValidation"),
      })
      .optional(),
    status: z.string().nonempty({ message: t("validation.select", { field: "status" }) }),
    currencyId: z.string().nonempty({ message: t("validation.select", { field: "currency" }) }),
    languageId: z.string().nonempty({ message: t("validation.select", { field: "language" }) }),
    websiteType: z.string().nonempty({ message: t("validation.select", { field: "website type" }) }),
    isDefault: z.boolean().optional(),
  })

  const {
    formState: { errors, isDirty, isValid },
    getValues,
    register,
    watch,
    reset,
  } = useForm<FormValuesVirtual>({
    resolver: zodResolver(schema),
    mode: "all",
    defaultValues: {
      name: "",
      description: "",
      type: StoreTypes.Virtual,
      address: "",
      status: "",
      currencyId: "",
      languageId: "",
      websiteType: "",
      isDefault: false,
    },
  })
  const watchIsDefault = watch("isDefault")
  watch("websiteType")
  const isWebsite = getValues().websiteType && getValues().websiteType !== "0"
  const isInvalid = !isDirty || !isValid
  const formFieldProps = { errors, register }

  const changesStore = (): void => {
    const formValues = getValues()
    const object = getVirtualModel(formValues)
    if (params.id) {
      updateStoreFrontMutation.mutate(object)
    } else {
      createStoreFrontMutation.mutate(object)
    }
  }

  useEffect(() => {
    if (params.id && storeFront) {
      setName(storeFront.name)
      reset({
        name: storeFront.name,
        description: storeFront.description,
        type: storeFront.type,
        address: storeFront.address,
        status: storeFront.status,
        currencyId: storeFront.currencyId,
        languageId: storeFront.languageId,
        websiteType: storeFront.websiteType,
        isDefault: storeFront.isDefault,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, storeFront])

  return (
    <VStack align="start" data-testid="changes-store-container">
      <PageHeader
        title={
          params.id ? `${t("editItem", { name })} ${t("storeFront")}` : t("addNew", { item: t("storeFront_other") })
        }
      />
      <Flex data-testid="changes-store-form" direction="column" pl={20} pr={4} py={4} width="100%">
        <FormField<FormValuesVirtual>
          label={t("name")}
          name="name"
          isRequired
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Input data-testid="store-front-name-field" maxLength={30} placeholder={t("storeFronts.placeholder.name")} />
        </FormField>
        <FormField<FormValuesVirtual>
          label={t("storeFronts.description")}
          name="description"
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Textarea
            data-testid="store-front-description-field"
            placeholder={t("storeFronts.placeholder.description")}
          />
        </FormField>
        <FormField<FormValuesVirtual>
          label={t("storeFronts.type")}
          name="type"
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input
            data-testid="store-front-type-field"
            value={getValues().type === StoreTypes.Physical ? t("storeFronts.physical") : t("storeFronts.virtual")}
            readOnly
          />
        </FormField>
        <FormField<FormValuesVirtual>
          label={t("storeFronts.webAddress")}
          name="address"
          isRequired
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Input data-testid="store-front-web-address-field" placeholder={t("storeFronts.placeholder.webAddress")} />
        </FormField>
        <FormField<FormValuesVirtual>
          label={t("status_label")}
          name="status"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Select data-testid="store-front-status-select">
            <option value="" disabled>
              {t("select")}
            </option>
            {statuses.map((item) => (
              <option key={item} value={item}>
                {t(`statuses.${item}`)}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField<FormValuesVirtual>
          label={t("storeFronts.currency")}
          name="currencyId"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Select data-testid="store-front-currency-select">
            <option value="" disabled>
              {t("select")}
            </option>
            {tenancyCurrencies.map(({ currencyId, currency: { localName } }) => (
              <option key={currencyId} value={currencyId}>
                {localName}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField<FormValuesVirtual>
          label={t("storeFronts.language")}
          name="languageId"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Select data-testid="store-front-language-select">
            <option value="" disabled>
              {t("select")}
            </option>
            {tenancyLanguages?.map(({ languageId, language: { name } }) => (
              <option key={languageId} value={languageId}>
                {name}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField<FormValuesVirtual>
          label={t("storeFronts.websiteType")}
          name="websiteType"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Select data-testid="store-front-website-select">
            <option value="" disabled>
              {t("select")}
            </option>
            {websiteTypes.map((websiteType) => (
              <option key={websiteType} value={websiteType}>
                {websiteType}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField<FormValuesVirtual>
          label={t("storeFronts.defaultProducts")}
          name="isDefault"
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Switch data-testid="store-front-default-products-switch" isChecked={watchIsDefault} />
        </FormField>

        <Flex align="center" gridGap="12" justify="flex-start" py={4}>
          <Button
            data-testid="store-front-done-button"
            isDisabled={isInvalid}
            variant="primary"
            width="5rem"
            onClick={changesStore}
          >
            {t("done")}
          </Button>
          {isWebsite && (
            <Button borderColor="#0073b3" color="#0073b3" data-testid="store-front-integrate-button" variant="outline">
              Integrate with {getValues().websiteType}
            </Button>
          )}
          <Button data-testid="store-front-hydrate-button" onClick={onHydrateOpen}>
            {t("storeFronts.hydrateStoreFront")}
          </Button>
          {getValues().websiteType === WebsiteTypes.WooCommerce && (
            <>
              <Button data-testid="store-front-generate-key-button" onClick={onGenerateOpen}>
                {t("storeFronts.generateKey")}
              </Button>
              <Button onClick={onFullSyncOpen}>{t("storeFronts.fullSync")}</Button>
            </>
          )}
          <Button
            data-testid="store-front-cancel-button"
            variant="secondary"
            width="5rem"
            onClick={() => navigate(route.storeFronts.path)}
          >
            {t("cancel")}
          </Button>
        </Flex>
      </Flex>
      <FullSyncModal isOpen={isFullSyncOpen} onClose={onFullSyncClose} />
      <GenerateKeyModal isOpen={isGenerateOpen} onClose={onGenerateClose} />
      <HydrateStoreFrontModal isOpen={isHydrateOpen} onClose={onHydrateClose} />
    </VStack>
  )
}

export { VirtualStoreChanges }
