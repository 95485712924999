import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { CategoryForm } from "./CategoryForm"
import { Category, TenancyLanguage, api, mockData } from "api"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { TENANCY_ID } from "common/constants"
import { route } from "features/app/routes"

const EditProductCategory: FC = () => {
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const [category, setCategory] = useState<Category>(mockData.initCategory)
  const { data } = api.useProductCategory(TENANCY_ID, params.id || "")
  const { data: tenancyLanguages } = api.useTenancyLanguages(TENANCY_ID)
  const updateProductCategory = api.useUpdateProductCategoriesMutation(
    { onSuccess: () => navigate(route.categories.path) },
    TENANCY_ID,
    params.id || ""
  )

  const editCategory = (category: Partial<Category>): void => {
    const language = tenancyLanguages?.value?.find((item) => item.isDefault && item.isEnabled) as TenancyLanguage
    const object = {
      imageId: category.imageId,
      taxCategoryId: category.taxCategoryId,
      status: category.status,
      translations: [
        {
          languageId: language.languageId,
          value: JSON.stringify({
            Name: category.name,
            Description: category.description,
          }),
        },
      ],
    }
    updateProductCategory.mutate(object)
  }

  useEffect(() => {
    if (data) {
      setCategory(data)
      setName(data.name)
    }
  }, [data, params.id])

  return (
    <>
      <PageHeader title={t("editItem", { name })} />
      <CategoryForm category={category} isEdit onDone={editCategory} />
    </>
  )
}

export { EditProductCategory }
