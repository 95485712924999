import React from "react"

import { RouteConfigObject } from "./routes"
import {
  CreditNoteView,
  CustomerChangesPage,
  CustomerInfo,
  CustomersView,
  InvoiceView,
  PaymentView,
  SalesChannelsView,
  SalesOrderDetail,
  SalesOrdersView,
  ShipmentMethodsChanges,
  ShipmentMethodsView,
} from "../sales"

export const salesRoutes: RouteConfigObject = {
  customers: {
    path: "/sales/customers",
    name: "sales.customers",
    element: <CustomersView />,
  },
  customerInfo: {
    path: "/sales/customers/info/:id",
    name: "customers.customer",
    element: <CustomerInfo />,
  },
  customerOrder: {
    path: "/sales/customers/info/:id/salesOrder/:orderId",
    name: "customers.customer",
    element: <SalesOrderDetail />,
  },
  editCustomerOrder: {
    path: "/sales/customers/:id/:orderId",
    name: "customers.customer",
    element: <SalesOrderDetail />,
  },
  customersAdd: {
    path: "/sales/customers/add",
    name: "customers.addCustomer",
    element: <CustomerChangesPage />,
  },
  customersEdit: {
    path: "/sales/customers/:id",
    name: "customers.editCustomer",
    element: <CustomerChangesPage />,
  },
  salesChannels: {
    path: "/sales/salesChannels",
    name: "sales.salesChannels",
    element: <SalesChannelsView />,
  },
  salesOrders: {
    path: "/sales/salesOrders",
    name: "sales.salesOrders",
    element: <SalesOrdersView />,
  },
  salesOrderDetail: {
    path: "/sales/salesOrders/:orderId",
    name: "sales.salesOrders",
    element: <SalesOrderDetail />,
  },
  shipment: {
    path: "/sales/shipmentMethods",
    name: "sales.shipmentMethods",
    element: <ShipmentMethodsView />,
  },
  shipmentAdd: {
    path: "/sales/shipmentMethods/add",
    name: "shipmentMethods.add",
    element: <ShipmentMethodsChanges />,
  },
  shipmentEdit: {
    path: "/sales/shipmentMethods/:id",
    name: "shipmentMethods.edit",
    element: <ShipmentMethodsChanges />,
  },
  invoice: {
    path: "/sales/invoice",
    name: "sales.invoice",
    element: <InvoiceView />,
  },
  payment: {
    path: "/sales/payment",
    name: "sales.payment",
    element: <PaymentView />,
  },
  creditNote: {
    path: "/sales/creditNote",
    name: "sales.creditNote",
    element: <CreditNoteView />,
  },
}
