import { FC, useReducer } from "react"
import { useAuth } from "react-oidc-context"

import { useAdminsAckbarCommands } from "features/admins"
import { Command, CommandContext, CommandState, commandReducer, initState } from "features/commands"
import { useCentralCommandAckbarCommands } from "features/customers"

type Props = {
  overrides?: Partial<CommandState>
}

const CommandProvider: FC<Props> = ({ children, overrides = {} }) => {
  const [state, dispatch] = useReducer(commandReducer, { ...initState, ...overrides })
  const { isAuthenticated } = useAuth()
  const hiddenCommands = [false, isAuthenticated ? "auth > logIn" : "auth > logOut"].filter(Boolean)

  const appCommands: Command[] = [...useAdminsAckbarCommands(), ...useCentralCommandAckbarCommands()]

  const commands = [
    ...state.commands?.filter(
      (command) => (isAuthenticated || command.id.startsWith("auth")) && !hiddenCommands.includes(command.id)
    ),
    ...appCommands,
  ]

  return (
    <CommandContext.Provider value={{ state: { ...state, commands }, dispatch }}>{children}</CommandContext.Provider>
  )
}

export type { Props as CommandProviderProps }

export { CommandProvider }
