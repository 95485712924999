import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Switch,
  Text,
} from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useState } from "react"

type FullSyncModalProps = {
  isOpen: boolean
  onClose: () => void
}

const FullSyncModal: FC<FullSyncModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation()
  const [preClearDown, setPreClearDown] = useState(false)

  const onPreClearDown = () => {}

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton data-testid="close-button" />
        <ModalBody mt={4}>
          <FormControl>
            <FormLabel>{t("storeFronts.preClearDown")}</FormLabel>
            <Switch
              data-testid="store-front-pre-clear-down-switch"
              isChecked={preClearDown}
              onChange={(e) => setPreClearDown(e.target.checked)}
            />
          </FormControl>
          <Flex direction="column" mt={4} opacity={preClearDown ? 1 : 0} pb={2}>
            <Text>{t("storeFronts.preClearDownConfirm")}</Text>
            <Flex alignItems="center" justifyContent="space-between" pt={4}>
              <Button data-testid="yes-button" variant="primary" width="5rem" onClick={onPreClearDown}>
                {t("yes")}
              </Button>
              <Button data-testid="no-button" variant="secondary" width="5rem" onClick={() => setPreClearDown(false)}>
                {t("no")}
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export { FullSyncModal }
