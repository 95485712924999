import { Currency } from "../generated"

export const initCurrency = {
  id: "",
  localName: "",
  iso: "",
  symbol: "",
  position: "",
  delimiter: "",
  tenancyCurrencies: [],
} as Currency
