import { Entity } from "api"
import { RouteConfig, routes } from "features/app/routes"

export type CommandId =
  | "auth > signin"
  | "auth > signout"
  | "clipboard > copyToken"
  | "clipboard > copyUser"
  | "customer > manage > enable"
  | "customer > manage > selectCustomer"
  | "customer > manage > disable"
  | "route > goTo"

export type CommandArgs = any[] | undefined

export type Command = {
  id: CommandId
  label?: string
  args?: CommandArgs
}

export const authCommands: Command[] = [
  //
  { id: "auth > signin" },
  { id: "auth > signout" },
]

const hasNoRouteParams = (route: RouteConfig) => !route.path.includes(":")
const ackbarRoutes = routes.filter(hasNoRouteParams)

export const routeCommands: Command[] = ackbarRoutes.map((routeConfig) => ({
  id: "route > goTo",
  args: [routeConfig],
  label: routeConfig.name,
}))

export const clipboardCommands: { [key: string]: Command } = {
  copyToken: { id: "clipboard > copyToken" },
  copyUser: { id: "clipboard > copyUser" },
}

export const customerCommands: Command[] = [
  // disabled until auth client update prevents hang when loading customers from API
  // { id: "customer > manage > enable" },
  { id: "customer > manage > disable" },
]

export const customerSubcommands = (entities: Entity[]): Command[] =>
  entities.map((entity) => ({
    id: "customer > manage > selectCustomer",
    label: entity.name,
    args: [entity],
  }))

export const commands: Command[] = [
  ...authCommands,
  ...routeCommands,
  ...Object.values(clipboardCommands),
  ...customerCommands,
]
