/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Admin } from '../models/Admin';
import type { Entity } from '../models/Entity';
import type { EntityMetadata } from '../models/EntityMetadata';
import type { EntityResponse } from '../models/EntityResponse';
import { request as __request } from '../core/request';

export class EntitiesService {

    /**
     * Get Entity Admins.
     * ## Requires:
     * ### admins.Read
     * @returns any Success
     * @throws ApiError
     */
    public static async adminsGetAdminsAsync({
        id,
        select,
        expand,
    }: {
        id: string,
        /** Limits the properties returned in the result. **/
        select?: string,
        /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
        expand?: string,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Admin)> {
        const result = await __request({
            useBifrost: true,
            method: 'GET',
            path: `/api/entities/${id}/admins`,
            query: {
                '$select': select,
                '$expand': expand,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Gets all Entities.
     * ## Requires:
     * ### entities.Read in root permissions.
     * @returns any Success
     * @throws ApiError
     */
    public static async entitiesGetAllAsync({
        select,
        expand,
        filter,
        orderby,
        top,
        skip,
        count = false,
    }: {
        /** Limits the properties returned in the result. **/
        select?: string,
        /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
        expand?: string,
        /** Restricts the set of items returned. For the allowed functions please refer to the Bifrost Swagger document https://bifrost.iamcloud.net/api/swagger/index.html **/
        filter?: string,
        /** Specifies the order in which items are returned. The maximum number of expressions is 5. **/
        orderby?: string,
        /** Limits the number of items returned from a collection. **/
        top?: number,
        /** Excludes the specified number of items of the queried collection from the result. **/
        skip?: number,
        /** Indicates whether the total count of items within a collection are returned in the result. **/
        count?: boolean,
    }): Promise<{
        readonly '@odata.context'?: string,
        readonly '@odata.count'?: number,
        readonly value?: Array<Entity>,
    }> {
        const result = await __request({
            useBifrost: true,
            method: 'GET',
            path: `/api/entities`,
            query: {
                '$select': select,
                '$expand': expand,
                '$filter': filter,
                '$orderby': orderby,
                '$top': top,
                '$skip': skip,
                '$count': count,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Post Entities.
     * Use this endpoint to create an entity with a default tenancy, assign the entity an owner admin and add tenancy admins. When you create an entity, you can assign up to 10 admins to its default tenancy, concurrently.
     * @returns any Created
     * @throws ApiError
     */
    public static async entitiesPostAsync({
        requestBody,
    }: {
        requestBody?: Entity,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & EntityResponse)> {
        const result = await __request({
            useBifrost: true,
            method: 'POST',
            path: `/api/entities`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not Acceptable`,
                500: `Internal Server Error`,
            },
        });
        return result.body;
    }

    /**
     * Get Entity.
     * ## Requires:
     * ### entities.Read in root permissions.
     * @returns any Success
     * @throws ApiError
     */
    public static async entitiesGetAsync({
        id,
        select,
        expand,
    }: {
        id: string,
        /** Limits the properties returned in the result. **/
        select?: string,
        /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
        expand?: string,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Entity)> {
        const result = await __request({
            useBifrost: true,
            method: 'GET',
            path: `/api/entities/${id}`,
            query: {
                '$select': select,
                '$expand': expand,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update Entity.
     * ## Requires:
     * ### entities.Update on the default Tenancy.
     * @returns any Success
     * @throws ApiError
     */
    public static async entitiesPatchAsync({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: Entity,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Entity)> {
        const result = await __request({
            useBifrost: true,
            method: 'PATCH',
            path: `/api/entities/${id}`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not Acceptable`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Delete Entity Admins.
     * ## Requires:
     * ### admins.Delete on the default Tenancy.
     * @returns any Success
     * @throws ApiError
     */
    public static async entitiesDeleteAdminsAsync({
        id,
        childId,
    }: {
        id: string,
        childId: string,
    }): Promise<{
        readonly '@odata.context'?: string,
        readonly '@odata.count'?: number,
        readonly value?: Array<Admin>,
    }> {
        const result = await __request({
            useBifrost: true,
            method: 'DELETE',
            path: `/api/entities/${id}/admins/${childId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not Acceptable`,
                500: `Internal Server Error`,
            },
        });
        return result.body;
    }

    /**
     * Gets the Entity metadata.
     * @returns any Success
     * @throws ApiError
     */
    public static async entitiesGetMetadataAsync({
        id,
    }: {
        id: string,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & EntityMetadata)> {
        const result = await __request({
            useBifrost: true,
            method: 'GET',
            path: `/api/entities/${id}/metadata`,
            errors: {
                404: `Not Found`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}