import { Box, Input, NumberInput, NumberInputField, Select } from "@chakra-ui/react"
import { FC, useMemo } from "react"
import { Column, Row, useExpanded, usePagination, useTable } from "react-table"

import { Pagination, Table } from "common/components"
import { globalCurrency } from "common/utils/currency.utils"

type VariantType = {
  id: string
  variant: string
  supplierSku: string
  orderQty: number
  rrp: number
}

type VariantsTableProps = {
  variants: VariantType[]
}

const VariantsTable: FC<VariantsTableProps> = ({ variants }) => {
  const format = (val: number): string => `${globalCurrency.symbol} ` + val
  //const parse = (val: string): string => val.replace(/^\$/, "")

  const pageSizes = [10, 20, 30]

  const columnData: Column<VariantType>[] = [
    {
      Header: "Variant",
      accessor: "variant",
    },
    {
      Header: "Supplier SKU",
      accessor: "supplierSku",
    },
    {
      Header: "Order Qty",
      accessor: "orderQty",
      Cell: ({ row }) => (
        <Input
          fontSize={14}
          name="orderQty"
          placeholder="1"
          textAlign="right"
          value={row.original.orderQty}
          width="70%"
          onChange={() => {}}
        />
      ),
    },
    {
      Header: "RRP",
      accessor: "rrp",
      Cell: ({ row }) => (
        <NumberInput fontSize={14} name="rrp" placeholder="1" value={format(row.original.rrp)} onChange={() => {}}>
          <NumberInputField textAlign="right" width="80%" />
        </NumberInput>
      ),
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, []),
      data: useMemo(() => variants, [variants]),
    },
    useExpanded,
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<VariantType>): React.ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => {
          if (cell.column.Header === "Variant" || cell.column.Header === "Supplier SKU") {
            return (
              <Table.TD {...cell.getCellProps()} textAlign="left">
                {cell.render("Cell")}
              </Table.TD>
            )
          }

          return (
            <Table.TD {...cell.getCellProps()} textAlign="right">
              {cell.render("Cell")}
            </Table.TD>
          )
        })}
      </Table.TR>
    )
  }

  return (
    <Box position="relative" width="100%">
      <Table {...getTableProps()} width="100%">
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.TR
              bg="white"
              color="#36495E"
              fontSize="md"
              textTransform="capitalize"
              {...headerGroup.getHeaderGroupProps()}
            >
              <Table.TH textAlign="left" width="20%">
                Variant
              </Table.TH>
              <Table.TH textAlign="left" width="25%">
                Supplier SKU
              </Table.TH>
              <Table.TH textAlign="right" width="30%">
                Order Qty
              </Table.TH>
              <Table.TH textAlign="right" width="30%">
                RRP
              </Table.TH>
            </Table.TR>
          ))}
        </Table.Header>
        <Table.Body fontSize={14} {...getTableBodyProps()}>
          {page.map(renderRow)}
        </Table.Body>
        <Table.Footer>
          <Table.TR bg="#fafafa">
            <Table.TD colSpan={columnData.length - 2}>
              {/*<Text>Items per page:</Text>*/}
              <Select value={pageSize} width="40%" onChange={(event) => setPageSize(Number(event.currentTarget.value))}>
                {pageSizes.map((item) => (
                  <option key={item}>{item}</option>
                ))}
              </Select>
            </Table.TD>
            <Table.TD colSpan={columnData.length - 2}>
              <Pagination
                currentPage={pageIndex}
                setPageSize={() => {}}
                totalPages={pageOptions.length}
                onPageChange={gotoPage}
              />
            </Table.TD>
          </Table.TR>
        </Table.Footer>
      </Table>
    </Box>
    /* eslint-enable react/jsx-key */
  )
}

export { VariantsTable }
