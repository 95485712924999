import { Box, Button, Flex, Link, Stack, Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

import { SUPPORT_EMAIL, SUPPORT_SEND_EMAIL } from "common/constants"

export type ErrorSplashProps = {
  errorMessage?: string
  emitRetry: () => void
  emitSignout: () => void
}

const ErrorSplash: FC<ErrorSplashProps> = ({ errorMessage, emitRetry, emitSignout }) => {
  const { t } = useTranslation()

  return (
    <Box background="white" bottom="0" left="0" position="fixed" right="0" top="0" zIndex="2">
      <Flex align="center" flex={1} flexDir="column" height="100%" justify="center">
        <Box mb={6} textAlign="center">
          {errorMessage && (
            <Text mb={4}>
              <strong>{errorMessage}</strong>
            </Text>
          )}
          <Text>
            {t("ifProblemPersistsContact")}{" "}
            <Link href={SUPPORT_SEND_EMAIL} variant="secondary">
              {SUPPORT_EMAIL}
            </Link>
          </Text>
        </Box>
        <Stack align="center" direction="row" spacing={4}>
          <Button variant="primary" onClick={emitRetry}>
            {t("tryAgain")}
          </Button>
          <Button variant="tertiary" onClick={emitSignout}>
            {t("signout")}
          </Button>
        </Stack>
      </Flex>
    </Box>
  )
}

export { ErrorSplash }
