import { Button, Flex, Grid, GridItem, Text } from "@chakra-ui/react"
import { FC, useContext } from "react"
import { useNavigate } from "react-router-dom"

import { api, mockData } from "api"
import { InShopInfo } from "common/components/InShopInfo/InShopInfo"
import { TENANCY_ID } from "common/constants"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

type ExchangeInfoProps = {
  onOpen: () => void
}

const ExchangeInfo: FC<ExchangeInfoProps> = ({ onOpen }) => {
  const navigate = useNavigate()
  const { state, dispatch } = useContext(ProductsContext)
  const { order } = state.makeOrder
  const { data } = api.useSalesOrders(TENANCY_ID)

  const buttonStyles = {
    borderRadius: 10,
    color: "white",
    fontSize: 24,
  }

  const cancelExchange = (): void => {
    const isCancel: boolean = window.confirm("Are you sure you want to cancel exchange?")
    if (isCancel) {
      const findOrder = data?.value?.find((item) => item.orderId === order.orderId)
      if (findOrder?.orderId) {
        dispatch({ type: Types.SetOrder, payload: { order: findOrder } })
        dispatch({ type: Types.SetExchangeOrder, payload: { exchangeOrder: mockData.initOrder } })
        dispatch({ type: Types.IsOrderChanged, payload: { isOrderChanged: false } })
      }
    }
  }

  return (
    <Grid
      data-testid="orderInfo"
      style={{
        background: "#007abd",
        minHeight: "200px",
        width: "100%",
        color: "White",
        fontWeight: "bold",
        padding: "10px 0",
      }}
      templateColumns="2fr 3fr"
    >
      <InShopInfo order={order} />
      <GridItem
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 0",
        }}
      >
        <Button
          background="#149a45"
          display="flex"
          flexDirection="column"
          height="5rem"
          px="4rem"
          style={buttonStyles}
          onClick={() => navigate("/exchangePayment")}
        >
          <Text>Process Exchange</Text>
        </Button>
        {state.bool.isOrderChanged && (
          <Flex>
            <Button
              background="transparent"
              borderColor="white"
              borderWidth={2}
              height="4rem"
              mr={4}
              px="2rem"
              size="lg"
              style={buttonStyles}
              onClick={onOpen}
            >
              Add product
            </Button>
            <Button
              background="#a02013"
              height="4rem"
              px="2rem"
              size="lg"
              style={buttonStyles}
              onClick={cancelExchange}
            >
              Cancel Change
            </Button>
          </Flex>
        )}
      </GridItem>
    </Grid>
  )
}

export { ExchangeInfo }
