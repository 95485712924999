import { Flex, Image, Tag, Text } from "@chakra-ui/react"
import { DownIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useSortBy, useTable } from "react-table"

import { Category } from "api"
import { Pagination, Table, TableItemMenu } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"
import { DefaultImageIcon } from "common/theme"

type ProductCategoryTableProps = {
  allCategories: Category[]
  categories: Category[]
  deleteCategory: (item: Category, type: string) => void
  setFilter: (category: Category) => void
  loading: boolean
}

const PRODUCT_CATEGORY_TABLE = "PRODUCT_CATEGORY_TABLE"

const ProductCategoryTable: FC<ProductCategoryTableProps> = ({
  categories,
  loading,
  deleteCategory,
  setFilter,
  allCategories,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [storedPageSize, setStoredPageSize] = useStoredPageSize(PRODUCT_CATEGORY_TABLE)

  const CategoryCell: FC<{ category: Category }> = ({ category }) => (
    <TableItemMenu
      deleteClick={() => deleteCategory(category, "category")}
      editClick={() => navigate(category.id)}
      value={category.name}
      onClick={() => setFilter(category)}
    />
  )

  const columnData: Column<Category>[] = [
    {
      Header: t("image") as string,
      accessor: "imageId",
      Cell: ({ row }) =>
        row.original.image ? (
          <Image height="40px" src={row.original.image.thumbnailUrl} width="40px" />
        ) : (
          <DefaultImageIcon fontSize={40} />
        ),
      disableSortBy: true,
    },
    {
      id: "name",
      Header: t("name") as string,
      accessor: "name",
      Cell: ({ row: { original: category } }) => <CategoryCell category={category} />,
    },
    {
      Header: t("description") as string,
      accessor: "description",
      disableSortBy: true,
    },
    // {
    //   Header: t("inventory.tax_category") as string,
    //   accessor: "taxCategory",
    //   Cell: ({ row }) => <Text>{row.original.taxCategory.name}</Text>,
    //   disableSortBy: true,
    // },
    {
      Header: t("product_category.no_of_subcategories") as string,
      // @ts-ignore
      Cell: ({ row }) => {
        const subCategories = allCategories.filter((item) => item.parentCategoryId === row.original.id)

        return <Text>{subCategories?.length || 0}</Text>
      },
      disableSortBy: true,
    },
    {
      Header: t("status_label") as string,
      accessor: "status",
      Cell: ({ row }) => (
        <Tag
          background={row.original.status === "Active" ? "success.02" : "warning.02"}
          borderColor={row.original.status === "Active" ? "success.03" : "warning.03"}
          borderRadius={2}
          color="text.01"
          fontSize={12}
        >
          {t(`statuses.${row.original.status}`)}
        </Tag>
      ),
      disableSortBy: true,
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setPageSize,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, [allCategories]),
      data: useMemo(() => categories, [categories]),
      initialState: {
        pageSize: storedPageSize ?? DEFAULT_ITEMS_PER_PAGE,
      },
    },
    useSortBy,
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<Category>): React.ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => {
          switch (cell.column.Header) {
            case t("product_category.no_of_subcategories"):
              return (
                <Table.TD textAlign="right" width="20%" {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </Table.TD>
              )
            case t("status_label"):
              return (
                <Table.TD textAlign="center" {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </Table.TD>
              )
            default:
              return <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
          }
        })}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} data-testid="categoriesTable" width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR bg="white" color="#36495E" fontSize="lg" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              switch (column.Header) {
                case t("product_category.no_of_subcategories"):
                  return (
                    <Table.TH
                      textAlign="right"
                      textTransform="initial"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </Table.TH>
                  )
                case t("status_label"):
                  return (
                    <Table.TH
                      textAlign="center"
                      textTransform="capitalize"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </Table.TH>
                  )
                default:
                  return (
                    <Table.TH
                      textAlign="left"
                      textTransform="capitalize"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <Flex align="center" justify="flex-start">
                        {column.render("Header")}
                        <Text marginLeft="1rem">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <DownIcon />
                            ) : (
                              <DownIcon transform="rotate(180deg)" />
                            )
                          ) : (
                            ""
                          )}
                        </Text>
                      </Flex>
                    </Table.TH>
                  )
              }
            })}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()} textAlign="left">
        {loading ? (
          <Table.Skeleton columnWidths={[32, 16, 32, 64]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={(size) => {
                setPageSize(size)
                setStoredPageSize(size)
              }}
              totalPages={pageOptions.length}
              onPageChange={gotoPage}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { ProductCategoryTable }
