import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web"

import { REACT_APP_APP_INSIGHTS_CONNECTION_STRING as connectionString } from "environment"

const reactPlugin = new ReactPlugin()

const appInsights = new ApplicationInsights({
  config: {
    appId: "InShop",
    connectionString,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
})

if (connectionString) {
  appInsights.loadAppInsights()

  const telemetryInitializer = (envelope: ITelemetryItem) => {
    // this is the only way to set the cloud role, but eslint didn't like it (due to unallowed reassignment) so am disabling temporarily. There's no risk involved because we're only overwriting if it doesn't exist
    /* eslint-disable */
    envelope.tags = envelope.tags || []
    envelope.tags.push({ "ai.cloud.role": "InShop" })
    /* eslint-enable */
  }
  appInsights.addTelemetryInitializer(telemetryInitializer)
}

export { reactPlugin, appInsights }
