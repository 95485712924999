import { useTranslation } from "@iac/translations.i18n-instance"
import * as z from "zod"

import { numberField, numbersNlettersNdash, numbersNlettersNspecialCharacters } from "common/validation"

export const useValidationSchemes = () => {
  const { t } = useTranslation()

  const infoSchema = z.object({
    name: z
      .string()
      .max(15)
      .nonempty({ message: t("validation.required", { field: "name" }) }),
    description: z
      .string()
      .max(200)
      .nonempty({ message: t("validation.required", { field: "description" }) }),
    shortName: z
      .string()
      .max(10)
      .nonempty({ message: t("validation.required", { field: "short name" }) }),
    supplierId: z.string().nonempty({ message: t("validation.select", { field: "supplier" }) }),
    supplierSku: z
      .string()
      .max(15)
      .nonempty({ message: t("validation.required", { field: "supplier SKU" }) })
      .regex(numbersNlettersNdash, { message: t("validation.lettersNnumbers") }),
    countryOfOriginId: z.number().positive({ message: t("validation.select", { field: "country" }) }),
    productCategoryId: z.string().nonempty({ message: t("validation.select", { field: "category" }) }),
    unitOfMeasureId: z.string().nonempty({ message: t("validation.select", { field: "unit of measure" }) }),
    inventoryType: z.string().nonempty({ message: t("validation.select", { field: "inventory type" }) }),
    retailPrice: z.string().regex(numberField, { message: t("validation.numbers") }),
  })

  const taxInfoSchema = z.object({
    hsCode: z.string(),
    sellingTaxCategoryId: z.string().nonempty({ message: t("validation.select", { field: "sales tax category" }) }),
  })

  const initialStockSchema = z.object({
    initialStock: z
      .string()
      .nonempty({ message: t("validation.required", { field: "initial stock" }) })
      .regex(numberField, { message: t("validation.numbers") }),
    initialCost: z
      .string()
      .nonempty({ message: t("validation.required", { field: "initial cost" }) })
      .regex(numberField, { message: t("validation.numbers") }),
    carryingCost: z.string().nullable().optional(),
    retailPrice: z
      .string()
      .nonempty({ message: t("validation.select", { field: "RRP" }) })
      .regex(numberField, { message: t("validation.numbers") }),
  })

  const defaultVariantsSchema = z.object({
    supplierSku: z.string().nonempty({ message: t("validation.required", { field: "supplier sku" }) }),
    supplierBarcode: z.string().min(6).nullable().or(z.literal("")),
    variantStock: z.string().nonempty({ message: t("validation.required", { field: "initial stock per variant" }) }),
    variantCost: z.string().nonempty({ message: t("validation.select", { field: "unit cost per variant" }) }),
    variantRetailPrice: z.string().nonempty({ message: t("validation.select", { field: "RRP" }) }),
  })

  const editVariantsSchema = z.object({
    receiptDisplayName: z
      .string()
      .max(15)
      .nonempty({ message: t("validation.required", { field: "name" }) })
      .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") }),
    supplierBarcode: z.string().min(7).nullable().or(z.literal("")),
    notes: z.string().optional(),
  })

  return { infoSchema, taxInfoSchema, initialStockSchema, defaultVariantsSchema, editVariantsSchema }
}
