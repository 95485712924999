import { extendTheme } from "@chakra-ui/react"
import { ThemeProvider as MonoRepoThemeProvider } from "@iac/components.theme-provider"
import { iamCloudThemeOptions } from "@iac/themes.iamcloud"
import { concat, mergeDeepWith } from "ramda"
import { FC, ReactElement, memo } from "react"

import { irisThemeConfig, themeOptions } from "./appStyles"

// Allow for application specific theme style merges
const combinedThemes = extendTheme({
  ...mergeDeepWith(concat, themeOptions, iamCloudThemeOptions),
  config: irisThemeConfig,
})

export const ThemeProvider: FC = memo(({ children }) => (
  <MonoRepoThemeProvider themeOptions={combinedThemes}>{children as ReactElement}</MonoRepoThemeProvider>
))
