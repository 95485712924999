const TROUBLESHOOTING = "TROUBLESHOOTING"
const MAX_REPORTS_COUNT = 1000

export type TroubleshootingRecord = {
  timestamp: string
  message: string | { method: string; url: string; requestBody: string; responseBody: string }
}

export const getTroubleshooting = () => localStorage.getItem(TROUBLESHOOTING)

export const getTroubleshootingParsed = (): TroubleshootingRecord[] =>
  !!getTroubleshooting() ? JSON.parse(getTroubleshooting() as string) : []

export const toggleEnableTroubleshooting = (isEnabled: boolean) => {
  isEnabled ? localStorage.setItem(TROUBLESHOOTING, JSON.stringify([])) : localStorage.removeItem(TROUBLESHOOTING)
}

export const addToTroubleshooting = (message: any) => {
  if (localStorage.getItem(TROUBLESHOOTING)) {
    localStorage.setItem(
      TROUBLESHOOTING,
      JSON.stringify(
        (getTroubleshootingParsed().length >= MAX_REPORTS_COUNT
          ? getTroubleshootingParsed().slice(1)
          : getTroubleshootingParsed() ?? []
        ).concat({
          timestamp: new Date().toString(),
          message,
        })
      )
    )
  }
}

export const addScanToTroubleshooting = (code: string) => {
  addToTroubleshooting(`Scan Detected: ${code}`)
}

export const addRequestToTroubleshooting = (
  method: string,
  url: string,
  requestBody: any,
  response: Response,
  responseBody: any
) => {
  if (!url.endsWith("/support/logs"))
    addToTroubleshooting({
      method,
      url,
      requestBody: JSON.stringify(requestBody),
      responseBody: response.ok ? "ok" : responseBody,
    })
}
