import type { ProductVariant } from "../models/ProductVariant"
import { request as __request } from "../core/request"
import { trackPromise } from "react-promise-tracker"

export class ProductVariantsService {
  /**
   * Gets all SearchingProduct Variants.
   * ## Requires:
   * ### productVariants.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async productVariantsGetAllAsync({
    select,
    expand,
    parentId,
    tenancyId,
  }: {
    parentId: string
    tenancyId: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<ProductVariant>
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/${parentId}/variants`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  public static async productVariantsStoreFrontsGetAllAsync({
    select,
    expand,
    parentId,
    tenancyId,
    variantId,
  }: {
    parentId: string
    tenancyId: string
    variantId: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<{ storeFrontId: string; stock: number; retailPrice: number }>
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/${parentId}/variants/${variantId}/storefronts`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Create SearchingProduct Variant.
   * ## Requires:
   * ### productVariants.Create
   * @returns any Success
   * @throws ApiError
   */
  public static async productVariantsPostAsync({
    parentId,
    tenancyId,
    requestBody,
  }: {
    tenancyId: string
    parentId: string
    requestBody?: Partial<ProductVariant>
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & ProductVariant
  > {
    const result = await trackPromise(
      __request({
        method: "POST",
        path: `/api/tenancies/${tenancyId}/isa/products/${parentId}/variants`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Gets SearchingProduct Variant.
   * ## Requires:
   * ### productVariants.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async productVariantsGetAsync({
    id,
    parentId,
    tenancyId,
    select,
    expand,
  }: {
    parentId: string
    tenancyId: string
    id: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & ProductVariant
  > {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/${parentId}/variants/${id}`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Update SearchingProduct Variant.
   * ## Requires:
   * ### productVariants.Update
   * @returns any Success
   * @throws ApiError
   */
  public static async productVariantsPatchAsync({
    tenancyId,
    parentId,
    id,
    requestBody,
  }: {
    tenancyId: string
    parentId: string
    id: string
    requestBody?: ProductVariant
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & ProductVariant
  > {
    const result = await trackPromise(
      __request({
        method: "PATCH",
        path: `/api/tenancies/${tenancyId}/isa/products/${parentId}/variants/${id}`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          409: `Conflict`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Delete the SearchingProduct Variant.
   * ## Requires:
   * ### productVariants.Delete
   * @returns any Success
   * @throws ApiError
   */
  public static async productVariantsDeleteAsync({
    id,
    parentId,
    tenancyId,
  }: {
    id: string
    parentId: string
    tenancyId: string
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & ProductVariant
  > {
    const result = await trackPromise(
      __request({
        method: "DELETE",
        path: `/api/tenancies/${tenancyId}/isa/products/${parentId}/variants/${id}`,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  public static async productVariantGetAssociatedProductsAsync({
    tenancyId,
    productId,
    variantId,
  }: {
    tenancyId: string
    productId: string
    variantId: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: {
      products: { id: UUID; name: string; imageUrl: string }[]
      variants: { id: UUID; name: string; imageUrl: string }[]
    }[]
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/${productId}/variants/associated?productVariantId=${variantId}`,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  public static async productVariantGetCrossLinkedProductsAsync({
    tenancyId,
    productId,
    variantId,
  }: {
    tenancyId: string
    productId: string
    variantId: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: {
      products: { id: UUID; name: string; imageUrl: string }[]
      variants: { id: UUID; name: string; imageUrl: string }[]
    }[]
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/${productId}/variants/crosslinked?productVariantId=${variantId}`,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }
}
