import { assoc, drop, take } from "ramda"

import { entities, root } from "./entities"
import { OWNER_ADMIN_ID } from "api/bifrost-mock/constants"
import { AdminStatuses } from "api/generated"
import { AdminRoles } from "api/generated/models/AdminRoles" // https://stackoverflow.com/a/59665223/21115
import { AdminAndTenancyAdmin } from "api/handmade"
import { AdminType } from "features/admins"

// TODO: remove this, since moved to /api/bifrost-mock/admins/mocks/data

export const allAdmins = [
  {
    id: OWNER_ADMIN_ID,
    role: AdminRoles.IDX_ADMINISTRATOR,
    name: "Rick Sanchez",
    email: "rick@picklerick.xyz",
    status: AdminStatuses.ACCEPTED,
  },
  {
    id: "ea449226-78af-45ba-be3f-a6af3505d4c6",
    role: AdminRoles.MIGRATION_ADMINISTRATOR,
    name: "Richard Hendricks",
    email: "richard@piedpiper.io",
    status: AdminStatuses.ACCEPTED,
    tenancyLinks: [
      {
        tenancyId: "6f60e153-5656-40ec-beba-57f8c64b46cb",
        role: AdminRoles.TENANCY_ADMINISTRATOR,
        status: "Pending",
        tenancyName: "Test Identity App",
        entityName: "Test Identity App",
        entityId: "41563070-723e-4d0f-b957-1f4fde21c7f9",
        isOwner: false,
      },
    ],
  },
  {
    id: "0b31a43e-e52a-4da3-94e9-72cf8fdbe38a",
    role: AdminRoles.HELPDESK,
    name: "Maurice Moss",
    email: "m.moss@reynholm-industries.com",
    status: AdminStatuses.ACCEPTED,
  },
  {
    id: "2dbb334e-5aca-4358-a1eb-52aca01c8a6d",
    role: AdminRoles.FINANCE,
    name: "Marty Byrd",
    email: "marty@byrd-accounting.com",
    status: AdminStatuses.ACCEPTED,
    tenancyLinks: [
      {
        tenancyId: "6f60e153-5656-40ec-beba-57f8c64b46cb",
        role: AdminRoles.TENANCY_ADMINISTRATOR,
        status: AdminStatuses.REJECTED,
        tenancyName: "Test Identity App",
        entityName: "Test Identity App",
        entityId: "41563070-723e-4d0f-b957-1f4fde21c7f9",
        isOwner: false,
      },
    ],
  },
  {
    id: "a22a76b6-8eac-4771-adaa-637cf8b60525",
    role: AdminRoles.LEGAL,
    name: "Bob Loblaw",
    email: "bob@bobloblawlawblog.com",
    status: AdminStatuses.ACCEPTED,
  },
  {
    id: "c1e4da0e-a131-4b7d-84d9-3a60da739908",
    role: AdminRoles.TENANCY_ADMINISTRATOR,
    name: "Ian Grimm",
    email: "ian@mythicquest.com",
    status: AdminStatuses.ACCEPTED,
  },
  {
    id: "be187330-c75d-4def-b824-4f041a052f86",
    role: AdminRoles.PARTNER_MANAGER,
    name: "Malcolm F Tucker",
    email: "malcolm@nomfup.com",
    status: AdminStatuses.PENDING,
  },
  {
    id: "606052a3-6cde-4a51-b9df-2dab9905d96e",
    role: AdminRoles.FINANCE,
    name: "Mark Corrigan",
    email: "m.corrigan@jlb-credit.com",
    status: AdminStatuses.ACCEPTED,
  },
  {
    id: "ca6f6327-93c6-466e-9234-f33a2f5eb5c9",
    role: AdminRoles.PARTNER_ADMINISTRATOR,
    name: "Alan Partridge",
    email: "alan@theoasthouse.co.uk",
    status: AdminStatuses.ACCEPTED,
  },
  {
    id: "3d17f5b4-1c13-4796-8500-88f693422c38",
    role: AdminRoles.LEGAL,
    name: "Basil Fawlty",
    email: "basil@fawltytowers.co.uk",
    status: AdminStatuses.ACCEPTED,
  },
  {
    id: "7edc517b-1f74-4a1d-ba24-17f1b0fac4b7",
    role: AdminRoles.TENANCY_ADMINISTRATOR,
    name: "Jim Lahey",
    email: "jim@sunnyvale.ca",
    status: AdminStatuses.ACCEPTED,
  },
  {
    id: "1cc85ef3-8d9f-46a7-ab2c-220d2f11ec4f",
    role: AdminRoles.PARTNER_MANAGER,
    name: "Roman Roy",
    email: "roman.roy@waystar-royco.com",
    status: AdminStatuses.ACCEPTED,
  },
  {
    id: "e9e5c42d-a92f-4ef7-98ff-74e5aef8e649",
    role: AdminRoles.FINANCE,
    name: "David Brent",
    email: "d.brent@wernhamhogg.com",
    status: AdminStatuses.ACCEPTED,
  },
].map(assoc("entityId", root.id)) as AdminAndTenancyAdmin[]

export const admins = take(8, allAdmins)

export const adminsWithOwners = allAdmins.map((admin, i) => ({ ...admin, owner: entities[i] }))

export const adminType: { [key in AdminType]: AdminAndTenancyAdmin } = {
  owner: { ...admins[0], isOwner: true },
  tenancy: { ...admins[1], owner: entities[0] },
  partner: { ...admins[3], owner: entities[1] },
  external: { ...admins[4], owner: entities[2] },
}

export const newAdmin = { ...adminType.tenancy, name: "Gary Numan", email: "here@inmycar.com", isNew: true }

export const tenancyAdmins: AdminAndTenancyAdmin[] = [
  ...Object.values(adminType),
  { ...adminsWithOwners[5] },
  { ...adminsWithOwners[6] },
]

export const suggestedAdmins = drop(7, adminsWithOwners)

export const primary: AdminAndTenancyAdmin = {
  id: OWNER_ADMIN_ID,
  role: AdminRoles.TENANCY_ADMINISTRATOR,
  status: AdminStatuses.ACCEPTED,
  isOwner: true,
  name: "Tony Soprano",
  email: "tony@badabingclub.com",
}
