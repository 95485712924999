import { Flex, Heading, useDisclosure } from "@chakra-ui/react"
import { ChevronLeftIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { StoreFrontsTable } from "./storeFrontsTable/StoreFrontsTable"
import { StoryTypeButton } from "./storeTypeButton/StoryTypeButton"
import { StoreFront, api, mockData } from "api"
import { useStoreFronts } from "api/hooks"
import { ConfirmModal, PageLayout } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"

const STORE_FRONTS_TABLE = "STORE_FRONTS_TABLE"

const StoreFrontsView: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [search, setSearch] = useState("")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [storeFront, setStoreFront] = useState<StoreFront>(mockData.initStoreFont)
  const [storeFronts, setStoreFronts] = useState<StoreFront[] | undefined>([])
  const [storeFrontsCount, setStoreFrontsCount] = useState(1)
  const deleteStoreFrontsMutation = api.useDeleteStoreFrontsMutation(
    { onSuccess: () => onClose() },
    TENANCY_ID,
    storeFront.id
  )
  const [storedPageSize, setStoredPageSize] = useStoredPageSize(STORE_FRONTS_TABLE)

  const [options, setOptions] = useState({
    skip: 0,
    top: storedPageSize ?? DEFAULT_ITEMS_PER_PAGE,
  })

  const { data: storeFrontsData, isLoading } = useStoreFronts(TENANCY_ID, {
    top: options.top,
    skip: options.skip,
    count: true,
    filter: `startsWith(name, '${search ?? ""}')`,
  })

  const refetchStoreFronts = async (top: number, skip: number) => {
    setOptions({
      ...options,
      top,
      skip,
    })
    setStoredPageSize(top)
  }

  const deleteStore = (): void => deleteStoreFrontsMutation.mutate()

  useEffect(() => {
    if (storeFrontsData?.value) {
      setStoreFronts(storeFrontsData?.value)
      setStoreFrontsCount(storeFrontsData.count)
    }
  }, [storeFrontsData])

  return (
    <>
      <Flex align="center" data-testid="page-header" justify="space-between" mb={0} pb={8} pl={20} pt={16} width="90%">
        <Heading
          _hover={{ textDecoration: "underline", cursor: "pointer" }}
          alignItems="center"
          data-testid="header-title"
          display="flex"
          fontWeight="bold"
          size="md"
          onClick={() => navigate("/settings")}
        >
          <ChevronLeftIcon color="ui.03" mr={3} width="20px" />
          {t("storeFront_other")}
        </Heading>
        <StoryTypeButton />
      </Flex>
      <PageLayout
        confirmModal={
          <ConfirmModal
            isOpen={isOpen}
            name={storeFront.name}
            type="store"
            isDelete
            onClick={deleteStore}
            onClose={onClose}
          />
        }
        setSearch={setSearch}
        table={
          <StoreFrontsTable
            itemsPerPage={options.top}
            loading={isLoading}
            pageCount={Math.ceil(storeFrontsCount / options.top)}
            refetch={refetchStoreFronts}
            search={search}
            setStoreFront={setStoreFront}
            storeFronts={storeFronts || []}
            onOpen={onOpen}
          />
        }
      />
    </>
  )
}

export { StoreFrontsView }
