import { Button, ComponentWithAs, IconProps, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

import { InShopIcon, ShopAssistIcon } from "common/theme"

type AppMenuProductsProps = {
  goToRoute: (route: string) => () => void
  activeMenu: "inShop" | "main"
}

const AppMenuProducts: FC<AppMenuProductsProps> = ({ goToRoute, activeMenu }) => {
  const { t } = useTranslation()

  return (
    <SimpleGrid columns={2}>
      {activeMenu === "main" ? (
        <AppMenuProductTile
          buttonTestId="go-to-in-shop-bttn"
          Icon={InShopIcon}
          label={t("goTo", { location: t("inShop") })}
          onClick={goToRoute("/inShop")}
        />
      ) : (
        <AppMenuProductTile
          buttonTestId="go-to-shop-assist-bttn"
          Icon={ShopAssistIcon}
          label={t("goTo", { location: t("ShopAssist") })}
          onClick={goToRoute("/inventory")}
        />
      )}
    </SimpleGrid>
  )
}

type AppMenuProductTileProps = {
  Icon: ComponentWithAs<"svg", IconProps>
  label: string
  onClick: () => void
  buttonTestId: string
}

const AppMenuProductTile: FC<AppMenuProductTileProps> = ({ Icon, label, onClick, buttonTestId }) => (
  <Stack direction="row" fontSize="sm" spacing={2}>
    <Icon boxSize={9} color="info.01" />
    <Button
      color={useColorModeValue("text-01", "darkTheme.text-01")}
      data-testid={buttonTestId}
      variant="link"
      onClick={onClick}
    >
      <Text
        fontSize="sm"
        fontWeight="normal"
        lineHeight={4}
        overflowWrap="break-word"
        textAlign="left"
        whiteSpace="break-spaces"
      >
        {label}
      </Text>
    </Button>
  </Stack>
)

export type { AppMenuProductsProps }

export { AppMenuProducts }
