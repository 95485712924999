import { useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { CustomerTable } from "./customerTable/CustomerTable"
import { Customer, api, mockData } from "api"
import { ConfirmModal, PageHeader, PageLayout } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"
import { route } from "features/app/routes"

const CUSTOMERS_TABLE = "CUSTOMERS_TABLE"

const CustomersView: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [search, setSearch] = useState("")
  const { onClose, isOpen, onOpen } = useDisclosure()
  const [customer, setCustomer] = useState<Customer>(mockData.initCustomer)
  const [customers, setCustomers] = useState<Customer[] | undefined>([])
  const [customersCount, setCustomersCount] = useState(1)

  const { data: searchingCustomers } = api.useSearchCustomers(TENANCY_ID, search, { expand: "salesorders" })
  const deleteCustomerMutation = api.useDeleteCustomerMutation({ onSuccess: () => onClose() }, TENANCY_ID, customer.id)

  const [storedPageSize, setStoredPageSize] = useStoredPageSize(CUSTOMERS_TABLE)

  const [options, setOptions] = useState({
    top: storedPageSize || DEFAULT_ITEMS_PER_PAGE,
    skip: 0,
  })

  const { data, isLoading } = api.useCustomersAll(TENANCY_ID, {
    top: options.top,
    skip: options.skip,
    count: true,
    expand: "salesorders",
  })

  const refetchCustomers = async (top: number, skip: number) => {
    setStoredPageSize(top)
    setOptions({
      top,
      skip,
    })
  }

  const deleteCustomer = (): void => deleteCustomerMutation.mutate()

  useEffect(() => {
    if (data?.value) {
      setCustomers(data?.value)
      setCustomersCount(data.count)
    }
  }, [data])

  useEffect(() => {
    if (search.length === 0) {
      setCustomers(data?.value)
    } else {
      setCustomers(searchingCustomers?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchingCustomers, search])

  return (
    <>
      <PageHeader
        buttonTitle={t("customers.addCustomer")}
        link="/sales"
        title={t("customer_other")}
        isArrow
        onClick={() => navigate(route.customersAdd.path)}
      />
      <PageLayout
        confirmModal={
          <ConfirmModal
            isOpen={isOpen}
            name={customer.name || "this"}
            type="customer"
            isDelete
            onClick={deleteCustomer}
            onClose={onClose}
          />
        }
        setSearch={setSearch}
        table={
          <CustomerTable
            customers={customers || []}
            itemsPerPage={options.top}
            loading={isLoading}
            pageCount={Math.ceil(customersCount / options.top)}
            refetch={refetchCustomers}
            setCustomer={setCustomer}
            onOpen={onOpen}
          />
        }
      />
    </>
  )
}

export { CustomersView }
