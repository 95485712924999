import { Supplier } from "../generated"

export const suppliers = [
  {
    id: "7930a0a7-94de-4dce-83ed-b48d197ac090",
    tenancyId: "",
    name: "Tao",
    email: "",
    phone: "",
    companyName: "TAO SL",
    taxRegistrationNumber: "",
    address: "",
    priceIncludesTax: true,
    applyResellerTax: false,
    countryId: 86,
    defaultProductCategoryId: "1233213",
    defaultProductCountryOfOriginId: 84,
    status: "Active",
  } as Supplier,
  {
    id: "6e31a56c-a691-4611-bd28-da46d7fa0c1c",
    tenancyId: "",
    name: "WeFiesta",
    email: "",
    phone: "",
    companyName: "WeFiest de globos SL",
    taxRegistrationNumber: "",
    address: "",
    priceIncludesTax: true,
    applyResellerTax: false,
    countryId: 262,
    defaultProductCountryOfOriginId: 1,
    status: "Active",
  } as Supplier,
  {
    id: "e96a4489-7ddc-462a-8d7d-c5d004a79b5f",
    tenancyId: "",
    name: "Liragam",
    email: "",
    phone: "",
    companyName: "Liragam SL",
    taxRegistrationNumber: "",
    address: "",
    priceIncludesTax: false,
    applyResellerTax: false,
    countryId: 260,
    defaultProductCountryOfOriginId: 1,
    status: "Active",
  } as Supplier,
  {
    id: "2e2d7986-f9e9-4e47-b46a-3a327f53f20e",
    tenancyId: "",
    name: "Rubbie's",
    email: "",
    phone: "",
    companyName: "Rubbie's LTD",
    taxRegistrationNumber: "",
    address: "",
    priceIncludesTax: false,
    applyResellerTax: false,
    countryId: 248,
    defaultProductCountryOfOriginId: 1,
    status: "Active",
  } as Supplier,
  {
    id: "52acbed4-0df2-4e27-9a8a-92c3de07c135",
    tenancyId: "",
    name: "Smiffy's",
    email: "",
    phone: "",
    companyName: "Smiffys LTD",
    taxRegistrationNumber: "",
    address: "",
    priceIncludesTax: true,
    applyResellerTax: false,
    countryId: 248,
    defaultProductCountryOfOriginId: 1,
    status: "Active",
  } as Supplier,
  {
    id: "15794b60-55e5-47fd-a64d-defdd0282ea9",
    tenancyId: "",
    name: "Morphsuit",
    email: "",
    phone: "",
    companyName: "Smiffys LTD",
    taxRegistrationNumber: "",
    address: "",
    priceIncludesTax: false,
    applyResellerTax: false,
    countryId: 238,
    defaultProductCountryOfOriginId: 1,
    status: "Active",
  } as Supplier,
  {
    id: "23215c77-1f90-40ae-8f52-262764382117",
    tenancyId: "",
    name: "La Central",
    email: "",
    phone: "",
    companyName: "Smiffys LTD",
    taxRegistrationNumber: "",
    address: "",
    priceIncludesTax: true,
    applyResellerTax: false,
    countryId: 238,
    defaultProductCountryOfOriginId: 1,
    status: "Active",
  } as Supplier,
]
