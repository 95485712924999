import { Box, Button, Icon, IconButton, Stack, UseDisclosureReturn } from "@chakra-ui/react"
import { NotificationsIcon, SettingsIcon, WaffleIcon } from "@iac/components.icons.react"
import { IamCloudLogo } from "@iac/components.logos.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { ProfileMenu } from "../app/App/components"
import {
  getLocalStorageStoreFrontIdInShop,
  removeLocalStorageStoreFrontIdInShop,
} from "../inShop/storeFrontLocalSrotage"
import { Notifications } from "../notifications/Notifications"
import { Ackbar } from "features/commands"

type Props = {
  disclosureProps: UseDisclosureReturn
  isAuthenticated?: boolean
}

const AppBar: FC<Props> = ({ disclosureProps, isAuthenticated }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const localStorageStoreFrontInShop = getLocalStorageStoreFrontIdInShop()

  const [showNotifications, setShowNotifications] = useState(false)

  const goToRoute = (route: string) => () => {
    navigate(route)
    setShowNotifications(false)
  }

  const onChangeStoreFront = () => {
    removeLocalStorageStoreFrontIdInShop()
    navigate("/", { replace: true })
    window.location.reload()
  }

  return (
    <Stack
      align="center"
      as="header"
      bg="elevation.01"
      boxShadow="nav"
      direction="row"
      height={14}
      justify="stretch"
      px={4}
      zIndex="sticky"
    >
      <IconButton
        _hover={{}}
        aria-label={t("IAM Cloud: Iris")}
        boxSize={8}
        icon={<IamCloudLogo as={Icon} type="mark" width="8" />}
        ml="-2"
        variant="appbar"
        onClick={goToRoute("/")}
      />

      {isAuthenticated && (
        <>
          <Box>
            <IconButton
              aria-label={t("Main Menu")}
              {...disclosureProps.getButtonProps()}
              icon={<WaffleIcon />}
              variant="appbar"
            />
          </Box>
          {location.pathname.includes("/inShop") && localStorageStoreFrontInShop && (
            <Button h={8} variant="secondary" onClick={onChangeStoreFront}>
              {t("changeStorefront")}
            </Button>
          )}
          <Box flex={1}>
            <Ackbar />
          </Box>
          <Box pos="relative">
            <IconButton
              aria-label={t("Notifications")}
              icon={<NotificationsIcon />}
              variant="appbar"
              onClick={() => setShowNotifications(!showNotifications)}
            />
            {showNotifications && <Notifications notifications={[]} setShowNotifications={setShowNotifications} />}
          </Box>
          <IconButton
            aria-label={t("Settings")}
            icon={<SettingsIcon />}
            variant="appbar"
            onClick={goToRoute("/tenancy")}
          />
          <ProfileMenu />
        </>
      )}
    </Stack>
  )
}

export { AppBar }

export type { Props as AppBarProps }
