import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Switch,
} from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { useStoreFronts } from "api/hooks"
import { FormField } from "common/components"
import { TENANCY_ID } from "common/constants"
import { formStyles } from "common/theme"

type HydrateStoreFrontModalProps = {
  isOpen: boolean
  onClose: () => void
}

type HydrateType = {
  storeFrontId: string
  maintainStockLevels: boolean
}

const HydrateStoreFrontModal: FC<HydrateStoreFrontModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation()
  const { data: storeFrontsData } = useStoreFronts(TENANCY_ID)

  const schema = z.object({
    storeFrontId: z.string(),
    maintainStockLevels: z.boolean().optional(),
  })

  const {
    formState: { errors },
    register,
    watch,
  } = useForm<HydrateType>({
    resolver: zodResolver(schema),
    mode: "all",
    defaultValues: {
      storeFrontId: "",
      maintainStockLevels: false,
    },
  })

  // const watchStoreFront = watch("storeFrontId")
  const watchMaintain = watch("maintainStockLevels")
  const formFieldProps = { errors, register }

  // const { data: storeFront } = api.useStoreFront(TENANCY_ID, watchStoreFront || "")

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton data-testid="close-button" onClick={onClose} />
        <ModalBody mt={4}>
          <FormField<HydrateType>
            label={t("product.selectStore")}
            name="storeFrontId"
            {...formFieldProps}
            stackProps={{ ...formStyles.control, width: "80%" }}
          >
            <Select data-testid="store-select">
              <option value="" disabled>
                {t("select")}
              </option>
              {storeFrontsData?.value?.map((store) => (
                <option key={store.id} value={store.id}>
                  {store.name}
                </option>
              ))}
            </Select>
          </FormField>
          <FormField<HydrateType>
            label={t("storeFronts.maintainStockLevels")}
            name="maintainStockLevels"
            {...formFieldProps}
            stackProps={{ ...formStyles.control }}
          >
            <Switch data-testid="store-front-maintain-switch" isChecked={watchMaintain} />
          </FormField>
          <Button data-testid="done-button">{t("done")}</Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export { HydrateStoreFrontModal }
