import { useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { VariantOptionsTable } from "./variantOptionsTable/VariantOptionsTable"
import { VariantTypeOption, api, mockData } from "api"
import { ConfirmModal, PageHeader, PageLayout } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"
import { route } from "features/app/routes"

const VARIANT_OPTIONS_TABLE = "VARIANT_OPTIONS_TABLE"

const VariantOptionsView: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [variantOption, setVariantOption] = useState<VariantTypeOption>(mockData.initVariantOption)
  const [variantOptions, setVariantOptions] = useState<VariantTypeOption[] | undefined>([])
  const deleteVariantOption = api.useDeleteVariantOptionMutation(
    { onSuccess: () => onClose() },
    TENANCY_ID,
    variantOption.id
  )

  const [storedPageSize, setStoredPageSize] = useStoredPageSize(VARIANT_OPTIONS_TABLE)

  const [options, setOptions] = useState({
    skip: 0,
    top: storedPageSize ?? DEFAULT_ITEMS_PER_PAGE,
  })

  const { data: variantOptionsArray, isLoading } = api.useVariantOptions(TENANCY_ID, {
    top: options.top,
    skip: options.skip,
    count: true,
  })

  const refetchVariantOptions = async (top: number, skip: number) => {
    setStoredPageSize(top)
    setOptions({
      top,
      skip,
    })
  }

  const deleteVariant = (): void => deleteVariantOption.mutate()

  useEffect(() => {
    if (variantOptionsArray) {
      setVariantOptions(variantOptionsArray?.value)
    }
  }, [variantOptionsArray])

  useEffect(() => {
    if (search.length === 0) {
      setVariantOptions(variantOptionsArray?.value)
    } else {
      const findOption = variantOptionsArray?.value?.filter((item: VariantTypeOption) =>
        item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      setVariantOptions(findOption)
    }
  }, [search, variantOptionsArray?.value])

  return (
    <>
      <PageHeader
        buttonTitle={t("variant_option.add_new_option")}
        link="/inventory"
        title={t("variant_option_other")}
        isArrow
        onClick={() => navigate(route.addVariantOption.path)}
      />
      <PageLayout
        confirmModal={
          <ConfirmModal
            isOpen={isOpen}
            name={variantOption.name}
            type={t("variant_option.variant_option")}
            isDelete
            onClick={deleteVariant}
            onClose={onClose}
          />
        }
        setSearch={setSearch}
        table={
          <VariantOptionsTable
            isLoading={isLoading}
            itemsPerPage={options.top}
            refetch={refetchVariantOptions}
            setVariantOption={setVariantOption}
            variantOptions={variantOptions || []}
            onOpen={onOpen}
          />
        }
      />
    </>
  )
}

export { VariantOptionsView }
