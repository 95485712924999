import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"

import { LanguagesTable } from "./languagesTable/LanguagesTable"
import { TenancyLanguage, api } from "api"
import { PageHeader, PageLayout } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"

const LANGUAGES_TABLE = "LANGUAGES_TABLE"

const LanguagesView: FC = () => {
  const { t } = useTranslation()
  const [search, setSearch] = useState("")
  const [tenancyLanguages, setTenancyLanguages] = useState<TenancyLanguage[] | undefined>([])
  const [languagesCount, setLanguagesCount] = useState(1)
  const [storedPageSize, setStoredPageSize] = useStoredPageSize(LANGUAGES_TABLE)
  const [options, setOptions] = useState({
    skip: 0,
    top: storedPageSize ?? DEFAULT_ITEMS_PER_PAGE,
  })

  const { data: tenancyLanguagesData, isLoading } = api.useTenancyLanguages(TENANCY_ID, {
    expand: "language",
    top: options.top,
    skip: options.skip,
    count: true,
  })

  const refetchLanguages = async (top: number, skip: number) => {
    setOptions({
      ...options,
      top,
      skip,
    })
    setStoredPageSize(top)
  }
  useEffect(() => {
    if (tenancyLanguagesData?.value && tenancyLanguagesData?.count) {
      setTenancyLanguages(tenancyLanguagesData.value)
      setLanguagesCount(tenancyLanguagesData.count)
    }
  }, [tenancyLanguagesData])

  useEffect(() => {
    if (search.length === 0) {
      setTenancyLanguages(tenancyLanguagesData?.value)
    } else {
      const findLanguage = tenancyLanguagesData?.value?.filter((item) =>
        item?.language?.name?.toLocaleLowerCase().includes(search)
      )
      setTenancyLanguages(findLanguage)
    }
    // eslint-disable-next-line
  }, [search])

  return (
    <>
      <PageHeader link="/settings" title={t("languages.systemLanguage")} isArrow />
      <PageLayout
        setSearch={setSearch}
        table={
          <LanguagesTable
            itemsPerPage={options.top}
            loading={isLoading}
            pageCount={Math.ceil(languagesCount / options.top)}
            refetch={refetchLanguages}
            tenancyLanguages={tenancyLanguages || []}
          />
        }
      />
    </>
  )
}

export { LanguagesView }
