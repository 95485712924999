import { FC, useCallback, useEffect, useState } from "react"

import { OnlineStatusContext } from "./OnlineStatusContext"
import { useAuth } from "features/auth"

const OnlineStatusProvider: FC = ({ children }) => {
  const { error, isAuthenticated, signinSilent } = useAuth()
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true)

  useEffect(() => {
    if (error && isAuthenticated && onlineStatus) {
      signinSilent()
    }
  }, [error, isAuthenticated, onlineStatus, signinSilent])

  const handleOfflineStatusChange = useCallback(() => {
    setOnlineStatus(false)
  }, [])

  const handleOnlineStatusChange = useCallback(() => {
    setOnlineStatus(true)
  }, [])

  useEffect(() => {
    window.addEventListener("offline", handleOfflineStatusChange)
    window.addEventListener("online", handleOnlineStatusChange)

    return () => {
      window.removeEventListener("offline", handleOfflineStatusChange)
      window.removeEventListener("online", handleOnlineStatusChange)
    }
  }, [handleOfflineStatusChange, handleOnlineStatusChange])

  return <OnlineStatusContext.Provider value={onlineStatus}>{children}</OnlineStatusContext.Provider>
}

export { OnlineStatusProvider }
