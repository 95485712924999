import { products } from "./products"
import { SalesOrderStatus } from "../generated"

export const orders = [
  {
    orderId: "O3048998",
    id: "O3048998",
    tenancyId: "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55",
    customerId: "",
    storeFrontId: "",
    deliveryDate: "",
    currencyId: "",
    orderDate: "22/04/2021",
    orderLines: [
      { ...products[0], quantity: 2 },
      { ...products[1], quantity: 1 },
    ],
    status: SalesOrderStatus.PAID,
    subtotal: Number((products[0].retailPrice * 2 + products[1].retailPrice).toFixed(2)),
    tax: Number((products[0].tax * 2 + products[1].tax).toFixed(2)),
    deposit: Number((products[0].deposit + products[1].deposit).toFixed(2)),
    orderDiscount: 0,
    discount: 333.7,
    discountType: "",
    paymentType: "Cash",
    sellerName: "",
    paidAmount: 0,
    change: 0,
    total: 1198.83,
  },
  {
    orderId: "O7387509",
    id: "O3048998",
    tenancyId: "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55",
    customerId: "",
    storeFrontId: "",
    deliveryDate: "",
    currencyId: "",
    orderDate: "20/04/2021",
    orderLines: [
      { ...products[0], quantity: 4 },
      { ...products[1], quantity: 3 },
      { ...products[2], quantity: 1 },
    ],
    status: SalesOrderStatus.CANCELED,
    subtotal: Number((products[0].retailPrice * 4 + products[1].retailPrice * 3 + products[2].retailPrice).toFixed(2)),
    tax: Number((products[0].tax * 4 + products[1].tax * 3 + products[2].tax).toFixed(2)),
    deposit: Number((products[0].deposit + products[1].deposit + products[2].deposit).toFixed(2)),
    orderDiscount: 0,
    discount: 717.41,
    total: 2689.3,
    discountType: "",
    paymentType: "Cash",
    sellerName: "",
    paidAmount: 0,
    change: 0,
  },
  {
    orderId: "O09756382",
    id: "O3048998",
    tenancyId: "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55",
    customerId: "",
    storeFrontId: "",
    deliveryDate: "",
    currencyId: "",
    orderDate: "15/04/2021",
    orderLines: [
      { ...products[1], quantity: 3 },
      { ...products[2], quantity: 2 },
      { ...products[3], quantity: 1 },
    ],
    status: SalesOrderStatus.PENDING,
    subtotal: Number((products[1].retailPrice * 3 + products[2].retailPrice * 2 + products[3].retailPrice).toFixed(2)),
    tax: Number((products[1].tax * 3 + products[2].tax * 2 + products[3].tax).toFixed(2)),
    deposit: Number((products[1].deposit + products[2].deposit + products[3].deposit).toFixed(2)),
    orderDiscount: 0,
    discount: 182.3,
    total: 2011.42,
    discountType: "",
    paymentType: "Cash",
    paidAmount: 0,
    sellerName: "",
    change: 0,
  },
  {
    orderId: "O9348576",
    id: "O3048998",
    tenancyId: "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55",
    customerId: "",
    storeFrontId: "",
    deliveryDate: "",
    currencyId: "",
    orderDate: "15/04/2021",
    orderLines: [
      { ...products[1], quantity: 2 },
      { ...products[2], quantity: 4 },
      { ...products[3], quantity: 3 },
    ],
    status: SalesOrderStatus.PENDING,
    subtotal: Number(
      (products[1].retailPrice * 2 + products[2].retailPrice * 4 + products[3].retailPrice * 3).toFixed(2)
    ),
    tax: Number((products[1].tax * 2 + products[2].tax * 4 + products[3].tax * 3).toFixed(2)),
    deposit: Number((products[1].deposit + products[2].deposit + products[3].deposit).toFixed(2)),
    orderDiscount: 0,
    discount: 71.47,
    total: 1991.54,
    discountType: "",
    paymentType: "Cash",
    paidAmount: 0,
    sellerName: "",
    change: 0,
  },
]
