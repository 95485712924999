import { Flex, Text, Textarea } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { format, parseISO } from "date-fns"
import { FC, useContext } from "react"

import { Order } from "api"
import { ProductsContext } from "features/inShop/product/ProductState/context"

type SalesOrderDetailHeaderProps = {
  onClick: () => void
  order: Order
}

const SalesOrderDetailHeader: FC<SalesOrderDetailHeaderProps> = ({ onClick, order }) => {
  const { t } = useTranslation()
  const {
    state: {
      globalEntities: {
        tenancyCurrency: {
          currency: { symbol },
        },
      },
    },
  } = useContext(ProductsContext)

  return (
    <>
      <Flex pl={20} pr={4} py={4} width="100%">
        <Flex justify="space-between" mr="4rem" width="55%">
          <Flex direction="column">
            <Flex mb={6}>
              <Text fontWeight="bold" mr={6}>
                {t("orderDate")}
              </Text>
              <Text data-testid="orderDate">
                {order.orderDate ? format(parseISO(order.orderDate), "dd/MM/yyyy") : "N/A"}
              </Text>
            </Flex>
            <Flex>
              <Text fontWeight="bold" mr={6}>
                {t("customer")}
              </Text>
              <Text data-testid="customer">{order.customerId ? order.customer?.name : "N/A"}</Text>
            </Flex>
          </Flex>
          <Flex direction="column">
            <Flex mb={6}>
              <Text fontWeight="bold" mr={6}>
                {t("orderStatus")}
              </Text>
              <Text data-testid="orderStatus">{order.status}</Text>
            </Flex>
            <Flex>
              <Text fontWeight="bold" mr={6}>
                {t("paymentMethod")}
              </Text>
              <Text data-testid="paymentMethod">{order.paymentType}</Text>
            </Flex>
          </Flex>
          <Flex direction="column">
            <Flex mb={6}>
              <Text fontWeight="bold" mr={6}>
                {t("paidAmount")}
              </Text>
              <Text data-testid="paidAmount">
                {order.paidAmount.toFixed(2)} {symbol}
              </Text>
            </Flex>
            <Flex>
              <Text fontWeight="bold" mr={6}>
                {t("remainingAmount")}
              </Text>
              <Text data-testid="remainingAmount">0.00 {symbol}</Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          <Flex direction="column" mr={6}>
            <Text fontWeight="bold">{t("deliveryAddress")}</Text>
            <Textarea
              background="field.01"
              data-testid="delivery-address-field"
              mt={2}
              placeholder={t("writeAddress")}
              resize="none"
              width="15rem"
            />
          </Flex>
          <Flex direction="column">
            <Text fontWeight="bold">{t("notes")}</Text>
            <Textarea
              background="field.01"
              data-testid="notes-field"
              mt={2}
              placeholder={t("writeNotes")}
              resize="none"
              value={order.notes}
              width="15rem"
              readOnly
              onClick={onClick}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export { SalesOrderDetailHeader }
