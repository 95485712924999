import { useQuery } from "@tanstack/react-query"
import { useLocation } from "react-router-dom"
import { useDebounce } from "use-debounce"

import { InShopService } from "../generated"
import { DEBOUNCE_DELAY } from "../utils"

export const useSearchProductsDebounced = (tenancyId: UUID, storeFrontId: UUID, productName: string) => {
  const [productNameDebounced] = useDebounce(productName, DEBOUNCE_DELAY)

  return useQuery(
    [productNameDebounced],
    () =>
      InShopService.searchProductsGetAsync({
        tenancyId,
        storeFrontId,
        productName,
        searchByStoreFront: true,
        filterOptions: { includeOutOfStock: false, includeInactive: false, isBarcode: false },
      }),
    { cacheTime: 0 }
  )
}

export const useSearchProducts = (tenancyId: UUID, storeFrontId: UUID, productName: string) => {
  const location = useLocation()
  const searchByStoreFront = location.pathname.includes("/inShop")

  return useQuery(
    [productName],
    () =>
      InShopService.searchProductsGetAsync({
        tenancyId,
        storeFrontId,
        productName,
        searchByStoreFront,
        filterOptions: {
          includeInactive: false,
          includeOutOfStock: false,
        },
      }),
    { cacheTime: 0 }
  )
}
