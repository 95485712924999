import { Box, Flex, Spinner } from "@chakra-ui/react"
import { FC } from "react"

type Props = {
  isFullScreen?: boolean
}

const Loading: FC<Props> = ({ isFullScreen }) =>
  isFullScreen ? (
    <Box background="white" bottom="0" left="0" position="fixed" right="0" top="0" zIndex="docked">
      <LoadingSpinner />
    </Box>
  ) : (
    <LoadingSpinner />
  )

const LoadingSpinner = () => (
  <Flex align="center" flex={1} height="100%" justify="center">
    <Spinner size="xl" />
  </Flex>
)

export { Loading }

export type { Props as LoadingProps }
