import { VariantOptionValue } from "../models/VariantOptionValue";
import { request as __request } from "../core/request";
import { trackPromise } from "react-promise-tracker";

export class VariantOptionValuesService {
  /**
   * Gets all Variant Type Option Values.
   * ## Requires:
   * ### variantOptionValues.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async variantOptionValuesGetAllAsync({
    select,
    expand,
    tenancyId,
    parentId,
  }: {
    tenancyId: string,
    parentId: string,
    /** Limits the properties returned in the result. **/
    select?: string,
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string,
  }): Promise<{
    readonly '@odata.context'?: string,
    readonly value?: Array<VariantOptionValue>,
  }> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/tenancies/${tenancyId}/isa/variantoptions/${parentId}/variantoptionvalues`,
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Create Variant Type Option Value.
   * ## Requires:
   * ### variantOptionValues.Create
   * @returns any Success
   * @throws ApiError
   */
  public static async variantOptionValuesPostAsync({
    tenancyId,
    parentId,
    requestBody,
  }: {
    tenancyId: string,
    parentId: string,
    requestBody?: Partial<VariantOptionValue>,
  }): Promise<({
    readonly '@odata.context'?: string,
  } & VariantOptionValue)> {
    const result = await trackPromise(__request({
      method: 'POST',
      path: `/api/tenancies/${tenancyId}/isa/variantoptions/${parentId}/variantoptionvalues`,
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Gets Variant Type Option Value.
   * ## Requires:
   * ### variantOptionValues.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async variantOptionValuesGetAsync({
    id,
    tenancyId,
    parentId,
    select,
    expand,
  }: {
    tenancyId: string,
    parentId: string,
    id: string,
    /** Limits the properties returned in the result. **/
    select?: string,
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string,
  }): Promise<{
    readonly '@odata.context'?: string,
  } & VariantOptionValue> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/tenancies/${tenancyId}/isa/variantoptions/${parentId}/variantoptionvalues/${id}`,
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Update Variant Type Option Value.
   * ## Requires:
   * ### variantOptionValues.Update
   * @returns any Success
   * @throws ApiError
   */
  public static async variantOptionsPatchAsync({
    tenancyId,
    parentId,
    id,
    requestBody,
  }: {
    tenancyId: string,
    parentId: string,
    id: string,
    requestBody?: Partial<VariantOptionValue>,
  }): Promise<({
    readonly '@odata.context'?: string,
  } & VariantOptionValue)> {
    const result = await trackPromise(__request({
      method: 'PATCH',
      path: `/api/tenancies/${tenancyId}/isa/variantoptions/${parentId}/variantoptionvalues/${id}`,
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        409: `Conflict`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Delete the Variant Type Option Value.
   * ## Requires:
   * ### variantOptionValues.Delete
   * @returns any Success
   * @throws ApiError
   */
  public static async variantOptionValuesDeleteAsync({
    id,
    tenancyId,
    parentId,
  }: {
    id: string,
    tenancyId: string,
    parentId: string,
  }): Promise<({
    readonly '@odata.context'?: string,
  } & VariantOptionValue)> {
    const result = await trackPromise(__request({
      method: 'DELETE',
      path: `/api/tenancies/${tenancyId}/isa/variantoptions/${parentId}/variantoptionvalues/${id}`,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }
}