import { Flex, Input, Select, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import { isValidPhoneNumber } from "libphonenumber-js"
import { FC, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { Customer, api } from "api"
import { FormButtons, FormCountrySelect, FormField, FormPhoneNumberInput } from "common/components"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { TENANCY_ID } from "common/constants"
import { LONG_FORM_ELEMENT_WIDTH, formStyles } from "common/theme/formStyles"
import { numbersNlettersNspecialCharacters } from "common/validation"
import { route } from "features/app/routes"

enum CustomerTypes {
  Individual = "Individual",
  Business = "Business",
}

type FormValues = {
  name: string
  countryId: string
  customerType: string
  nifOrCif: string
  companyRegistrationNumber: string
  taxRegistrationNumber: string
  nationalId: string
  nie: string
  notes: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zipCode: string
  townOrCity: string
  county: string
  region: string
  postCode: string
  email: string
  phone: string
  mobile: string
}

type CustomerChangesProps = { customerId?: string; setCustomerAndCloseModal?: (customer?: Customer) => void }

// eslint-disable-next-line sonarjs/cognitive-complexity
const CustomerChanges: FC<CustomerChangesProps> = ({ customerId, setCustomerAndCloseModal }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { data } = api.useCustomerEntity(TENANCY_ID, customerId || "")
  const { data: tenancyData } = api.useTenancy(TENANCY_ID)

  const createCustomerMutation = api.useCreateSalesCustomerMutation(
    {
      onSuccess: (newCustomer) =>
        setCustomerAndCloseModal ? setCustomerAndCloseModal(newCustomer) : navigate(route.customers.path),
    },
    TENANCY_ID
  )

  const updateCustomerMutation = api.useUpdateSalesCustomerMutation(
    {
      onSuccess: (newCustomer) =>
        setCustomerAndCloseModal ? setCustomerAndCloseModal(newCustomer) : navigate(route.customers.path),
    },
    TENANCY_ID,
    customerId || ""
  )

  const customerTypes = [CustomerTypes.Individual, CustomerTypes.Business]

  const schema = z
    .object({
      name: z
        .string()
        .nonempty()
        .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") }),
      countryId: z.string().nonempty({ message: t("validation.select", { field: "country" }) }),
      customerType: z.string().nonempty({ message: t("validation.select", { field: "customerType" }) }),
      nifOrCif: z.string().optional(),
      nie: z.string().optional(),
      companyRegistrationNumber: z.string().optional(),
      taxRegistrationNumber: z.string().optional(),
      nationalId: z.string().optional(),
      notes: z.string().optional(),
      addressLine1: z.string().optional(),
      addressLine2: z.string().optional(),
      city: z.string().optional(),
      state: z.string().optional(),
      zipCode: z.string().max(10).optional(),
      townOrCity: z.string().optional(),
      county: z.string().optional(),
      region: z.string().optional(),
      postCode: z.string().optional(),
      email: z.string().email({ message: t("validation.email") }),
      phone: z.string().refine((val) => isValidPhoneNumber(val), {
        message: t("validation.phone"),
      }),
      mobile: z
        .string()
        .refine((val) => isValidPhoneNumber(val), {
          message: t("validation.phone"),
        })
        .optional()
        .or(z.literal("+")),
    })
    .refine((input) => {
      if (input.countryId === "826") {
        return input.customerType === CustomerTypes.Business
          ? !!input.companyRegistrationNumber && !!input.postCode
          : !!input.postCode
      }

      if (!input.city || !input.region) return false

      if (input.countryId === "724") {
        return (input.customerType === CustomerTypes.Business ? !!input.nifOrCif : !!input.nie) && !!input.postCode
      }

      return input.customerType === CustomerTypes.Business
        ? !!input.companyRegistrationNumber && !!input.zipCode
        : !!input.zipCode
    })

  const {
    formState: { errors, isValid, isDirty },
    getValues,
    setValue,
    register,
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      name: "",
      countryId: "",
      customerType: "",
      companyRegistrationNumber: "",
      nationalId: "",
      taxRegistrationNumber: undefined,
      nifOrCif: "",
      nie: "",
      notes: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      townOrCity: "",
      region: "",
      county: "",
      postCode: "",
      email: "",
      phone: "",
      mobile: "",
    },
  })

  const watchCustomerType = watch("customerType")
  const watchCountryId = watch("countryId")
  const watchPhone = watch("phone")
  const watchMobile = watch("mobile")
  const isInvalid = !isValid || !isDirty
  const formFieldProps = { errors, register }

  useEffect(() => {
    if (tenancyData?.countryId && !customerId) {
      setValue("countryId", String(tenancyData?.countryId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenancyData])

  const getFields = (countryId: number, customerType: CustomerTypes, nationalId: string) => {
    if (countryId === 724) {
      customerType === CustomerTypes.Individual ? setValue("nie", nationalId) : setValue("nifOrCif", nationalId)
    } else {
      customerType === CustomerTypes.Business && setValue("companyRegistrationNumber", nationalId)
    }
  }

  useEffect(() => {
    if (watchCountryId && !customerId) {
      setValue("phone", "+34")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCountryId])

  useEffect(() => {
    if (data && customerId) {
      const address = JSON.parse(data.address)
      getFields(data.countryId, data.customerType as CustomerTypes, data.nationalId)
      setValue("name", data.name)
      setValue("countryId", String(data.countryId))
      setValue("customerType", data.customerType)
      setValue("email", data.email)
      setValue("phone", data.phone)
      setValue("mobile", address.mobile)
      setValue("city", address.city)
      setValue("addressLine1", address.addressLine1)
      setValue("addressLine2", address.addressLine2)
      setValue("region", address.region)
      setValue("postCode", address.postCode)
      setValue("state", address.state)
      setValue("zipCode", address.zipCode)
      setValue("county", address.county)
      setValue("townOrCity", address.townOrCity)
      setValue("notes", data.notes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, customerId])

  const changesCustomer = (): void => {
    const {
      name,
      countryId,
      customerType,
      nie,
      nifOrCif,
      taxRegistrationNumber,
      companyRegistrationNumber,
      phone,
      notes,
      email,
      addressLine1,
      addressLine2,
      region,
      city,
      postCode,
      zipCode,
      townOrCity,
      county,
      state,
    } = getValues()
    const addressCommon = {
      addressLine1,
      addressLine2,
      region,
    }
    const addressForSpain = JSON.stringify({
      ...addressCommon,
      postCode,
      city,
    })
    const addressForUK = JSON.stringify({
      ...addressCommon,
      county,
      townOrCity,
      postCode,
      phone: phone.replace(/ /g, ""),
    })
    const addressOther = JSON.stringify({
      ...addressCommon,
      state,
      zipCode,
      city,
    })
    const address = countryId === "724" ? addressForSpain : countryId === "826" ? addressForUK : addressOther

    const data = {
      name,
      countryId: +countryId,
      customerType,
      nationalId: nie || nifOrCif || companyRegistrationNumber,
      taxRegistrationNumber,
      notes,
      phone: phone === "+34" ? "" : phone.replace(/ /g, ""),
      email,
      address,
    }
    if (!customerId) {
      createCustomerMutation.mutateAsync(data)
    } else {
      updateCustomerMutation.mutateAsync(data)
    }
  }

  return (
    <>
      {!setCustomerAndCloseModal && (
        <PageHeader
          buttonTitle={customerId && t("back")}
          isAddIconVisible={false}
          link={route.customers.path}
          subtitle={customerId && getValues().name}
          title={customerId ? `${t("editItem", { name: t("customer") })}` : t("addItem", { name: t("customer") })}
          variant="secondary"
          width="150px"
          isArrow
          onClick={() => customerId && navigate(-1)}
        />
      )}
      <Tabs width="100%">
        <TabList borderBottom="none" p={4} pl={40}>
          <Tab data-testid="customer-general-tab">{t("general")}</Tab>
          <Tab data-testid="customer-address-tab">{t("address")}</Tab>
        </TabList>

        <TabPanels pl={20}>
          <TabPanel px={0} width="100%">
            <FormField<FormValues>
              label={t("name")}
              name="name"
              isRequired
              {...formFieldProps}
              stackProps={{ ...formStyles.control, width: LONG_FORM_ELEMENT_WIDTH }}
            >
              <Input data-testid="customer-name-field" />
            </FormField>
            <FormField<FormValues>
              errors={errors}
              label={t("country")}
              name="countryId"
              stackProps={{ ...formStyles.control }}
              isRequired
            >
              <FormCountrySelect
                data-testid="customer-country-select"
                value={watchCountryId}
                onChange={(val) => setValue("countryId", val.toString(), { shouldDirty: true, shouldValidate: true })}
              />
            </FormField>
            <Flex justify="space-between" width={LONG_FORM_ELEMENT_WIDTH} wrap="wrap">
              <FormField<FormValues>
                label={t("customerTypeLabel")}
                name="customerType"
                isRequired
                {...formFieldProps}
                stackProps={formStyles.control}
              >
                <Select data-testid="customer-type-select">
                  <option value="" disabled>
                    {t("select")}
                  </option>
                  {customerTypes.map((type) => (
                    <option key={type} value={type}>
                      {type === CustomerTypes.Individual ? t("individual") : t("business")}
                    </option>
                  ))}
                </Select>
              </FormField>
              {watchCountryId === "724" &&
                watchCustomerType &&
                (watchCustomerType === CustomerTypes.Business ? (
                  <FormField<FormValues>
                    label={t("nifOrCif")}
                    name="nifOrCif"
                    isRequired
                    {...formFieldProps}
                    formControlProps={{ width: "unset" }}
                    stackProps={formStyles.control}
                  >
                    <Input data-testid="customer-nif-or-cif-field" />
                  </FormField>
                ) : (
                  <FormField<FormValues>
                    label={t("nie")}
                    name="nie"
                    isRequired
                    {...formFieldProps}
                    formControlProps={{ width: "unset" }}
                    stackProps={formStyles.control}
                  >
                    <Input data-testid="customer-nie-field" />
                  </FormField>
                ))}
              {watchCountryId !== "826" && watchCountryId !== "724" && watchCustomerType === CustomerTypes.Business && (
                <FormField<FormValues>
                  label={t("companyRegistrationNumber")}
                  name="companyRegistrationNumber"
                  isRequired
                  {...formFieldProps}
                  formControlProps={{ width: "unset" }}
                  stackProps={formStyles.control}
                >
                  <Input data-testid="customer-registration-number-v" />
                </FormField>
              )}
            </Flex>
            {watchCountryId === "826" && watchCustomerType === CustomerTypes.Business && (
              <Flex justify="space-between" width={LONG_FORM_ELEMENT_WIDTH} wrap="wrap">
                <FormField<FormValues>
                  label={t("companyRegistrationNumber")}
                  name="companyRegistrationNumber"
                  isRequired
                  {...formFieldProps}
                  formControlProps={{ width: "unset" }}
                  stackProps={formStyles.control}
                >
                  <Input data-testid="customer-registration-number-field" />
                </FormField>
                <FormField<FormValues>
                  label={t("vatRegistrationNumber")}
                  name="taxRegistrationNumber"
                  {...formFieldProps}
                  formControlProps={{ width: "unset" }}
                  stackProps={formStyles.control}
                >
                  <Input data-testid="customer-vat-registration-number-field" />
                </FormField>
              </Flex>
            )}
            <Flex justify="space-between" width={LONG_FORM_ELEMENT_WIDTH} wrap="wrap">
              <FormField<FormValues>
                label={t("email")}
                name="email"
                isRequired
                {...formFieldProps}
                formControlProps={{ width: "unset" }}
                stackProps={{ ...formStyles.control }}
              >
                <Input data-testid="customer-email-field" />
              </FormField>
              <FormField<FormValues>
                errors={errors}
                formControlProps={{ width: "unset" }}
                label={t("phone")}
                name="phone"
                stackProps={{ ...formStyles.control }}
                isRequired
              >
                <FormPhoneNumberInput
                  dataTestId="customer-phone-select"
                  value={watchPhone}
                  onChange={(val) => setValue("phone", val, { shouldDirty: true, shouldValidate: true })}
                />
              </FormField>
              {watchCountryId === "826" && (
                <FormField<FormValues>
                  errors={errors}
                  formControlProps={{ width: "unset" }}
                  label={t("mobile")}
                  name="mobile"
                  stackProps={{ ...formStyles.control }}
                >
                  <FormPhoneNumberInput
                    dataTestId="customer-mobile-phone-field"
                    value={watchMobile}
                    onChange={(val) => setValue("mobile", val, { shouldDirty: true, shouldValidate: true })}
                  />
                </FormField>
              )}
            </Flex>
            {watchCustomerType !== "" && (
              <FormField<FormValues>
                label={t("notes")}
                name="notes"
                {...formFieldProps}
                stackProps={{ ...formStyles.control, width: LONG_FORM_ELEMENT_WIDTH }}
              >
                <Input data-testid="customer-notes-field" />
              </FormField>
            )}
          </TabPanel>
          <TabPanel px={0}>
            <FormField<FormValues>
              label={t("addressLine", { number: 1 })}
              name="addressLine1"
              {...formFieldProps}
              stackProps={{ ...formStyles.control, width: LONG_FORM_ELEMENT_WIDTH }}
            >
              <Input data-testid="customer-address-line1-field" />
            </FormField>
            <FormField<FormValues>
              label={t("addressLine", { number: 2 })}
              name="addressLine2"
              {...formFieldProps}
              stackProps={{ ...formStyles.control, width: LONG_FORM_ELEMENT_WIDTH }}
            >
              <Input data-testid="customer-address-line2-field" />
            </FormField>
            <Flex justify="space-between" width={LONG_FORM_ELEMENT_WIDTH} wrap="wrap">
              {watchCountryId === "826" && (
                <FormField<FormValues>
                  label={t("county")}
                  name="county"
                  {...formFieldProps}
                  formControlProps={{ width: "unset" }}
                  stackProps={{ ...formStyles.control }}
                >
                  <Input data-testid="customer-country-field" />
                </FormField>
              )}
              {watchCountryId === "826" ? (
                <FormField<FormValues>
                  label={t("townOrCity")}
                  name="townOrCity"
                  {...formFieldProps}
                  formControlProps={{ width: "unset" }}
                  stackProps={{ ...formStyles.control }}
                >
                  <Input data-testid="customer-town-or-city-field" />
                </FormField>
              ) : (
                <>
                  <FormField<FormValues>
                    label={t("city")}
                    name="city"
                    isRequired
                    {...formFieldProps}
                    formControlProps={{ width: "unset" }}
                    stackProps={{ ...formStyles.control }}
                  >
                    <Input data-testid="customer-city-field" />
                  </FormField>
                  <FormField<FormValues>
                    label={t("region_label")}
                    name="region"
                    isRequired
                    {...formFieldProps}
                    formControlProps={{ width: "unset" }}
                    stackProps={{ ...formStyles.control }}
                  >
                    <Input data-testid="customer-region-field" />
                  </FormField>
                </>
              )}
              {(watchCountryId === "724" || watchCountryId === "826") && (
                <FormField<FormValues>
                  label={t("postCode")}
                  name="postCode"
                  isRequired
                  {...formFieldProps}
                  formControlProps={{ width: "unset" }}
                  stackProps={{ ...formStyles.control }}
                >
                  <Input data-testid="customer-post-code-field" maxLength={10} />
                </FormField>
              )}
              {watchCountryId !== "724" && watchCountryId !== "826" && (
                <>
                  <FormField<FormValues>
                    label={t("state")}
                    name="state"
                    {...formFieldProps}
                    formControlProps={{ width: "unset" }}
                    stackProps={{ ...formStyles.control }}
                  >
                    <Input data-testid="customer-state-field" />
                  </FormField>
                  <FormField<FormValues>
                    label={t("zipCode")}
                    name="zipCode"
                    isRequired
                    {...formFieldProps}
                    formControlProps={{ width: "unset" }}
                    stackProps={{ ...formStyles.control }}
                  >
                    <Input data-testid="customer-zip-code-field" maxLength={10} />
                  </FormField>
                </>
              )}
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Flex pl={20} pr={4} py={4}>
        <FormButtons
          cancel={() => (setCustomerAndCloseModal ? setCustomerAndCloseModal() : navigate(route.customers.path))}
          disabled={isInvalid}
          done={changesCustomer}
        />
      </Flex>
    </>
  )
}

export { CustomerChanges }
