import { Box, forwardRef } from "@chakra-ui/react"
import { FormRichSelectProps, FormRichSelect as Select } from "@iac/components.form"
import { useTranslation } from "@iac/translations.i18n-instance"
import React from "react"

export const FormRichSelect = forwardRef<FormRichSelectProps & { dataTestId?: string }, "div">(
  ({ dataTestId = "rich-select-container", options, ...props }, ref) => {
    const { t } = useTranslation()

    return (
      <Box data-testid={dataTestId} ref={ref} width="100%">
        <Select key={options.length} data-testid="rich-select" options={options} {...props} placeholder={t("select")} />
      </Box>
    )
  }
)
