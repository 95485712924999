import { Flex, Input, Textarea } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as z from "zod"

import { TenancyLanguage, api } from "api"
import { useUnitOfMeasure } from "api/hooks"
import { FormButtons, FormField, PageHeader } from "common/components"
import { TENANCY_ID } from "common/constants"
import { formStyles } from "common/theme"
import { numbersNletters, numbersNlettersNspecialCharacters } from "common/validation"
import { route } from "features/app/routes"

type FormValues = {
  name: string
  shortName: string
  description: string
}

const UnitsOfMeasureChanges = () => {
  const params = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const { data } = useUnitOfMeasure(TENANCY_ID, params.id || "")
  const { data: tenancyLanguages } = api.useTenancyLanguages(TENANCY_ID)

  const createUnitOfMeasureMutation = api.useCreateUnitOfMeasureMutation(
    { onSuccess: () => navigate(route.unitsOfMeasure.path) },
    TENANCY_ID
  )
  const updateUnitOfMeasureMutation = api.useUpdateUnitOfMeasureMutation(
    { onSuccess: () => navigate(route.unitsOfMeasure.path) },
    TENANCY_ID,
    params.id || ""
  )

  const schema = z.object({
    name: z
      .string()
      .max(15)
      .regex(numbersNletters, { message: t("validation.lettersNnumbers") }),
    shortName: z
      .string()
      .max(15)
      .regex(numbersNletters, { message: t("validation.lettersNnumbers") }),
    description: z
      .string()
      .max(200)
      .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") }),
  })

  const {
    formState: { errors, isDirty, isValid },
    getValues,
    register,
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: "all",
    defaultValues: {
      name: "",
      shortName: "",
      description: "",
    },
  })

  const isInvalid = !isDirty || !isValid
  const formFieldProps = { errors, register }

  const changeUnitOfMeasure = (): void => {
    const unit = getValues()
    const defaultLanguage = tenancyLanguages?.value?.find(
      (item: TenancyLanguage) => item.isDefault && item.isEnabled
    ) as TenancyLanguage
    const requestBody = {
      translations: [
        {
          languageId: defaultLanguage.languageId,
          value: JSON.stringify({
            Name: unit.name,
            ShortName: unit.shortName,
            Description: unit.description,
          }),
        },
      ],
    }
    if (params.id) {
      return updateUnitOfMeasureMutation.mutate(requestBody)
    }
    createUnitOfMeasureMutation.mutate(requestBody)
  }

  useEffect(() => {
    if (params.id && data) {
      setName(data.name)
      setValue("name", data.name)
      setValue("shortName", data.shortName)
      setValue("description", data.description)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, params.id])

  return (
    <>
      <PageHeader title={params.id ? `${t("editItem", { name })}` : t("unitOfMeasure.addNewUnitOfMeasure")} />
      <Flex direction="column" pl={20} pr={4} width="100%">
        <FormField<FormValues>
          label={t("name")}
          name="name"
          isRequired
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Input data-testid="unit-name-field" maxLength={15} placeholder="e.g. oz" />
        </FormField>
        <FormField<FormValues>
          label={t("unitOfMeasure.shortName")}
          name="shortName"
          isRequired
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Input data-testid="unit-short-name-field" maxLength={30} />
        </FormField>
        <FormField<FormValues>
          label={t("description")}
          name="description"
          isRequired
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Textarea data-testid="unit-description-field" placeholder={t("description_placeholder")} />
        </FormField>

        <FormButtons
          cancel={() => navigate(route.unitsOfMeasure.path)}
          disabled={isInvalid}
          done={changeUnitOfMeasure}
        />
      </Flex>
    </>
  )
}

export { UnitsOfMeasureChanges }
