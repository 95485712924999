import { Box, Flex, Image, Input, Switch, Text, VStack } from "@chakra-ui/react"
import { FC, useContext, useEffect, useState } from "react"

import { ProductImage, mockData } from "api"
import { getAvailability } from "common/utils/availability.utils"
import { ProductsContext } from "features/inShop/product/ProductState/context"

const RefundProductDetails: FC = () => {
  const { state } = useContext(ProductsContext)
  const { refundOrderProduct } = state.refund
  const [mainImage, setMainImage] = useState("")
  const [availability, setAvailability] = useState("")
  const [isReturned, setIsReturned] = useState(false)
  const [returnedCount, setReturnedCount] = useState(0)

  useEffect(() => {
    const mockProd = mockData.order.orders
      .find((item) => item.orderId === state.refund.refundOrder.orderId)
      ?.orderLines.find((item) => item.id === refundOrderProduct.id)
    mockProd && setReturnedCount(mockProd.retailPrice - refundOrderProduct.retailPrice)
    const image = refundOrderProduct.images.find((image: ProductImage) => image.isMainImage)
    image && setMainImage(image.thumbnailUrl)
    setAvailability(getAvailability(state.makeOrder.orderProduct.inventoryType))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundOrderProduct])

  useEffect(() => {
    if (returnedCount > 0) {
      setIsReturned(true)
    } else {
      setIsReturned(false)
    }
  }, [returnedCount])

  useEffect(() => {
    const mockProd = mockData.order.orders
      .find((item) => item.orderId === state.refund.refundOrder.orderId)
      ?.orderLines.find((item) => item.id === refundOrderProduct.id)
    if (isReturned) {
      mockProd && setReturnedCount(mockProd.retailPrice - refundOrderProduct.retailPrice)
    } else {
      setReturnedCount(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReturned])

  return (
    <VStack align="center" background="#36495E" color="white" fontWeight="bold" padding="20px" width="400px">
      <Flex align="center" direction="column" flex={1} width="100%">
        <Text fontSize={22} mb={4}>
          {refundOrderProduct.name}
        </Text>
        <Box height="365px" width="90%">
          <Image
            borderColor="white"
            borderRadius={mainImage ? "20px" : "0"}
            borderWidth={1}
            height={mainImage ? "auto" : "100%"}
            src={mainImage}
            width="100%"
          />
        </Box>
        <Flex align="center" direction="column" fontSize={30} marginTop="20px">
          <Text>{availability}</Text>
          <Text>€ {refundOrderProduct.retailPrice}</Text>
        </Flex>
        <Flex align="center" direction="column" fontSize={20} marginTop="20px">
          <Text>Return to stock</Text>
          <Flex align="center">
            <Input
              background="white"
              color="#36495E"
              fontSize={15}
              height="30px"
              marginRight="25px"
              padding="2px"
              textAlign="center"
              value={returnedCount}
              width="30px"
            />
            <Switch
              defaultIsChecked={isReturned}
              isChecked={isReturned}
              size="lg"
              onChange={() => setIsReturned(!isReturned)}
            />
          </Flex>
        </Flex>
      </Flex>
    </VStack>
  )
}

export { RefundProductDetails }
