import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

import { ErrorSplash } from "./ErrorSplash"

type Props = {
  onRetry: () => void
  onSignout: () => void
}

const AuthError: FC<Props> = ({ onRetry, onSignout }) => {
  const { t } = useTranslation()

  return (
    <ErrorSplash
      emitRetry={onRetry}
      emitSignout={onSignout}
      errorMessage={t("There is a problem with your session.")}
    />
  )
}

export { AuthError }
