import { useDisclosure } from "@chakra-ui/react"
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js"
import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { ProductModel, api, mockData } from "api"
import { OutOfStockModal } from "common/components/OutOfStockModal/OutOfStockModal"
import { BarcodeNotFoundModal } from "common/components/ScannedBarcode/BarcodeNotFoundModal"
import { TENANCY_ID } from "common/constants"
import { DiscountTypes } from "common/discountTypes"
import { InventoryTypes } from "common/inventoryTypes"
import { addScanToTroubleshooting } from "common/utils/troubleshooting.utils"
import { InShopProductsModal } from "features/inShop/components/InShopProcuctsModal/InShopProcuctsModal"
import { generateOrder, getInitProduct } from "features/inShop/inShopUtils"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

export const Scanner = () => {
  const navigate = useNavigate()
  const appInsights = useAppInsightsContext()
  const location = useLocation()
  const onInShopPage = location.pathname.includes("/inShop")
  const trackScan = useTrackEvent(appInsights, "scan", {})
  const {
    onOpen: onBarcodeNotFoundModalOpen,
    isOpen: isBarcodeNotFoundModalOpen,
    onClose: onBarcodeNotFoundModalClose,
  } = useDisclosure()
  const { isOpen: isOutOfStockOpen, onOpen: onOutOfStockOpen, onClose: onOutOfStockClose } = useDisclosure()
  const { onOpen: onSameBarcodeOpen, isOpen: isSameBarcodeOpen, onClose: onSameBarcodeClose } = useDisclosure()
  const { state, dispatch } = useContext(ProductsContext)
  const [productName, setProductName] = useState("")
  const [sCode, setSCode] = useState("")
  const [variantToEdit, setVariantToEdit] = useState({
    productId: "",
    variantId: "",
  })
  const [foundProducts, setFoundProducts] = useState<ProductModel[]>([])
  const {
    data: searchingProducts,
    refetch,
    isLoading,
  } = api.useSearchProducts(TENANCY_ID, state.globalEntities.storeFrontId, productName)

  useEffect(() => {
    if (!window.onScan.isAttachedTo(document)) {
      window.onScan.attachTo(document, {
        reactToPaste: false,
        stopPropagation: false,
        preventDefault: true,
        ignoreIfFocusOn: ["input", "textarea"],
        onScan: (sCode: string) => {
          addScanToTroubleshooting(sCode)
          setProductName(sCode)
          setSCode(sCode)
          if (!onInShopPage) refetch()
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addProductToOrder = (product: ProductModel) => {
    const openOutOfStockModal = () => {
      setVariantToEdit({ productId: product.productId, variantId: product.id })
      onOutOfStockOpen()
    }
    const isExist = state.makeOrder.order.orderLines.find((item) => item.id === product.id)
    if (isExist) {
      if (product.stock < isExist.quantity + 1 && isExist.inventoryType === InventoryTypes.Stocked) {
        return openOutOfStockModal()
      }

      const changedProducts = state.makeOrder.order.orderLines.map((item) =>
        item.id === product.id
          ? getChangedProduct({ ...product, discountType: DiscountTypes.Amount, quantity: item.quantity + 1 })
          : item
      )
      const generatedOrder = generateOrder(changedProducts, mockData.initOrder, state)
      dispatch({ type: Types.SetOrder, payload: { order: generatedOrder } })
    } else {
      if (product.stock === 0) return openOutOfStockModal()

      const products = [...state.makeOrder.order.orderLines, product]
      const generatedOrder = generateOrder(products, mockData.initOrder, state)
      dispatch({ type: Types.SetOrder, payload: { order: generatedOrder } })
    }
    navigate("/inShop/product")
  }

  useEffect(() => {
    if (productName && !isLoading) {
      setProductName("")

      if (searchingProducts?.value?.length === 0) return onBarcodeNotFoundModalOpen()

      if (onInShopPage) {
        trackScan(true)
        const products = (searchingProducts?.value || []).map((item) => getInitProduct(item))

        if (products?.length === 1 && products[0]?.id) {
          addProductToOrder(products[0])
        }
        if (products?.length > 1) {
          setFoundProducts(products)
          onSameBarcodeOpen()
        }
      } else {
        const product = searchingProducts?.value?.[0]
        if (product) navigate(`/inventory/products/info/${product.productId}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchingProducts, refetch, productName])

  const goToVariantPage = () => {
    navigate(`/inventory/products/info/${variantToEdit.productId}/${variantToEdit.variantId}`)
    onOutOfStockClose()
  }

  const onSelectProduct = (product: ProductModel) => {
    onSameBarcodeClose()
    addProductToOrder(product)
  }

  return (
    <>
      <BarcodeNotFoundModal isOpen={isBarcodeNotFoundModalOpen} sCode={sCode} onClose={onBarcodeNotFoundModalClose} />
      <OutOfStockModal isOpen={isOutOfStockOpen} onClick={goToVariantPage} onClose={onOutOfStockClose} />
      <InShopProductsModal
        isOpen={isSameBarcodeOpen}
        products={foundProducts}
        onClose={onSameBarcodeClose}
        onSelectProduct={onSelectProduct}
      />
    </>
  )
}

const getChangedProduct = (product: ProductModel): ProductModel => {
  const subtotal = product.retailPrice * product.quantity
  const discount =
    product.discountType === DiscountTypes.Amount
      ? product.discount * product.quantity
      : ((product.subtotal + product.tax) * product.discount) / 100
  const total = subtotal + product.tax * product.quantity - discount - product.deposit

  return { ...product, subtotal, discount, total }
}
