import { Button, Flex } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

type CashType = {
  returnToPaymentType: () => void
}

const Cash: FC<CashType> = ({ returnToPaymentType }) => {
  const { t } = useTranslation()
  const buttonStyles = {
    fontSize: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "14rem",
    borderRadius: 10,
    height: "12rem",
    bg: "white",
    boxShadow: "box",
  }

  return (
    <Flex flex={1} justify="flex-end" pr={5} pt="3.5rem" width="100%">
      <Button
        fontWeight="normal"
        {...buttonStyles}
        data-testid="change-payment-method-button"
        variant="text"
        whiteSpace="pre-line"
        onClick={returnToPaymentType}
      >
        {t("changePaymentMethod")}
      </Button>
    </Flex>
  )
}

export { Cash }
