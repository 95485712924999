import { Button, Flex, useMediaQuery } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, SetStateAction, useContext } from "react"

import { buttonStyle, sizes } from "./keyboardStyles"
import { ProductsContext } from "features/inShop/product/ProductState/context"

type FastKeyboardProps = {
  setAmount: Dispatch<SetStateAction<string>>
  setIsError: Dispatch<SetStateAction<boolean>>
  setKeyboardType: Dispatch<SetStateAction<string>>
}

export const FastKeyboard: FC<FastKeyboardProps> = ({ setAmount, setIsError, setKeyboardType }) => {
  const { t } = useTranslation()
  const {
    state: {
      globalEntities: {
        tenancyCurrency: {
          currency: { symbol },
        },
      },
    },
  } = useContext(ProductsContext)
  const [isLongThan769] = useMediaQuery("(min-height: 769px)")
  const values = {
    first: [1, 2],
    second: [5, 10],
    third: [20, 50],
  }

  const onPress = (value: number) => {
    setAmount(String(value))
    setKeyboardType("fast")
  }

  const getButton = (value: number) => (
    <Button
      key={value}
      {...sizes.sm}
      {...buttonStyle(isLongThan769)}
      _notFirst={{ marginRight: 0 }}
      mr={3}
      onClick={() => onPress(value)}
    >
      {symbol}
      {value}
    </Button>
  )

  return (
    <Flex direction="column">
      <Flex mb={4}>{values.first.map((value) => getButton(value))}</Flex>
      <Flex mb={4}>{values.second.map((value) => getButton(value))}</Flex>
      <Flex mb={4}>{values.third.map((value) => getButton(value))}</Flex>
      <Flex>
        <Button
          {...sizes.lg}
          {...buttonStyle(isLongThan769)}
          width="100%"
          onClick={() => {
            setAmount("")
            setIsError(false)
          }}
        >
          {t("clear")}
        </Button>
      </Flex>
    </Flex>
  )
}
