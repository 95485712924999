import { Flex } from "@chakra-ui/react"
import { FC } from "react"

import { PageHeader } from "common/components/PageHeader/PageHeader"
import { Search } from "features/inventory/search/Search"

const UsersView: FC = () => (
  <>
    <PageHeader buttonTitle="Add New User" title="Users" onClick={() => {}} />
    <Flex data-testid="supplierCredit" direction="column" p={4}>
      <Search placeholder="Search for users" titleButton="Search user" onSearch={() => {}} />
    </Flex>
  </>
)

export { UsersView }
