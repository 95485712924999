import { FC } from "react"

import { ImagesControl } from "./ImagesControl"
import { ImageType } from "api"

type ImagesControlSingleProps = {
  imageId?: UUID | null
  onChange: (imageId: UUID) => void
  imageType?: ImageType
  label?: string
  libraryImagesIds?: UUID[]
}

const ImagesControlSingle: FC<ImagesControlSingleProps> = ({ imageId, onChange, ...props }) => (
  <ImagesControl
    {...props}
    images={imageId ? [{ imageId, order: 1, isMainImage: true }] : []}
    isMultiple={false}
    onChange={(images) => onChange(images[0]?.imageId)}
  />
)

export type { ImagesControlSingleProps }

export { ImagesControlSingle }
