import { Flex, Image, Link, Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"

import { InShopLogo } from "common/theme"

const GET_ON_GOOGLE_PLAY_IMAGE_SRC =
  "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
const GOOGLE_PLAY_APP_HREF =
  "https://play.google.com/store/apps/details?id=com.iamcloud.inShop&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"

export const DownloadAppPage = () => {
  const { t } = useTranslation()

  return (
    <Flex align="center" direction="column" height="100%" justify="center">
      <Flex direction="column">
        <Flex align="center" mb={12}>
          <InShopLogo boxSize="190px" />
          <Text color="text.01" fontSize="12xl" ml={8}>
            {t("inShop")}
          </Text>
        </Flex>
        <Flex direction="column" ml={4}>
          <Text mb={6} variant="heading-03">
            {t("downloadAppPage.message")}
          </Text>
          <Text mb={16} variant="heading-04">
            {t("downloadAppPage.haveYouDownloadedApp")}
          </Text>
          <Link href={GOOGLE_PLAY_APP_HREF} m="-18px" w="280px" isExternal>
            <Image alt="Get it on Google Play" src={GET_ON_GOOGLE_PLAY_IMAGE_SRC} w="280px" />
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}
