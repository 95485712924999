import { Flex } from "@chakra-ui/react"
import { FC } from "react"

import { PageHeader } from "common/components/PageHeader/PageHeader"
import { Search } from "features/inventory/search/Search"

const RolesView: FC = () => (
  <>
    <PageHeader buttonTitle="Add New Role" title="Roles" onClick={() => {}} />
    <Flex data-testid="roles" direction="column" p={4}>
      <Search placeholder="Search for role" titleButton="Search role" onSearch={() => {}} />
    </Flex>
  </>
)

export { RolesView }
