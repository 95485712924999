import { Box, Button, Flex, HStack, IconButton, useToken } from "@chakra-ui/react"
import { DockBottomRightIcon, ErrorSolidIcon, WarningSolidIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, ReactNode } from "react"

import { StatusAlertStatus } from "./StatusAlertStatus"

type Props = {
  docked: boolean
  messageText: string
  status: StatusAlertStatus
  emitCancelRetrySignin: () => void
  emitRetrySignin: () => void
  emitSignin: () => void
  emitToggleDocked?: () => void
}

const backgroundColors: { [key in StatusAlertStatus]: string } = {
  authentication: "error.02",
  connection: "warning.02",
  countdown: "info.02",
  success: "",
}

const borderColors: { [key in StatusAlertStatus]: string } = {
  authentication: "error.03",
  connection: "warning.03",
  countdown: "info.03",
  success: "",
}

const icons: { [key in StatusAlertStatus]: object | null } = {
  authentication: <ErrorSolidIcon boxSize="4" color="error.01" />,
  connection: <WarningSolidIcon boxSize="4" color="warning.01" />,
  countdown: null,
  success: null,
}

const StatusAlertView: FC<Props> = ({
  docked,
  messageText,
  status,
  emitCancelRetrySignin,
  emitRetrySignin,
  emitSignin,
  emitToggleDocked,
}) => {
  const { t } = useTranslation()
  const dockedSize = useToken("space", 12)

  // success shows nothing.
  if (status === "success") {
    return null
  }

  const dockButton: { [key in StatusAlertStatus]: ReactNode } = {
    authentication: <Box />,
    connection: (
      <IconButton alignItems="center" aria-label={t("dock")} variant="spectre-icon" onClick={emitToggleDocked}>
        {docked ? icons[status] : <DockBottomRightIcon boxSize="3" />}
      </IconButton>
    ),
    countdown: <Box />,
    success: <Box />,
  }

  const dockedTransform = `translate(calc(50vw - ${dockedSize}), calc(100vh - 180%))`

  const signinButton = (
    <Button size="sm" variant="text" onClick={emitSignin}>
      {t("signin")}
    </Button>
  )

  const actionButtons: { [key in StatusAlertStatus]: ReactNode } = {
    authentication: (
      <>
        <Button size="sm" variant="text" onClick={emitRetrySignin}>
          {t("tryAgain")}
        </Button>
        {signinButton}
      </>
    ),
    connection: null,
    countdown: (
      <>
        <Button size="sm" variant="text" onClick={emitCancelRetrySignin}>
          {t("cancel")}
        </Button>
        {signinButton}
      </>
    ),
    success: null,
  }

  return (
    <Flex justify="center" width="full">
      <Flex
        _hover={docked ? { background: borderColors[status] } : {}}
        align="center"
        bg={backgroundColors[status]}
        borderColor={borderColors[status]}
        borderRadius="lg"
        borderWidth="1px"
        boxShadow="box"
        direction="row"
        justify="center"
        mt={5}
        overflow="hidden"
        pos="fixed"
        transform={docked ? dockedTransform : "none"}
        transition={[null, null, "transform .25s ease, width .1s ease"]}
        width={docked ? 12 : ["95%", null, 2 / 3]}
        zIndex="popover"
      >
        <Flex justify="center" overflow="hidden" width={docked ? "100%" : 0}>
          {dockButton[status]}
        </Flex>
        <Flex
          align="center"
          height={docked ? 12 : undefined}
          justifyContent="space-between"
          overflow="hidden"
          py={status === "connection" ? 1 : 2}
          whiteSpace={[null, null, "nowrap"]}
          width={docked ? 0 : "100%"}
        >
          <Box />
          <Flex alignItems="center" wrap="wrap">
            <Flex
              alignItems="center"
              color="text.01"
              fontSize="sm"
              gridGap={2}
              lineHeight="normal"
              ml={status === "countdown" ? 2 : 4}
            >
              <Box>{icons[status]}</Box>
              <Box>{messageText}</Box>
            </Flex>
            <HStack ml={4} spacing={5}>
              {actionButtons[status]}
            </HStack>
          </Flex>
          <Box mr={1}>{dockButton[status]}</Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export type { Props as StatusAlertViewProps }

export { StatusAlertView }
