export const numbersNletters = /^(?!\s*$)[A-Za-z0-9 _]+$/

export const webAddress = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\\+.~#?&/=]*)/

export const numbersNlettersNspecialCharacters = /^(?!\s*$)[\w\\[\]` ?!@#$€%^&*()={}:;<>+'-]*$/

export const numberField = /^[0-9.]+$/

export const onlyLetters = /^[A-Za-z _]+$/

export const numbersNlettersNdash = /^[A-Za-z0-9 _\\-]+$/

export const numbersNlettersNDot = /^[A-Za-z0-9 \\.]+$/

export const phoneValidation = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\\./0-9]*$/

export const emailValidation =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
