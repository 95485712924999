import { Box, Button, Flex, Heading, Text, VStack } from "@chakra-ui/react"
import { ChevronLeftIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import { AddProductAccordion } from "./components/AddProductAccordion"
import { Info } from "./components/Info"
import { InitialStock } from "./components/InitialStock"
import { TaxInfo } from "./components/TaxInfo"
import { ConfirmVariants } from "./ConfirmVariants"
import { DefaultVariants } from "./DefaultVariants"
import { ProductAPI } from "api"
import { FormButtons } from "common/components"
import { route } from "features/app/routes"

type ProductsChangesProps = {
  initialProductAPI: ProductAPI
  onSubmit: (productAPI: Partial<ProductAPI>) => void
}

export type SelectedVariants = {
  id: UUID
  name: string
  optionValues: { id: string; value: string }[]
}[]

export const ProductsChanges: FC<ProductsChangesProps> = ({ initialProductAPI, onSubmit }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [productAPI, setProductAPI] = useState<ProductAPI>(initialProductAPI)

  const [variants, setVariants] = useState<SelectedVariants>([])
  const [isDefaultVariants, setIsDefaultVariants] = useState(false)
  const [isReviewVariants, setIsReviewVariants] = useState(false)
  const [formsIsValid, setFormsIsValid] = useState({ productInfo: false, taxInfo: false, initialStock: false })
  const isInvalid = !Object.values(formsIsValid).every(Boolean)

  const infoRef = useRef<() => Partial<ProductAPI>>(() => productAPI)
  const taxInfoRef = useRef<() => Partial<ProductAPI>>(() => productAPI)
  const initialStockRef = useRef<() => Partial<ProductAPI>>(() => productAPI)
  const pageRef = useRef<HTMLDivElement>(null)

  const onConfirmAddProduct = (productAPI: Partial<ProductAPI>) => onSubmit(productAPI)

  return (
    <VStack align="start" pl={20} pr={28} py={16} ref={pageRef}>
      {isReviewVariants && <ConfirmVariants goBack={() => setIsReviewVariants(false)} product={productAPI} />}
      <Box display={isReviewVariants ? "none" : "contents"}>
        <Heading
          _hover={isDefaultVariants ? {} : { textDecoration: "underline", cursor: "pointer" }}
          alignItems="center"
          display="flex"
          fontWeight="bold"
          mb="60px"
          size="md"
          onClick={() => {
            if (!isDefaultVariants) navigate(-1)
          }}
        >
          {isDefaultVariants ? (
            <Button
              borderColor="info.01"
              borderWidth={1}
              fontSize="unset"
              mr={4}
              padding={3}
              variant="ghost"
              width="40px"
              onClick={() => setIsDefaultVariants(false)}
            >
              <ChevronLeftIcon color="info.01" />
            </Button>
          ) : (
            <ChevronLeftIcon color="ui.03" mr={3} width="20px" />
          )}

          <Text fontSize="2xl">{t("product.addNewProduct")}</Text>
          {isDefaultVariants && (
            <Text color="text.02" ml={8}>
              {t("product.reviewProductVariants")}
            </Text>
          )}
        </Heading>
        {isDefaultVariants && (
          <Flex direction="column" width="100%">
            <DefaultVariants
              productAPIState={[productAPI, setProductAPI]}
              variants={variants}
              onConfirm={onConfirmAddProduct}
              onReviewVariants={() => setIsReviewVariants(true)}
            />
          </Flex>
        )}

        <Flex display={isDefaultVariants ? "none" : "contents"}>
          <AddProductAccordion
            info={
              <Info
                getValuesRef={infoRef}
                productAPIState={[productAPI, setProductAPI]}
                onIsValidChange={(isValid) => setFormsIsValid({ ...formsIsValid, productInfo: isValid })}
              />
            }
            initialStock={
              <InitialStock
                getValuesRef={initialStockRef}
                productAPI={productAPI}
                variantsState={[variants, setVariants]}
                onIsValidChange={(isValid) => setFormsIsValid({ ...formsIsValid, initialStock: isValid })}
              />
            }
            taxInfo={
              <TaxInfo
                getValuesRef={taxInfoRef}
                productAPI={productAPI}
                onIsValidChange={(isValid) => setFormsIsValid({ ...formsIsValid, taxInfo: isValid })}
              />
            }
          />
          <FormButtons
            cancel={() => navigate(route.products.path)}
            disabled={isInvalid}
            done={() => {
              setProductAPI({
                ...productAPI,
                ...infoRef.current(),
                ...taxInfoRef.current(),
                ...initialStockRef.current(),
              })
              pageRef.current?.scrollIntoView()
              setIsDefaultVariants(true)
            }}
            titleDone={t("next")}
          />
        </Flex>
      </Box>
    </VStack>
  )
}
