import { Box, Button, Flex, Image, Text, VStack } from "@chakra-ui/react"
import { FC, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { RemainingProductForm } from "./RemainingProductForm/RemainingProductForm"
import { ProductImage, mockData } from "api"
import { SelectDiscount } from "common/components/SelectDiscount/SelectDiscount"
import { getAvailability } from "common/utils/availability.utils"
import { confirmOrder, generateOrder } from "features/inShop/inShopUtils"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

const RemainingProductDetails: FC = () => {
  const navigate = useNavigate()
  const { state, dispatch } = useContext(ProductsContext)
  const { orderProduct } = state.makeOrder
  const [mainImage, setMainImage] = useState("")
  const [availability, setAvailability] = useState("")
  const [discount, setDiscount] = useState<number>(0)
  const [typeDiscount, setTypeDiscount] = useState(orderProduct.discountType)
  const [showForm, setShowForm] = useState(false)

  const buttonStyles = {
    color: "white",
    height: 60,
    width: 168,
    fontSize: 25,
    borderRadius: 10,
  }

  const hideForm = (value: boolean): void => setShowForm(value)

  useEffect(() => {
    setDiscount(orderProduct.discount)
    setTypeDiscount(orderProduct.discountType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderProduct.id])

  useEffect(() => {
    const image = orderProduct.images.find((image: ProductImage) => image.isMainImage)
    image && setMainImage(image.thumbnailUrl)
    setAvailability(getAvailability(orderProduct.inventoryType))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderProduct.id])

  return (
    <VStack
      align="center"
      background="#36495E"
      color="white"
      fontWeight="bold"
      height="100%"
      padding="20px"
      width="400px"
    >
      <Flex align="center" direction="column" flex={1} height="100%" width="100%">
        <Flex align="center" direction="column" flex={1}>
          <Text fontSize={22} mb={4}>
            {orderProduct.name}
          </Text>
          <Box height="360px" width="90%">
            <Image
              borderColor="white"
              borderRadius={mainImage ? "20px" : "0"}
              borderWidth={1}
              height={mainImage ? "auto" : "100%"}
              src={mainImage}
              width="100%"
            />
          </Box>
        </Flex>
        {!showForm ? (
          <>
            <Flex align="center" direction="column" fontSize={30} marginTop="20px">
              <Text>{availability}</Text>
              <Text>€ {orderProduct.retailPrice}</Text>
            </Flex>
            <Flex
              direction="column"
              flex={1}
              fontSize={30}
              justify="space-between"
              marginBottom="2rem"
              marginTop="2rem"
              width="100%"
            >
              <Flex align="center" justify="space-between">
                <Button
                  style={{ ...buttonStyles, background: "#149a45" }}
                  onClick={() => {
                    const remainingOrder = generateOrder([orderProduct], mockData.initOrder, state)
                    dispatch({
                      type: Types.SetRemainingOrder,
                      payload: { remainingOrder },
                    })
                    navigate("/remainingPayment")
                  }}
                >
                  Pay
                </Button>
                <Button style={{ ...buttonStyles, background: "#007abd" }} onClick={() => setShowForm(true)}>
                  Deposit
                </Button>
              </Flex>
              <Flex align="center" justify="space-between" width="100%">
                <Text>Discount</Text>
                <SelectDiscount
                  typeDiscount={typeDiscount}
                  value={discount}
                  onChangeInput={(event) => setDiscount(+event.currentTarget.value)}
                  onChangeSelect={(event) => setTypeDiscount(event.currentTarget.value)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      const orderProd = { ...orderProduct, discount, typeDiscount }
                      const remainingProduct = state.remaining.remainingOrder.orderLines.find(
                        (item) => item.id === orderProd.id
                      )
                      if (!remainingProduct) {
                        const order = generateOrder([orderProd], mockData.initOrder, state)
                        dispatch({
                          type: Types.SetRemainingOrder,
                          payload: { remainingOrder: order },
                        })
                      }
                      dispatch({
                        type: Types.SetOrderProduct,
                        payload: { orderProduct: orderProd },
                      })
                      confirmOrder(orderProd, state, dispatch)
                    }
                  }}
                />
              </Flex>
            </Flex>
          </>
        ) : (
          <RemainingProductForm hideForm={hideForm} />
        )}
      </Flex>
    </VStack>
  )
}

export { RemainingProductDetails }
