import {
  Box,
  // Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Textarea,
} from "@chakra-ui/react"
// import { format } from "date-fns"
import { FC, useContext, useEffect, useState } from "react"
import { DayPicker } from "react-day-picker"

import { CalendarIcon } from "common/theme"
import { globalCurrency, symbolPosition } from "common/utils/currency.utils"
// import { confirmOrder } from "features/inShop/inShopUtils"
import { ProductsContext } from "features/inShop/product/ProductState/context"
// import { Types } from "features/inShop/product/ProductState/reducers"

type RemainingProductFormType = {
  hideForm: (value: boolean) => void
}

const RemainingProductForm: FC<RemainingProductFormType> = ({ hideForm }) => {
  const { state } = useContext(ProductsContext)
  const { remainingProduct } = state.remaining
  // const formatDate = "MM/dd/yyyy"
  const [deposit, setDeposit] = useState(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deliveryDate, setDeliveryDate] = useState(remainingProduct.deliveryDate)
  const [note, setNote] = useState("")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openNote, setOpenNote] = useState(false)
  // const [showButtons, setShowButtons] = useState(true)
  // const [overlay, setOverlay] = useState(false)

  const formControlStyle = {
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 0,
  }

  // const buttonStyle = {
  //   width: "100%",
  //   height: "45px",
  //   fontSize: 20,
  //   borderRadius: 10,
  //   zIndex: 0,
  // }

  const inputGroupStyle = {
    maxWidth: "155px",
    zIndex: 0,
  }

  const inputStyle = {
    background: "white",
    color: "#656464",
    fontSize: 18,
    paddingRight: 5,
    borderRadius: 5,
  }

  const labelStyle = {
    margin: 0,
    fontSize: 22,
  }

  // const showPicker = (): void => {
  //   setOverlay(true)
  //   setShowButtons(false)
  // }
  //
  // const chooseDate = (day: Date): void => {
  //   setOverlay(false)
  //   setDeliveryDate(format(day, formatDate))
  //   setShowButtons(true)
  // }

  // const getProduct = (): void => {
  //   const newProd = {
  //     ...remainingProduct,
  //     deposit,
  //     deliveryDate,
  //     note,
  //   }
  //
  //   dispatch({ type: Types.SetRemainingProduct, payload: { remainingProduct: newProd } })
  //   confirmOrder(newProd, state, dispatch)
  //   hideForm(false)
  // }

  useEffect(() => {
    setDeposit(remainingProduct.deposit)
    setNote(remainingProduct.notes)
    setDeliveryDate(remainingProduct.deliveryDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      //align="center"
      data-testid="form"
      style={{
        background: "#007abd",
        width: "100%",
        borderRadius: "10px",
        color: "white",
        padding: 16,
        fontWeight: "bold",
        fontSize: "22px",
      }}
    >
      <Box
        style={{
          position: "relative",
        }}
      >
        <FormControl id="deposit" style={formControlStyle}>
          <FormLabel style={{ ...labelStyle, fontWeight: "bold" }}>Deposit</FormLabel>
          <InputGroup style={inputGroupStyle}>
            <InputLeftElement
              children={<Text color="#656464">{globalCurrency.symbol}</Text>}
              maxWidth="30px"
              pointerEvents="none"
            />
            <Input
              placeholder="0.00"
              style={{ ...inputStyle, textAlign: "right" }}
              type="number"
              value={deposit}
              variant="filled"
              onChange={({ currentTarget }) => setDeposit(+currentTarget.value)}
            />
          </InputGroup>
        </FormControl>
        <Flex align="center" justify="space-between" marginBottom="10px">
          <Text>Remaining</Text>
          <Text>
            {symbolPosition(
              Number(
                (
                  remainingProduct.quantity * (remainingProduct.retailPrice + remainingProduct.tax) -
                  remainingProduct.deposit
                ).toFixed(2)
              )
            )}
          </Text>
        </Flex>
        <FormControl id="delivery" style={formControlStyle}>
          <FormLabel style={{ ...labelStyle, fontWeight: "bold" }}>Delivery date</FormLabel>
          <InputGroup style={inputGroupStyle}>
            <InputLeftElement
              children={<CalendarIcon color="#656464" />}
              height="100%"
              pointerEvents="none"
              zIndex={0}
            />
            <DayPicker style={{ ...inputStyle, padding: 0 }} />
          </InputGroup>
        </FormControl>
        {openNote && (
          <FormControl
            id="note"
            style={{
              position: "absolute",
              zIndex: 1,
              left: 0,
              top: 0,
              height: "100%",
            }}
          >
            <Textarea
              data-testid="notes"
              placeholder="Add note"
              style={{
                background: "white",
                color: "#656464",
                resize: "none",
                borderRadius: 5,
                height: "100%",
                padding: "1rem",
              }}
              value={note}
              variant="filled"
              onChange={(event) => setNote(event.currentTarget.value)}
            >
              {note}
            </Textarea>
          </FormControl>
        )}
        {/*  {showButtons && (*/}
        {/*    <Button*/}
        {/*      data-testid="note-button"*/}
        {/*      style={{*/}
        {/*        ...buttonStyle,*/}
        {/*        background: "transparent",*/}
        {/*        border: "1px solid white",*/}
        {/*        textTransform: "capitalize",*/}
        {/*      }}*/}
        {/*      onClick={() => setOpenNote(!openNote)}*/}
        {/*    >*/}
        {/*      {note.length > 0 ? "edit notes" : "add notes"}*/}
        {/*    </Button>*/}
        {/*  )}*/}
      </Box>
      {/*{showButtons && (*/}
      {/*  <Button*/}
      {/*    data-testid="confirm-button"*/}
      {/*    style={{*/}
      {/*      ...buttonStyle,*/}
      {/*      marginTop: "10px",*/}
      {/*      background: "#096e30",*/}
      {/*      textTransform: "capitalize",*/}
      {/*    }}*/}
      {/*    onClick={openNote ? () => setOpenNote(false) : () => getProduct()}*/}
      {/*  >*/}
      {/*    {!openNote ? "confirm" : "done"}*/}
      {/*  </Button>*/}
      {/*)}*/}
    </Box>
  )
}

export { RemainingProductForm }
