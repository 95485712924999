import { LibraryImage } from "api"
import { request as __request } from "../core/request"
import { ImageType } from "api"
import { trackPromise } from "react-promise-tracker";

export class ImagesService {
  public static async imagesGetAllAsync({
    tenancyId,
    imageType,
  }: {
    tenancyId: string
    imageType: ImageType
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<LibraryImage>
  }> {
    const result = await trackPromise(__request({
      method: "GET",
      path: `/api/tenancies/${tenancyId}/isa/images`,
      query: {
        $filter: `type eq '${imageType}'`,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }))
    return result.body
  }

  public static async imagesGetByIdsAsync({ tenancyId, ids }: { tenancyId: string; ids: string[] }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<LibraryImage>
  }> {
    const result = await trackPromise(__request({
      method: "GET",
      path: `/api/tenancies/${tenancyId}/isa/images`,
      query: {
        $filter: `id in (${ids})`,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }))
    return result.body
  }

  public static async imagesGetAsync({ tenancyId, id }: { tenancyId: string; id: string }): Promise<LibraryImage> {
    const result = await trackPromise(__request({
      method: "GET",
      path: `/api/tenancies/${tenancyId}/isa/images/${id}`,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }))
    return result.body
  }

  public static async imagesPostAsync({
    tenancyId,
    imageType,
    file,
  }: {
    tenancyId: string
    imageType?: ImageType
    file: Blob
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & LibraryImage
  > {
    const result = await trackPromise(__request({
      method: "POST",
      path: `/api/tenancies/${tenancyId}/isa/images/upload`,
      formData: {
        content: file,
        type: imageType,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }))
    return result.body
  }
}
