export const initCustomer = {
  id: "",
  parentId: "",
  name: "",
  email: "",
  phone: "",
  mobile: "",
  companyName: "",
  nationalId: "",
  customerType: "",
  nie: "",
  nifOrCif: "",
  companyType: "",
  taxRegistrationNumber: "",
  address: "",
  countryId: 0,
  notes: "",
}
