import { Button, ButtonProps, Flex, Link, LinkProps } from "@chakra-ui/react"
import { FC, ReactElement, forwardRef } from "react"
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom"

type AppMenuLinkProps = {
  iconPlacement?: "left" | "right"
  icon: ReactElement
}

const linkStyles = {
  display: "inline-flex",
  alignItems: "center",
  size: "sm",
}

const LinkContent: FC<AppMenuLinkProps> = ({ iconPlacement, icon, children }) => (
  <>
    {iconPlacement === "left" && (
      <Flex as="span" mr={2}>
        {icon}
      </Flex>
    )}
    {children}
    {iconPlacement === "right" && (
      <Flex as="span" ml={2}>
        {icon}
      </Flex>
    )}
  </>
)

const AppMenuLink = forwardRef<HTMLAnchorElement, AppMenuLinkProps & RouterLinkProps>(
  ({ iconPlacement = "left", icon, children, to, ...rest }, ref) => (
    <Link as={RouterLink} ref={ref} to={to} variant="appmenu" {...linkStyles} {...rest}>
      <LinkContent icon={icon} iconPlacement={iconPlacement}>
        {children}
      </LinkContent>
    </Link>
  )
)

const AppMenuExternalLink = forwardRef<HTMLAnchorElement, AppMenuLinkProps & LinkProps>(
  ({ iconPlacement = "left", icon, children, ...rest }, ref) => (
    <Link ref={ref} target="_blank" variant="appmenu" {...linkStyles} {...rest}>
      <LinkContent icon={icon} iconPlacement={iconPlacement}>
        {children}
      </LinkContent>
    </Link>
  )
)

const AppMenuButtonLink = forwardRef<HTMLButtonElement, AppMenuLinkProps & ButtonProps>(
  ({ iconPlacement = "left", icon, children, ...rest }, ref) => (
    <Button
      h="auto"
      leftIcon={iconPlacement === "left" ? icon : undefined}
      ref={ref}
      rightIcon={iconPlacement === "right" ? icon : undefined}
      size="sm"
      variant="text"
      {...rest}
    >
      {children}
    </Button>
  )
)

export type { AppMenuLinkProps }

export { AppMenuLink, AppMenuExternalLink, AppMenuButtonLink }
