import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { SalesOrderDetailHeader } from "./SalesOrderDetailHeader"
import { SalesOrderDetailsTable } from "../salesOrderDetailsTable/SalesOrderDetailsTable"
import { UpdateOrderModal } from "../UpdateOrderModal/UpdateOrderModal"
import { Order, ProductModel, api, mockData } from "api"
import { NotImplemented } from "common/components"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { TENANCY_ID } from "common/constants"
import { ProductsContext } from "features/inShop/product/ProductState/context"

const SalesOrderDetail: FC = () => {
  const params = useParams()
  const { t } = useTranslation()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [notes, setNotes] = useState("")
  const [type, setType] = useState<"order" | "product">("order")

  const {
    state: {
      globalEntities: {
        tenancyCurrency: {
          currency: { symbol },
        },
      },
    },
  } = useContext(ProductsContext)
  const [order, setOrder] = useState<Order>(mockData.initOrder)
  const [product, setProduct] = useState<ProductModel>(mockData.initProduct)

  const { data: salesOrder, refetch } = api.useSalesOrder(TENANCY_ID, params.orderId || "", {
    expand: "orderlines($expand=productvariant),customer",
  })

  const updateSalesOrderMutation = api.useUpdateSalesOrderMutation(
    {
      onSuccess: () => {
        refetch()
        onClose()
      },
    },
    TENANCY_ID,
    order.id
  )

  const updateProduct = (): void => {
    switch (type) {
      case "order":
        const newOrder = {
          orderLines: order.orderLines,
          // notes,
        }

        updateSalesOrderMutation.mutate(newOrder)
        break
      case "product":
        const changedProducts = order.orderLines.map((item) => (item.id === product.id ? { ...product, notes } : item))
        const changedOrder = {
          orderLines: changedProducts.map((item) => ({ id: item.id, notes: item.notes })),
        }

        updateSalesOrderMutation.mutate(changedOrder)
        break
    }
    onClose()
  }
  useEffect(() => {
    if (params.orderId && salesOrder) {
      setOrder(salesOrder)
    }
  }, [params.orderId, salesOrder])

  return (
    <>
      <PageHeader subtitle={String(order.orderNumber)} title={t("orderNumber")} isArrow isGoBack />
      <SalesOrderDetailHeader
        order={order}
        onClick={() => {
          setNotes(order.notes)
          onOpen()
        }}
      />
      <Flex direction="column" pl={20} pr={4} py={4} width="100%">
        <SalesOrderDetailsTable
          products={order.orderLines}
          setNotes={setNotes}
          setProduct={setProduct}
          setType={setType}
          onOpen={onOpen}
        />
        <Flex
          borderBottomColor="ui-02"
          borderBottomWidth={1}
          color="text-01"
          fontSize={18}
          fontWeight="bold"
          pb={4}
          pt={8}
          width="100%"
        >
          <Flex justify="space-between" width="30%">
            <Text>{t("discount")}</Text>
            <Text>
              {order.discount.toFixed(2)} {symbol}
            </Text>
          </Flex>
        </Flex>
        <Flex
          borderBottomColor="ui-02"
          borderBottomWidth={1}
          color="text-01"
          fontSize={18}
          fontWeight="bold"
          py={4}
          width="100%"
        >
          <Flex justify="space-between" width="30%">
            <Text>{t("subtotal")}</Text>
            <Text>
              {order.subtotal.toFixed(2)} {symbol}
            </Text>
          </Flex>
        </Flex>
        <Flex
          borderBottomColor="ui-02"
          borderBottomWidth={1}
          color="text-01"
          fontSize={18}
          fontWeight="bold"
          py={4}
          width="100%"
        >
          <Flex justify="space-between" width="30%">
            <Text>{t("iva")}</Text>
            <Text>
              {order.tax.toFixed(2)} {symbol}
            </Text>
          </Flex>
        </Flex>
        <Flex
          borderBottomColor="ui-02"
          borderBottomWidth={1}
          color="text-01"
          fontSize={18}
          fontWeight="bold"
          py={4}
          width="100%"
        >
          <Flex justify="space-between" width="30%">
            <Text>{t("total")}</Text>
            <Text>
              {order.total.toFixed(2)} {symbol}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <NotImplemented>
        <Flex justify="flex-end" pr="3rem" width="100%">
          <Button
            backgroundColor="success-01"
            color="text-04"
            data-testid="pay-remainder-button"
            fontWeight="normal"
            mr="3rem"
            size="lg"
          >
            {t("payRemainder")}
          </Button>
          <Button data-testid="refund-or-exchange-button" fontWeight="normal" size="lg" variant="primary">
            {t("refundOrExchange")}
          </Button>
        </Flex>
      </NotImplemented>
      <UpdateOrderModal isOpen={isOpen} notesState={[notes, setNotes]} updateProduct={updateProduct} />
    </>
  )
}

export { SalesOrderDetail }
