import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

export type ConfirmModalProps = {
  name?: string | null
  type: string
  isOpen: boolean
  onClose: () => void
  onClick: () => void
  isDelete?: boolean
}

export const ConfirmModal: FC<ConfirmModalProps> = ({ name, type, isOpen, onClick, onClose, isDelete }) => {
  const { t } = useTranslation()

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} motionPreset="slideInRight" isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={10} data-testid="modal-container" p="1.5rem">
        {isDelete && (
          <ModalHeader color="Text01" data-testid="modal-header" p="0 0 1rem 0">
            {t("confirm_header")}
          </ModalHeader>
        )}
        <ModalBody p={0}>
          <Text color="Text01" data-testid="modal-text">
            {t("deleting_modal", { name: name || "this", type })}
          </Text>
        </ModalBody>
        <ModalFooter alignItems="center" display="flex" justifyContent="flex-start" mt="2rem" p={0}>
          <Button data-testid="modal-confirm" mr={4} variant="primary" onClick={onClick}>
            {t("confirm")}
          </Button>
          <Button data-testid="modal-close" variant="secondary" onClick={onClose}>
            {t("cancel")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
