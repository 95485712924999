import { useUserCustomer } from "./CustomerContext/CustomerContext"
import { Entity, EntityTypes } from "api"
import { emptyAdmin } from "features/admins"

export const childCustomerTypes = ["partner", "consumer"]

export const customerTypes = ["root", "distributor", ...childCustomerTypes]

export const allCustomerTypes = ["customer", ...customerTypes]

export type CustomerType = (typeof customerTypes)[number]

export type AllCustomerType = (typeof allCustomerTypes)[number]

const entityTypes: { [key in CustomerType]: EntityTypes } = {
  root: EntityTypes.IAMCLOUD,
  distributor: EntityTypes.DISTRIBUTOR,
  partner: EntityTypes.PARTNER,
  consumer: EntityTypes.CUSTOMER,
}

export const customersOf = (customerType: CustomerType): CustomerType[] =>
  customerTypes.slice(customerTypes.indexOf(customerType) + 1)

export const toEntityType = (customerType: CustomerType): EntityTypes => entityTypes[customerType]

export const toCustomerType = (entityType: EntityTypes | string): CustomerType => {
  if (entityType === EntityTypes.IAMCLOUD || entityType === "root") {
    return "root"
  }

  if (entityType === EntityTypes.DISTRIBUTOR || entityType === "distributor") {
    return "distributor"
  }

  if (entityType === EntityTypes.PARTNER || entityType === "partner") {
    return "partner"
  }

  // is consumer by default
  return "consumer"
}

export type CustomerFilterArgs = {
  entityType?: EntityTypes
  parentId?: string
  name?: string
}

export const useCustomerFilter = ({ entityType, parentId, name }: CustomerFilterArgs) => {
  const { entityType: userEntityType } = useUserCustomer()

  const determineUserEntityTypeCondition = (givenEntityType: EntityTypes) => {
    // for historical reference
    // the entity type:
    // 1 - IAM Cloud
    // 2 - Distributors
    // 3 - Partners
    // 4 - Customers/Consumers

    // replacing entitytype gt ${userEntityType}

    // 1 - IAM Cloud > 1 - IAM Cloud                      :false
    // 2 - Distributors > 1 - IAM Cloud                   :true
    // 3 - Partners > 1 - IAM Cloud                       :true
    // 4 - Customers/Consumers > 1 - IAM Cloud            :true

    // 1 - IAM Cloud > 2 - Distributors                   :false
    // 2 - Distributors > 2 - Distributors                :false
    // 3 - Partners > 2 - Distributors                    :true
    // 4 - Customers/Consumers > 2 - Distributors         :true

    // 1 - IAM Cloud > 3 - Partners                       :false
    // 2 - Distributors > 3 - Partners                    :false
    // 3 - Partners > 3 - Partners                        :false
    // 4 - Customers/Consumers > 3 - Partners             :true

    // 1 - IAM Cloud > 4 - Customers/Consumers            :false
    // 2 - Distributors > 4 - Customers/Consumers         :false
    // 3 - Partners > 4 - Customers/Consumers             :false
    // 4 - Customers/Consumers > 4 - Customers/Consumers  :false

    if (givenEntityType === EntityTypes.IAMCLOUD) {
      return `(entitytype eq '${EntityTypes.DISTRIBUTOR}' or entitytype eq '${EntityTypes.PARTNER}' or entitytype eq '${EntityTypes.CUSTOMER}')`
    }

    if (givenEntityType === EntityTypes.DISTRIBUTOR) {
      return `(entitytype eq '${EntityTypes.PARTNER}' or entitytype eq '${EntityTypes.CUSTOMER}')`
    }

    if (givenEntityType === EntityTypes.PARTNER) {
      return `(entitytype eq '${EntityTypes.CUSTOMER}')`
    }

    return `(entitytype ne '${EntityTypes.IAMCLOUD}' and entitytype ne '${EntityTypes.DISTRIBUTOR}' and entitytype ne '${EntityTypes.PARTNER}' and entitytype ne '${EntityTypes.CUSTOMER}')`
  }

  return [
    userEntityType && determineUserEntityTypeCondition(userEntityType),
    entityType && `entitytype eq '${entityType}'`,
    parentId && `parentid eq ${parentId}`,
    typeof name === "string" && `contains(name, '${name}')`,
  ]
    .filter(Boolean)
    .join(" and ")
}

export const EMPTY_ENTITY: Entity = {
  id: "",
  name: "",
  parentId: "",
  ownerAdmin: emptyAdmin,
  entityType: EntityTypes.CUSTOMER,
  defaultTenancyId: "",
  territoryId: "",
}
