import { Stack } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { ChangeEvent, FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { CompanyFormControl } from "./companyFormControl/CompanyFormControl"
import { Company, api } from "api"
import { FormButtons } from "common/components"
import { FormStringSelect } from "common/components/FormSelect/FormStringSelect"
import { PageHeader } from "common/components/PageHeader/PageHeader"

const initCompany = {
  id: "",
  name: "",
  website: "",
  industry: "",
  country: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  region: "",
  postCode: "",
  timeZone: "",
  email: "",
  phone: "",
  mobile: "",
  taxLabel: "",
  taxNumber: "",
}

const CompanyInfo: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: countriesData } = api.useSupportedCountries({ top: 500 })
  const [company, setCompany] = useState<Company>(initCompany)

  const setInfoValue = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.currentTarget
    setCompany({
      ...company,
      [name]: value,
    })
  }

  const saveCompany = (): void => {
    localStorage.setItem("company", JSON.stringify(company))
    setCompany(initCompany)
    navigate("/settings")
  }

  const cancel = () => {
    setCompany(initCompany)
    navigate("/settings")
  }

  const commonSelect = (label: string, name: string, value: string | number) => (
    <FormStringSelect
      controlWidth="100%"
      data={countriesData?.value.map((item) => ({ id: item.id ?? "", name: item.name }))}
      data-testid="company-info-select"
      label={label}
      name={name}
      selectWidth="50%"
      value={value}
      onChange={(event) =>
        setCompany({
          ...company,
          country: event.currentTarget.value,
        })
      }
    />
  )

  useEffect(() => {
    const company = JSON.parse(localStorage.getItem("company") as string)
    if (company && company.name) {
      setCompany(company)
    }
  }, [])

  return (
    <>
      <PageHeader link="/settings" title={t("companyInfo.companyInformation")} isArrow />
      <Stack data-testid="company-info-form" pl={20} pr={4} py={4} width="50%">
        <CompanyFormControl
          label={t("companyInfo.companyName")}
          name="name"
          placeholder="eg. Netflix"
          value={company.name}
          onChange={setInfoValue}
        />
        <CompanyFormControl
          label={t("website")}
          name="website"
          placeholder="www.company.com"
          value={company.website}
          onChange={setInfoValue}
        />
        {commonSelect(t("country"), "country", company.country)}
        {commonSelect(t("companyInfo.industry"), "industry", company.industry)}
        <CompanyFormControl
          label={t("addressLine", { number: 1 })}
          name="addressLine1"
          value={company.addressLine1}
          onChange={setInfoValue}
        />
        <CompanyFormControl
          label={t("addressLine", { number: 2 })}
          name="addressLine2"
          value={company.addressLine2}
          onChange={setInfoValue}
        />
        <CompanyFormControl
          controlWidth="50%"
          label={t("city")}
          name="city"
          value={company.city}
          onChange={setInfoValue}
        />
        <CompanyFormControl
          controlWidth="50%"
          label={t("region_label")}
          name="region"
          value={company.region}
          onChange={setInfoValue}
        />
        <CompanyFormControl
          controlWidth="50%"
          label={t("post_code")}
          name="postCode"
          value={company.postCode}
          onChange={setInfoValue}
        />
        {commonSelect(t("timeZone"), "timeZone", company.timeZone)}
        <CompanyFormControl
          controlWidth="50%"
          label={t("email")}
          name="email"
          value={company.email}
          onChange={setInfoValue}
        />
        <CompanyFormControl
          controlWidth="50%"
          label={t("phone")}
          name="phone"
          value={company.phone}
          onChange={setInfoValue}
        />
        <CompanyFormControl
          controlWidth="50%"
          label={t("mobile")}
          name="mobile"
          value={company.mobile}
          onChange={setInfoValue}
        />
        <CompanyFormControl
          controlWidth="50%"
          label={t("companyInfo.taxLabel")}
          name="taxLabel"
          value={company.taxLabel}
          onChange={setInfoValue}
        />
        <CompanyFormControl
          controlWidth="50%"
          label={t("companyInfo.taxNumber")}
          name="taxNumber"
          value={company.taxNumber}
          onChange={setInfoValue}
        />
        <FormButtons cancel={cancel} disabled={false} done={saveCompany} />
      </Stack>
    </>
  )
}

export { CompanyInfo }
