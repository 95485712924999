export const variantTypes = [
  {
    id: "1d950261-e632-4ceb-aba1-9296c61723cc",
    name: "Size",
    description: "Different sizes",
    options: [
      {
        id: "364d01eb-966f-4668-946e-b286a19f78a2",
        image: "",
        description: "small size",
        name: "small",
        status: "Active",
      },
      {
        id: "bd1f3108-9162-4fcb-99e2-1a20d1329d71",
        image: "",
        description: "medium size",
        name: "medium",
        status: "Active",
      },
      {
        id: "4af2f473-9f47-4fe9-b66a-2922dd56ad35",
        image: "",
        description: "large size",
        name: "large",
        status: "Active",
      },
    ],
  },
  {
    id: "5852578a-bf78-4f25-8309-87809d5f7ba1",
    name: "Colour",
    description: "Make life colourful",
    options: [
      {
        id: "8acbdbab-12eb-40ba-ba0b-ef916f53f65c",
        description: "red color",
        colour: "#ff0000",
        name: "Red",
        status: "Active",
      },
      {
        id: "02ae934f-cd02-40a2-ac5f-5c5313d52c1f",
        description: "blue color",
        colour: "#0000ff",
        name: "Blue",
        status: "Active",
      },
      {
        id: "b2051f70-fe10-464c-9a53-7bf1dbd8aa1c",
        description: "yellow color",
        colour: "#ffff00",
        name: "Yellow",
        status: "Inactive",
      },
      {
        id: "fe2c8fa4-4a2b-4561-b703-7e0d1e343882",
        description: "green color",
        colour: "#00ff00",
        name: "Green",
        status: "Active",
      },
      {
        id: "27c47058-1ebb-4b21-8a87-74abc242e954",
        description: "purple color",
        colour: "#800080",
        name: "Purple",
        status: "Active",
      },
      {
        id: "303c1751-756d-44b2-a2a6-53e8eca217ad",
        description: "orange color",
        colour: "#FFA500",
        name: "Orange",
        status: "Active",
      },
      {
        id: "0666c3a0-59ae-4f80-a5b4-9690f045de23",
        description: "black color",
        colour: "#000000",
        name: "Black",
        status: "Active",
      },
    ],
  },
  {
    id: "80d54e96-a6ab-45bf-a6a1-3daa64a6cdba",
    name: "Taste",
    description: "Food must taste good to be edible",
    options: [
      { id: "364d01eb-966f-4668-946e-b286a19f78a2", description: "bitter taste", name: "Bitter", status: "Active" },
      { id: "bd1f3108-9162-4fcb-99e2-1a20d1329d71", description: "crisp taste", name: "Crisp", status: "Inactive" },
      { id: "4af2f473-9f47-4fe9-b66a-2922dd56ad35", description: "salty taste", name: "Salty", status: "Active" },
      { id: "2ffb213d-e5f7-4a85-aa79-c0c499c1a59f", description: "sour taste", name: "Sour", status: "Inactive" },
      { id: "de6f8423-82c6-4b02-af14-8f35abe39fb1", description: "spicy taste", name: "Spicy", status: "Active" },
      { id: "cc8c031c-2631-4706-b375-670f8e036b07", description: "sweet taste", name: "Sweet", status: "Active" },
    ],
  },
]
