import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"
import { useNavigate } from "react-router-dom"

import { CategoryForm } from "./CategoryForm"
import { Category, TenancyLanguage, api, mockData } from "api"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { TENANCY_ID } from "common/constants"
import { route } from "features/app/routes"

const AddProductCategory: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: tenancyLanguages } = api.useTenancyLanguages(TENANCY_ID)
  const createProductCategory = api.useCreateProductCategoriesMutation(
    { onSuccess: () => navigate(route.categories.path) },
    TENANCY_ID
  )

  const addCategory = (category: Partial<Category>): void => {
    const language = tenancyLanguages?.value?.find((item) => item.isDefault && item.isEnabled) as TenancyLanguage
    const object = {
      imageId: category.imageId,
      taxCategoryId: category.taxCategoryId,
      parentCategoryId: category.parentCategoryId || undefined,
      status: category.status,
      translations: [
        {
          languageId: language.languageId,
          value: JSON.stringify({
            Name: category.name,
            Description: category.description,
          }),
        },
      ],
    }
    createProductCategory.mutate(object)
  }

  return (
    <>
      <PageHeader title={t("addNewCategory")} />
      <CategoryForm category={mockData.initCategory} onDone={addCategory} />
    </>
  )
}

export { AddProductCategory }
