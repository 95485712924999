import type { UnitOfMeasure } from "../models/UnitOfMeasure";
import { request as __request } from '../core/request';
import { trackPromise } from "react-promise-tracker";

export class UnitOfMeasuresService {

  /**
   * Gets all Unit Of Measures.
   * ## Requires:
   * ### unitOfMeasures.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async unitOfMeasuresGetAllAsync({
    select,
    expand,
    parentId,
  }: {
    parentId: string,
    /** Limits the properties returned in the result. **/
    select?: string,
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string,
  }): Promise<{
    readonly '@odata.context'?: string,
    readonly value?: Array<UnitOfMeasure>,
  }> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/tenancies/${parentId}/isa/unitofmeasures`,
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Create Unit Of Measure.
   * ## Requires:
   * ### unitOfMeasures.Create
   * @returns any Success
   * @throws ApiError
   */
  public static async unitOfMeasuresPostAsync({
    parentId,
    requestBody,
  }: {
    parentId: string,
    requestBody?: Partial<UnitOfMeasure>,
  }): Promise<({
    readonly '@odata.context'?: string,
  } & UnitOfMeasure)> {
    const result = await trackPromise(__request({
      method: 'POST',
      path: `/api/tenancies/${parentId}/isa/unitofmeasures`,
      body: { ...requestBody, id: undefined, tenancyId: undefined },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Gets Unit of Measure.
   * ## Requires:
   * ### unitOfMeasures.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async unitOfMeasuresGetAsync({
    id,
    parentId,
    select,
    expand,
  }: {
    parentId: string,
    id: string,
    /** Limits the properties returned in the result. **/
    select?: string,
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string,
  }): Promise<{
    readonly '@odata.context'?: string,
  } & UnitOfMeasure> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/tenancies/${parentId}/isa/unitofmeasures/${id}`,
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Update Unit Of Measure.
   * ## Requires:
   * ### unitOfMeasures.Update
   * @returns any Success
   * @throws ApiError
   */
  public static async unitOfMeasuresPatchAsync({
    parentId,
    id,
    requestBody,
  }: {
    parentId: string,
    id: string,
    requestBody?: Partial<UnitOfMeasure>,
  }): Promise<({
    readonly '@odata.context'?: string,
  } & UnitOfMeasure)> {
    const result = await trackPromise(__request({
      method: 'PATCH',
      path: `/api/tenancies/${parentId}/isa/unitofmeasures/${id}`,
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        409: `Conflict`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Delete the Unit Of Measure.
   * ## Requires:
   * ### unitOfMeasures.Delete
   * @returns any Success
   * @throws ApiError
   */
  public static async unitOfMeasuresDeleteAsync({
    id,
    parentId,
  }: {
    id: string,
    parentId: string,
  }): Promise<({
    readonly '@odata.context'?: string,
  } & UnitOfMeasure)> {
    const result = await trackPromise(__request({
      method: 'DELETE',
      path: `/api/tenancies/${parentId}/isa/unitofmeasures/${id}`,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

}