import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, ReactNode, SetStateAction, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useTable } from "react-table"

import { UnitOfMeasure } from "api"
import { Pagination, Table, TableItemMenu } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE } from "common/constants"

type UnitOfMeasuresTableProps = {
  loading: boolean
  onOpen: () => void
  units: UnitOfMeasure[]
  refetch: (top: number, skip: number) => void
  setUnitMeasure: Dispatch<SetStateAction<UnitOfMeasure>>
  itemsPerPage: number
}

const UnitOfMeasuresTable: FC<UnitOfMeasuresTableProps> = ({
  units,
  onOpen,
  setUnitMeasure,
  loading,
  refetch,
  itemsPerPage,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const NameCell: FC<{ unit: UnitOfMeasure }> = ({ unit }) => (
    <TableItemMenu
      deleteClick={() => {
        setUnitMeasure(unit)
        onOpen()
      }}
      editClick={() => navigate(unit.id)}
      value={unit.name}
    />
  )

  const columnData: Column<UnitOfMeasure>[] = [
    {
      Header: t("name") as string,
      accessor: "name",
      Cell: ({ row: { original: unit } }) => <NameCell unit={unit} />,
    },
    {
      Header: t("unitOfMeasure.shortName") as string,
      accessor: "shortName",
    },
    {
      Header: t("description") as string,
      accessor: "description",
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setPageSize,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, []),
      data: useMemo(() => units, [units]),
      initialState: {
        pageSize: itemsPerPage,
      },
    },
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<UnitOfMeasure>): ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} data-testid="unitOfMeasuresTable" width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR bg="white" color="#36495E" fontSize="lg" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Table.TH _first={{ width: "30%" }} {...column.getHeaderProps()} textTransform="capitalize">
                {column.render("Header")}
              </Table.TH>
            ))}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()} textAlign="left">
        {loading ? (
          <Table.Skeleton columnWidths={[32, 16, 32, 64]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={(event) => {
                setPageSize(event)
                refetch(event, 0)
              }}
              totalPages={pageOptions.length}
              onPageChange={gotoPage}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { UnitOfMeasuresTable }
