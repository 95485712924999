import { VariantTypeOption } from "../generated"

export const initVariantOption = {
  id: "",
  tenancyId: "",
  name: "",
  description: "",
  variantOptionValues: [],
  translations: [],
  translationValue: [],
} as VariantTypeOption
