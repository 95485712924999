import { CustomerType } from "../customer.utils"
import { AdminRoles } from "api/generated/models/AdminRoles"
import { customerSpecificRole } from "features/admins"

export const disallowedAdminRoles = [
  AdminRoles.CUSTOMER_ADMINISTRATOR,
  AdminRoles.DISTRIBUTOR_ADMINISTRATOR,
  AdminRoles.PARTNER_ADMINISTRATOR,
  AdminRoles.PARTNER_MANAGER,
]

export const determineAdminRole = (
  isCurrentOwner: boolean | undefined,
  isNewOwner: boolean,
  currentRole: AdminRoles | undefined,
  customerType: CustomerType
): AdminRoles | undefined => {
  if (isCurrentOwner) {
    return AdminRoles.TENANCY_ADMINISTRATOR
  }

  if (isNewOwner) {
    return customerSpecificRole[customerType]
  }

  // otherwise no role change needed
  return currentRole
}
