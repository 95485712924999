import { Button, Flex } from "@chakra-ui/react"
import { AddIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

import { CashIcon, CreditCardIcon } from "common/theme"

type PaymentButtonsProps = {
  cardOnClick: () => void
  cashOnClick: () => void
  cancelClick: () => void
  onAddCustomer?: () => void
}

const PaymentButtons: FC<PaymentButtonsProps> = ({ cashOnClick, cardOnClick, cancelClick, onAddCustomer }) => {
  const { t } = useTranslation()
  const buttonStyles = {
    background: "#FCFCFC",
    color: "#007abd",
    fontSize: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "240px",
    borderRadius: 10,
    height: "200px",
  }

  return (
    <>
      <Flex align="center" flex={1} justify="space-between" width="100%">
        <Button
          boxShadow="box"
          data-testid="cash-payment-button"
          style={{ ...buttonStyles, padding: 0, flexDirection: "column" }}
          onClick={cashOnClick}
        >
          <CashIcon fontSize={60} />
          {t("cash")}
        </Button>
        <Button
          boxShadow="box"
          data-testid="card-payment-button"
          style={{ ...buttonStyles, padding: 0, flexDirection: "column" }}
          onClick={cardOnClick}
        >
          <CreditCardIcon fontSize={60} />
          {t("card")}
        </Button>
      </Flex>
      <Flex align="center" justify="flex-end" width="100%">
        <Button data-testid="cancel-order-button" fontSize="lg" variant="destructive" width={44} onClick={cancelClick}>
          {t("cancelOrder")}
        </Button>
        {onAddCustomer && (
          <Button fontSize="lg" ml={12} variant="secondary" onClick={onAddCustomer}>
            <AddIcon mr={2} />
            {t("addCustomer")}
          </Button>
        )}
      </Flex>
    </>
  )
}

export { PaymentButtons }
