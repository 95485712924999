import { Button, Flex, useMediaQuery } from "@chakra-ui/react"
import { Dispatch, FC, SetStateAction, useContext } from "react"

import { buttonStyle, sizes } from "./keyboardStyles"
import { SalesOrderStatus } from "api"
import { appInsights } from "appInsights"
import { DiscountTypes } from "common/discountTypes"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

type ClosedKeyboardProps = {
  setAmount: Dispatch<SetStateAction<string>>
  setIsError: Dispatch<SetStateAction<boolean>>
  createSalesOrder: (order: any) => void
}

export const ClosedKeyboard: FC<ClosedKeyboardProps> = ({ setAmount, setIsError, createSalesOrder }) => {
  const {
    state: {
      makeOrder: { order },
      globalEntities: {
        storeFrontId,
        tenancyCurrency: {
          currencyId,
          currency: { symbol },
        },
      },
    },
    dispatch,
  } = useContext(ProductsContext)
  const [isLongThan769] = useMediaQuery("(min-height: 750px)")
  const values = [1, 2, 5, 10, 20, 50, 100]

  const completeOrder = (value: number, changed: number): void => {
    appInsights.startTrackEvent("Create Order")
    const date = new Date()

    const orderLines = order.orderLines.map((item) => ({
      productVariantId: item.id,
      subtotal: item.subtotal,
      tax: item.tax,
      quantity: item.quantity,
      discount: item.discount,
      deposit: item.deposit,
      discountType: DiscountTypes.Amount,
      total: item.total,
      deliveryDate: item.deliveryDate || date.toISOString(),
      notes: item.notes,
    }))
    const createOrder = {
      ...order,
      orderDiscount: undefined,
      customerId: order.customerId || undefined,
      currencyId,
      discountType: DiscountTypes.Amount,
      orderId: undefined,
      orderDate: date.toISOString(),
      orderLines,
      storeFrontId,
      status: SalesOrderStatus.PAID,
      paidAmount: value,
      change: changed,
    }
    createSalesOrder(createOrder)
  }

  const onPress = (value: number) => {
    setAmount(String(value))
    const changed = +(value - order.total).toFixed(2)
    if (changed >= 0) {
      setIsError(false)
      dispatch({ type: Types.SetOrder, payload: { order: { ...order, paidAmount: value, change: changed } } })
      setAmount(String(value))
      completeOrder(value, changed)
    } else {
      setIsError(true)
    }
  }

  return (
    <Flex align="center" justify="space-between" mb={6} width="100%">
      {values.map((value) => (
        <Button key={value} {...sizes.md} {...buttonStyle(isLongThan769)} onClick={() => onPress(value)}>
          {symbol}
          {value}
        </Button>
      ))}
    </Flex>
  )
}
