import { FormControl, FormLabel, Input } from "@chakra-ui/react"
import { ChangeEvent, FC } from "react"

type CompanyFormControlProps = {
  name: string
  label: string
  controlWidth?: string
  value: string | number
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

const CompanyFormControl: FC<CompanyFormControlProps> = ({
  value,
  name,
  label,
  onChange,
  controlWidth = "100%",
  placeholder = "",
}) => {
  const formStyles = {
    control: {
      width: controlWidth,
      marginBottom: 20,
    },
    input: {
      borderRadius: 4,
      background: "white",
    },
  }

  return (
    <FormControl data-testid="form-control" style={formStyles.control}>
      <FormLabel data-testid="form-label" fontWeight="bold">
        {label}
      </FormLabel>
      <Input
        data-testid="company-field"
        name={name}
        placeholder={placeholder}
        style={formStyles.input}
        value={value}
        onChange={onChange}
      />
    </FormControl>
  )
}

export { CompanyFormControl }
