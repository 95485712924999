import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { CategoryForm } from "./CategoryForm"
import { Category, TenancyLanguage, api, mockData } from "api"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { TENANCY_ID } from "common/constants"
import { route } from "features/app/routes"

const EditSubCategory: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [name, setName] = useState("")
  const [subCategory, setSubCategory] = useState<Category>(mockData.initCategory)
  const { data } = api.useProductCategory(TENANCY_ID, params.categoryId || "")
  const { data: tenancyLanguages } = api.useTenancyLanguages(TENANCY_ID)
  const updateProductSubCategory = api.useUpdateProductCategoriesMutation(
    { onSuccess: () => navigate(route.categories.path) },
    TENANCY_ID,
    params.categoryId || ""
  )

  const editSubCategory = (subCategory: Partial<Category>): void => {
    const language = tenancyLanguages?.value?.find((item) => item.isDefault && item.isEnabled) as TenancyLanguage
    const object = {
      taxCategoryId: subCategory.taxCategoryId,
      parentCategoryId: subCategory.parentCategoryId,
      status: subCategory.status,
      translations: [
        {
          languageId: language.languageId,
          value: JSON.stringify({
            Name: subCategory.name,
            Description: subCategory.description,
          }),
        },
      ],
    }
    updateProductSubCategory.mutate(object)
  }

  useEffect(() => {
    if (data) {
      setName(data.name)
      setSubCategory(data)
    }
  }, [data, params.categoryId])

  return (
    <>
      <PageHeader title={t("editItem", { name })} />
      <CategoryForm category={subCategory} isEdit onDone={editSubCategory} />
    </>
  )
}

export { EditSubCategory }
