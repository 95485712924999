import { Box, Flex } from "@chakra-ui/react"
import { FC } from "react"

import { PageHeader } from "common/components/PageHeader/PageHeader"
import { Search } from "features/inventory/search/Search"

const PurchaseOrderView: FC = () => (
  <Box p={4}>
    <PageHeader buttonTitle="Add New Order" title="Purchase Order" onClick={() => {}} />
    <Flex data-testid="purchaseOrder" direction="column" p={4}>
      <Search placeholder="Search for purchase order" titleButton="Search purchase order" onSearch={() => {}} />
    </Flex>
  </Box>
)

export { PurchaseOrderView }
