import { Flex, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Tag, Text } from "@chakra-ui/react"
import { DownIcon, EditIcon, MoreHorizontalSolidIcon, RemoveIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useSortBy, useTable } from "react-table"

import { Category, TaxCategory, api } from "api"
import { Pagination, Table } from "common/components"
import { COUNTRY_ID, DEFAULT_ITEMS_PER_PAGE } from "common/constants"
import { DefaultImageIcon } from "common/theme"
import { route } from "features/app/routes"

type ProductSubcategoryTableProps = {
  categoryId: string
  subcategories: Category[]
  deleteSubcategory: (item: Category, type: string) => void
  loading: boolean
}

const ProductSubcategoryTable: FC<ProductSubcategoryTableProps> = ({
  subcategories,
  loading,
  deleteSubcategory,
  categoryId,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: taxCategories } = api.useTaxCategories(COUNTRY_ID)

  const CategoryCell: FC<{ category: Category }> = ({ category }) => (
    <Flex align="center">
      <Text mr={6} width="70%">
        {category.name}
      </Text>
      <Menu placement="left">
        <MenuButton
          aria-label="Options"
          as={IconButton}
          background="ui.01"
          borderColor="ui.02"
          borderWidth={1}
          icon={<MoreHorizontalSolidIcon color="black" />}
        />
        <MenuList>
          <MenuItem
            icon={<EditIcon fontSize={20} />}
            onClick={() => navigate(`${route.categories.path}/${categoryId}/${category.id}`)}
          >
            <Text fontSize={16} ml={6}>
              {t("edit")}
            </Text>
          </MenuItem>
          <MenuItem icon={<RemoveIcon fontSize={20} />} onClick={() => deleteSubcategory(category, "subcategory")}>
            <Text fontSize={16} ml={6}>
              {t("delete")}
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )

  const columnData: Column<Category>[] = [
    {
      Header: "Image",
      accessor: "imageId",
      Cell: ({ row }) =>
        row.original.image ? (
          <Image height="40px" src={row.original.image.thumbnailUrl} width="40px" />
        ) : (
          <DefaultImageIcon fontSize={40} />
        ),
      disableSortBy: true,
    },
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original: category } }) => <CategoryCell category={category} />,
    },
    {
      Header: "Description",
      accessor: "description",
      disableSortBy: true,
    },
    {
      Header: "Tax Category",
      accessor: "taxCategoryId",
      Cell: ({ row }) => {
        const taxCategory = taxCategories?.value?.find((item) => item.id === row.original.taxCategoryId) as TaxCategory

        return <Text>{taxCategory?.name || ""}</Text>
      },
      disableSortBy: true,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <Tag
          background={row.original.status === "Active" ? "success.02" : "warning.02"}
          borderColor={row.original.status === "Active" ? "success.03" : "warning.03"}
          borderRadius={2}
          color="text.01"
          fontSize={12}
        >
          {t(`statuses.${row.original.status}`)}
        </Tag>
      ),
      disableSortBy: true,
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, [categoryId]),
      data: useMemo(() => subcategories, [subcategories]),
    },
    useSortBy,
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<Category>): React.ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()} _hover={{ background: "#e3dcde" }}>
        {row.cells.map((cell) => {
          if (cell.column.Header === "Status") {
            return (
              <Table.TD textAlign="center" {...cell.getCellProps()}>
                {cell.render("Cell")}
              </Table.TD>
            )
          }

          return <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
        })}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} data-testid="categoriesTable" width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR bg="white" color="#36495E" fontSize="lg" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              if (column.Header === "Status") {
                return (
                  <Table.TH
                    textAlign="center"
                    textTransform="capitalize"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </Table.TH>
                )
              }

              return (
                <Table.TH
                  textAlign="left"
                  textTransform="capitalize"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <Flex align="center" justify="flex-start">
                    {column.render("Header")}
                    <Text marginLeft="1rem">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <DownIcon />
                        ) : (
                          <DownIcon transform="rotate(180deg)" />
                        )
                      ) : (
                        ""
                      )}
                    </Text>
                  </Flex>
                </Table.TH>
              )
            })}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()} textAlign="left">
        {loading ? (
          <Table.Skeleton columnWidths={[32, 16, 32, 64]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              setPageSize={() => {}}
              totalPages={pageOptions.length}
              onPageChange={gotoPage}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { ProductSubcategoryTable }
