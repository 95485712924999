import { FC } from "react"

import { PhotoLibraryPanel } from "./PhotoLibraryPanel"
import { LibraryImage, api } from "api"
import { TENANCY_ID } from "common/constants"

type ImagesUploadedByIdsProps = {
  libraryImagesIds: UUID[]
  selectedImages: Set<LibraryImage>
  onImageClick: (image: LibraryImage) => void
}

const ImagesUploadedByIds: FC<ImagesUploadedByIdsProps> = ({ selectedImages, onImageClick, libraryImagesIds }) => {
  const { data: imagesData } = api.useImagesByIds(TENANCY_ID, libraryImagesIds)

  return (
    <>
      {imagesData?.value && (
        <PhotoLibraryPanel
          libraryImages={imagesData?.value}
          selectedImages={selectedImages}
          onImageClick={onImageClick}
        />
      )}
    </>
  )
}

export type { ImagesUploadedByIdsProps }

export { ImagesUploadedByIds }
