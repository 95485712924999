import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  NumberInput,
  NumberInputField,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { AddIcon, ChevronDownIcon, ChevronUpIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { getDiscount } from "../inShopUtils"
import { DiscountTypes } from "common/discountTypes"
import { route } from "features/app/routes"
import { AddCustomerModal } from "features/customers/AddCustomer/AddCustomerModal"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

const MAX_DISCOUNT_PERCENTAGE = "100"

// eslint-disable-next-line sonarjs/cognitive-complexity
const PriceBreakdownBox = ({ isOrderSummaryVariant }: { isOrderSummaryVariant?: boolean }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    state: {
      makeOrder: { order },
      globalEntities: { tenancyCurrency },
    },
    dispatch,
  } = useContext(ProductsContext)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [discount, setDiscount] = useState("")
  const [isExpanded, setIsExpanded] = useState(false)
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const tenancyCurrencySymbol = tenancyCurrency.currency?.symbol

  const boxStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    border: `1px solid "#cac5c6"`,
    borderLeftRadius: 5,
    fontSize: 14,
    borderRightWidth: 1,
    borderRightColor: "CFD3D8",
  }

  const onChange = (event: string) => {
    if (event.includes("-")) return
    const productsDiscount = order.orderLines.map((item) => getDiscount(item)).reduce((a, b) => a + b)
    const total = +(order.subtotal + order.tax - productsDiscount).toFixed(2)
    if (order.discountType === DiscountTypes.Amount) {
      if (+event >= total) {
        setDiscount(total.toFixed(2))
      } else {
        setDiscount(event)
      }
    } else {
      if (+event > +MAX_DISCOUNT_PERCENTAGE) {
        setDiscount(MAX_DISCOUNT_PERCENTAGE)
      } else {
        setDiscount(event)
      }
    }
  }

  const onBlur = () => {
    const productsDiscount = order.orderLines.map((item) => getDiscount(item)).reduce((a, b) => a + b)
    const total = +(order.subtotal + order.tax - productsDiscount).toFixed(2)
    const orderDiscount =
      order.discountType === DiscountTypes.Amount ? +discount : +((total * +discount) / 100).toFixed(2)
    const mainDiscount = +(productsDiscount + orderDiscount).toFixed(2)
    const changedOrder = {
      ...order,
      discount: mainDiscount,
      orderDiscount: +discount,
      total: +(total - orderDiscount).toFixed(2),
    }

    dispatch({ type: Types.SetOrder, payload: { order: changedOrder } })
  }
  const onTypeSelect = (type: string) => {
    setDiscount("0")
    const productsDiscount = order.orderLines
      .map((item) => +(getDiscount(item) * item.quantity).toFixed(2))
      .reduce((a, b) => a + b)
    const total = +(order.subtotal + order.tax - productsDiscount).toFixed(2)
    const mainDiscount = +productsDiscount.toFixed(2)
    dispatch({
      type: Types.SetOrder,
      payload: {
        order: {
          ...order,
          orderDiscount: 0,
          discount: mainDiscount,
          discountType: type,
          total: +total.toFixed(2),
        },
      },
    })
  }

  useEffect(() => {
    if (isOrderSummaryVariant) {
      setIsExpanded(true)
    }
  }, [isOrderSummaryVariant])

  return (
    <Flex align="start" data-testid="PriceBreakdownBox" direction="column" pb={4} px={6} width="100%">
      {!isOrderSummaryVariant && (
        <IconButton
          aria-label={t("Expand price breakdown box button")}
          borderBottomRadius={0}
          data-testid="expand-panel-button"
          h={8}
          icon={isExpanded ? <ChevronDownIcon boxSize={4} /> : <ChevronUpIcon boxSize={4} />}
          ml={8}
          w={28}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      )}

      <Flex bg="elevation.04" borderRadius="lg" fontWeight="bold" justify="space-between" paddingY={6} width="100%">
        <Flex direction="column" fontSize={18} justifyContent="flex-end" letterSpacing={1.5} px={6} w="450px">
          {isExpanded ? (
            <>
              <Flex justify="space-between" mb={3}>
                <Text>{t("discount")}</Text>
                <Text>
                  {order.discount.toFixed(2)} {tenancyCurrencySymbol}
                </Text>
              </Flex>
              <Flex justify="space-between" mb={3}>
                <Text>{t("subtotal")}</Text>
                <Text>
                  {order.subtotal.toFixed(2)} {tenancyCurrencySymbol}
                </Text>
              </Flex>
              <Flex justify="space-between" mb={3}>
                <Text>{t("iva")}</Text>
                <Text>
                  {order.tax.toFixed(2)} {tenancyCurrencySymbol}
                </Text>
              </Flex>
              <Flex justify="space-between">
                <Text>{t("total")}</Text>
                <Text>
                  {order.total.toFixed(2)} {tenancyCurrencySymbol}
                </Text>
              </Flex>
              <Flex display="none" justify="space-between" mb={3}>
                <Text>{t("deposit")}</Text>
                <Text>
                  {order.deposit.toFixed(2)} {tenancyCurrencySymbol}
                </Text>
              </Flex>
              <Flex display="none" justify="space-between">
                <Text>{t("remaining")}</Text>
                <Text>
                  {(order.total - order.deposit).toFixed(2)} {tenancyCurrencySymbol}
                </Text>
              </Flex>
            </>
          ) : (
            <>
              <Flex justify="space-between" mb={2}>
                <Text>{t("total")}</Text>
                <Text>
                  {order.total.toFixed(2)} {tenancyCurrencySymbol}
                </Text>
              </Flex>
            </>
          )}
        </Flex>
        {!isOrderSummaryVariant && (
          <>
            <Flex align="flex-start" direction="column" gridColumnStart="2" gridRowStart={1} justify="end" pr={6}>
              <Flex direction="column">
                {isExpanded && (
                  <Flex justify="flex-end">
                    <FormControl
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      maxW="250px"
                      mb="2rem"
                    >
                      <FormLabel color="text.01" fontSize={18} mb={0}>
                        {t("discount")}:
                      </FormLabel>
                      <Flex align="center" justify="flex-end" width="60%">
                        <Box
                          data-testid="select-currency-button"
                          pos="relative"
                          {...boxStyles}
                          _hover={{ cursor: !!order.orderLines.length ? "pointer" : "not-allowed" }}
                          height="40px"
                          width="40%"
                          zIndex={1}
                          onClick={() => {
                            if (!!order.orderLines.length) setIsOpenSelect(!isOpenSelect)
                          }}
                        >
                          <Text>{order.discountType === DiscountTypes.Amount ? tenancyCurrencySymbol : "%"}</Text>
                          {isOpenSelect && (
                            <DiscountTypesSelect currencySymbol={tenancyCurrencySymbol} onTypeSelect={onTypeSelect} />
                          )}
                        </Box>
                        <NumberInput
                          data-testid="discount-field"
                          min={0}
                          precision={2}
                          value={discount}
                          onBlur={onBlur}
                          onChange={onChange}
                        >
                          <NumberInputField
                            bg="white"
                            borderLeftRadius={0}
                            borderLeftWidth={0}
                            borderRightRadius={5}
                            disabled={!order.orderLines.length}
                            placeholder="0"
                            pr={4}
                            textAlign="right"
                          />
                        </NumberInput>
                      </Flex>
                    </FormControl>
                  </Flex>
                )}
                <Flex gridGap={4} mt={2}>
                  <Button
                    borderRadius={5}
                    data-testid="add-customer-button"
                    fontSize={18}
                    fontWeight="normal"
                    height="auto"
                    leftIcon={order.customerId ? undefined : <AddIcon mr={2} />}
                    padding=".8rem 1.3rem"
                    variant="secondary"
                    onClick={() => onOpen()}
                  >
                    {order.customerId ? t("changeCustomer") : t("addCustomer")}
                  </Button>
                  <Button
                    borderRadius={5}
                    data-testid="process-payment-button"
                    fontSize={18}
                    fontWeight="normal"
                    height="auto"
                    padding=".8rem 1.3rem"
                    variant="primary"
                    onClick={() => {
                      if (order.orderLines.length > 0) {
                        dispatch({ type: Types.OpenDetails, payload: { openDetails: false } })
                        navigate(route.order.path)
                      }
                    }}
                  >
                    {t("processPayment")}
                  </Button>
                </Flex>
              </Flex>
            </Flex>

            <AddCustomerModal isOpen={isOpen} onClose={onClose} />
          </>
        )}
      </Flex>
    </Flex>
  )
}

const DiscountTypesSelect = ({
  currencySymbol,
  onTypeSelect,
}: {
  currencySymbol?: string | null
  onTypeSelect: (type: DiscountTypes) => void
}) => (
  <Box bg="white" borderRadius={5} borderWidth={1} pos="absolute" py="5px" top="41px" width="100%" zIndex={1}>
    <Flex
      _hover={{ cursor: "pointer", background: "#DBF3FF" }}
      justify="center"
      onClick={() => onTypeSelect(DiscountTypes.Amount)}
    >
      {currencySymbol}
    </Flex>
    <Flex
      _hover={{ cursor: "pointer", background: "#DBF3FF" }}
      justify="center"
      onClick={() => onTypeSelect(DiscountTypes.Percentage)}
    >
      %
    </Flex>
  </Box>
)

export { PriceBreakdownBox }
