import { InventoryTypes } from "common/inventoryTypes"

export const products = [
  {
    id: "55eb3ca3-d41c-423a-bcd8-9c5f724c69fb",
    productId: "",
    name: "Lenovo Z50-70",
    receiptDisplayName: "df",
    inventoryType: InventoryTypes.OnRequest,
    quantity: 0,
    stock: 0,
    retailPrice: 457.64,
    subtotal: 0,
    tax: 15.2,
    productDiscount: "0",
    discount: 30,
    deposit: 45.9,
    total: 0,
    discountType: "Percentage",
    deliveryDate: "15/05/2021",
    notes: "Laptop with Intel Pentium, 15.6'",
    images: [
      {
        imageId: "36edead5-a8d5-451d-80a0-41e858c94017",
        productId: "55eb3ca3-d41c-423a-bcd8-9c5f724c69fb",
        isMainImage: true,
        order: 1,
        url: "",
        thumbnailUrl:
          "https://www.notebookcheck-ru.com/fileadmin/Notebooks/Lenovo/IdeaPad_Z50_70_59427656/Lenovo_Ideapad_Z50_70_Teaser.jpg",
        name: "testImage",
        type: "image/png",
      },
    ],
    attributes: [],
  },
  {
    id: "60aa7768-b6cc-41dd-b90d-0bc766b66f0b",
    productId: "",
    name: "Dell Inspiron 3576-1466",
    receiptDisplayName: "df",
    inventoryType: InventoryTypes.Stocked,
    quantity: 0,
    stock: 0,
    retailPrice: 573.35,
    subtotal: 0,
    tax: 13.5,
    productDiscount: "0",
    discount: 50,
    deposit: 78.9,
    total: 0,
    discountType: "Percentage",
    deliveryDate: "14/05/2021",
    notes: "Laptop with Intel Core 5, 8GB RAM",
    images: [
      {
        imageId: "2a85a08c-02e7-4d34-afbf-e0bca923ab10",
        productId: "60aa7768-b6cc-41dd-b90d-0bc766b66f0b",
        isMainImage: true,
        order: 1,
        url: "",
        thumbnailUrl: "https://shop.by/images/dell_inspiron_15_3576_(3576_1466)_3.jpg",
        name: "testImage",
        type: "image/png",
      },
    ],
    attributes: [],
  },
  {
    id: "536eef14-12fc-4d88-bfd7-185b3f4b3962",
    productId: "",
    name: "Xiaomi MI A3",
    receiptDisplayName: "df",
    inventoryType: InventoryTypes.Artisanal,
    quantity: 0,
    stock: 0,
    retailPrice: 195.8,
    subtotal: 0,
    tax: 9.3,
    productDiscount: "0",
    discount: 15,
    deposit: 30,
    total: 0,
    discountType: "Amount",
    deliveryDate: "14/05/2021",
    notes: "Phone with blue back, 128GB storage, 3GB RAM",
    images: [
      {
        imageId: "348c77d1-b11e-464b-8343-5f6ddf2ec1ce",
        productId: "536eef14-12fc-4d88-bfd7-185b3f4b3962",
        isMainImage: true,
        order: 1,
        url: "",
        thumbnailUrl: "",
        name: "testImage",
        type: "image/png",
      },
    ],
    attributes: [],
  },
  {
    id: "4a1f0dc3-a72b-48b8-bb78-e45b56d5ca42",
    productId: "",
    name: "Xiaomi mi band 4",
    receiptDisplayName: "df",
    inventoryType: InventoryTypes.Artisanal,
    quantity: 0,
    stock: 0,
    retailPrice: 21.57,
    subtotal: 0,
    tax: 1.4,
    productDiscount: "0",
    discount: 10,
    deposit: 5,
    total: 0,
    discountType: "Percentage",
    deliveryDate: "20/05/2021",
    notes: "Fitness bracelet",
    images: [
      {
        imageId: "afae9ca1-e456-4a9b-a08e-5bf295d1f683",
        productId: "4a1f0dc3-a72b-48b8-bb78-e45b56d5ca42",
        isMainImage: true,
        order: 1,
        url: "",
        thumbnailUrl: "https://i01.appmifile.com/webfile/globalimg/Cindy/band4NFC.png",
        name: "testImage",
        type: "image/png",
      },
    ],
    attributes: [],
  },
]
