import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, ReactElement } from "react"

type AddProductAccordionProps = {
  info: ReactElement
  taxInfo: ReactElement
  initialStock: ReactElement
}

const AddProductAccordion: FC<AddProductAccordionProps> = ({ info, taxInfo, initialStock }) => {
  const { t } = useTranslation()

  return (
    <Accordion width="100%" allowToggle>
      <AccordionItem border="1px solid #CBCBCB" borderRadius="10px" data-testid="product-info-tab" mb="32px">
        <AccordionHeading title={t("product.productInfo")} />
        <AccordionPanel pb={4}>
          <Flex direction="column" pl="76px" width="100%">
            {info}
          </Flex>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem border="1px solid #CBCBCB" borderRadius="10px" data-testid="tax-info-tab" mb="32px">
        <AccordionHeading title={t("product.taxInfo")} />
        <AccordionPanel pb={4}>
          <Flex direction="column" pl="76px" width="100%">
            {taxInfo}
          </Flex>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem border="1px solid #CBCBCB" borderRadius="10px" data-testid="variants-tab" mb="32px">
        <AccordionHeading title={t("product.variantsAndStoreFronts")} />
        <AccordionPanel pb={4}>
          <Flex direction="column" pl="76px" width="100%">
            {initialStock}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

const AccordionHeading: FC<{ title: string }> = ({ title }) => (
  <AccordionButton height="80px">
    <AccordionIcon backgroundColor="Info01" borderRadius="100%" boxSize="24px" color="white" ml="12px" mr="44px" />
    <Text color="text.02" fontSize="2xl" fontWeight="semibold">
      {title}
    </Text>
  </AccordionButton>
)

export type { AddProductAccordionProps }

export { AddProductAccordion }
