import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useContext, useEffect } from "react"

import { TenancyCurrency, TenancyLanguage, api } from "api"
import { TENANCY_ID } from "common/constants"
import { setSystemLanguageLocale } from "common/systemLanguageStorage"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"
import { getLocalStorageStoreFrontIdInShop } from "features/inShop/storeFrontLocalSrotage"

export const Lookups: FC = () => {
  const { i18n } = useTranslation()
  const { state, dispatch } = useContext(ProductsContext)
  const { data: tenancyCurrencies } = api.useTenancyCurrencies(TENANCY_ID, { expand: "currency" })
  const { data: tenancyLanguages } = api.useTenancyLanguages(TENANCY_ID, { expand: "language" })
  const { data: countries } = api.useSupportedCountries({ orderby: "name", top: 500 })
  const localStorageStoreFrontInShop = getLocalStorageStoreFrontIdInShop()

  useEffect(() => {
    if (tenancyCurrencies?.value) {
      const tenancyCurrency = tenancyCurrencies?.value?.find((item) => item.isDefault) as TenancyCurrency
      dispatch({ type: Types.SetTenancyCurrencies, payload: { tenancyCurrencies: tenancyCurrencies.value } })
      dispatch({ type: Types.SetTenancyCurrency, payload: { tenancyCurrency } })
      dispatch({
        type: Types.SetOrder,
        payload: { order: { ...state.makeOrder.order, currencyId: tenancyCurrency?.currencyId } },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenancyCurrencies])

  useEffect(() => {
    if (tenancyLanguages?.value) {
      const tenancyLanguage = tenancyLanguages?.value?.find((item) => item.isDefault) as TenancyLanguage
      const systemLanguageLocale = tenancyLanguages.value.find((item) => item.isSystem)?.language.locale
      if (systemLanguageLocale) {
        i18n.changeLanguage(systemLanguageLocale)
        setSystemLanguageLocale(systemLanguageLocale)
      }
      dispatch({ type: Types.SetTenancyLanguages, payload: { tenancyLanguages: tenancyLanguages.value } })
      dispatch({ type: Types.SetTenancyLanguage, payload: { tenancyLanguage } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenancyLanguages])

  useEffect(() => {
    if (localStorageStoreFrontInShop) {
      dispatch({
        type: Types.SetOrder,
        payload: { order: { ...state.makeOrder.order, storeFrontId: localStorageStoreFrontInShop } },
      })
      dispatch({ type: Types.SetStoreFrontId, payload: { storeFrontId: localStorageStoreFrontInShop } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (countries?.value) {
      dispatch({ type: Types.SetCountries, payload: { countries: countries?.value || [] } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries])

  return <div />
}
