import { createRouteCommand } from "../commands/commands.utils"
import { useAdminsApp } from "features/admins"
import { Command } from "features/commands"

const useAdminsAckbarCommands = (): Command[] => {
  const { appName, hasApp } = useAdminsApp()

  // Other admins specific ackbar commands in addition to route commands
  // could be created and returned here.
  const commands: Command[] = [createRouteCommand({ path: "tenancy" }, appName)]

  return hasApp ? commands : []
}

export { useAdminsAckbarCommands }
