import { categories } from "./categories"
import { initSupplier } from "./initSupplier"
import { initUnitOfMeasure } from "./initUnitOfMeasure"
import { suppliers } from "./suppliers"
import { CatalogProduct } from "../generated"

export const catalogProducts: CatalogProduct[] = [
  {
    id: "8feb7fe2-0dd0-4ecd-8f8f-72a5d8ee47e0",
    name: "Balloon Transparent",
    description:
      "Twenty 30-second applications within half an hour is well in excess of almost anyone’s use of a sanitizer.",
    shortName: "",
    image: "",
    supplier: suppliers[0],
    supplierSKU: "875136",
    countryOfOrigin: "0",
    hsCode: "",
    category: categories[3],
    iva: { id: "", name: "" },
    purchaseCostIncludesTax: false,
    rrpIncludesTax: false,

    unitOfMeasure: initUnitOfMeasure,
    availability: { id: "7e682f76-53d8-408c-8586-38900f0c903e", name: "Stocked" },
    initialStock: 0,
    initialCostOfGoods: 0,
    carryingCost: 0,
    rrp: 0.72,
    variants: [
      {
        id: "1c916e03-98cc-4b22-a297-c189a0e453b4",
        variant: "Large/Blue",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "HP-BAL001-L-B",
        supplierBarcode: "",
        image: "",
        stock: "500",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "b3142aa5-639b-4678-baec-56fbe264605a",
        variant: "Large/Red",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "HP-BAL001-L-R",
        supplierBarcode: "",
        image: "",
        stock: "500",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "53a98677-34de-456f-874d-17fbb484495e",
        variant: "Large/Yellow",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "HP-BAL001-L-Y",
        supplierBarcode: "",
        image: "",
        stock: "500",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "f4578ca7-58c0-4e1f-8328-787fab6fd329",
        variant: "Medium/Blue",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "HP-BAL001-M-B",
        supplierBarcode: "",
        image: "",
        stock: "500",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "68e436b3-6a3f-43e2-b3c3-0fec207f39d2",
        variant: "Medium/Red",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "HP-BAL001-M-R",
        supplierBarcode: "",
        image: "",
        stock: "500",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "97a7942f-2945-4b55-93d7-3683ee2ba532",
        variant: "Medium/Yellow",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "HP-BAL001-M-Y",
        supplierBarcode: "",
        image: "",
        stock: "500",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "33907ffc-382f-475e-8a58-a8c9fda9f604",
        variant: "Small/Blue",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "HP-BAL001-M-B",
        supplierBarcode: "",
        image: "",
        stock: "500",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "3be1a0f6-d2fd-401d-a508-fc520fe584a9",
        variant: "Small/Red",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "HP-BAL001-M-R",
        supplierBarcode: "",
        image: "",
        stock: "500",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "dd1cbe49-c91b-41e4-8224-30783cc49043",
        variant: "Small/Yellow",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "HP-BAL001-M-Y",
        supplierBarcode: "",
        image: "",
        stock: "500",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
    ],
    officialLicense: false,
    storeFronts: [],

    supplierBarcode: "",
    initialStockPerVariant: 0,
    unitCostPerVariant: 0,
    bufferStockPerVariant: 0,
    unitRrpPerVariant: 0,

    stockLevel: 83,
    status: "Active",
  },
  {
    id: "d5e82bc7-d94c-4e0a-b1f8-f81792089ba6",
    name: "Balloon Metallic",
    description: "Balloons Metallic, 40 cm, no print",
    shortName: "Bat-Met",
    image: "",
    supplier: initSupplier,
    supplierSKU: "243586",
    countryOfOrigin: "0",
    hsCode: "",
    category: categories[3],
    iva: { id: "", name: "" },
    purchaseCostIncludesTax: false,
    rrpIncludesTax: false,

    unitOfMeasure: initUnitOfMeasure,
    availability: { id: "7e682f76-53d8-408c-8586-38900f0c903e", name: "Stocked" },
    initialStock: 0,
    initialCostOfGoods: 0,
    carryingCost: 0,
    rrp: 0,
    variants: [
      {
        id: "a6ed84d9-4634-4862-ae73-db606e93d79f",
        variant: "Medium/Red",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "6e8e2c91-ddca-4f17-aabb-6a87e3e213af",
        variant: "Medium/Yellow",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "8e309122-6bc1-4806-b28a-9a8569594eeb",
        variant: "Small/Blue",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
    ],
    officialLicense: false,
    storeFronts: [],

    supplierBarcode: "",
    initialStockPerVariant: 0,
    unitCostPerVariant: 0,
    bufferStockPerVariant: 0,
    unitRrpPerVariant: 0,

    stockLevel: 300,
    status: "Active",
  },
  {
    id: "99f6f5de-d350-430a-97f3-1dc9d458838a",
    name: "party Hat",
    description: "",
    shortName: "",
    image: "",
    supplier: initSupplier,
    supplierSKU: "457820",
    countryOfOrigin: "0",
    hsCode: "",
    category: categories[0],
    unitOfMeasure: initUnitOfMeasure,
    availability: { id: "7e682f76-53d8-408c-8586-38900f0c903e", name: "Stocked" },
    initialStock: 0,
    initialCostOfGoods: 0,
    carryingCost: 0,
    rrp: 0,
    variants: [
      {
        id: "ff82c2bd-cfa5-4955-95e4-ac9a44af5ff3",
        variant: "Small/Blue",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "42a4cd19-5bdb-4646-ac7b-0a860014f7a9",
        variant: "Small/Red",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
    ],
    officialLicense: false,
    storeFronts: [],
    stockLevel: 50,
    iva: { id: "", name: "" },
    purchaseCostIncludesTax: false,
    rrpIncludesTax: false,

    supplierBarcode: "",
    initialStockPerVariant: 0,
    unitCostPerVariant: 0,
    bufferStockPerVariant: 0,
    unitRrpPerVariant: 0,

    status: "Active",
  },
  {
    id: "dc476da7-a225-4adb-967e-efad7215aa87",
    name: "Pinata Small",
    description: "",
    shortName: "",
    image: "",
    supplier: initSupplier,
    supplierSKU: "935874",
    countryOfOrigin: "0",
    hsCode: "",
    category: categories[6],
    iva: { id: "", name: "" },
    purchaseCostIncludesTax: false,
    rrpIncludesTax: false,

    unitOfMeasure: initUnitOfMeasure,
    availability: { id: "c08ef387-c06f-4504-accd-aeee4be3e5a7", name: "Artisanal" },
    initialStock: 0,
    initialCostOfGoods: 0,
    carryingCost: 0,
    rrp: 0,
    variants: [
      {
        id: "1560e23a-2510-4b4e-96db-467e0453291c",
        variant: "Large/Blue",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "8b1e0f26-74af-4359-9593-ba26daf69039",
        variant: "Large/Red",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "4548dc15-95c8-4ac2-9b91-a6174c7b296d",
        variant: "Large/Yellow",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "c189f2d4-9392-47ba-a5da-1d5afaf2d11f",
        variant: "Medium/Blue",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
    ],
    officialLicense: false,
    storeFronts: [],

    supplierBarcode: "",
    initialStockPerVariant: 0,
    unitCostPerVariant: 0,
    bufferStockPerVariant: 0,
    unitRrpPerVariant: 0,

    stockLevel: 0,
    status: "Active",
  },
  {
    id: "16c45270-3134-4c98-bbe2-2a26ead15c3c",
    name: "Pinata Big",
    description: "",
    shortName: "",
    image: "",
    supplier: initSupplier,
    supplierSKU: "574126",
    countryOfOrigin: "0",
    hsCode: "",
    category: categories[6],
    iva: { id: "", name: "" },
    purchaseCostIncludesTax: false,
    rrpIncludesTax: false,

    unitOfMeasure: initUnitOfMeasure,
    availability: { id: "c08ef387-c06f-4504-accd-aeee4be3e5a7", name: "Artisanal" },
    initialStock: 0,
    initialCostOfGoods: 0,
    carryingCost: 0,
    rrp: 0,
    variants: [
      {
        id: "19f7b00f-6f17-4286-af99-e1d4a9311f77",
        variant: "Small/Red",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "1ea0c503-079f-44a4-8556-43b0f1591c00",
        variant: "Small/Yellow",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
    ],
    officialLicense: false,
    storeFronts: [],

    supplierBarcode: "",
    initialStockPerVariant: 0,
    unitCostPerVariant: 0,
    bufferStockPerVariant: 0,
    unitRrpPerVariant: 0,

    stockLevel: 0,
    status: "Active",
  },
  {
    id: "25398c55-f396-4f82-9f1d-9045ab40037f",
    name: "Gift box",
    description: "",
    shortName: "",
    image: "",
    supplier: initSupplier,
    supplierSKU: "578963",
    countryOfOrigin: "0",
    hsCode: "",
    category: categories[4],
    iva: { id: "", name: "" },
    purchaseCostIncludesTax: false,
    rrpIncludesTax: false,

    unitOfMeasure: initUnitOfMeasure,
    availability: { id: "7e682f76-53d8-408c-8586-38900f0c903e", name: "Stocked" },
    initialStock: 0,
    initialCostOfGoods: 0,
    carryingCost: 0,
    rrp: 0,
    variants: [
      {
        id: "941441cf-7c7a-4fd5-ae91-73d7107b0883",
        variant: "Medium/Yellow",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
      {
        id: "3f001071-0c70-4cf1-8773-299e400fdd7e",
        variant: "Small/Blue",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
    ],
    officialLicense: false,
    storeFronts: [],

    supplierBarcode: "",
    initialStockPerVariant: 0,
    unitCostPerVariant: 0,
    bufferStockPerVariant: 0,
    unitRrpPerVariant: 0,

    stockLevel: 3,
    status: "Active",
  },
  {
    id: "dbaf38a2-7fc5-40cc-9b89-96cdc99b3343",
    name: "Standard BD candelas",
    description: "",
    shortName: "",
    image: "",
    supplier: initSupplier,
    supplierSKU: "345871",
    countryOfOrigin: "0",
    hsCode: "",
    category: categories[5],
    iva: { id: "", name: "" },
    purchaseCostIncludesTax: false,
    rrpIncludesTax: false,

    unitOfMeasure: initUnitOfMeasure,
    availability: { id: "7e682f76-53d8-408c-8586-38900f0c903e", name: "Stocked" },
    initialStock: 0,
    initialCostOfGoods: 0,
    carryingCost: 0,
    rrp: 0,
    variants: [
      {
        id: "e0926f4f-e38f-4806-8779-600901c6babf",
        variant: "Medium/Yellow",
        receiptDisplayName: "",
        sku: "",
        supplierSku: "",
        supplierBarcode: "",
        image: "",
        stock: "",
        availableStockPerStoreFront: [],
        retailPrice: [],
        notes: "",
        status: "Active",
      },
    ],
    officialLicense: false,
    storeFronts: [],

    supplierBarcode: "",
    initialStockPerVariant: 0,
    unitCostPerVariant: 0,
    bufferStockPerVariant: 0,
    unitRrpPerVariant: 0,

    stockLevel: 0,
    status: "Active",
  },
]
