import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { TenancyLanguage, TenancyLanguagesService } from "../generated"
import { ODataOptions } from "../handmade"

export const useTenancyLanguages = (parentId: UUID, options?: ODataOptions) =>
  useQuery(["tenancyLanguages", options], () =>
    TenancyLanguagesService.tenancyLanguagesGetAllAsync({ parentId, ...options })
  )

export const useUpdateTenancyLanguageMutation = (
  { onSuccess }: { onSuccess: (language: TenancyLanguage) => void },
  parentId: UUID,
  id: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (tenancyLanguage: Partial<TenancyLanguage>) =>
      TenancyLanguagesService.tenancyLanguagesPatchAsync({ parentId, id, requestBody: tenancyLanguage as any }),
    {
      onSuccess: (language) => {
        queryClient.invalidateQueries(["tenancyLanguages"])
        onSuccess(language)
      },
      onError: () => {},
    }
  )
}
