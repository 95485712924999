import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Switch, Text } from "@chakra-ui/react"
import { CheckmarkCircleSolidIcon, EditIcon, MoreHorizontalSolidIcon, RemoveIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, ReactNode, SetStateAction, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useTable } from "react-table"

import { TenancyCurrency, api } from "api"
import { Pagination, Table } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"
import { Positions } from "common/utils/currency.utils"
import { route } from "features/app/routes"

type CurrenciesTableProps = {
  symbol: string
  loading: boolean
  onOpen: () => void
  tenancyCurrencies: TenancyCurrency[]
  setTenancyCurrency: Dispatch<SetStateAction<TenancyCurrency>>
}

const CURRENCIES_TABLE = "CURRENCIES_TABLE"

const CurrenciesTable: FC<CurrenciesTableProps> = ({
  tenancyCurrencies,
  onOpen,
  symbol,
  setTenancyCurrency,
  loading,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [storedPageSize, setStoredPageSize] = useStoredPageSize(CURRENCIES_TABLE)

  const [id, setId] = useState("")
  const updateTenancyCurrency = api.useUpdateTenancyCurrencyMutation(
    { onSuccess: () => navigate(route.currencies.path) },
    TENANCY_ID,
    id
  )

  const setDefaultCurrency = async (currency: TenancyCurrency) => {
    await setId(currency.currencyId)
    await update()
  }

  const update = () => {
    const object = {
      isDefault: true,
    }
    updateTenancyCurrency.mutate(object)
  }

  const NameCell: FC<{ currency: TenancyCurrency }> = ({ currency }) => (
    <Flex align="center">
      <Text mr={6} width="70%">
        {currency.currency.localName}
      </Text>
      <Menu placement="left">
        <MenuButton
          aria-label="Options"
          as={IconButton}
          background="ui.01"
          borderColor="ui.02"
          borderWidth={1}
          icon={<MoreHorizontalSolidIcon color="black" />}
          onClick={() => setId(currency.currencyId)}
        />
        <MenuList>
          {currency.isDefault ? (
            <>
              <MenuItem icon={<CheckmarkCircleSolidIcon color="success.01" />}>
                <Text fontSize={16} ml={6}>
                  {t("currencies.default")}
                </Text>
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem icon={<EditIcon fontSize={20} />} onClick={() => navigate(currency.currencyId)}>
                <Text fontSize={16} ml={6}>
                  {t("edit")}
                </Text>
              </MenuItem>
              <MenuItem
                icon={<CheckmarkCircleSolidIcon color="success.01" />}
                onClick={() => setDefaultCurrency(currency)}
              >
                <Text fontSize={16} ml={6}>
                  {t("makeDefault")}
                </Text>
              </MenuItem>
              <MenuItem
                color="danger.01"
                icon={<RemoveIcon />}
                onClick={() => {
                  setTenancyCurrency(currency)
                  onOpen()
                }}
              >
                <Text fontSize={16} ml={6}>
                  {t("delete")}
                </Text>
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>
    </Flex>
  )

  const symbolPosition = (curr: TenancyCurrency): string =>
    curr.currency?.position === Positions.beginning
      ? `${symbol} ${detectDelimiter(curr)}`
      : `${detectDelimiter(curr)} ${symbol}`

  const detectDelimiter = (curr: TenancyCurrency): string =>
    String(curr.rate.toFixed(2))
      .split(".")
      .join(curr?.currency.delimiter || ".")

  const columnData: Column<TenancyCurrency>[] = [
    {
      Header: t("currencies.currency") as string,
      // @ts-ignore
      Cell: ({ row }) => <NameCell currency={row.original} />,
    },
    {
      Header: t("currencies.symbol") as string,
      // @ts-ignore
      Cell: ({ row }) => row.original.currency.symbol,
    },
    {
      Header: t("currencies.rate") as string,
      accessor: "rate",
      // @ts-ignore
      Cell: ({ row }) => symbolPosition(row.original),
    },
    {
      Header: t("currencies.defaultCurrency") as string,
      // @ts-ignore
      Cell: ({ row }) => <Switch isChecked={row.original.isDefault} readOnly />,
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setPageSize,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, [symbol]),
      data: useMemo(() => tenancyCurrencies, [tenancyCurrencies]),
      initialState: {
        pageSize: storedPageSize ?? DEFAULT_ITEMS_PER_PAGE,
      },
    },
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<TenancyCurrency>): ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} data-testid="currenciesTable" width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR bg="white" color="#36495E" fontSize="lg" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Table.TH {...column.getHeaderProps()} textTransform="capitalize">
                {column.render("Header")}
              </Table.TH>
            ))}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()} textAlign="left">
        {loading ? (
          <Table.Skeleton columnWidths={[32, 16, 32, 64]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={(size) => {
                setPageSize(size)
                setStoredPageSize(size)
              }}
              totalPages={pageOptions.length}
              onPageChange={gotoPage}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { CurrenciesTable }
