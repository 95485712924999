import { Button, Flex, Heading, Text } from "@chakra-ui/react"
import { AddIcon, ChevronLeftIcon, EditIcon } from "@iac/components.icons.react"
import { FC } from "react"
import { useLocation, useNavigate } from "react-router-dom"

type PageHeaderProps = {
  buttonTitle?: string
  onClick?: () => void
  title: string
  variant?: string
  width?: string
  marginBottom?: string
  isArrow?: boolean
  link?: string
  isGoBack?: boolean
  isAddButtonVisible?: boolean
  isAddIconVisible?: boolean
  isEditIconVisible?: boolean
  subtitle?: string
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  buttonTitle,
  onClick,
  variant = "primary",
  width = "auto",
  marginBottom = "0",
  isArrow = false,
  link = "",
  isGoBack = false,
  isAddButtonVisible = true,
  isAddIconVisible = true,
  isEditIconVisible = false,
  subtitle,
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Flex
      align="center"
      data-testid="page-header"
      justify="space-between"
      mb={marginBottom}
      pb={8}
      pl={20}
      pt={16}
      width="90%"
    >
      <Heading
        _hover={{ textDecoration: isArrow ? "underline" : "none", cursor: isArrow ? "pointer" : "auto" }}
        alignItems="center"
        data-testid="header-title"
        display="flex"
        fontWeight="bold"
        size="md"
        onClick={() => (isGoBack ? navigate(-1) : navigate(link || location.pathname))}
      >
        {isArrow && <ChevronLeftIcon color="ui.03" mr={3} width="20px" />}
        {title}
      </Heading>
      {subtitle && (
        <Text color="text.02" flex={1} fontSize={20} ml="3rem">
          {subtitle}
        </Text>
      )}
      {onClick && buttonTitle && isAddButtonVisible && (
        <Button data-testid="header-button" variant={variant} w={width} onClick={onClick}>
          {isAddIconVisible && <AddIcon mr={2} />}
          {isEditIconVisible && <EditIcon mr={2} />}
          {buttonTitle}
        </Button>
      )}
    </Flex>
  )
}

export { PageHeader }
