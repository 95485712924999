import { FC } from "react"

import { PhotoLibraryPanel } from "./PhotoLibraryPanel"
import { ImageType, LibraryImage, api } from "api"
import { TENANCY_ID } from "common/constants"

type ImagesUploadedByImageTypeProps = {
  imageType: ImageType
  selectedImages: Set<LibraryImage>
  onImageClick: (image: LibraryImage) => void
}

const ImagesUploadedByImageType: FC<ImagesUploadedByImageTypeProps> = ({ selectedImages, onImageClick, imageType }) => {
  const { data: imagesData } = api.useImages(TENANCY_ID, imageType)

  return (
    <>
      {imagesData?.value && (
        <PhotoLibraryPanel
          libraryImages={imagesData?.value}
          selectedImages={selectedImages}
          onImageClick={onImageClick}
        />
      )}
    </>
  )
}

export type { ImagesUploadedByImageTypeProps }

export { ImagesUploadedByImageType }
