import { Box, Flex, Image, Text, VStack } from "@chakra-ui/react"
import { FC, useContext, useEffect, useState } from "react"

import { ProductImage, mockData } from "api"
import { SelectDiscount } from "common/components/SelectDiscount/SelectDiscount"
import { getAvailability } from "common/utils/availability.utils"
import { confirmOrder, generateOrder } from "features/inShop/inShopUtils"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"
import "./ExchangeProductDetails.css"

const ExchangeProductDetails: FC = () => {
  const { state, dispatch } = useContext(ProductsContext)
  const { makeOrder } = state
  const [mainImage, setMainImage] = useState("")
  const [availability, setAvailability] = useState("")
  const [discount, setDiscount] = useState(0)
  const [typeDiscount, setTypeDiscount] = useState(makeOrder.orderProduct.discountType)

  useEffect(() => {
    setDiscount(makeOrder.orderProduct.discount)
    setTypeDiscount(makeOrder.orderProduct.discountType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeOrder.orderProduct.id])

  useEffect(() => {
    const image = makeOrder.orderProduct.images.find((image: ProductImage) => image.isMainImage)
    image && setMainImage(image.thumbnailUrl)
    setAvailability(getAvailability(makeOrder.orderProduct.inventoryType))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeOrder.orderProduct.id])

  return (
    <VStack
      align="center"
      background="#36495E"
      color="white"
      fontWeight="bold"
      height="100%"
      padding="20px"
      width="400px"
    >
      <Flex align="center" direction="column" flex={1} height="100%" width="100%">
        <Text fontSize={22} mb={4}>
          {makeOrder.orderProduct.name}
        </Text>
        <Box height="365px" width="90%">
          <Image
            borderColor="white"
            borderRadius={mainImage ? "20px" : "0"}
            borderWidth={1}
            height={mainImage ? "auto" : "100%"}
            src={mainImage}
            width="100%"
          />
        </Box>
        <Flex align="center" direction="column" fontSize={30} marginTop="20px">
          <Text>{availability}</Text>
          <Text>€ {makeOrder.orderProduct.retailPrice}</Text>
        </Flex>
        <Flex align="flex-end" flex={1} fontSize={30} marginBottom="2rem" width="100%">
          <Flex align="center" justify="space-between" width="100%">
            <Text>Discount</Text>
            <SelectDiscount
              typeDiscount={typeDiscount}
              value={discount}
              onChangeInput={(event) => {
                setDiscount(+event.currentTarget.value)
              }}
              onChangeSelect={(event) => setTypeDiscount(event.currentTarget.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  const orderProduct = { ...makeOrder.orderProduct, discount, typeDiscount }
                  const exchangeProduct = state.exchange.exchangeOrder.orderLines.find(
                    (item) => item.id === orderProduct.id
                  )
                  if (!exchangeProduct) {
                    const order = generateOrder([orderProduct], mockData.initOrder, state)
                    dispatch({
                      type: Types.SetExchangeOrder,
                      payload: { exchangeOrder: order },
                    })
                  }
                  dispatch({
                    type: Types.SetOrderProduct,
                    payload: { orderProduct },
                  })
                  confirmOrder(orderProduct, state, dispatch)
                }
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </VStack>
  )
}

export { ExchangeProductDetails }
