import { Button, Grid, GridItem, Text } from "@chakra-ui/react"
import { FC, useContext } from "react"
import { useNavigate } from "react-router-dom"

import { Order, api, mockData } from "api"
import { InShopInfo } from "common/components/InShopInfo/InShopInfo"
import { TENANCY_ID } from "common/constants"
import { symbolPosition } from "common/utils/currency.utils"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

export const RefundInfo: FC = () => {
  const navigate = useNavigate()
  const { state, dispatch } = useContext(ProductsContext)
  const { order } = state.makeOrder
  const { data } = api.useSalesOrders(TENANCY_ID)

  const buttonStyles = {
    borderRadius: 10,
    color: "white",
    fontSize: 24,
  }

  const cancelRefund = (): void => {
    const isCancel: boolean = window.confirm("Are you sure you want to cancel refund?")
    if (isCancel) {
      const findOrder = data?.value?.find((item: Order) => item.orderId === order.orderId)
      if (findOrder?.orderId) {
        dispatch({ type: Types.SetOrder, payload: { order: findOrder } })
        dispatch({ type: Types.SetRefundTotal, payload: { total: 0 } })
        dispatch({ type: Types.SetOpenRefund, payload: { openRefundProduct: false } })
        dispatch({ type: Types.SetRefundOrder, payload: { refundOrder: mockData.initOrder } })
        dispatch({ type: Types.IsOrderChanged, payload: { isOrderChanged: false } })
      }
    }
  }

  return (
    <Grid
      data-testid="orderInfo"
      style={{
        background: "#007abd",
        minHeight: "200px",
        width: "100%",
        color: "white",
        fontWeight: "bold",
        padding: "10px 0",
      }}
      templateColumns="2fr 3fr"
    >
      <InShopInfo order={order} />
      <GridItem
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 0",
        }}
      >
        <Button
          background="#149a45"
          display="flex"
          flexDirection="column"
          height="5rem"
          px="4rem"
          style={buttonStyles}
          onClick={() => navigate("/refundPayment")}
        >
          <Text>Process Refund</Text>
          <Text mt={2}>{symbolPosition(state.refund.total)}</Text>
        </Button>
        {state.bool.isOrderChanged && (
          <Button background="#a02013" height="4rem" px="2rem" size="lg" style={buttonStyles} onClick={cancelRefund}>
            Cancel Change
          </Button>
        )}
      </GridItem>
    </Grid>
  )
}
