import { Box, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react"
import { CheckmarkCircleIcon, CheckmarkIcon, MoreHorizontalSolidIcon, RemoveIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

import { api } from "api"
import { TENANCY_ID } from "common/constants"

type ImageDisplayProps = {
  imageId: string
  isMainImage: boolean
  onDelete: () => void
  onMainImageChange: () => void
  isShowMakeMainImageButton: boolean
}

export const ImageDisplay: FC<ImageDisplayProps> = ({
  imageId,
  isMainImage,
  onDelete,
  onMainImageChange,
  isShowMakeMainImageButton,
}) => {
  const { t } = useTranslation()
  const { data: imageData } = api.useImage(TENANCY_ID, imageId)
  const mainImageStyles = {
    border: "2px solid #4DA3D7",
    boxShadow: "0px 0px 5px #449FDB",
  }

  return (
    <>
      {imageData && (
        <Box position="relative">
          <Image
            alt="Image Preview"
            {...(isMainImage && mainImageStyles)}
            boxSize="140px"
            src={imageData.thumbnailUrl as string}
          />
          <Box position="absolute" right="4px" top="4px">
            {isMainImage && isShowMakeMainImageButton ? (
              <CheckmarkCircleIcon color="info.01" height="28px" width="28px" />
            ) : (
              <Menu placement="left">
                <MenuButton
                  _active={{ background: "inherit" }}
                  _hover={{ background: "inherit" }}
                  as={IconButton}
                  backgroundColor="transparent"
                  height="unset"
                  icon={<MoreHorizontalSolidIcon color="#B2B2B2" height="28px" transform="rotate(90deg)" />}
                />
                <MenuList minWidth="auto">
                  {isShowMakeMainImageButton && (
                    <MenuItem icon={<CheckmarkIcon fontSize={20} />} p={3} py={2} onClick={() => onMainImageChange()}>
                      <Text fontSize="xs">{t("imagesControl.makeTheMainImage")}</Text>
                    </MenuItem>
                  )}
                  <MenuItem color="danger-01" icon={<RemoveIcon fontSize={20} />} p={3} onClick={() => onDelete()}>
                    <Text fontSize="xs">{t("delete")}</Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}
