import { Box, Flex, Image } from "@chakra-ui/react"
import { CheckmarkCircleIcon } from "@iac/components.icons.react"
import { FC } from "react"

import { LibraryImage } from "api"

type IPhotoLibraryPanelProps = {
  selectedImages: Set<LibraryImage>
  onImageClick: (image: LibraryImage) => void
  libraryImages: LibraryImage[]
}

const PhotoLibraryPanel: FC<IPhotoLibraryPanelProps> = ({ selectedImages, onImageClick, libraryImages }) => (
  <Flex direction="row" gridGap={7} justifyContent="flex-start" mt={3} wrap="wrap">
    {libraryImages &&
      libraryImages.map((image) => (
        <Box key={image.id} pos="relative" onClick={() => onImageClick(image)}>
          <ImageWithSelection image={image} isSelected={selectedImages.has(image)} />
        </Box>
      ))}
  </Flex>
)

const ImageWithSelection = ({ image, isSelected }: { image: LibraryImage; isSelected: boolean }) => {
  const selectedStyles = {
    outline: "3px solid",
    outlineColor: "interactive.01",
  }

  return (
    <>
      <Image
        alt="Image Preview"
        boxSizing="border-box"
        cursor="pointer"
        {...(isSelected && selectedStyles)}
        boxSize="120px"
        data-testid="ImageUpload__preview"
        mt={2}
        src={image.thumbnailUrl as string}
      />
      {isSelected && <CheckmarkCircleIcon height="28px" pos="absolute" right="-14px" top="-4px" width="28px" />}
    </>
  )
}

export type { IPhotoLibraryPanelProps }

export { PhotoLibraryPanel }
