import { Box, Button, Flex, Heading, Spinner, Stack, Stat, StatLabel, StatNumber, Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { QueryObserverResult } from "@tanstack/react-query"
import { milliseconds as convertToMilliseconds } from "date-fns"
import { FC } from "react"

import { useHealthcheck } from "api/hooks"
import { StatusIcon } from "common/components"
import { REACT_APP_REGION as irisRegion } from "environment"

type InfraStatus = {
  duration: string
  name: string
  status: string
}

type HealthResponse = {
  region: string
  items: InfraStatus[]
}

export const Healthcheck: FC = () => {
  const query = useHealthcheck() as StatusProps

  return <Status {...query} />
}

export type StatusProps = Pick<QueryObserverResult<HealthResponse, any>, "data" | "error" | "isLoading" | "refetch">

export const Status: FC<StatusProps> = ({ data, error, isLoading, refetch }) => {
  const { t } = useTranslation()
  const hasError = Boolean(error) || data?.items.filter((i) => i.status !== "Healthy").length

  return (
    <Flex align="center" bg="elevation.01" h="100vh" justify="center" w="100vw">
      <Stack align="center" flex={1} pos="relative" px={16} spacing={32}>
        <Stack
          align="center"
          display={["none", "none", "none", "flex"]}
          flex={1}
          height="100%"
          justify="center"
          spacing={12}
        >
          <Heading size="2xl">IAM {hasError ? "Unhealthy" : "Healthy"}</Heading>
          <Box>
            {isLoading ? (
              <Spinner h={32} w={32} />
            ) : (
              <StatusIcon size="8rem" status={hasError ? "Error" : "Accepted"} />
            )}
          </Box>
          <Button size="lg" variant="outline" onClick={() => refetch()}>
            {t("Check again")}
          </Button>
        </Stack>
        <Stack
          align="center"
          direction={["column", "column", "column", "row"]}
          flex={1}
          maxW="1280px"
          spacing={16}
          w="100%"
        >
          <Tile error={error} name="Iris" region={irisRegion} />
          <Tile error={error} name="Bifrost" region={data?.region} />
          {(data?.items ?? []).map((item) => (
            <InfraTile {...item} key={item.name} />
          ))}
        </Stack>
      </Stack>
    </Flex>
  )
}

type TileProps = {
  name: string
  region?: string
  error?: any
}

const Tile: FC<TileProps> = ({ name, error, region }) => {
  const { t } = useTranslation()

  return (
    <Stack flex={1} spacing={4}>
      <Heading>{name}</Heading>
      <Stack align="center" direction="row" spacing={4}>
        <Box>
          <StatusIcon size="3rem" status={error ? "Error" : "Accepted"} />
        </Box>
        <Stat>
          <StatLabel>{t("Region")}</StatLabel>
          <StatNumber>{region}</StatNumber>
        </Stat>
      </Stack>
    </Stack>
  )
}

const calculateResponseTime = (duration: string): number | null => {
  const [hourString, minuteString, secondsAndMillisecondsString] = duration.split(":")
  const [secondsString, millisecondsString] = secondsAndMillisecondsString.split(".")

  const hours = Number(hourString)
  const minutes = Number(minuteString)
  const seconds = Number(secondsString)
  const milliseconds = Number(millisecondsString)

  ;[hours, minutes, seconds, milliseconds].forEach((value) => {
    if (isNaN(value)) return null
  })

  return convertToMilliseconds({ hours, minutes, seconds }) + milliseconds
}

const InfraTile: FC<InfraStatus> = ({ name, status, duration }) => {
  const { t } = useTranslation()

  const responseTime = calculateResponseTime(duration)

  return (
    <Stack flex={1} spacing={4}>
      <Heading>{name}</Heading>
      <Stack align="center" direction="row" spacing={4}>
        <Box>
          <StatusIcon size="3rem" status={status === "Healthy" ? "Accepted" : "Error"} />
        </Box>
        <Stat>
          {responseTime ? (
            <>
              <StatLabel>{t("Duration")}</StatLabel>
              <StatNumber>{responseTime} ms</StatNumber>
            </>
          ) : (
            <Text>{t("Error loading data")}</Text>
          )}
        </Stat>
      </Stack>
    </Stack>
  )
}
