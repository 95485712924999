import { useQuery } from "@tanstack/react-query"

import { ImagesService } from "../generated"
import { ImageType } from "api"

export const useImages = (tenancyId: UUID, imageType: ImageType) =>
  useQuery(["images"], () => ImagesService.imagesGetAllAsync({ tenancyId, imageType }))

export const useImagesByIds = (tenancyId: UUID, ids: string[]) =>
  useQuery(["imagesByIds"], () => ImagesService.imagesGetByIdsAsync({ tenancyId, ids }), { enabled: !!ids })

export const useImage = (tenancyId: UUID, id: UUID) =>
  useQuery([id], () => ImagesService.imagesGetAsync({ tenancyId, id }))
