import { Box, Flex, Image, Text } from "@chakra-ui/react"

import { ProductModel } from "api"
import { DefaultImageIcon } from "common/theme"

type ProductDisplayProps = {
  product: ProductModel
  onSelectProduct: (product: ProductModel) => void
  isSmallSize?: boolean
}

export const ProductDisplay = ({ product, onSelectProduct, isSmallSize }: ProductDisplayProps) => (
  <Flex
    cursor="pointer"
    direction="column"
    justify="flex-end"
    w={isSmallSize ? "120px" : 40}
    onClick={() => onSelectProduct(product)}
  >
    {product.images[0]?.thumbnailUrl ? (
      <Image
        borderRadius="5px"
        boxSize={isSmallSize ? "120px" : 40}
        objectFit="cover"
        src={product.images[0]?.thumbnailUrl}
      />
    ) : (
      <DefaultImageIcon boxSize={isSmallSize ? "120px" : 40} color="text.03" />
    )}
    <Box h={14}>
      <Text fontSize="md" fontWeight="bold" p={2}>
        {product.receiptDisplayName}
      </Text>
    </Box>
  </Flex>
)
