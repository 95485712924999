import * as admin from "./admins"
import * as auth from "./auth"
import * as catalogProduct from "./catalogProducts"
import * as category from "./categories"
import * as country from "./countries"
import * as currency from "./currencies"
import * as customer from "./customers"
import * as entity from "./entities"
import { initCatalogProduct } from "./initCatalogProduct"
import { initCategory } from "./initCategory"
import { initCountry } from "./initCountry"
import { initCurrency } from "./initCurrency"
import { initCustomer } from "./initCustomer"
import { initLanguage } from "./initLanguage"
import { initOrder } from "./initOrder"
import { initProduct } from "./initProduct"
import { initProductAPI } from "./initProductAPI"
import { initProductVariant } from "./initProductVariant"
import { initStoreFont } from "./initStoreFont"
import { initSupplier } from "./initSupplier"
import { initTenancyCurrency } from "./initTenancyCurrency"
import { initTenancyLanguage } from "./initTenancyLanguage"
import { initUnitOfMeasure } from "./initUnitOfMeasure"
import { initVariant } from "./initVariant"
import { initVariantOption } from "./initVariantOption"
import { initVariantOptionValue } from "./initVariantOptionValue"
import { initVariantType } from "./initVariantType"
import * as note from "./notes"
import * as order from "./orders"
import * as orderDashboard from "./ordersDashboard"
import * as product from "./products"
import * as shippingMethod from "./shipmentMethods"
import * as shippingTerm from "./shippingTerms"
import * as status from "./statuses"
import * as storeFont from "./storeFronts"
import * as supplier from "./suppliers"
import * as taxCategory from "./taxCategories"
import * as tenancy from "./tenancies"
import * as unitOfMeasure from "./unitsOfMeasure"
import * as variantType from "./variantTypes"

export const mockData = {
  auth,
  admin,
  category,
  country,
  currency,
  customer,
  catalogProduct,
  entity,
  note,
  order,
  orderDashboard,
  product,
  shippingMethod,
  shippingTerm,
  status,
  storeFont,
  supplier,
  unitOfMeasure,
  variantType,
  initCategory,
  initCountry,
  initCurrency,
  initCustomer,
  initLanguage,
  initCatalogProduct,
  initOrder,
  initProduct,
  initProductAPI,
  initProductVariant,
  initStoreFont,
  initSupplier,
  initTenancyCurrency,
  initTenancyLanguage,
  initUnitOfMeasure,
  initVariant,
  initVariantOption,
  initVariantOptionValue,
  initVariantType,
  taxCategory,
  tenancy,
}
