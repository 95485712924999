import { Flex, Text } from "@chakra-ui/react"
import { FC } from "react"
import { Link } from "react-router-dom"

type ISACardProps = {
  title: string
  link: string
}

export const ISACard: FC<ISACardProps> = ({ title, link, children }) => (
  <Flex
    _hover={{
      cursor: "pointer",
      filter: `drop-shadow(2px 2px 10px info.01)`,
    }}
    align="center"
    background="ui.01"
    borderColor="ui.02"
    borderRadius={10}
    borderWidth={1}
    boxShadow="link-box"
    color="info.01"
    data-testid="card-container"
    flexDirection="column"
    justify="center"
    maxW={72}
    minH={32}
    minW={40}
    px={5}
    py={2.5}
  >
    <Link
      data-testid="card-link"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        fontWeight: "bold",
      }}
      to={link}
    >
      <Text color="info.01" data-testid="card-title" fontSize="xl" mb={2.5} textAlign="center">
        {title}
      </Text>
      {children}
    </Link>
  </Flex>
)
