import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { CrossIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Card } from "./Card"
import { Cash } from "./Cash"
import { PaymentButtons } from "./PaymentButtons"
import { SalesOrderStatus, api } from "api"
import { appInsights } from "appInsights"
import { ConfirmModal } from "common/components/ConfirmModal/ConfirmModal"
import { TENANCY_ID } from "common/constants"
import { DiscountTypes } from "common/discountTypes"
import { AddCustomerModal } from "features/customers/AddCustomer/AddCustomerModal"
import { resetState } from "features/inShop/inShopUtils"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

type PaymentTypeProps = {
  isOrderCreatingErrorState: [boolean, Dispatch<SetStateAction<boolean>>]
  showButtonsState: [boolean, Dispatch<SetStateAction<boolean>>]
  receiptButtonsState: [boolean, Dispatch<SetStateAction<boolean>>]
}

export const PaymentType: FC<PaymentTypeProps> = ({
  showButtonsState,
  receiptButtonsState,
  isOrderCreatingErrorState,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [showButtons, setShowButtons] = showButtonsState
  const [receiptButtons, setReceiptButtons] = receiptButtonsState
  const [isOrderCreatingError, setIsOrderCreatingError] = isOrderCreatingErrorState
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isCustomerModalOpen, onOpen: onCustomerModalOpen, onClose: onCustomerModalClose } = useDisclosure()
  const [isCard, setIsCard] = useState(false)
  const [isCash, setIsCash] = useState(false)
  const { state, dispatch } = useContext(ProductsContext)
  const {
    globalEntities: { tenancyCurrency, storeFrontId },
    makeOrder: { order },
  } = state
  const { data: customersData } = api.useCustomersAll(TENANCY_ID)

  const createSalesOrder = api.useCreateSalesOrderMutation(
    {
      onSuccess: (data) => {
        dispatch({ type: Types.SetOrder, payload: { order: { ...order, id: data.id || "" } } })
        setReceiptButtons(true)
        appInsights.stopTrackEvent("Create Order")
      },
    },
    {
      onError: () => {
        setIsOrderCreatingError(true)
        setReceiptButtons(false)
        setShowButtons(true)
      },
    },
    TENANCY_ID
  )

  const buttonStyles = {
    fontSize: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15rem",
    borderRadius: 10,
    height: "12rem",
  }

  const returnToPaymentType = () => {
    setIsCard(false)
    setIsCash(false)
    setShowButtons(true)
    dispatch({ type: Types.IsPaymentCash, payload: { isPaymentCash: false } })
  }

  const resetOrder = () => {
    dispatch({ type: Types.IsPayment, payload: { isPayment: false } })
    dispatch({ type: Types.HideFields, payload: { hideFields: false } })
    resetState(dispatch)
    navigate("/inShop")
  }

  useEffect(() => {
    if (isOrderCreatingError) {
      setTimeout(() => setIsOrderCreatingError(false), 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrderCreatingError])

  const completeOrder = (): void => {
    appInsights.startTrackEvent("Create Order")
    const date = new Date()
    const { order } = state.makeOrder
    const orderLines = order.orderLines.map((item) => ({
      productVariantId: item.id,
      subtotal: item.subtotal,
      tax: item.tax,
      quantity: item.quantity,
      discount: item.discount,
      deposit: item.deposit,
      discountType: DiscountTypes.Amount,
      total: item.total,
      deliveryDate: item.deliveryDate || date.toISOString(),
      notes: item.notes,
    }))
    const createOrder = {
      ...order,
      customerId: order.customerId || undefined,
      orderDiscount: undefined,
      sellerName: undefined,
      tenancyId: undefined,
      currencyId: tenancyCurrency.currencyId,
      discountType: DiscountTypes.Amount,
      orderId: undefined,
      orderDate: "2022-01-05T00:00:00Z",
      orderLines,
      storeFrontId,
      status: SalesOrderStatus.PAID,
    }
    createSalesOrder.mutate(createOrder)
  }

  useEffect(() => {
    if (receiptButtons) {
      setShowButtons(false)
      setIsCash(false)
      setIsCard(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptButtons])

  const customerName = customersData?.value?.find((customer) => customer.id === order.customerId)?.name

  return (
    <Flex
      align="center"
      bg="customer.root.background"
      borderRadius="lg"
      direction="column"
      justify="flex-start"
      mb={4}
      mr={6}
      mt={6}
      px={10}
      py={6}
      width="650px"
    >
      {isOrderCreatingError && (
        <Box bg="info.01" borderRadius={5} padding="1rem 2rem 1rem 1rem" pos="absolute" right="50%" top={3}>
          <CrossIcon
            _hover={{ cursor: "pointer" }}
            fontSize={12}
            position="absolute"
            right={2}
            top={2}
            onClick={() => setIsOrderCreatingError(false)}
          />
          <Text fontSize={15}>Couldn't save order, please try again</Text>
        </Box>
      )}
      <Flex align="center" justify="space-between" marginTop={0} width="100%">
        <Flex align="baseline" h={9}>
          {customerName && (
            <>
              <Text color="text.01" fontSize={24} mr={6}>
                {t("customer")}:
              </Text>
              <Text color="text.02" fontSize={20}>
                {customerName ?? t("newCustomer")}
              </Text>
            </>
          )}
        </Flex>
        <AddCustomerModal isOpen={isCustomerModalOpen} onClose={onCustomerModalClose} />
      </Flex>
      <Flex color="text.01" direction="column" fontSize={24} justify="center" pt={16} width="100%">
        <Flex
          align="center"
          borderBottomColor="ui.05"
          borderBottomWidth={1}
          justify="space-between"
          mb={4}
          pb={2}
          width="100%"
        >
          <Text fontWeight={600}>{t("total")}</Text>
          <Text fontWeight={300}>
            {order.total.toFixed(2)} {tenancyCurrency.currency?.symbol}
          </Text>
        </Flex>
        <Flex
          align="center"
          borderBottomColor="ui.05"
          borderBottomWidth={1}
          justify="space-between"
          mb={4}
          pb={2}
          width="100%"
        >
          <Text fontWeight={600}>{t("payment")}</Text>
          <Text fontWeight={300}>
            {order.paymentType === "Cash"
              ? `${order.paidAmount.toFixed(2)} ${tenancyCurrency.currency?.symbol}`
              : order.paymentType === "Card"
              ? `${order.paidAmount.toFixed(2)} ${tenancyCurrency.currency?.symbol}`
              : `${order.deposit.toFixed(2)} ${tenancyCurrency.currency?.symbol}`}
          </Text>
        </Flex>
        {((receiptButtons && order.paymentType === "Cash") || isCash) && (
          <Flex
            align="center"
            borderBottomColor="ui.05"
            borderBottomWidth={1}
            justify="space-between"
            mb={4}
            pb={2}
            width="100%"
          >
            <Text fontWeight={600}>Change</Text>
            <Text fontWeight={300}>
              {order.change.toFixed(2)} {tenancyCurrency.currency?.symbol}
            </Text>
          </Flex>
        )}
        {(showButtons || (receiptButtons && order.paymentType === "Card")) && (
          <Flex
            align="center"
            borderBottomColor="ui.05"
            borderBottomWidth={1}
            justify="space-between"
            pb={2}
            width="100%"
          >
            <Text fontWeight={600}>{t("remaining")}</Text>
            <Text fontWeight={300}>
              {order.paymentType === "Card"
                ? `${order.change.toFixed(2)} ${tenancyCurrency.currency?.symbol}`
                : `${(order.total - order.deposit).toFixed(2)} ${tenancyCurrency.currency?.symbol}`}
            </Text>
          </Flex>
        )}
      </Flex>
      {showButtons && (
        <PaymentButtons
          cancelClick={onOpen}
          cardOnClick={() => {
            dispatch({
              type: Types.SetOrder,
              payload: { order: { ...state.makeOrder.order, paymentType: "Card" } },
            })
            setIsCard(true)
            setIsCash(false)
            setShowButtons(false)
          }}
          cashOnClick={() => {
            dispatch({
              type: Types.SetOrder,
              payload: { order: { ...state.makeOrder.order, paymentType: "Cash" } },
            })
            dispatch({
              type: Types.IsPaymentCash,
              payload: { isPaymentCash: true },
            })
            setShowButtons(false)
            setIsCash(true)
            setIsCard(false)
          }}
          onAddCustomer={customerName ? undefined : () => onCustomerModalOpen()}
        />
      )}
      {!showButtons && isCash && <Cash returnToPaymentType={returnToPaymentType} />}
      {!showButtons && isCard && <Card completeOrder={completeOrder} returnToPaymentType={returnToPaymentType} />}
      {receiptButtons && (
        <Flex alignItems="center" height="100%" justify="space-between" width="100%">
          <Button
            bg="text.10"
            boxShadow="box"
            color="success.01"
            data-testid="no-receipt-button"
            style={{
              ...buttonStyles,
              marginRight: "3rem",
              fontWeight: "normal",
            }}
            whiteSpace="pre-line"
            onClick={resetOrder}
          >
            {t("noReceipt")}
          </Button>
          <a
            href={"isa://order?type=receipt&id=" + order.id + `&lang=es&storefrontId=${storeFrontId}`}
            style={{ ...buttonStyles, padding: 0 }}
            onClick={() => null}
          >
            <Button
              bg="text.10"
              boxShadow="box"
              color="info.01"
              data-testid="print-receipt-button"
              style={{ ...buttonStyles, width: "100%", fontWeight: "normal" }}
              whiteSpace="pre-line"
              onClick={resetOrder}
            >
              {t("printReceipt")}
            </Button>
          </a>
        </Flex>
      )}
      <ConfirmModal
        isOpen={isOpen}
        type="order"
        onClick={() => {
          resetState(dispatch)
          navigate(-1)
        }}
        onClose={onClose}
      />
    </Flex>
  )
}
