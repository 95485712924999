import { Button, SimpleGrid } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { AnimateSharedLayout, motion } from "framer-motion"
import { cond, map, pipe, sort } from "ramda"
import { FC } from "react"

import { AddTenancyAdminCard, AddTenancyAdminCardProps } from "./AddTenancyAdminCard"
import { HomeIndicator, api } from "api"
import { AdminAndTenancyAdmin } from "api/handmade"
import { SectionHeader } from "common/components"
import { AdminType, useManageAdminsContext } from "features/admins"
import { AdminCardPlaceholder, useManagedCustomer } from "features/customers"

const AnimatedGrid = motion(SimpleGrid)

const adminTypePrecedence: AdminType[] = ["owner", "tenancy", "partner", "external"]

const sortByAdminType = (a: AddTenancyAdminCardProps, b: AddTenancyAdminCardProps): number => {
  if (a.admin.isNew || b.admin.isOwner) return 1
  if (a.admin.isOwner || b.admin.isNew) return -1

  const diff = adminTypePrecedence.indexOf(a.adminType) - adminTypePrecedence.indexOf(b.adminType)

  if (diff === 0) return a.admin?.name! > b.admin?.name! ? 1 : -1

  return diff
}

const withStatuses =
  (adminToAdd: AdminAndTenancyAdmin | null, entityId?: string) =>
  (admin: AdminAndTenancyAdmin): Omit<AddTenancyAdminCardProps, "dispatch"> => {
    const editStatus = cond([
      [() => adminToAdd?.id === admin.id, () => "editing-this"],
      [() => adminToAdd !== null, () => "editing-other"],
      [() => true, () => "read-only"],
    ])() as EditStatus

    const adminType = cond([
      [() => admin.isOwner === true, () => "owner"],
      [
        () =>
          admin.homeIndicator === HomeIndicator.HOME ||
          admin.homeIndicator === HomeIndicator.IAMCLOUD ||
          admin.isNew === true,
        () => "tenancy",
      ],
      [() => admin.homeIndicator === HomeIndicator.EXTERNAL, () => "external"],
      [
        () => admin.homeIndicator === HomeIndicator.PARTNER || admin.homeIndicator === HomeIndicator.DISTRIBUTOR,
        () => "partner",
      ],
    ])() as AdminType

    return { admin, adminType, editStatus }
  }

const TenancyAdmins: FC = () => {
  const { t } = useTranslation()
  const managedCustomer = useManagedCustomer()

  const { data: { value: tenancyAdmins = [] } = { value: [] } } = api.useTenancyAdmins(
    managedCustomer?.defaultTenancyId!
  )
  const { adminToAdd, addNewAdmin } = useManageAdminsContext()

  const adminCardProps = pipe(
    map(withStatuses(adminToAdd, managedCustomer?.id)),
    sort(sortByAdminType)
  )(tenancyAdmins as AdminAndTenancyAdmin[])

  return (
    <SectionHeader
      data-testid="TenancyAdmins"
      flex={{ sm: 2, lg: 3, xl: 4 }}
      header={
        <Button isDisabled={Boolean(adminToAdd)} variant="tertiary" onClick={addNewAdmin}>
          {t("Add new admin")}
        </Button>
      }
      title={t("Tenancy admins")}
    >
      <AnimateSharedLayout>
        <AnimatedGrid gap={4} templateColumns="repeat(auto-fill, minmax(340px, 1fr))" layout>
          {adminCardProps.map((adminCardProps) => (
            <motion.div key={adminCardProps.admin!.id} layout>
              <AddTenancyAdminCard {...adminCardProps} />
            </motion.div>
          ))}
          {adminToAdd && (
            <motion.div key="newAdmin" layout>
              <AddTenancyAdminCard admin={adminToAdd} adminType="tenancy" editStatus="editing-this" />
            </motion.div>
          )}
          <motion.div key="placeholder" layout>
            <AdminCardPlaceholder
              h={320}
              hasAdmins={tenancyAdmins.length > 0 || adminToAdd !== null}
              isDisabled={adminToAdd !== null}
              onClick={addNewAdmin}
            />
          </motion.div>
        </AnimatedGrid>
      </AnimateSharedLayout>
    </SectionHeader>
  )
}

export { TenancyAdmins }
