import { Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, ReactNode, SetStateAction, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useTable } from "react-table"

import { VariantTypeOption } from "api"
import { Pagination, Table, TableItemMenu } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE } from "common/constants"

type VariantOptionsTableProps = {
  onOpen: () => void
  variantOptions: VariantTypeOption[]
  isLoading?: boolean
  refetch: (top: number, skip: number) => void
  setVariantOption: Dispatch<SetStateAction<VariantTypeOption>>
  itemsPerPage: number
}

const VariantOptionsTable: FC<VariantOptionsTableProps> = ({
  variantOptions,
  onOpen,
  isLoading,
  setVariantOption,
  refetch,
  itemsPerPage,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const OptionCell: FC<{ option: VariantTypeOption }> = ({ option }) => (
    <TableItemMenu
      deleteClick={() => {
        setVariantOption(option)
        onOpen()
      }}
      editClick={() => navigate(option.id)}
      value={option.name}
      width="30%"
      onClick={() => navigate(`${option.id}/variantOptionValues`)}
    />
  )

  const columnData: Column<VariantTypeOption>[] = [
    {
      Header: t("name") as string,
      accessor: "name",
      Cell: ({ row: { original: option } }) => <OptionCell option={option} />,
    },
    {
      Header: t("description") as string,
      accessor: "description",
    },
    {
      Header: t("variant_option.no_of_option_values") as string,
      // @ts-ignore
      Cell: () => <Text textAlign="right">{0}</Text>,
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setPageSize,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, []),
      data: useMemo(() => variantOptions, [variantOptions]),
      initialState: {
        pageSize: itemsPerPage,
      },
    },
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<VariantTypeOption>): ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} data-testid="variantOptions" width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR bg="white" color="#36495E" fontSize="lg" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Table.TH
                _first={{ width: "40%" }}
                _last={{ textAlign: "right" }}
                textTransform="none"
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </Table.TH>
            ))}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()} textAlign="left">
        {isLoading ? (
          <Table.Skeleton columnWidths={[32, 16, 32, 64]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={(event) => {
                setPageSize(event)
                refetch(event, 0)
              }}
              totalPages={pageOptions.length}
              onPageChange={gotoPage}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { VariantOptionsTable }
