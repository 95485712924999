import { RouteConfig } from "./RouteConfig"
import { Entity } from "api"
import { Command } from "features/commands"

export const createRouteCommand = (routeConfig: RouteConfig, label: string): Command => ({
  id: "route > goTo",
  args: [routeConfig],
  label,
})

export const authCommands: Command[] = [{ id: "auth > signin" }, { id: "auth > signout" }]

export const clipboardCommands: { [key: string]: Command } = {
  copyToken: { id: "clipboard > copyToken" },
  copyUser: { id: "clipboard > copyUser" },
}

export const customerCommands: Command[] = [
  // disabled until auth client update prevents hang when loading customers from API
  // { id: "customer > manage > enable" },
  { id: "customer > manage > disable" },
]

export const customerSubcommands = (entities: Entity[]): Command[] =>
  entities.map((entity) => ({
    id: "customer > manage > selectCustomer",
    label: entity.name,
    args: [entity],
  }))

export const commands: Command[] = [...authCommands, ...Object.values(clipboardCommands), ...customerCommands]
