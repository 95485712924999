import { SearchIcon } from "@chakra-ui/icons"
import {
  Box,
  Flex,
  FlexProps,
  Input,
  InputGroup,
  InputLeftAddon,
  List,
  ListItem,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { motion } from "framer-motion"
import { FC, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { ProductModel, api } from "api"
import { FoundProductsList } from "common/components"
import { OutOfStockModal } from "common/components/OutOfStockModal/OutOfStockModal"
import { TENANCY_ID } from "common/constants"
import { InventoryTypes } from "common/inventoryTypes"
import { route } from "features/app/routes"
import { getCount, getInitProduct } from "features/inShop/inShopUtils"
import { ProductsContext } from "features/inShop/product/ProductState/context"

const AnimatedBox = motion(Box)

// eslint-disable-next-line sonarjs/cognitive-complexity
const Ackbar: FC<FlexProps> = (flexProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [isVisible, setIsVisible] = useState(false)
  const [isFoundProductsVisible, setIsFoundProductsVisible] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { state, dispatch } = useContext(ProductsContext)
  const {
    globalEntities: { storeFrontId },
  } = state
  const [searchValue, setSearchValue] = useState("")
  const [forVariant, setForVariant] = useState({
    productId: "",
    variantId: "",
  })
  const { data: searchingProducts, isLoading } = api.useSearchProductsDebounced(TENANCY_ID, storeFrontId, searchValue)

  useEffect(() => {
    if (isLoading) setIsFoundProductsVisible(true)
  }, [isLoading])

  const goToVariantPage = () => {
    navigate(`${route.productInfo.path}/${forVariant.productId}/editVariant/${forVariant.variantId}`)
    onClose()
  }

  const onSelectProduct = (product: ProductModel) => {
    if (product) {
      const isProductExist = state.makeOrder.order.orderLines.find((item) => item.id === product.id)
      if (isProductExist) {
        if (product.inventoryType === InventoryTypes.Stocked && isProductExist.quantity + 1 <= product.stock) {
          getCount({ ...isProductExist, quantity: isProductExist.quantity + 1 }, state, dispatch)
        } else {
          onOpen()
          setForVariant({ productId: product.productId, variantId: product.id })
        }
      } else {
        const addedProduct = { ...getInitProduct(product), quantity: 1 }
        getCount(addedProduct, state, dispatch)
      }
    }
  }

  useEffect(() => {
    setIsVisible(location.pathname.includes("/inShop/product"))
  }, [location.pathname])

  return (
    <Flex
      align="flex-end"
      borderRadius="md"
      direction="column"
      flex={0.5}
      pos="relative"
      px={2}
      role="group"
      style={isVisible ? {} : { display: "none" }}
      {...flexProps}
    >
      <AnimatedBox
        animate={{ width: isExpanded ? "576px" : "7rem" }}
        initial={{ width: "7rem" }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
      >
        <InputGroup
          _focusWithin={{
            outline: 1,
            outlineOffset: "1px",
            boxShadow: "outline",
            borderRadius: "md",
          }}
          _groupHover={{ borderColor: "hover.field.01" }}
          _hover={{ borderColor: "hover.field.01" }}
          bg="white"
          flex={1}
          size="sm"
          tabIndex={-1}
          zIndex="banner"
        >
          <InputLeftAddon _groupHover={{ borderColor: "hover.field.01" }} bg="field.01" borderRadius={5} p={2.5}>
            <SearchIcon color="#979797" />
          </InputLeftAddon>
          <Input
            _active={{ outline: 0, boxShadow: "none" }}
            _focus={{ borderWidth: "1px" }}
            _groupHover={{ borderColor: "hover.field.01" }}
            borderLeft={0}
            boxShadow="none"
            data-testid="search-product-input"
            outline={0}
            p="2px"
            placeholder={t("searchPlaceholder")}
            value={searchValue}
            onBlur={() => {
              if (!isFoundProductsVisible) setIsExpanded(false)
            }}
            onChange={(event) => setSearchValue(event.target.value)}
            onFocus={() => setIsExpanded(true)}
          />
        </InputGroup>
      </AnimatedBox>
      {isFoundProductsVisible && (
        <>
          <List
            bg={searchingProducts?.value?.length ? "field.01" : "elevation.01"}
            borderColor="ui.02"
            borderRadius="sm"
            borderWidth="1px"
            boxShadow="modal"
            maxH="calc(100vh - 9rem)"
            mt={2}
            overflowY="scroll"
            pos="absolute"
            top={8}
            width="576px"
            zIndex="overlay"
          >
            {searchingProducts?.value?.length && !isLoading ? (
              <FoundProductsList
                products={searchingProducts?.value}
                onProductClick={(p) => {
                  onSelectProduct(p)
                  setSearchValue("")
                  setIsFoundProductsVisible(false)
                  setIsExpanded(false)
                }}
              />
            ) : (
              <ListItem cursor="pointer" px={6} py={3}>
                {isLoading ? <Spinner /> : t("noResults")}
              </ListItem>
            )}
          </List>
          <Box
            bottom="0"
            left="0"
            position="fixed"
            right="0"
            top="0"
            zIndex="sticky"
            onClick={() => {
              setSearchValue("")
              setIsFoundProductsVisible(false)
              setIsExpanded(false)
            }}
          />
        </>
      )}
      <OutOfStockModal isOpen={isOpen} onClick={goToVariantPage} onClose={onClose} />
    </Flex>
  )
}

export { Ackbar }
