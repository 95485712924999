import { Button, Flex } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

export type FormButtonsProps = {
  titleDone?: string
  titleCancel?: string
  cancelWidth?: string
  done?: () => void
  cancel: () => void
  disabled?: boolean
  width?: string
  padding?: string
  distanceBetweenButtons?: string
  disableCancelButton?: boolean
  buttonTestIdSuffix?: string
}

export const FormButtons: FC<FormButtonsProps> = ({
  cancel,
  done,
  titleDone,
  titleCancel,
  cancelWidth = "5rem",
  disabled = false,
  padding = "1rem 0",
  distanceBetweenButtons = "48px",
  disableCancelButton = false,
  buttonTestIdSuffix = "",
}) => {
  const { t } = useTranslation()

  return (
    <Flex align="center" gridGap={distanceBetweenButtons} justify="flex-start" p={padding}>
      <Button
        data-testid={"done-button" + buttonTestIdSuffix}
        disabled={disabled}
        variant="primary"
        width={cancelWidth}
        onClick={done}
      >
        {titleDone || t("done")}
      </Button>
      <Button
        data-testid={"cancel-button" + buttonTestIdSuffix}
        disabled={disableCancelButton ? disabled : false}
        variant="secondary"
        width={cancelWidth}
        onClick={cancel}
      >
        {titleCancel || t("cancel")}
      </Button>
    </Flex>
  )
}
