import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { Image, ProductImage, ProductImagesService } from "../generated"

export const useProductImages = (parentId: UUID, tenancyId: UUID) =>
  useQuery(
    ["productImages", parentId, tenancyId],
    () => ProductImagesService.productImagesGetAllAsync({ parentId, tenancyId }),
    {
      enabled: !!parentId && !!tenancyId,
    }
  )

export const useProductImage = (parentId: UUID, tenancyId: UUID, id: UUID) =>
  useQuery(["productImages", parentId, id], () =>
    ProductImagesService.productImagesGetAsync({ id, parentId, tenancyId })
  )

export const useCreateProductImageMutation = (
  { onSuccess }: { onSuccess: () => void },
  parentId: UUID,
  tenancyId: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (productImage: ProductImage) =>
      ProductImagesService.productImagesPostAsync({ parentId, tenancyId, requestBody: productImage as any }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["productImages"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useUpdateProductImagesMutation = (parentId: UUID, tenancyId: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (images: Image[]) => ProductImagesService.productImagesPatchAsync({ tenancyId, parentId, requestBody: images }),
    {
      onSuccess: () => queryClient.invalidateQueries(["productImages"]),
      onError: () => {},
    }
  )
}

export const useDeleteProductImageMutation = (tenancyId: UUID, parentId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(() => ProductImagesService.productImagesDeleteAsync({ id, parentId, tenancyId }), {
    onSuccess: () => queryClient.invalidateQueries(["productImages"]),
    onError: () => {},
  })
}
