import { Dispatch, SetStateAction } from "react"

import { InitialStateType, ProductStateDispatcher } from "./product/ProductState/context"
import { Types } from "./product/ProductState/reducers"
import { Order, ProductModel, mockData } from "api"
import { DiscountTypes } from "common/discountTypes"
import { InventoryTypes } from "common/inventoryTypes"

export const changeCount = (
  value: number,
  setCount: Dispatch<SetStateAction<number>>,
  product: ProductModel,
  state: InitialStateType,
  dispatch: ProductStateDispatcher,
  onOpen?: () => void
): void =>
  setCount((prev): number => {
    if (value > product.stock) {
      if (onOpen && product.inventoryType === InventoryTypes.Stocked) {
        onOpen()
      }

      return prev
    }
    if (value === 0) return prev
    getCount({ ...product, quantity: value }, state, dispatch)

    return value
  })

export const generateOrder = (products: ProductModel[], order: Order, state: InitialStateType): Order => {
  if (products.length) {
    const totalSub = Number(
      products
        .map(({ subtotal }) => subtotal)
        .reduce((x: number, y: number) => x + y)
        .toFixed(2)
    )
    const totalTax = Number(
      products
        .map(({ tax, quantity }) => +(tax * quantity).toFixed(2))
        .reduce((x: number, y: number) => x + y)
        .toFixed(2)
    )
    const totalDiscount = Number(
      products
        .map(({ discount }) => discount)
        .reduce((x: number, y: number) => x + y)
        .toFixed(2)
    )
    const totalDeposit = Number(
      products
        .map(({ deposit }) => deposit)
        .reduce((x: number, y: number) => x + y)
        .toFixed(2)
    )

    const preTotal = Number((totalSub + totalTax - totalDiscount).toFixed(2))
    const orderDiscount =
      state.makeOrder.order.discountType === DiscountTypes.Amount
        ? state.makeOrder.order.orderDiscount
        : +((preTotal * state.makeOrder.order.orderDiscount) / 100).toFixed(2)
    const discount = +(totalDiscount + orderDiscount).toFixed(2)
    const total = +(preTotal - orderDiscount).toFixed(2)

    return {
      ...state.makeOrder.order,
      orderLines: products,
      subtotal: totalSub,
      tax: totalTax,
      deposit: totalDeposit,
      discount,
      total,
    }
  }

  return order
}

export const getDiscount = (product: ProductModel): number => {
  switch (product.discountType) {
    case "Percentage": {
      const subtotal = +((product.retailPrice + product.tax) * product.quantity).toFixed(2)

      return +((subtotal * +product.productDiscount) / 100).toFixed(2)
    }
    case "Amount": {
      return Number((+product.productDiscount * product.quantity).toFixed(2))
    }
    default:
      return 0
  }
}

export const deleteProduct = (
  product: ProductModel,
  id: string,
  state: InitialStateType,
  dispatch: ProductStateDispatcher
): void => {
  dispatch({ type: Types.OpenDetails, payload: { openDetails: false } })
  const removedOrder = state.makeOrder.order.orderLines.filter((item: ProductModel) => item.id !== id)
  const generatedOrder = generateOrder(removedOrder, mockData.initOrder, state)
  dispatch({ type: Types.SetOrder, payload: { order: generatedOrder } })
}

export const confirmOrder = (
  product: ProductModel,
  state: InitialStateType,
  dispatch: ProductStateDispatcher
): void => {
  const newListProducts = state.makeOrder.order.orderLines.map((item: ProductModel) =>
    item.id === product.id ? product : item
  )
  const generatedOrder = generateOrder(newListProducts, mockData.initOrder, state)
  dispatch({ type: Types.SetOrder, payload: { order: generatedOrder } })
}

export const getCount = (product: ProductModel, state: InitialStateType, dispatch: ProductStateDispatcher): void => {
  if (product.quantity === 0) {
    return deleteProduct(product, product.id, state, dispatch)
  }

  const subtotal = product.retailPrice * product.quantity
  const discount = getDiscount(product)
  const total = +(subtotal + product.tax * product.quantity - discount).toFixed(2)

  const changedProduct = {
    ...product,
    subtotal,
    discount,
    total,
  }

  product.isActive && dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: changedProduct } })

  if (!state.makeOrder.order.orderLines.length) {
    const generatedOrder = generateOrder([changedProduct], mockData.initOrder, state)

    return dispatch({ type: Types.SetOrder, payload: { order: generatedOrder } })
  }
  const existProduct = state.makeOrder.order.orderLines.find((item: ProductModel) => item.id === product.id)
  if (existProduct) {
    const newOrderProducts = state.makeOrder.order.orderLines.map((item: ProductModel) =>
      item.id === changedProduct.id ? changedProduct : item
    )
    const generatedOrder = generateOrder(newOrderProducts, mockData.initOrder, state)

    return dispatch({ type: Types.SetOrder, payload: { order: generatedOrder } })
  }
  const products = [...state.makeOrder.order.orderLines, changedProduct]

  const generatedOrder = generateOrder(products, mockData.initOrder, state)

  return dispatch({ type: Types.SetOrder, payload: { order: generatedOrder } })
}

export const resetState = (dispatch: ProductStateDispatcher) => {
  dispatch({ type: Types.SetOrder, payload: { order: mockData.initOrder } })
  dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: mockData.initProduct } })
  dispatch({ type: Types.OpenDetails, payload: { openDetails: false } })
}

export const getInitProduct = (product: ProductModel): ProductModel => ({
  ...product,
  productDiscount: product.productDiscount ?? "0",
  deliveryDate: product.deliveryDate ?? "",
  discount: getDiscount(product),
  discountType: product.discountType ?? DiscountTypes.Amount,
  subtotal: product.subtotal ?? 0,
  tax: product.tax ?? 0,
  total: product.total ?? 0,
  deposit: product.deposit ?? 0,
  quantity: product.quantity ?? 0,
})
