/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The type of entity being created. You can only create a Distributor, Partner and/or Customer entity.
 */
export enum EntityTypes {
    IAMCLOUD = 'IAMCloud',
    DISTRIBUTOR = 'Distributor',
    PARTNER = 'Partner',
    CUSTOMER = 'Customer',
}