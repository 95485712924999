import { Flex, Input } from "@chakra-ui/react"
import { EditIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, SetStateAction, useContext, useMemo } from "react"
import { Column, Row, usePagination, useTable } from "react-table"

import { ProductModel } from "api"
import { Table } from "common/components"
import { ProductsContext } from "features/inShop/product/ProductState/context"

type OrderDetailsTableProps = {
  products: ProductModel[]
  setNotes: Dispatch<SetStateAction<string>>
  setProduct: Dispatch<SetStateAction<ProductModel>>
  setType: Dispatch<SetStateAction<"order" | "product">>
  onOpen: () => void
}

const SalesOrderDetailsTable: FC<OrderDetailsTableProps> = ({ products, setNotes, setProduct, setType, onOpen }) => {
  const { t } = useTranslation()
  const {
    state: {
      globalEntities: {
        tenancyCurrency: {
          currency: { symbol },
        },
      },
    },
  } = useContext(ProductsContext)

  const columnData: Column<ProductModel>[] = [
    {
      Header: t("productTitle") as string,
      accessor: "name",
      Cell: ({ row }) => (
        <Input backgroundColor="ui.06" value={row.original.productVariant?.receiptDisplayName} readOnly />
      ),
    },
    {
      Header: t("quantity") as string,
      accessor: "quantity",
      Cell: ({ row }) => (
        <Input backgroundColor="ui.06" textAlign="right" value={row.original.quantity} width="60%" readOnly />
      ),
    },
    {
      Header: t("price") as string,
      accessor: "retailPrice",
      Cell: ({ row }) => (
        <Input
          backgroundColor="ui.06"
          textAlign="right"
          value={`${row.original.productVariant?.storeFronts[0]?.retailPrice.toFixed(2)} ${symbol}`}
          width="60%"
          readOnly
        />
      ),
    },
    {
      Header: t("discount") as string,
      accessor: "discount",
      Cell: ({ row }) => (
        <Input
          backgroundColor="ui.06"
          textAlign="right"
          value={`${row.original.discount.toFixed(2)} ${symbol}`}
          width="60%"
          readOnly
        />
      ),
    },
    {
      Header: t("totalPaid") as string,
      accessor: "deposit",
      Cell: ({ row }) => (
        <Input
          backgroundColor="ui.06"
          textAlign="right"
          value={`${row.original.deposit.toFixed(2)} ${symbol}`}
          width="60%"
          readOnly
        />
      ),
    },
    {
      Header: t("totalRemaining") as string,
      // @ts-ignore
      Cell: ({ row }) => (
        <Input
          backgroundColor="ui.06"
          textAlign="right"
          value={`${(row.original.total - row.original.deposit).toFixed(2)} ${symbol}`}
          width="60%"
          readOnly
        />
      ),
    },
    {
      id: "edit",
      // @ts-ignore
      Cell: ({ row }) => (
        <Flex
          _hover={{ cursor: "pointer" }}
          align="center"
          backgroundColor="ui.01"
          borderColor="ui.02"
          borderRadius={4}
          borderWidth={1}
          data-testid="edit-order-button"
          height="40px"
          justify="center"
          width="40px"
          onClick={() => {
            setProduct(row.original)
            setType("product")
            setNotes(row.original.notes)
            onOpen()
          }}
        >
          <EditIcon color="interactive.05" />
        </Flex>
      ),
    },
  ]

  const { getTableProps, page, getTableBodyProps, headerGroups, prepareRow } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, []),
      data: useMemo(() => products, [products]),
    },
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<ProductModel>): React.ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Table.TD _notFirst={{ textAlign: "right" }} {...cell.getCellProps()}>
            {cell.render("Cell")}
          </Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} data-testid="orderProducts" flex={1} width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR
            bg="ui.background"
            color="#36495E"
            fontSize="lg"
            textAlign="center"
            textTransform="capitalize"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => (
              <Table.TH _notFirst={{ textAlign: "right" }} textTransform="none" {...column.getHeaderProps()}>
                {column.render("Header")}
              </Table.TH>
            ))}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()} textAlign="center">
        {page.map(renderRow)}
      </Table.Body>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export type { OrderDetailsTableProps }

export { SalesOrderDetailsTable }
