import { useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useState } from "react"

import { SalesOrdersTable } from "./salesOrdersTable/SalesOrdersTable"
import { Order, api, mockData } from "api"
import { ConfirmModal, PageHeader, PageLayout } from "common/components"
import { TENANCY_ID } from "common/constants"

const SalesOrdersView: FC = () => {
  const { t } = useTranslation()

  const { data: salesOrdersData, isLoading } = api.useSalesOrders(TENANCY_ID, {
    expand: "storefront",
    orderby: "orderDate desc",
  })
  const { onClose, isOpen, onOpen } = useDisclosure()
  const [order, setOrder] = useState<Order>(mockData.initOrder)
  const deleteSalesOrderMutation = api.useDeleteSalesOrderMutation({ onSuccess: () => onClose() }, TENANCY_ID, order.id)
  const deleteSalesOrder = (): void => deleteSalesOrderMutation.mutate()

  return (
    <>
      <PageHeader
        buttonTitle={t("salesOrders.addNewOrder")}
        link="/sales"
        title={t("salesOrders.salesOrdersTitle")}
        isArrow
      />
      <PageLayout
        confirmModal={
          <ConfirmModal
            isOpen={isOpen}
            name={order.id || "this"}
            type="order"
            isDelete
            onClick={deleteSalesOrder}
            onClose={onClose}
          />
        }
        setSearch={() => {}}
        table={
          <SalesOrdersTable
            loading={isLoading}
            salesOrders={salesOrdersData?.value || []}
            setOrder={(order: Order) => setOrder(order)}
            onOpen={onOpen}
          />
        }
      />
    </>
  )
}

export { SalesOrdersView }
