import {
  Box,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Switch,
  useDisclosure,
} from "@chakra-ui/react"
import { ReloadIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { IsDefaultCurrencyModal } from "./IsDefaultCurrencyModal"
import { Currency, TenancyCurrency, api, mockData } from "api"
import { FormButtons } from "common/components"
import { CommonFormControl } from "common/components/CommonFormControl/CommonFormControl"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { TENANCY_ID } from "common/constants"
import { route } from "features/app/routes"

// eslint-disable-next-line sonarjs/cognitive-complexity
const CurrencyChanges = () => {
  const params = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [name, setName] = useState("")
  const [rate, setRate] = useState("")
  const [currencies, setCurrencies] = useState<Currency[] | undefined>([])
  const [currency, setCurrency] = useState<TenancyCurrency>(mockData.initTenancyCurrency)
  const [defaultCurrency, setDefaultCurrency] = useState<TenancyCurrency | undefined>(mockData.initTenancyCurrency)
  const { data: currenciesArray } = api.useCurrencies()
  const { data: tenancyCurrencies } = api.useTenancyCurrencies(TENANCY_ID, { expand: "currency" })
  const { data } = api.useTenancyCurrency(TENANCY_ID, params.id || "", { expand: "currency" })

  const newCurrencies = useMemo(
    () =>
      currenciesArray?.value?.filter(({ id }) =>
        tenancyCurrencies?.value?.every(({ currencyId }) => currencyId !== id)
      ),
    [currenciesArray, tenancyCurrencies]
  )
  const createTenancyCurrency = api.useCreateTenancyCurrencyMutation(
    { onSuccess: () => navigate(route.currencies.path) },
    TENANCY_ID
  )
  const updateTenancyCurrency = api.useUpdateTenancyCurrencyMutation(
    { onSuccess: () => navigate(route.currencies.path) },
    TENANCY_ID,
    params.id || ""
  )

  const currencyChanges = (): void => {
    const object = {
      isDefault: currency.isDefault,
      rate: currency.isDefault ? undefined : +rate,
    }
    params.id
      ? updateTenancyCurrency.mutate(object)
      : createTenancyCurrency.mutate({ ...object, currencyId: currency.currencyId || currency.currency.id })
  }

  const boxStyles = {
    background: "elevation.01",
    borderWidth: 1,
    borderColor: "#cac5c6",
    borderRightRadius: 3,
    fontSize: 14,
    padding: 2,
  }

  useEffect(() => {
    if (params.id && data) {
      setCurrency(data)
      setName(data?.currency?.localName || "")
      setRate(String(data?.rate))
    }
  }, [data, params.id])

  useEffect(() => {
    setCurrencies(newCurrencies)
  }, [newCurrencies])

  useEffect(() => setDefaultCurrency(tenancyCurrencies?.value?.find((item) => item.isDefault)), [tenancyCurrencies])

  const setIsDefault = (isDefault: boolean) => setCurrency({ ...currency, isDefault })

  return (
    <>
      <PageHeader
        title={
          params.id
            ? t("currencies.currencyChanges.editCurrency", { name })
            : t("currencies.currencyChanges.addANewCurrency")
        }
      />
      <Flex direction="column" pl={20} pr={4} py={4} width="100%">
        {!params.id && (
          <CommonFormControl label={t("currencies.currencyChanges.currency")}>
            <Select
              data-testid="currency-select"
              value={currency?.currency.localName}
              onChange={(event) => {
                const findItem = currencies?.find((item) => item.localName === event.currentTarget.value) as Currency
                setCurrency({ ...currency, currency: findItem })
              }}
            >
              <option value="" disabled>
                {t("select")}
              </option>
              {currencies?.map((item) => (
                <option key={item.iso} value={item.localName || ""}>
                  {item.symbol} {item.localName}
                </option>
              ))}
            </Select>
          </CommonFormControl>
        )}
        <CommonFormControl
          label={t("currencies.currencyChanges.exchangeRate")}
          tooltipLabel={t("currencies.currencyChanges.tooltipLabel")}
          isInfo
        >
          <Flex align="center" justify="space-between">
            <Flex width="40%">
              <NumberInput borderRightRadius={0} borderRightWidth={0} value={1} isReadOnly>
                <NumberInputField borderRightRadius={0} borderRightWidth={0} px={3} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Box {...boxStyles}>{defaultCurrency?.currency?.symbol}</Box>
            </Flex>
            {!currency.isDefault && (
              <>
                <Flex justify="flex-end" width="40%">
                  <NumberInput
                    borderRightRadius={0}
                    borderRightWidth={0}
                    data-testid="currency-rate-field"
                    precision={2}
                    step={0.01}
                    value={rate}
                    allowMouseWheel
                    onChange={(event) => {
                      if (+event < 0) {
                        setRate("0")
                      } else {
                        setRate(event)
                      }
                    }}
                  >
                    <NumberInputField borderRightRadius={0} borderRightWidth={0} px={3} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Box {...boxStyles}>{currency.currency.symbol}</Box>
                </Flex>
                <ReloadIcon _hover={{ cursor: "pointer" }} color="#007abd" fontSize={22} ml={2} />
              </>
            )}
          </Flex>
        </CommonFormControl>
        {params.id && (
          <CommonFormControl label={t("currencies.currencyChanges.makeDefaultCurrency")}>
            <Switch
              data-testid="currency-is-default-switch"
              isChecked={currency.isDefault}
              onChange={(event) =>
                event.currentTarget.checked
                  ? onOpen()
                  : setCurrency({ ...currency, isDefault: event.currentTarget.checked })
              }
            />
          </CommonFormControl>
        )}

        <FormButtons
          cancel={() => navigate(route.currencies.path)}
          disabled={IsDisabled(params.id, data, rate, currency)}
          done={currencyChanges}
        />
      </Flex>
      <IsDefaultCurrencyModal
        isOpen={isOpen}
        name={currency?.currency?.localName || ""}
        setIsDefault={setIsDefault}
        onClose={onClose}
      />
    </>
  )
}

const IsDisabled = (
  matchParamsId: string | undefined,
  currencyToEdit: TenancyCurrency | undefined,
  rate: string,
  currency: TenancyCurrency
): boolean =>
  matchParamsId
    ? currencyToEdit?.rate === +rate && currencyToEdit?.isDefault === currency.isDefault
    : !currency.currency.localName || !rate

export { CurrencyChanges }
