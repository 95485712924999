import { FC, createContext, useReducer } from "react"

import {
  BoolActions,
  ExchangeActions,
  GlobalEntitiesActions,
  MakeOrderActions,
  RefundActions,
  RemainingActions,
  boolReducer,
  exchangeReducer,
  globalEntitiesReducer,
  makeOrderReducer,
  refundReducer,
  remainingReducer,
} from "./reducers"
import { Country, Order, ProductModel, TenancyCurrency, TenancyLanguage, mockData } from "api"

export type ProductStateDispatcher = (action: ProductStateAction) => void

type BoolType = {
  openDetails: boolean
  isUpdate: boolean
  isPayment: boolean
  isPaymentCash: boolean
  hideFields: boolean
  showCustomerDetail: boolean
  isOrderChanged: boolean
  openRefundProduct: boolean
  openExchangeProduct: boolean
  openRemainingProduct: boolean
}

type OrderType = {
  order: Order
  orderProduct: ProductModel
  typeOrder: string
}

type ExchangeOrderType = {
  exchangeOrder: Order
  exchangeProduct: ProductModel
}

type RemainingType = {
  remainingOrder: Order
  remainingProduct: ProductModel
}

type RefundType = {
  refundOrder: Order
  refundOrderProduct: ProductModel
  total: number
}

type GlobalEntitiesType = {
  tenancyCurrency: TenancyCurrency
  tenancyLanguage: TenancyLanguage
  tenancyCurrencies: TenancyCurrency[]
  tenancyLanguages: TenancyLanguage[]
  storeFrontId: string
  countries: Country[]
}

export type InitialStateType = {
  globalEntities: GlobalEntitiesType
  makeOrder: OrderType
  bool: BoolType
  refund: RefundType
  exchange: ExchangeOrderType
  remaining: RemainingType
}

const initialState = {
  globalEntities: {
    tenancyCurrency: mockData.initTenancyCurrency,
    tenancyLanguage: mockData.initTenancyLanguage,
    tenancyCurrencies: [],
    tenancyLanguages: [],
    storeFrontId: "",
    countries: [],
  },
  makeOrder: {
    order: mockData.initOrder,
    orderProduct: mockData.initProduct,
    typeOrder: "",
  },
  bool: {
    openDetails: false,
    isUpdate: false,
    isPayment: false,
    isPaymentCash: false,
    hideFields: false,
    showCustomerDetail: false,
    isOrderChanged: false,
    openRefundProduct: false,
    openExchangeProduct: false,
    openRemainingProduct: false,
  },
  refund: {
    refundOrder: mockData.initOrder,
    refundOrderProduct: {
      ...mockData.initProduct,
      returnedCount: 0,
    },
    total: 0,
    returnedCount: 0,
  },
  exchange: {
    exchangeOrder: mockData.initOrder,
    exchangeProduct: mockData.initProduct,
  },
  remaining: {
    remainingOrder: mockData.initOrder,
    remainingProduct: mockData.initProduct,
  },
}

const ProductsContext = createContext<{
  state: InitialStateType
  dispatch: ProductStateDispatcher
}>({
  state: initialState,
  dispatch: () => null,
})

const mainReducer = (
  { globalEntities, makeOrder, bool, refund, exchange, remaining }: InitialStateType,
  action: ProductStateAction
) => ({
  globalEntities: globalEntitiesReducer(globalEntities, action),
  makeOrder: makeOrderReducer(makeOrder, action),
  bool: boolReducer(bool, action),
  refund: refundReducer(refund, action),
  exchange: exchangeReducer(exchange, action),
  remaining: remainingReducer(remaining, action),
})

const ProductsProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)

  return <ProductsContext.Provider value={{ state, dispatch }}>{children}</ProductsContext.Provider>
}

export { ProductsContext, ProductsProvider }

export type ProductStateAction =
  | GlobalEntitiesActions
  | MakeOrderActions
  | BoolActions
  | RefundActions
  | ExchangeActions
  | RemainingActions
