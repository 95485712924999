import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { CloseCircleIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"
import { useLocation } from "react-router-dom"

import { ProductDisplay } from "./ProductDisplay"
import { ProductModel } from "api"

type Props = {
  isOpen: boolean
  onClose: () => void
  products?: ProductModel[]
  onSelectProduct: (product: ProductModel) => void
}

export const InShopProductsModal: FC<Props> = ({ isOpen, onClose, products, onSelectProduct }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const isOrderPage = location.pathname.includes("/inShop/product")

  return (
    <Modal
      isOpen={isOpen}
      motionPreset="slideInRight"
      scrollBehavior="inside"
      size={isOrderPage ? "md" : "full"}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        containerProps={{ justifyContent: "flex-end" }}
        h="inherit"
        maxH="100%"
        mb={4}
        mt={16}
        mx={6}
        w={isOrderPage ? "424px" : "100%"}
      >
        <ModalHeader borderBottom="1px solid #E5E5E5" fontSize="lg" fontWeight="normal" lineHeight="20px" px={6} py={3}>
          {t("Select an item")}
        </ModalHeader>
        <ModalCloseButton>
          <CloseCircleIcon color="interactive.05" />
        </ModalCloseButton>

        <ModalBody p={4}>
          <Flex pt={4}>
            <Text color="text.09" fontSize="sm">
              {`${products?.length} `}
              {t("results for")}
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              &nbsp;
              {t("the same barcode")}
            </Text>
          </Flex>

          <Flex columnGap={4} direction="row" gridRowGap={4} justifyContent="flex-start" mb={6} mt={8} wrap="wrap">
            {products?.map((product: ProductModel) => (
              <ProductDisplay
                key={product.id}
                isSmallSize={isOrderPage}
                product={product}
                onSelectProduct={onSelectProduct}
              ></ProductDisplay>
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
