import { StoreTypes } from "../storeTypeButton/StoryTypeButton"

enum WebsiteTypes {
  Custom = "Custom Created",
  WooCommerce = "WooCommerce",
  Shopify = "Shopify",
  Wix = "Wix",
}

type FormValuesVirtual = {
  name: string
  description: string
  type: string
  address: string
  status: string
  currencyId: string
  languageId: string
  websiteType: string
  isDefault: boolean
}

const getVirtualModel = (
  formValues: FormValuesVirtual
): {
  name: string
  description: string
  type: StoreTypes
  address: string
  status: string
  currencyId: string
  languageId: string
  isDefault: boolean
  websiteType: string
} => ({
  name: formValues.name,
  description: formValues.description,
  type: StoreTypes.Virtual,
  address: formValues.address,
  status: formValues.status,
  currencyId: formValues.currencyId,
  languageId: formValues.languageId,
  isDefault: formValues.isDefault,
  websiteType: formValues.websiteType,
})

export { WebsiteTypes, getVirtualModel }

export type { FormValuesVirtual }
