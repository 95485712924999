import {
  Button,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { AddIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useContext, useEffect, useState } from "react"

import { Customer, api } from "api"
import { SearchPanel } from "common/components/SearchPanel/SearchPanel"
import { TENANCY_ID } from "common/constants"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"
import { CustomerChanges } from "features/sales/customers/customerChanges/CustomerChanges"

type AddCustomerProps = {
  isOpen: boolean
  onClose: () => void
}

const AddCustomerModal: FC<AddCustomerProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState<string>("")
  const [isAddingNewCustomer, setIsAddingNewCustomer] = useState(false)
  const { state, dispatch } = useContext(ProductsContext)
  const { data, isInitialLoading } = api.useSearchCustomers(TENANCY_ID, search)

  useEffect(() => setIsAddingNewCustomer(false), [isOpen])

  const setCustomerAndCloseModal = (customer?: Customer) => {
    if (customer) {
      dispatch({
        type: Types.SetOrder,
        payload: { order: { ...state.makeOrder.order, customerId: customer.id } },
      })
    }
    onClose()
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      motionPreset="slideInBottom"
      scrollBehavior="inside"
      size="3xl"
      isCentered
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent p={4} pb={0}>
        {isAddingNewCustomer ? (
          <>
            <ModalCloseButton data-testid="icon-close" />
            <ModalBody p={0}>
              <CustomerChanges setCustomerAndCloseModal={setCustomerAndCloseModal} />
            </ModalBody>
          </>
        ) : (
          <>
            <ModalHeader pb={0} px={4}>
              {t("search.searchExistingCustomer")}
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody px={4}>
              <SearchPanel
                buttonVariant="primary"
                paddingLeft={0}
                placeholder={t("search.searchEmailOrMobileNumber")}
                width="85%"
                onSearch={(search) => setSearch(search)}
              />
              {isInitialLoading ? (
                <Flex align="center" h="60px" justify="center">
                  <Spinner color="#0073b3" height={33} width={33} />
                </Flex>
              ) : (
                <Flex flexDirection="column" pl={6} py={5}>
                  {data?.value &&
                    data?.value.map((customer) => (
                      <Grid
                        key={customer.id}
                        cursor="pointer"
                        my={2}
                        py={1}
                        templateColumns="2fr 3fr"
                        onClick={() => setCustomerAndCloseModal(customer)}
                      >
                        <Text fontSize="16px">{customer.name}</Text>
                        <Text fontSize="16px">{customer.companyType}</Text>
                      </Grid>
                    ))}
                </Flex>
              )}
            </ModalBody>
            <ModalFooter alignItems="center" display="flex" justifyContent="space-between" pb={8} px={4}>
              <Button
                data-testid="add-customer-button-modal"
                fontSize={16}
                leftIcon={<AddIcon boxSize="14px" />}
                variant="primary"
                onClick={() => setIsAddingNewCustomer(true)}
              >
                {t("addANewCustomer")}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export { AddCustomerModal }
