import { Grid, GridItem, Text } from "@chakra-ui/react"
import { AddIcon, MinimiseIcon } from "@iac/components.icons.react"
import { FC, useContext, useEffect, useState } from "react"

import { Order, ProductModel, api, mockData } from "api"
import { TENANCY_ID } from "common/constants"
import { symbolPosition } from "common/utils/currency.utils"
import { confirmOrder, generateOrder } from "features/inShop/inShopUtils"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

type Props = {
  object: ProductModel
}

const RefundProductItem: FC<Props> = ({ object }) => {
  const { state, dispatch } = useContext(ProductsContext)
  const { refund, makeOrder } = state
  const [product, setProduct] = useState<ProductModel>(object)
  const [isAdded, setIsAdded] = useState(false)
  const [order, setOrder] = useState<Order>(mockData.initOrder)
  const { data } = api.useSalesOrders(TENANCY_ID)

  const changeCount = (type: string): void => {
    const orderProd = order?.orderLines.find((item) => item.id === product.id)
    if (!isAdded && (!makeOrder.orderProduct.id || makeOrder.orderProduct.id !== product.id)) {
      orderProd?.id && dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: orderProd } })
      setIsAdded(true)
    }
    if (type === "add") {
      setProduct((prev): ProductModel => {
        if (object.retailPrice === orderProd?.retailPrice) {
          return product
        }
        dispatch({ type: Types.SetRefundTotal, payload: { total: refund.total - product.retailPrice } })
        const prod = { ...product, count: prev.quantity + 1 }
        makeRefundOrder(prod)
        const changedProducts = makeOrder.order.orderLines.map((item) => (item.id === prod.id ? prod : item))
        dispatch({ type: Types.SetOrder, payload: { order: { ...makeOrder.order, orderLines: changedProducts } } })
        confirmOrder(prod, state, dispatch)

        return prod
      })
    } else if (type === "minus") {
      setProduct((prev): ProductModel => {
        if (prev.quantity === 0) {
          return product
        }
        dispatch({ type: Types.SetRefundTotal, payload: { total: refund.total + product.retailPrice } })
        const prod = { ...product, count: prev.quantity - 1 }
        makeRefundOrder(prod)
        const changedProducts = makeOrder.order.orderLines.map((item) => (item.id === prod.id ? prod : item))
        dispatch({ type: Types.SetOrder, payload: { order: { ...makeOrder.order, orderLines: changedProducts } } })
        confirmOrder(prod, state, dispatch)

        return prod
      })
    }
  }

  const makeRefundOrder = (product: ProductModel): void => {
    const mockProd = order?.orderLines.find((item) => item.id === product.id)
    const count = (mockProd && mockProd.quantity - product.quantity) || 0
    if (count === 0) {
      const filteredProducts = refund.refundOrder.orderLines.filter((item) => item.id !== product.id)
      dispatch({
        type: Types.SetRefundOrder,
        payload: { refundOrder: { ...refund.refundOrder, orderLines: filteredProducts } },
      })
    }
    if (mockProd && refund.refundOrder.orderLines.length) {
      const existProduct = refund.refundOrder.orderLines.find((item) => item.id === product.id)
      const refundOrder = generateOrder(
        [...refund.refundOrder.orderLines, { ...product, quantity: count }],
        mockData.initOrder,
        state
      )
      !existProduct &&
        dispatch({
          type: Types.SetRefundOrder,
          payload: { refundOrder },
        })
    }
    if (mockProd && !refund.refundOrder.orderLines.length) {
      const refundOrder = generateOrder([{ ...product, quantity: count }], mockData.initOrder, state)
      dispatch({
        type: Types.SetRefundOrder,
        payload: { refundOrder },
      })
    }
  }

  useEffect(() => {
    const mockOrder = data?.value?.find((item) => item.orderId === makeOrder.order.orderId)
    mockOrder && setOrder(mockOrder)
    if (JSON.stringify(order) === JSON.stringify(state.makeOrder.order)) {
      dispatch({ type: Types.IsOrderChanged, payload: { isOrderChanged: false } })
    } else {
      dispatch({ type: Types.IsOrderChanged, payload: { isOrderChanged: true } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeOrder.order, order])

  return (
    <Grid
      _hover={{
        background: "rgb(246, 246, 246)",
        cursor: "pointer",
      }}
      data-testid="product"
      style={{
        background: "transparent",
        borderBottom: "1px solid #97979",
        padding: "8px 30px 8px 15px",
        color: "#36495E",
        fontWeight: "bold",
        fontSize: 24,
        width: "100%",
      }}
      templateColumns="4fr 1fr 1fr 1fr 1fr"
      onClick={() => {
        dispatch({
          type: Types.SetRefundProduct,
          payload: {
            refundOrderProduct: object,
          },
        })
        dispatch({ type: Types.SetOpenRefund, payload: { openRefundProduct: true } })
      }}
    >
      <GridItem
      //align="left"
      >
        <Text>{product.name}</Text>
      </GridItem>
      <GridItem alignItems="center" display="flex" justifyContent="center" onClick={(event) => event.stopPropagation()}>
        <MinimiseIcon
          _hover={{ cursor: "pointer" }}
          aria-label="remove-count"
          background="#007abd"
          borderRadius={3}
          color="white"
          onClick={() => {
            dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: object } })
            changeCount("minus")
          }}
        />
        <GridItem px={4}>{product.quantity}</GridItem>
        <AddIcon
          _hover={{ cursor: "pointer" }}
          aria-label="add-count"
          background="#007abd"
          borderRadius={3}
          color="white"
          onClick={() => {
            dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: object } })
            changeCount("add")
          }}
        />
      </GridItem>
      <GridItem>{symbolPosition(Number((product.quantity * product.retailPrice).toFixed(2)))}</GridItem>
      <GridItem>{symbolPosition(product.deposit)}</GridItem>
      <GridItem>{symbolPosition(0)}</GridItem>
    </Grid>
  )
}

export type { Props as RefundProductItemProps }

export { RefundProductItem }
