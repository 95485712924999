import { Box, Button, Fade, Flex, Input, Stack, Tag, TagCloseButton, TagLabel, useDisclosure } from "@chakra-ui/react"
import { CloseCircleIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react"

import { isReturnKey } from "common/utils"

type ConfigSearchProps = {
  placeholder?: string
  buttonTitle?: string
  onSearch: (search: string) => void
  onRemove?: () => void
  filter?: any
  buttonVariant?: string
  width?: string
  paddingLeft?: number
}

const SearchPanel: FC<ConfigSearchProps> = ({
  placeholder,
  onSearch,
  paddingLeft = 4,
  buttonTitle,
  onRemove = () => {},
  filter,
  buttonVariant = "secondary",
  width = "50%",
}) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchInput, setSearchInput] = useState("")

  useEffect(() => {
    searchInput ? onOpen() : onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput])

  return (
    <Flex pl={paddingLeft} pt={10} width={width}>
      <Flex data-testid="search-container" width="100%">
        <Box flex={1} pos="relative">
          <Input
            bg="white"
            borderBottomRightRadius="none"
            borderColor="#e5e5e5"
            borderRightWidth={0}
            borderTopRightRadius="none"
            boxShadow="input"
            data-testid="search-input"
            height="2rem"
            placeholder={placeholder || t("configSearch.placeholder")}
            value={searchInput}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) => setSearchInput(target.value)}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (isReturnKey(e)) onSearch(searchInput)
            }}
          />
          <Box pos="absolute" right="3rem" top={0} zIndex="1">
            <Stack direction="row" my={1} pos="relative" spacing={2} shouldWrapChildren>
              {filter?.id && (
                <Tag>
                  <TagLabel>{filter?.label}</TagLabel>
                  <TagCloseButton onClick={onRemove} />
                </Tag>
              )}
            </Stack>
          </Box>
          <Fade in={isOpen} transition={{ exit: { duration: 0.5 }, enter: { duration: 0 } }}>
            <Button
              _active={{ bg: "gray.300" }}
              _hover={{ bg: "gray.100" }}
              bg="unset"
              borderRadius="4px"
              boxSize="24px"
              color="text.01"
              data-testid="close-button"
              minW="unset"
              padding={0}
              pos="absolute"
              right="14px"
              top="4px"
              zIndex="1"
              onClick={() => {
                setSearchInput("")
                setTimeout(() => onSearch(""), 100)
              }}
            >
              <CloseCircleIcon />
            </Button>
          </Fade>
        </Box>
        <Button
          borderLeftRadius="none"
          data-testid="search-button"
          fontSize={14}
          height="2rem"
          variant={buttonVariant}
          onClick={() => onSearch(searchInput)}
        >
          {buttonTitle || t("configSearch.search")}
        </Button>
      </Flex>
    </Flex>
  )
}

export type { ConfigSearchProps }

export { SearchPanel }
