import {
  Box,
  BoxProps,
  Table as ChakraTable,
  Flex,
  Skeleton,
  TableBodyProps,
  TableCellProps,
  TableColumnHeaderProps,
  TableFooterProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useTheme,
} from "@chakra-ui/react"
import { range } from "ramda"
import { FC, ReactNode } from "react"

import { DEFAULT_ITEMS_PER_PAGE } from "common/constants"

const Header: FC<TableHeadProps> = (props) => <Thead data-testid="Table.Header" {...props} />

const Body: FC<TableBodyProps> = (props) => <Tbody data-testid="Table.Body" flex={1} {...props} />

const Footer: FC<TableFooterProps> = (props) => <Tfoot data-testid="Table.Footer" {...props} />

const TR: FC<TableRowProps> = (props) => <Tr data-testid="Table.TR" {...props} />

const TH: FC<TableColumnHeaderProps> = (props) => (
  <Th _hover={{ bg: props.onClick ? "hover.tab.01" : "" }} data-testid="Table.TH" {...props} />
)

type TDProps = TableCellProps & { colSpan?: number }
const TD: FC<TDProps> = (props) => <Td data-testid="Table.TD" {...props} />

type SkeletonRowsProps = {
  columnWidths: number[]
  rowCount?: number
  error?: Error | null
}

export const SkeletonRows: FC<SkeletonRowsProps> = ({ columnWidths, rowCount = DEFAULT_ITEMS_PER_PAGE, error }) => (
  <>
    {range(0, rowCount).map((i) => (
      <TR key={i} visibility={error ? "hidden" : "visible"}>
        {columnWidths.map((width, i) => (
          <TD key={i}>
            <Skeleton h={6} width={width} />
          </TD>
        ))}
      </TR>
    ))}
  </>
)

type ErrorProps = BoxProps & { error?: Error | null }

const ErrorMessage: FC<ErrorProps> = ({ error, ...boxProps }) =>
  error?.message ? (
    <Flex
      align="center"
      bottom={0}
      color="text.02"
      fontSize="md"
      justify="center"
      left={0}
      position="absolute"
      right={0}
      top={0}
      zIndex={1}
      {...boxProps}
    >
      <Text>{error?.message}</Text>
    </Flex>
  ) : null

type EmptyRowsProps = { count: number }

const EmptyRows: FC<EmptyRowsProps> = ({ count }) => {
  const { sizes } = useTheme()

  return <TR h={`calc(${sizes.tableRow} * ${count})`} />
}

type TableType = {
  Header: FC<TableHeadProps>
  Body: FC<TableBodyProps>
  Footer: FC<TableFooterProps>
  TR: FC<TableRowProps>
  TH: FC<TableColumnHeaderProps>
  TD: FC<TDProps>
  Skeleton: FC<SkeletonRowsProps>
  Error: FC<ErrorProps>
  EmptyRows: FC<EmptyRowsProps>
} & FC<TableProps & { panel?: ReactNode }>

export const Component: TableType = ({ panel, ...props }) => (
  <Box bg="ui.01" borderRadius={4} boxShadow="table" overflow="hidden" position="relative">
    {panel && (
      <Box borderBottomWidth="1px" borderColor="ui.02" p={4} pt={5}>
        {panel}
      </Box>
    )}
    <ChakraTable flex={1} {...props} />
  </Box>
)

Component.Header = Header
Component.Body = Body
Component.Footer = Footer
Component.TR = TR
Component.TH = TH
Component.TD = TD
Component.Skeleton = SkeletonRows
Component.Error = ErrorMessage
Component.EmptyRows = EmptyRows

export { Component as Table }
