import { SearchIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import { ChangeEvent, FC, KeyboardEvent, useState } from "react"

import { isReturnKey } from "common/utils"

export type SearchProps = {
  onSearch: (search: string) => void
  placeholder?: string
  titleButton: string
}

export const Search: FC<SearchProps> = ({ onSearch, placeholder = "Search...", titleButton }) => {
  const [searchInput, setSearchInput] = useState("")

  return (
    <Flex data-testid="search-container">
      <Box flex={1} pos="relative">
        <InputGroup flex={1}>
          <InputLeftElement children={<SearchIcon color="#cac5c6" />} />
          <Input
            background="white"
            borderBottomRightRadius="none"
            borderColor="#e5e5e5"
            borderRightWidth={0}
            borderTopRightRadius="none"
            boxShadow="input"
            data-testid="search-input"
            placeholder={placeholder}
            value={searchInput}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) => setSearchInput(target.value)}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (isReturnKey(e)) onSearch(searchInput)
            }}
          />
        </InputGroup>
      </Box>
      <Button
        borderLeftRadius="none"
        data-testid="search-button"
        variant="primary"
        onClick={() => onSearch(searchInput)}
      >
        {titleButton}
      </Button>
    </Flex>
  )
}
