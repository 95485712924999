import { Box, Button, Flex, FormLabel, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

import { ImageDisplay } from "./ImageDisplay"
import { Image, ImageType } from "api"
import { ImagesSelectModal } from "common/components/index"

type ImagesControlProps = {
  images: Image[]
  onChange: (images: Image[]) => void
  imageType?: ImageType
  isMultiple?: boolean
  label?: string
  libraryImagesIds?: UUID[]
}

const ImagesControl: FC<ImagesControlProps> = ({
  images = [],
  onChange,
  imageType,
  isMultiple = true,
  label,
  libraryImagesIds,
}) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const changeMainImage = (id: string) =>
    onChange(
      images.map((image, index) => ({ imageId: image.imageId, order: index + 1, isMainImage: image.imageId === id }))
    )

  const deleteImage = (id: string) =>
    onChange(
      images
        .filter(({ imageId }) => imageId !== id)
        .map(({ imageId, isMainImage }, index) => ({ imageId, order: index + 1, isMainImage }))
    )

  const addImages = (ids: string[]) =>
    onChange(
      images.length && isMultiple
        ? images.concat(
            ...ids.map((id, index) => ({ imageId: id, order: index + 1 + images.length, isMainImage: false }))
          )
        : ids.map((id, index) => ({ imageId: id, order: index + 1, isMainImage: index === 0 }))
    )

  return (
    <Box borderColor="#E5E5E5" borderWidth="1px" minH="270px" minW="300px" p="4">
      <FormLabel mb={2}>{label ?? t("image_other")}</FormLabel>
      <Flex
        as="form"
        data-testid="ImageUpload"
        direction="row"
        gridGap={3}
        justify="flex-start"
        maxW="635px"
        wrap="wrap"
      >
        {images?.length ? (
          images.map(({ imageId, isMainImage }) => (
            <ImageDisplay
              key={imageId}
              imageId={imageId}
              isMainImage={isMainImage}
              isShowMakeMainImageButton={images.length !== 1}
              onDelete={() => deleteImage(imageId)}
              onMainImageChange={() => changeMainImage(imageId)}
            />
          ))
        ) : (
          <Box bg="#F2F2F2" borderColor="#E5E5E5" borderWidth="1px" h={140} w={140} />
        )}
        <Button data-testid="choose-images-button" size="sm" variant="tertiary" onClick={onOpen}>
          {t("imagesControl.chooseFiles")}
        </Button>
      </Flex>

      <ImagesSelectModal
        imageType={imageType}
        isMultiple={isMultiple}
        isOpen={isOpen}
        libraryImagesIds={libraryImagesIds}
        onClose={onClose}
        onDone={(ids) => addImages(ids)}
      />
    </Box>
  )
}

export { ImagesControl }
