import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { FormField } from "common/components"
import { formStyles } from "common/theme"

type GenerateKeyModalProps = {
  isOpen: boolean
  onClose: () => void
}

type GeneratedType = {
  generatedKey: string
}

const GenerateKeyModal: FC<GenerateKeyModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation()

  const schema = z.object({
    generatedkey: z.string(),
  })

  const {
    formState: { errors },
    register,
  } = useForm<GeneratedType>({
    resolver: zodResolver(schema),
    mode: "all",
    defaultValues: {
      generatedKey: "",
    },
  })

  const formFieldProps = { errors, register }

  const generateKey = () => {}

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton data-testid="close-button" />
        <ModalBody mt={4}>
          <FormField<GeneratedType>
            label={t("storeFronts.generateKey")}
            name="generatedKey"
            {...formFieldProps}
            stackProps={{ ...formStyles.control, width: "80%" }}
          >
            <Input />
          </FormField>
          <Button data-testid="generate-button" onClick={generateKey}>
            {t("storeFronts.generateKey")}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export { GenerateKeyModal }
