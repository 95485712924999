import { useTenancyPermissionsForCurrentUser } from "api/hooks"
import { PermissionType } from "features/auth"
import { useManagedCustomer } from "features/customers"

const useHasTenancyPermissions = (allowIfAny: PermissionType[]) => {
  const { defaultTenancyId } = useManagedCustomer()
  const { data: tenancyPermissions, isLoading } = useTenancyPermissionsForCurrentUser()

  return {
    hasTenancyPermissions: isLoading
      ? false
      : tenancyPermissions.filter(
          (tenancyPermission) =>
            tenancyPermission.tenancyId === defaultTenancyId &&
            tenancyPermission?.permissions?.some(
              (permission) => permission && allowIfAny.includes(permission as PermissionType)
            )
        ).length > 0,
    isLoading,
  }
}

export { useHasTenancyPermissions }
