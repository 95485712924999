import { Box, Input, Text } from "@chakra-ui/react"
import { FC, useMemo } from "react"
import { Column, Row, useExpanded, usePagination, useTable } from "react-table"

import { CatalogProduct, mockData } from "api"
import { Pagination, Table } from "common/components"

type OrderType = {
  id: string
  name: string
  supplierSku: string
  orderQty: number
  rrp: number
}

type OrderProductsTableProps = {
  onOpen: () => void
  products: OrderType[]
  setFilter: (product: CatalogProduct) => void
}

const OrderProductsTable: FC<OrderProductsTableProps> = ({ products, onOpen, setFilter }) => {
  const ProductCell: FC<{ product: OrderType }> = ({ product }) => (
    <Box
      _hover={{ cursor: "pointer", textDecoration: "underline" }}
      onClick={() => {
        onOpen()
        const prod = mockData.catalogProduct.catalogProducts.find((item) => item.id === product.id)
        prod && setFilter(prod)
      }}
    >
      {product.name}
    </Box>
  )

  const columnData: Column<OrderType>[] = [
    {
      Header: "Product",
      accessor: "name",
      Cell: ({ row: { original: product } }) => <ProductCell product={product} />,
    },
    {
      Header: "Supplier SKU",
      accessor: "supplierSku",
      Cell: ({ row }) => <Text>{row.original.supplierSku}</Text>,
    },
    {
      Header: "Order Qty",
      accessor: "orderQty",
      Cell: ({ row }) => (
        <Input
          name="orderQty"
          placeholder="1"
          textAlign="right"
          value={row.original.orderQty}
          width="30%"
          onChange={() => {}}
        />
      ),
    },
    {
      Header: "RRP",
      accessor: "rrp",
      Cell: ({ row }) => (
        <Input placeholder="1" textAlign="right" value={row.original.rrp} width="30%" onChange={() => {}} />
      ),
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, []),
      data: useMemo(() => products, [products]),
    },
    useExpanded,
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<OrderType>): React.ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Table.TD {...cell.getCellProps()} textAlign="right">
            {cell.render("Cell")}
          </Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Box position="relative" width="100%">
      <Table {...getTableProps()} width="100%">
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.TR
              bg="white"
              color="#36495E"
              fontSize="lg"
              textTransform="capitalize"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => {
                if (column.Header === "Status") {
                  return (
                    <Table.TH {...column.getHeaderProps()} textAlign="center">
                      {column.render("Header")}
                    </Table.TH>
                  )
                }

                return (
                  <Table.TH {...column.getHeaderProps()} textAlign="right">
                    {column.render("Header")}
                  </Table.TH>
                )
              })}
            </Table.TR>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>{page.map(renderRow)}</Table.Body>
        <Table.Footer>
          <Table.TR bg="#fafafa">
            <Table.TD colSpan={columnData.length}>
              <Pagination
                currentPage={pageIndex}
                setPageSize={() => {}}
                totalPages={pageOptions.length}
                onPageChange={gotoPage}
              />
            </Table.TD>
          </Table.TR>
        </Table.Footer>
      </Table>
    </Box>
    /* eslint-enable react/jsx-key */
  )
}

export { OrderProductsTable }
