import { useMutation, useQueryClient } from "@tanstack/react-query"

import { TroubleshootingService } from "../generated"
import { TroubleshootingRecord } from "common/utils/troubleshooting.utils"

export const useSendTroubleshootingLogs = (tenancyId: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (log: TroubleshootingRecord[]) =>
      TroubleshootingService.troubleshootingPostAsync({
        tenancyId,
        requestBody: log,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(["troubleshooting"]),
      onError: () => {},
    }
  )
}
