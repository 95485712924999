import type { TenancyCurrency } from "../models/TenancyCurrency";
import { request as __request } from '../core/request';
import { trackPromise } from "react-promise-tracker";

export class TenancyCurrenciesService {

  /**
   * Gets all Tenancy currencies.
   * ## Requires:
   * ### tenancyCurrencies.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async tenancyCurrenciesGetAllAsync({
    select,
    expand,
    parentId,
  }: {
    parentId: string,
    /** Limits the properties returned in the result. **/
    select?: string,
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string,
  }): Promise<{
    readonly '@odata.context'?: string,
    readonly value?: Array<TenancyCurrency>,
  }> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/tenancies/${parentId}/isa/tenancycurrencies`,
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Create Tenancy Currency.
   * ## Requires:
   * ### tenancyCurrencies.Create
   * @returns any Success
   * @throws ApiError
   */
  public static async tenancyCurrenciesPostAsync({
    parentId,
    requestBody,
  }: {
    parentId: string,
    requestBody?: TenancyCurrency,
  }): Promise<({
    readonly '@odata.context'?: string,
  } & TenancyCurrency)> {
    const result = await trackPromise(__request({
      method: 'POST',
      path: `/api/tenancies/${parentId}/isa/tenancycurrencies`,
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Gets Tenancy Currency.
   * ## Requires:
   * ### tenancyCurrencies.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async tenancyCurrenciesGetAsync({
    parentId,
    id,
    select,
    expand,
  }: {
    parentId: string,
    id: string,
    /** Limits the properties returned in the result. **/
    select?: string,
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string,
  }): Promise<{
    readonly '@odata.context'?: string,
  } & TenancyCurrency> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/tenancies/${parentId}/isa/tenancycurrencies/${id}`,
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Update Tenancy Currency.
   * ## Requires:
   * ### tenancyCurrencies.Update
   * @returns any Success
   * @throws ApiError
   */
  public static async tenancyCurrenciesPatchAsync({
    parentId,
    id,
    requestBody,
  }: {
    parentId: string,
    id: string,
    requestBody?: {
      rate: number,
      isDefault: boolean,
      currencyId: string,
    },
  }): Promise<({
    readonly '@odata.context'?: string,
  } & TenancyCurrency)> {
    const result = await trackPromise(__request({
      method: 'PATCH',
      path: `/api/tenancies/${parentId}/isa/tenancycurrencies/${id}`,
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        409: `Conflict`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Delete the Tenancy Currency.
   * ## Requires:
   * ### tenancyCurrencies.Delete
   * @returns any Success
   * @throws ApiError
   */
  public static async tenancyCurrenciesDeleteAsync({
    parentId,
    id,
  }: {
    parentId: string,
    id: string,
  }): Promise<({
    readonly '@odata.context'?: string,
  } & TenancyCurrency)> {
    const result = await trackPromise(__request({
      method: 'DELETE',
      path: `/api/tenancies/${parentId}/isa/tenancycurrencies/${id}`,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

}
