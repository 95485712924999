import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

import { MenuLayout } from "common/components"
import { ProductCategoryIcon, ProductsIcon, UnitOfMeasureIcon, VariantTypesIcon } from "common/theme"
import { route } from "features/app/routes"

const InventoryView: FC = () => {
  const { t } = useTranslation()

  const cards = [
    { link: route.categories.path, title: t("product.productCategory"), icon: ProductCategoryIcon },
    { link: route.products.path, title: t("product.product_other"), icon: ProductsIcon },
    { link: route.variantOptions.path, title: t("variant_option_other"), icon: VariantTypesIcon },
    { link: route.unitsOfMeasure.path, title: t("unitOfMeasure.unitOfMeasure"), icon: UnitOfMeasureIcon },
  ]

  return <MenuLayout cards={cards} pageTitle={t("inventory")} />
}

export { InventoryView }
