import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react"
import { FC, ReactElement, useContext, useMemo } from "react"

import { ProductModel } from "api"
import { ProductsContext } from "features/inShop/product/ProductState/context"

type Props = {
  products?: ProductModel[]
  onProductClick?: (product: ProductModel) => void
  onProductDoubleClick?: (product: ProductModel) => void
  onVariantDoubleClick?: (product: ProductModel) => void
}

export const FoundProductsList: FC<Props> = ({
  products,
  onProductClick = () => true,
  onProductDoubleClick = () => true,
  onVariantDoubleClick = () => true,
}) => {
  const {
    state: {
      globalEntities: {
        tenancyCurrency: {
          currency: { symbol },
        },
      },
    },
  } = useContext(ProductsContext)

  const mappedProducts = useMemo(() => {
    if (products) {
      return Object.values(
        products.reduce(
          (products: { [productId: string]: ProductModel[] }, variant: ProductModel) => ({
            ...products,
            [variant.productId]: [...(products[variant.productId] || []), variant],
          }),
          {}
        )
      )
    }
  }, [products])

  return (
    <Accordion boxShadow="0px 4px 7px rgba(54, 73, 94, 0.1)" width="100%">
      {mappedProducts?.map((product) =>
        product.length > 1 ? (
          <AccordionItem key={product[0].id} border="none">
            <AccordionButton
              _hover={{ background: "#E5F2F8" }}
              backgroundColor="#F5F5F5"
              p={0}
              onDoubleClick={() => onProductDoubleClick({ ...product[0], id: product[0].productId })}
            >
              <ProductSearchItem accordionIcon={<AccordionIcon />} product={product[0]} symbol={symbol} />
            </AccordionButton>
            <AccordionPanel px={0} py="8px">
              <Box display="flex" flexDirection="column">
                {product.map((variant, i) => (
                  <Box
                    key={variant.id}
                    _hover={{ background: "#E5F2F8" }}
                    borderBottom={i < product.length - 1 ? "0.5px dashed #bfb8b8" : "none"}
                    onClick={() => onProductClick(variant)}
                    onDoubleClick={() => onVariantDoubleClick(variant)}
                  >
                    <ProductSearchItem product={variant} symbol={symbol} isVariant />
                  </Box>
                ))}
              </Box>
            </AccordionPanel>
          </AccordionItem>
        ) : (
          <Box
            key={product[0].id}
            _hover={{ background: "#E5F2F8" }}
            backgroundColor="#F5F5F5"
            onClick={() => onProductClick(product[0])}
            onDoubleClick={() => onProductDoubleClick({ ...product[0], id: product[0].productId })}
          >
            <ProductSearchItem product={product[0]} symbol={symbol} />
          </Box>
        )
      )}
    </Accordion>
  )
}

type ProductItemType = {
  product: ProductModel
  accordionIcon?: ReactElement
  symbol: string | null | undefined
  isVariant?: boolean
}

export const ProductSearchItem = ({ product, accordionIcon, symbol, isVariant = false }: ProductItemType) => (
  <Flex
    align="center"
    cursor="pointer"
    display="grid"
    flex="1"
    gridTemplateColumns={`${isVariant ? "3fr" : "2fr"} 4fr 1fr 1fr 0.6fr`}
    height="48px"
    justifyItems="flex-start"
    userSelect="none"
  >
    <Box justifySelf="center">
      {product.images[0]?.thumbnailUrl && (
        <Image borderRadius="5px" boxSize="46px" mr="27px" src={product.images[0].thumbnailUrl} />
      )}
    </Box>
    <Text>{isVariant ? product.receiptDisplayName : product.name}</Text>
    <Box>
      {product.attributes[0]?.color && isVariant && (
        <Box backgroundColor={product.attributes[0]?.color} borderRadius="7px" boxSize="24px" />
      )}
    </Box>
    <Text justifySelf="end">
      {(product.retailPrice + product.tax).toFixed(2)}
      {symbol}
    </Text>
    <Box justifySelf="center">{accordionIcon && <Box>{accordionIcon}</Box>}</Box>
  </Flex>
)
