import { useTranslation } from "@iac/translations.i18n-instance"

import { ADMINS_HAS_APP_PERMISSIONS } from "features/admins"
import { ADMINS } from "features/app/appNames"
import { useHasTenancyPermissions } from "features/auth"

const useAdminsApp = () => {
  const { t } = useTranslation()
  const { hasTenancyPermissions, isLoading } = useHasTenancyPermissions(ADMINS_HAS_APP_PERMISSIONS)

  return { appName: t(ADMINS), hasApp: !isLoading && hasTenancyPermissions, isLoading }
}

export { useAdminsApp }
