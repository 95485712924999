import { Language } from "../generated"

export const initLanguage = {
  id: "",
  name: "",
  isoCode: "",
  supportedAsSystem: false,
  locale: "",
  tenancyLanguages: [],
} as Language
