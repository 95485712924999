import { request as __request } from "../core/request"
import { trackPromise } from "react-promise-tracker"
import { TroubleshootingRecord } from "common/utils/troubleshooting.utils"

export class TroubleshootingService {
  public static async troubleshootingPostAsync({
    tenancyId,
    requestBody,
  }: {
    tenancyId: string
    requestBody: TroubleshootingRecord[]
  }): Promise<{
    readonly "@odata.context"?: string
  }> {
    const result = await trackPromise(
      __request({
        method: "POST",
        path: `/api/tenancies/${tenancyId}/support/logs`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }
}
