import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react"
import { EditIcon, MoreHorizontalSolidIcon, RemoveIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

type TableItemMenuProps = {
  value: string
  width?: string
  onClick?: () => void
  editClick: () => void
  deleteClick?: () => void
  isShowEdit?: boolean
  isShowDelete?: boolean
}

const TableItemMenu: FC<TableItemMenuProps> = ({
  width = "70%",
  value,
  onClick,
  editClick,
  deleteClick,
  isShowEdit = true,
  isShowDelete = true,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <Flex align="center">
      <Text
        _hover={{ textDecoration: onClick ? "underline" : "none", cursor: onClick ? "pointer" : "auto" }}
        data-testid="click-item"
        mr={6}
        width={width}
        onClick={onClick}
      >
        {value}
      </Text>
      <Menu placement="left">
        <MenuButton
          aria-label="Options"
          as={IconButton}
          background="ui.01"
          borderColor="ui.02"
          borderWidth={1}
          data-testid="menu-button"
          icon={<MoreHorizontalSolidIcon color="black" />}
        />
        <MenuList>
          {isShowEdit && (
            <MenuItem data-testid="edit-bttn-table" icon={<EditIcon fontSize={20} />} onClick={editClick}>
              <Text fontSize={16} ml={6}>
                {t("edit")}
              </Text>
            </MenuItem>
          )}
          {isShowDelete && (
            <MenuItem
              color="danger.01"
              data-testid="delete-bttn-table"
              icon={<RemoveIcon fontSize={20} />}
              onClick={deleteClick}
            >
              <Text fontSize={16} ml={6}>
                {t("delete")}
              </Text>
            </MenuItem>
          )}
          {children}
        </MenuList>
      </Menu>
    </Flex>
  )
}

export { TableItemMenu }
