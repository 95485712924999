import { Box, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Tag, Text } from "@chakra-ui/react"
import { EditIcon, MoreHorizontalSolidIcon } from "@iac/components.icons.react"
import { FC, ReactNode, useMemo } from "react"
import { Column, Row, usePagination, useTable } from "react-table"

import { Pagination, Table } from "common/components"
import { PrintIcon } from "common/theme"

type StockPerVariant = {
  id: string
  variant: string
  onHand: number
  reserved: number
  available: number
  backOrder: number
  incoming: number
  leadTime: number
  safetyStock: number
  reorderPoint: number
  status: string
}

type PerVariantTableProps = {
  variants: StockPerVariant[]
}

export const PerVariantTable: FC<PerVariantTableProps> = ({ variants }) => {
  const styles = {
    active: {
      background: "#b8f6cf",
      color: "text.01",
      borderWidth: 1,
      borderColor: "#1fc65a",
    },
    inactive: {
      background: "#FEF1DD",
      color: "#C88720",
      borderRadius: 10,
      border: "1px solid #855A15",
    },
  }

  const columnData: Column<StockPerVariant>[] = [
    {
      Header: "Variant",
      accessor: "variant",
    },
    {
      Header: "On Hand",
      accessor: "onHand",
    },
    {
      Header: "Reserved",
      accessor: "reserved",
    },
    {
      Header: "Available",
      accessor: "available",
    },
    {
      Header: "Backorder",
      accessor: "backOrder",
    },
    {
      Header: "Incoming",
      accessor: "incoming",
    },
    {
      Header: "Lead Time",
      accessor: "leadTime",
    },
    {
      Header: "Safety Stock",
      accessor: "safetyStock",
    },
    {
      Header: "Reorder Point",
      accessor: "reorderPoint",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <Tag fontSize={12} style={row.original.status === "Active" ? styles.active : styles.inactive}>
          {row.original.status}
        </Tag>
      ),
    },
    {
      id: "buttons",
      // @ts-ignore
      Cell: ({ row }) => (
        <Flex align="center" justify="flex-end" position="relative">
          <Box pos="absolute" right={0}>
            <Menu>
              <MenuButton
                aria-label="Options"
                as={IconButton}
                background="transparent"
                icon={<MoreHorizontalSolidIcon />}
              />
              <MenuList>
                <MenuItem icon={<EditIcon fontSize={20} />} py="0.75rem">
                  <Text fontSize={16} marginLeft={6}>
                    Edit
                  </Text>
                </MenuItem>
                <MenuItem icon={<PrintIcon fontSize={20} />} py="0.75rem">
                  <Text fontSize={16} ml={6}>
                    Print QR Code
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      ),
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, []),
      data: useMemo(() => variants, [variants]),
    },
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<StockPerVariant>): ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Table.TD {...cell.getCellProps()} textAlign="right" textTransform="capitalize">
            {cell.render("Cell")}
          </Table.TD>
        ))}
      </Table.TR>
    )
  }

  return (
    <Box position="relative" width="100%">
      <Table {...getTableProps()} width="100%">
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.TR
              bg="white"
              color="#36495E"
              fontSize="md"
              textTransform="capitalize"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <Table.TH {...column.getHeaderProps()} textAlign="right">
                  {column.render("Header")}
                </Table.TH>
              ))}
            </Table.TR>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()} fontSize={14}>
          {page.map(renderRow)}
        </Table.Body>
        <Table.Footer>
          <Table.TR bg="#fafafa">
            <Table.TD colSpan={columnData.length}>
              <Pagination
                currentPage={pageIndex}
                setPageSize={() => {}}
                totalPages={pageOptions.length}
                onPageChange={gotoPage}
              />
            </Table.TD>
          </Table.TR>
        </Table.Footer>
      </Table>
    </Box>
    /* eslint-enable react/jsx-key */
  )
}
