import { CheckIcon, CloseIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Text, useColorModeValue } from "@chakra-ui/react"
import { AdminNotificationIcon, SimpleNotificationIcon } from "@iac/components.icons.react"
import { FC } from "react"

enum NotificationTypes {
  Notification = "notification",
  Admin = "admin",
}

export type NotificationCardProps = {
  title: string
  text: string
  type: string
}

export const NotificationCard: FC<NotificationCardProps> = ({ type, text, title }) => {
  const buttonsColor = useColorModeValue("#2f2f2f", "#fcfcfc")

  return (
    <Flex
      background={useColorModeValue("#ffffff", "#000d14")}
      borderColor={useColorModeValue("#e5e5e5", "#323232")}
      borderRadius={4}
      borderStyle="solid"
      borderWidth="1px"
      flexWrap="wrap"
      fontSize={14}
      mb={2}
      p={2}
      width="100%"
    >
      <Flex>
        {type === NotificationTypes.Admin ? (
          <Box mr={2}>
            <Flex align="center" bg="complimentary.purple" borderRadius="full" h="24px" justify="center" w="24px">
              <AdminNotificationIcon color="white" fontSize={14} />
            </Flex>
          </Box>
        ) : (
          <Box mr={2}>
            <Flex align="center" bg="complimentary.gold" borderRadius="full" h="24px" justify="center" w="24px">
              <SimpleNotificationIcon color="white" fontSize={14} />
            </Flex>
          </Box>
        )}
        <Flex color={useColorModeValue("#2f2f2f", "#fcfcfc")} direction="column" mb={4} width="90%">
          <Text color="inherit" fontWeight={700}>
            {title}
          </Text>
          <Text color="inherit">{text}</Text>
        </Flex>
      </Flex>
      {type === NotificationTypes.Admin && (
        <Flex flex={1}>
          <Button color={buttonsColor} fontSize={12} mr={2} size="xs" variant="ghost">
            <CheckIcon color="4da104" mr={2} />
            Accept
          </Button>
          <Button color={buttonsColor} fontSize={12} size="xs" variant="ghost">
            <CloseIcon color="#b93d30" fontSize={10} mr={2} />
            Reject
          </Button>
        </Flex>
      )}
    </Flex>
  )
}
