import { VariantTypeOption } from "../models/VariantTypeOption";
import { request as __request } from "../core/request";
import { trackPromise } from "react-promise-tracker";

export class VariantOptionsService {
  /**
   * Gets all Variant Type Options.
   * ## Requires:
   * ### variantTypeOptions.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async variantOptionsGetAllAsync({
    select,
    expand,
    parentId,
  }: {
    parentId: string,
    /** Limits the properties returned in the result. **/
    select?: string,
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string,
  }): Promise<{
    readonly '@odata.context'?: string,
    readonly value?: Array<VariantTypeOption>,
  }> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/tenancies/${parentId}/isa/variantoptions`,
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Create Variant Type Option.
   * ## Requires:
   * ### variantTypeOptions.Create
   * @returns any Success
   * @throws ApiError
   */
  public static async variantOptionsPostAsync({
    parentId,
    requestBody,
  }: {
    parentId: string,
    requestBody?: Partial<VariantTypeOption>,
  }): Promise<({
    readonly '@odata.context'?: string,
  } & VariantTypeOption)> {
    const result = await trackPromise(__request({
      method: 'POST',
      path: `/api/tenancies/${parentId}/isa/variantoptions`,
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Gets Variant Type Option.
   * ## Requires:
   * ### variantTypeOptions.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async variantOptionsGetAsync({
    id,
    parentId,
    select,
    expand,
  }: {
    parentId: string,
    id: string,
    /** Limits the properties returned in the result. **/
    select?: string,
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string,
  }): Promise<{
    readonly '@odata.context'?: string,
  } & VariantTypeOption> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/tenancies/${parentId}/isa/variantoptions/${id}`,
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Update Variant Type Option.
   * ## Requires:
   * ### variantTypeOptions.Update
   * @returns any Success
   * @throws ApiError
   */
  public static async variantOptionsPatchAsync({
    parentId,
    id,
    requestBody,
  }: {
    parentId: string,
    id: string,
    requestBody?: Partial<VariantTypeOption>,
  }): Promise<({
    readonly '@odata.context'?: string,
  } & VariantTypeOption)> {
    const result = await trackPromise(__request({
      method: 'PATCH',
      path: `/api/tenancies/${parentId}/isa/variantoptions/${id}`,
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        409: `Conflict`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Delete the Variant Type Option.
   * ## Requires:
   * ### variantTypeOptions.Delete
   * @returns any Success
   * @throws ApiError
   */
  public static async variantOptionsDeleteAsync({
    id,
    parentId,
  }: {
    id: string,
    parentId: string,
  }): Promise<({
    readonly '@odata.context'?: string,
  } & VariantTypeOption)> {
    const result = await trackPromise(__request({
      method: 'DELETE',
      path: `/api/tenancies/${parentId}/isa/variantoptions/${id}`,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }
}