import { InventoryTypes } from "common/inventoryTypes"

export const initProduct = {
  id: "",
  productId: "",
  name: "",
  receiptDisplayName: "",
  quantity: 0,
  stock: 0,
  retailPrice: 0,
  inventoryType: InventoryTypes.Stocked,
  subtotal: 0,
  tax: 0,
  productDiscount: "0",
  discount: 0,
  deposit: 0,
  total: 0,
  discountType: "Amount",
  deliveryDate: "",
  notes: "",
  images: [],
  attributes: [],
}
