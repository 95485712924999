import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { UnitOfMeasure, UnitOfMeasuresService } from "../generated"
import { ODataOptions } from "../handmade"

export const useUnitOfMeasures = (parentId: UUID, options?: ODataOptions) =>
  useQuery(
    ["unitOfMeasures", options],
    () => UnitOfMeasuresService.unitOfMeasuresGetAllAsync({ parentId, ...options }),
    { cacheTime: 0 }
  )

export const useUnitOfMeasure = (parentId: UUID, id: UUID) =>
  useQuery(["unitOfMeasures", parentId, id], () => UnitOfMeasuresService.unitOfMeasuresGetAsync({ parentId, id }), {
    cacheTime: 0,
  })

export const useCreateUnitOfMeasureMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (unitOfMeasure: Partial<UnitOfMeasure>) =>
      UnitOfMeasuresService.unitOfMeasuresPostAsync({ parentId, requestBody: unitOfMeasure }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["unitOfMeasures"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useUpdateUnitOfMeasureMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (unitOfMeasure: Partial<UnitOfMeasure>) =>
      UnitOfMeasuresService.unitOfMeasuresPatchAsync({ parentId, id, requestBody: unitOfMeasure }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["unitOfMeasures"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useDeleteUnitOfMeasureMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(() => UnitOfMeasuresService.unitOfMeasuresDeleteAsync({ parentId, id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["unitOfMeasures"])
      onSuccess()
    },
    onError: () => {},
  })
}
