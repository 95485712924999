import { AspectRatio } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

const defaultMaxWidth = "100%"
const defaultRatio = 16 / 9

type BaseVideoPlayerProps = {
  videoId: string
  playerTitle?: string
}

const YoutubePlayer: FC<BaseVideoPlayerProps> = ({ videoId, playerTitle = "YouTube video player" }) => (
  <iframe
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    frameBorder="0"
    src={`https://www.youtube.com/embed/${videoId}`}
    title={playerTitle}
    allowFullScreen
  />
)

export type VideoPlayerProps = {
  maxWidth?: string
  ratio?: number
} & BaseVideoPlayerProps

export const VideoPlayer: FC<VideoPlayerProps> = ({
  playerTitle,
  videoId,
  maxWidth = defaultMaxWidth,
  ratio = defaultRatio,
}) => {
  const { t } = useTranslation()

  return (
    <AspectRatio aria-label={t("Video player")} maxWidth={maxWidth} ratio={ratio} role="img">
      <YoutubePlayer playerTitle={playerTitle} videoId={videoId} />
    </AspectRatio>
  )
}
