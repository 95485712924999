/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AdminRoles {
    IACADMINISTRATOR = 'IACAdministrator',
    DISTRIBUTOR_ADMINISTRATOR = 'DistributorAdministrator',
    PARTNER_ADMINISTRATOR = 'PartnerAdministrator',
    CUSTOMER_ADMINISTRATOR = 'CustomerAdministrator',
    PARTNER_MANAGER = 'PartnerManager',
    TENANCY_ADMINISTRATOR = 'TenancyAdministrator',
    IDX_ADMINISTRATOR = 'IDxAdministrator',
    MIGRATION_ADMINISTRATOR = 'MigrationAdministrator',
    HELPDESK = 'Helpdesk',
    FINANCE = 'Finance',
    LEGAL = 'Legal',
    CDMADMINISTRATOR = 'CDMAdministrator',
}