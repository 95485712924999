import { Flex, Grid, GridItem, Heading, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useContext, useEffect, useMemo, useState } from "react"

import { AddProductModal } from "./AddProductModal/AddProductModal"
import { ExchangeInfo } from "./ExchangeInfo/ExchangeInfo"
import { ExchangeProductDetails } from "./ExchangeProductDetails/ExchangeProductDetails"
import { ExchangeProductItem } from "./ExchangeProductItem/ExhangeProductItem"
import { Types } from "../inShop/product/ProductState/reducers"
import { ProductModel } from "api"
import { ProductsContext } from "features/inShop/product/ProductState/context"

const ExchangeView: FC = () => {
  const { state, dispatch } = useContext(ProductsContext)
  const { order } = state.makeOrder
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [products, setProducts] = useState(order.orderLines)

  useEffect(() => {
    dispatch({ type: Types.SetOpenExchange, payload: { openExchangeProduct: false } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useMemo(() => setProducts(order.orderLines), [order])

  return (
    <Flex flex={1} paddingTop="1.25rem">
      <Flex align="center" direction="column" flex={1}>
        <Heading color="#36495E" size="lg">
          Exchange
        </Heading>
        <Grid
          //align="center"
          style={{
            background: "transparent",
            padding: "8px 30px 8px 15px",
            color: "#36495E",
            fontWeight: "bold",
            fontSize: 24,
            width: "100%",
          }}
          templateColumns="4fr 1fr 1fr 1fr 1fr"
        >
          <GridItem paddingLeft="1rem" textAlign="left">
            <Text>Product</Text>
          </GridItem>
          <GridItem alignItems="center">
            <Text>QTY</Text>
          </GridItem>
          <GridItem>
            <Text>Paid</Text>
          </GridItem>
          <GridItem>
            <Text>Remaining</Text>
          </GridItem>
          <GridItem>
            <Text>Due</Text>
          </GridItem>
        </Grid>
        <Flex align="flex-start" direction="column" flex={1} width="100%">
          {products.map((item: ProductModel) => (
            <ExchangeProductItem key={item.id} object={item} />
          ))}
        </Flex>
        <ExchangeInfo onOpen={onOpen} />
      </Flex>
      {state.bool.openExchangeProduct && <ExchangeProductDetails />}
      <AddProductModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}

export { ExchangeView }
