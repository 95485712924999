import { Button, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react"
import { CheckmarkIcon } from "@iac/components.icons.react"
import { FC, useContext, useEffect, useState } from "react"

import { mockData } from "api"
import { Payment } from "common/components/Payment/Payment"
import { symbolPosition } from "common/utils/currency.utils"
import { ProductsContext } from "features/inShop/product/ProductState/context"

export const RefundPayment: FC = () => {
  const { state } = useContext(ProductsContext)
  const { refundOrder } = state.refund
  const [showButtons, setShowButtons] = useState(true)
  const [successRefund, setSuccessRefund] = useState(false)
  const [remaining, setRemaining] = useState<number>(0)

  const infoStyles = {
    marginBottom: "1rem",
    alignItems: "center",
    justifyContent: "space-between",
  }

  const buttonStyle = {
    background: "#007abd",
    color: "white",
    fontSize: 35,
    borderRadius: 10,
    height: "8rem",
    width: "15rem",
  }

  useEffect(() => {
    const mockOrder = mockData.order.orders.find((item) => item.orderId === refundOrder.orderId)
    if (mockOrder) {
      const mockRemaining = Number((mockOrder.total - mockOrder.deposit).toFixed(2))
      const refundRemaining = Number((state.makeOrder.order.total - state.makeOrder.order.deposit).toFixed(2))
      setRemaining(Number((refundRemaining - mockRemaining).toFixed(2)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const paymentMethod = (
    <>
      <Heading color="#36495E" size="lg">
        Original Payment Method: Cash
      </Heading>
      <Flex align="center" flex={1} justify="center">
        <Button style={{ ...buttonStyle, marginRight: "4rem" }} onClick={() => setShowButtons(false)}>
          Cash
        </Button>
        <Button style={buttonStyle} onClick={() => setShowButtons(false)}>
          Card
        </Button>
      </Flex>
    </>
  )

  return (
    <Grid height="100%" templateColumns="1fr 1fr">
      <Payment type="refund" />
      <GridItem display="flex" flexDirection="column" padding="4rem 2rem">
        {showButtons && !successRefund && paymentMethod}
        {!showButtons && !successRefund && (
          <Flex direction="column" height="100%" margin="0 auto" width="80%">
            <Flex color="#36495E" direction="column" fontSize={30} fontWeight="bold">
              <Flex style={infoStyles}>
                <Text>{remaining > 0 ? "Remaining" : "Change"}</Text>
                <Text>{symbolPosition(remaining)}</Text>
              </Flex>
            </Flex>
            <Flex align="flex-end" flex={1} justify="center" mb="6rem">
              <Button
                background="#149a45"
                borderRadius={10}
                color="white"
                fontSize={28}
                height="6rem"
                padding="0 3rem"
                onClick={() => setSuccessRefund(true)}
              >
                Process Refund
              </Button>
            </Flex>
          </Flex>
        )}
        {successRefund && !showButtons && (
          <Flex align="center" height="100%" justify="center">
            <Flex color="#149a45" direction="column" justify="center">
              <Text fontSize={40} fontWeight="bold">
                Refund made
              </Text>
              <Text fontSize={40} fontWeight="bold">
                Successfully
              </Text>
              <CheckmarkIcon height="200px" margin="0 auto" marginTop="5rem" width="200px" />
            </Flex>
          </Flex>
        )}
      </GridItem>
    </Grid>
  )
}
