/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Admin } from '../models/Admin';
import type { AdminTenancyPermission } from '../models/AdminTenancyPermission';
import type { TenancyAdmin } from '../models/TenancyAdmin';
import type { TenancyLinks } from '../models/TenancyLinks';
import { request as __request } from '../core/request';

export class AdminsService {

    /**
     * Get Admin.
     * ## Requires:
     * ### admins.Read
     * @returns any Success
     * @throws ApiError
     */
    public static async adminsGetAsync({
        id,
        select,
        expand,
    }: {
        id: string,
        /** Limits the properties returned in the result. **/
        select?: string,
        /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
        expand?: string,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Admin)> {
        const result = await __request({
            useBifrost: true,
            method: 'GET',
            path: `/api/admins/${id}`,
            query: {
                '$select': select,
                '$expand': expand,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get my tenancy permissions
     * @returns any Success
     * @throws ApiError
     */
    public static async adminsGetMeAsync({
        select,
        expand,
    }: {
        /** Limits the properties returned in the result. **/
        select?: string,
        /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
        expand?: string,
    }): Promise<{
        readonly '@odata.context'?: string,
        readonly '@odata.count'?: number,
        readonly value?: Array<AdminTenancyPermission>,
    }> {
        const result = await __request({
            useBifrost: true,
            method: 'GET',
            path: `/api/admins/me`,
            query: {
                '$select': select,
                '$expand': expand,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update Me.
     * @returns any Success
     * @throws ApiError
     */
    public static async adminsUpdateMeAsync({
        requestBody,
    }: {
        requestBody?: TenancyLinks,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Admin)> {
        const result = await __request({
            useBifrost: true,
            method: 'PUT',
            path: `/api/admins/me/tenancylinks`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Creates TenancyLinks for the Admin.
     * ## Requires:
     * ### admins.Create
     * @returns any Success
     * @throws ApiError
     */
    public static async adminsCreateTenancyLinks({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: TenancyLinks,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Admin)> {
        const result = await __request({
            useBifrost: true,
            method: 'POST',
            path: `/api/admins/${id}/tenancylinks`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Deletes TenancyLinks for the Admin.
     * ## Requires:
     * ### admins.Delete
     * @returns any Success
     * @throws ApiError
     */
    public static async adminsDeleteTenancyLinks({
        id,
    }: {
        id: string,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Admin)> {
        const result = await __request({
            useBifrost: true,
            method: 'DELETE',
            path: `/api/admins/${id}/tenancylinks`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Creates the Admin.
     * ## Requires:
     * ### admins.Create
     * @returns any Success
     * @throws ApiError
     */
    public static async tenanciesPostAdminAsync({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: Admin,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Admin)> {
        const result = await __request({
            useBifrost: true,
            method: 'POST',
            path: `/api/tenancies/${id}/admins`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Gets the admins.
     * ## Requires:
     * ### admins.Read
     * @returns any Success
     * @throws ApiError
     */
    public static async tenanciesGetAdminsAsync({
        id,
        select,
        expand,
        filter,
        orderby,
        top,
        skip,
        count = false,
    }: {
        id: string,
        /** Limits the properties returned in the result. **/
        select?: string,
        /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
        expand?: string,
        /** Restricts the set of items returned. For the allowed functions please refer to the Bifrost Swagger document https://bifrost.iamcloud.net/api/swagger/index.html **/
        filter?: string,
        /** Specifies the order in which items are returned. The maximum number of expressions is 5. **/
        orderby?: string,
        /** Limits the number of items returned from a collection. **/
        top?: number,
        /** Excludes the specified number of items of the queried collection from the result. **/
        skip?: number,
        /** Indicates whether the total count of items within a collection are returned in the result. **/
        count?: boolean,
    }): Promise<{
        readonly '@odata.context'?: string,
        readonly '@odata.count'?: number,
        readonly value?: Array<TenancyAdmin>,
    }> {
        const result = await __request({
            useBifrost: true,
            method: 'GET',
            path: `/api/tenancies/${id}/admins`,
            query: {
                '$select': select,
                '$expand': expand,
                '$filter': filter,
                '$orderby': orderby,
                '$top': top,
                '$skip': skip,
                '$count': count,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Gets the Admin.
     * ## Requires:
     * ### admins.Read
     * @returns any Success
     * @throws ApiError
     */
    public static async tenanciesGetAdminAsync({
        id,
        childId,
        select,
        expand,
    }: {
        id: string,
        childId: string,
        /** Limits the properties returned in the result. **/
        select?: string,
        /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
        expand?: string,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & TenancyAdmin)> {
        const result = await __request({
            useBifrost: true,
            method: 'GET',
            path: `/api/tenancies/${id}/admins/${childId}`,
            query: {
                '$select': select,
                '$expand': expand,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Updates the Admin.
     * ## Requires:
     * ### admins.Update
     * @returns any Success
     * @throws ApiError
     */
    public static async tenanciesPatchAdminAsync({
        id,
        childId,
        requestBody,
    }: {
        id: string,
        childId: string,
        requestBody?: Admin,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Admin)> {
        const result = await __request({
            useBifrost: true,
            method: 'PATCH',
            path: `/api/tenancies/${id}/admins/${childId}`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Deletes the Admin.
     * ## Requires:
     * ### admins.Delete
     * @returns any Success
     * @throws ApiError
     */
    public static async tenanciesDeleteAdminAsync({
        id,
        childId,
    }: {
        id: string,
        childId: string,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Admin)> {
        const result = await __request({
            useBifrost: true,
            method: 'DELETE',
            path: `/api/tenancies/${id}/admins/${childId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Restores the Admin.
     * ## Requires:
     * ### admins.Create
     * @returns any Success
     * @throws ApiError
     */
    public static async tenanciesRestoreAdminAsync({
        id,
        childId,
    }: {
        id: string,
        childId: string,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Admin)> {
        const result = await __request({
            useBifrost: true,
            method: 'POST',
            path: `/api/tenancies/${id}/admins/${childId}/restoreadmin`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}