import { BoxProps, Flex, Stack, useDisclosure } from "@chakra-ui/react"
import { Toaster } from "@iac/components.notification"
import { FC } from "react"
import { Outlet, useLocation } from "react-router-dom"

import { Scanner } from "./components/Scanner"
import { AppMenu } from "../AppMenu"
import { Lookups } from "common/components/Lookups/Lookups"
import { useAuth } from "features/auth"
import { AppBar } from "features/routeHeaders"

export const App: FC<BoxProps> = ({ children, ...boxProps }) => {
  const { isAuthenticated } = useAuth()

  const location = useLocation()
  const disclosureProps = useDisclosure()

  const appShell = (
    <>
      <AppBar disclosureProps={disclosureProps} isAuthenticated={isAuthenticated} />
      <AppMenu {...disclosureProps} />
    </>
  )

  const renderAppShell = !location.pathname.includes("configuration")

  const content = (
    <Flex direction="column" flex={1} overflowY="auto" pb={0}>
      <Outlet />
      {/* TODO: Renable with redesigned <Footer /> */}
    </Flex>
  )

  return (
    <Flex flex={1} maxH="100vh" minH="100vh">
      <Stack flex={1} spacing={0}>
        {renderAppShell && appShell}
        {content}
      </Stack>
      <Toaster />
      <Scanner />
      {isAuthenticated && <Lookups />}
    </Flex>
  )
}
