import { Box, BoxProps, Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, ReactNode } from "react"
import { Link } from "react-router-dom"

import { autherrorIllustration, errorIllustration, pageNotFoundIllustration } from "common/illustrations"

type Props = BoxProps & {
  heading?: ReactNode
  message?: ReactNode
  illustration?: string
  containerProps?: BoxProps
}

const messageStyles = {
  color: "text.02",
  fontSize: "xl",
}

const ErrorPage: FC<Props> = ({
  heading = "error.generic.heading",
  message = "error.generic.message",
  illustration = errorIllustration,
  containerProps = {},
  ...boxProps
}) => {
  const { t } = useTranslation()

  return (
    <Flex
      align="center"
      bg="elevation.01"
      borderRadius="md"
      direction="column"
      flex={1}
      justify="center"
      p={8}
      {...boxProps}
    >
      {typeof heading === "string" ? (
        <Text color="text.01" fontSize="2xl" fontWeight="bold">
          {t(heading)}
        </Text>
      ) : (
        heading
      )}

      <Box as="img" boxSize="illustration" src={illustration} {...containerProps} />

      {typeof message === "string" ? <Text {...messageStyles}>{t(message)}</Text> : message}
    </Flex>
  )
}

const AuthError: FC = () => (
  <ErrorPage
    containerProps={{ p: 4 }}
    heading="error.auth.heading"
    illustration={autherrorIllustration}
    message="error.auth.message"
  />
)

// 17216 - This is just a placeholder page for now - needs to be fleshed out in the future
const UnauthorizedError: FC = () => (
  <ErrorPage containerProps={{ p: 4 }} heading="error.unauthorised.heading" message="error.unauthorised.message" />
)

const PageNotFoundError: FC = () => {
  const { t } = useTranslation()

  return (
    <ErrorPage
      containerProps={{ p: 2 }}
      heading="error.pageNotFound.heading"
      illustration={pageNotFoundIllustration}
      message={
        <>
          <Text {...messageStyles}>{t("error.pageNotFound.message")}</Text>
          <Link to="/">
            <Text color="link.01" fontSize="md" m={4} textDecoration="underline">
              {t("error.pageNotFound.link")}
            </Text>
          </Link>
        </>
      }
    />
  )
}

export { PageNotFoundError, UnauthorizedError, AuthError, ErrorPage }

export type { Props as ErrorPageProps }
