import { PopToast } from "@iac/components.notification"
import { useTranslation } from "@iac/translations.i18n-instance"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { flatten, intersection } from "ramda"
import { useAuth } from "react-oidc-context"
import { useDebounce } from "use-debounce"

import { useUserAdmin } from "."
import { Admin, ApiError, EntitiesService, Entity, EntityTypes, Tenancy } from "../generated"
import { DEBOUNCE_DELAY } from "../utils"
import { api } from "api"
import { ODataOptions } from "api/handmade/core/ODataOptions"
import { PermissionType } from "features/auth"
import { CustomerFilterArgs, useCustomerFilter } from "features/customers"

export const useCustomersSearch = (filters: CustomerFilterArgs, options: ODataOptions = {}) => {
  const { isAuthenticated } = useAuth()
  const filter = useCustomerFilter(filters)
  const [filterDebounced] = useDebounce(filter, DEBOUNCE_DELAY)

  return useQuery(
    ["customers", filterDebounced, options.skip, options.top],
    () =>
      EntitiesService.entitiesGetAllAsync({
        filter: filterDebounced,
        orderby: "name",
        ...options,
      }),
    {
      enabled: isAuthenticated,
      keepPreviousData: true,
      // 30 seconds, this stale time could be wrong.
      staleTime: 30000,
    }
  )
}

export const useParentCustomersSearch = (
  { entityType, name }: { entityType: EntityTypes; name?: string },
  options: ODataOptions = {}
) => {
  const { isAuthenticated } = useAuth()

  const filter = [`entitytype lt ${entityType}`, typeof name === "string" && `contains(name, '${name}')`]
    .filter(Boolean)
    .join(" and ")

  const [filterDebounced] = useDebounce(filter, DEBOUNCE_DELAY)
  const { data: tenancies } = api.useTenancyPermissionsForCurrentUser()

  const requiredPermissions: PermissionType[] = ["all.Create", "all.Full", "customers.Create", "customers.Full"]

  const permittedTenancyIds = flatten([tenancies])
    .filter((t) => t && intersection([t.permissions, requiredPermissions]).length > 0)
    .map((t) => t?.tenancyId)

  return useQuery(
    ["entities", filterDebounced],
    async () => {
      const { value: parents = [] } = await EntitiesService.entitiesGetAllAsync({
        filter: filterDebounced,
        orderby: "name",
        ...options,
      })

      return parents.filter((p) => permittedTenancyIds.includes(p.defaultTenancyId!))
    },
    {
      enabled: isAuthenticated,
    }
  )
}

export const useCustomers = () => {
  const { isAuthenticated } = useAuth()

  return useQuery(["entities"], () => EntitiesService.entitiesGetAllAsync({ orderby: "name" }), {
    enabled: isAuthenticated,
  })
}

export const useEntity = ({ id }: { id: UUID }) => {
  const { isAuthenticated } = useAuth()

  return useQuery(["entities", id], () => EntitiesService.entitiesGetAsync({ id }), {
    enabled: isAuthenticated && !!id,
  })
}

export const useEntityOfUser = () => {
  const { data } = useUserAdmin()
  const id = data?.entityId ? data?.entityId : ""

  return useEntity({ id })
}

export type CustomerPayload = Omit<Entity, "defaultTenancyId"> & {
  ownerAdmin: Omit<Admin, "role"> & { role: string }
  defaultTenancy: Omit<Tenancy, "entityId" | "territoryId"> & { admins: Admin[] }
}

export const useCreateCustomerMutation = ({ onSuccess }: { onSuccess: () => void }) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(
    (customer: CustomerPayload) => EntitiesService.entitiesPostAsync({ requestBody: customer as any }),
    {
      onSuccess: () => {
        onSuccess()
        queryClient.invalidateQueries(["entities"])
      },
      onError: (error: ApiError) => {
        PopToast(t("Creation Error"), error.body.error.message, "error")
      },
    }
  )
}
