export const storeFronts = [
  {
    id: "4db4c269-ca1b-4b32-9153-cbef72491508",
    parentId: "",
    name: "BCN Store",
    description: "Physical Store",
    type: "Physical",
    address: "Torrent de L’olla, 4, 0812, BCN",
    status: "Active",
    isDefault: false,
    websiteType: "",
    deletedTS: "",
    currencyId: "",
    languageId: "",
  },
  {
    id: "f0e102aa-12bf-4e75-bfa3-59923b2c4ae9",
    parentId: "",
    name: "Online Shop",
    type: "Virtual",
    description: "Virtual Store",
    address: "www.happy-place.es",
    status: "Active",
    isDefault: false,
    websiteType: "",
    deletedTS: "",
    currencyId: "",
    languageId: "",
  },
]
