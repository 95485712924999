import { useQuery } from "@tanstack/react-query"

import { CountriesService, Country } from "../generated"
import { ODataOptions } from "../handmade"

const SUPPORTED_COUNTRIES = ["Spain", "United Kingdom", "United States"]

export const useCountries = (options?: ODataOptions) =>
  useQuery(["countries"], () => CountriesService.countriesGetAll({ ...options }))

export const useSupportedCountries = (options?: ODataOptions) => {
  const filterSupportedCountries = (countries: Country[]) =>
    countries.filter((country) => country.name && SUPPORTED_COUNTRIES.includes(country.name))

  return useQuery(["countries"], async () => {
    const result = await CountriesService.countriesGetAll({ ...options })

    return {
      ...result,
      value: filterSupportedCountries(result.value || []),
    }
  })
}
