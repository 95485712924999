import { Button, Flex, Grid, GridItem, Text } from "@chakra-ui/react"
import { CheckmarkIcon } from "@iac/components.icons.react"
import { FC, useContext, useEffect, useState } from "react"

import { Payment } from "common/components/Payment/Payment"
import { symbolPosition } from "common/utils/currency.utils"
import { ProductsContext } from "features/inShop/product/ProductState/context"

const RemainingPayment: FC = () => {
  const { state } = useContext(ProductsContext)
  const { remainingOrder } = state.remaining
  const [successPayment, setSuccessPayment] = useState(false)
  const [showButtons, setShowButtons] = useState(true)
  const [isRemaining, setIsRemaining] = useState(false)
  const [remaining, setRemaining] = useState<number>(0)

  const buttonStyle = {
    background: "#007abd",
    color: "white",
    fontSize: 35,
    borderRadius: 10,
    height: "8rem",
    width: "15rem",
  }

  const infoStyles = {
    marginBottom: "1rem",
    alignItems: "center",
    justifyContent: "space-between",
  }

  const paymentMethod = (
    <Flex align="center" flex={1} justify="center">
      <Button
        style={{ ...buttonStyle, marginRight: "4rem" }}
        onClick={() => {
          setShowButtons(false)
          setIsRemaining(true)
        }}
      >
        Cash
      </Button>
      <Button
        style={buttonStyle}
        onClick={() => {
          setShowButtons(false)
          setIsRemaining(true)
        }}
      >
        Card
      </Button>
    </Flex>
  )

  const successfullyPayment = (
    <Flex align="center" height="100%" justify="center">
      <Flex color="#149a45" direction="column" justify="center">
        <Text fontSize={40} fontWeight="bold" textAlign="center">
          Payment made
          <br />
          Successfully
        </Text>
        <CheckmarkIcon height="200px" margin="0 auto" marginTop="5rem" width="200px" />
      </Flex>
    </Flex>
  )

  const receiptType = (
    <Flex align="center" direction="column" flex={1} justify="center">
      <Flex>
        <Button style={{ ...buttonStyle, color: "info.02", marginRight: "6rem" }}>Print Receipt</Button>
        <Button style={buttonStyle}>Email Receipt</Button>
      </Flex>
      <Flex>
        <Button style={{ ...buttonStyle, color: "success.02", marginTop: "6rem", background: "#149a45" }}>
          No Receipt
        </Button>
      </Flex>
    </Flex>
  )

  useEffect(() => {
    setRemaining(Number((remainingOrder.total - remainingOrder.deposit).toFixed(2)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid height="100%" templateColumns="1fr 1fr">
      <Payment type="remaining" />
      <GridItem display="flex" flexDirection="column" padding="4rem 2rem">
        {showButtons && !isRemaining && !successPayment && paymentMethod}
        {!showButtons && !successPayment && isRemaining && (
          <Flex direction="column" height="100%" margin="0 auto" width="80%">
            <Flex color="#36495E" direction="column" fontSize={30} fontWeight="bold">
              <Flex style={infoStyles}>
                <Text>Remaining</Text>
                <Text>{symbolPosition(remaining)}</Text>
              </Flex>
            </Flex>
            <Flex align="flex-end" flex={1} justify="center" mb="6rem">
              <Button
                background="#149a45"
                borderRadius={10}
                color="white"
                fontSize={28}
                height="6rem"
                padding="0 3rem"
                onClick={() => {
                  setSuccessPayment(true)
                  setIsRemaining(false)
                  setTimeout(() => setSuccessPayment(false), 3000)
                }}
              >
                Process Payment
              </Button>
            </Flex>
          </Flex>
        )}
        {!showButtons && successPayment && !isRemaining && successfullyPayment}
        {!successPayment && !showButtons && !isRemaining && receiptType}
      </GridItem>
    </Grid>
  )
}

export { RemainingPayment }
