import { Input, InputGroup, InputLeftElement, Select } from "@chakra-ui/react"
import { ChangeEvent, FC, useEffect, useState } from "react"

type SelectDiscountType = {
  typeDiscount: string
  value: number
  onChangeSelect: (event: ChangeEvent<HTMLSelectElement>) => void
  onChangeInput: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyPress?: (event: any) => void
  maxWidth?: string
}

const SelectDiscount: FC<SelectDiscountType> = ({
  value,
  onChangeSelect,
  onChangeInput,
  onKeyPress,
  typeDiscount,
  maxWidth = "50%",
}) => {
  const [discount, setDiscount] = useState(typeDiscount)
  const discountTypes = [
    { name: "dollar", value: "$" },
    { name: "percent", value: "%" },
  ]

  useEffect(() => {
    setDiscount(typeDiscount)
  }, [typeDiscount])

  return (
    <InputGroup maxWidth={maxWidth} zIndex={0}>
      <InputLeftElement
        children={
          <Select
            style={{
              background: "#007abd",
              paddingLeft: 10,
            }}
            value={discount}
            onChange={onChangeSelect}
          >
            {discountTypes.map(({ name, value }) => (
              <option key={name} value={name}>
                {value}
              </option>
            ))}
          </Select>
        }
        className="currencies"
        width="55px"
      />
      <Input
        color="#36495E"
        fontSize={25}
        height="3rem"
        placeholder="0.00"
        style={{
          background: "white",
          paddingRight: 5,
        }}
        textAlign="right"
        type="number"
        value={value}
        variant="filled"
        onChange={onChangeInput}
        onKeyPress={onKeyPress}
      />
    </InputGroup>
  )
}

export { SelectDiscount }
