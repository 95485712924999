import { AdminRoles, AdminStatuses, EntityTypes, HomeIndicator } from "api//generated"

export const rootAdmin = {
  id: "46d907a5-1fa0-ea11-9cd8-84fdd17eeb35",
  email: "rootadmin@iamcloud.com",
  name: "Root Admin",
  entityId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
  isOwner: true,
  role: AdminRoles.IACADMINISTRATOR,
  homeIndicator: HomeIndicator.IAMCLOUD,
}

export const iamCloudAdmin = {
  id: "46d907a5-1fa0-ea11-9cd8-84fdd17eeb35",
  email: "iamcloudadmin@iamcloud.com",
  name: "IAM Cloud Admin",
  entityId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
  isOwner: false,
  role: AdminRoles.IACADMINISTRATOR,
  homeIndicator: HomeIndicator.IAMCLOUD,
}

export const tenancyAdmin = {
  id: "46d907a5-1fa0-ea11-9cd8-84fdd17eeb35",
  email: "tenancyadmin@iamcloud.com",
  name: "Tenancy Admin",
  entityId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
  isOwner: true,
  role: AdminRoles.TENANCY_ADMINISTRATOR,
  homeIndicator: HomeIndicator.HOME,
}

export const distributorAdmin = {
  id: "46d907a5-1fa0-ea11-9cd8-84fdd17eeb35",
  email: "distributoradmin@iamcloud.com",
  name: "Distributor Admin",
  entityId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
  isOwner: false,
  role: AdminRoles.DISTRIBUTOR_ADMINISTRATOR,
  homeIndicator: HomeIndicator.DISTRIBUTOR,
}

export const consumerAdmin = {
  id: "46d907a5-1fa0-ea11-9cd8-84fdd17eeb35",
  email: "distributoradmin@iamcloud.com",
  name: "Distributor Admin",
  entityId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
  isOwner: false,
  role: AdminRoles.CUSTOMER_ADMINISTRATOR,
  homeIndicator: HomeIndicator.HOME,
}

export const nonExternalPartnerAdmin = {
  id: "46d907a5-1fa0-ea11-9cd8-84fdd17eeb35",
  email: "partneradmin@iamcloud.com",
  name: "Partner Admin",
  entityId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
  isOwner: false,
  role: AdminRoles.PARTNER_ADMINISTRATOR,
  homeIndicator: HomeIndicator.PARTNER,
}

export const externalPartnerAdmin = {
  id: "46d907a5-1fa0-ea11-9cd8-84fdd17eeb35",
  email: "externaladmin@iamcloud.com",
  name: "External Admin",
  entityId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
  isOwner: false,
  role: AdminRoles.PARTNER_ADMINISTRATOR,
  homeIndicator: HomeIndicator.EXTERNAL,
}

export const adminRightsAndTenancies = {
  value: [
    {
      entityId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
      entityType: EntityTypes.IAMCLOUD,
      tenancyId: "3225f4b0-1fa0-ea11-9cd8-84fdd17eeb35",
      adminRole: "IACAdministrator" as AdminRoles.IACADMINISTRATOR,
      status: AdminStatuses.ACCEPTED,
      permissions: [
        "admins.Full",
        "agreements.Full",
        "agreements-admin.Full",
        "agreements-notes.Full",
        "applications.Full",
        "authentication-style.Full",
        "cdm.Create",
        "cdm.Read",
        "cdm.Update",
        "customers.Full",
        "domains.Full",
        "entities.Update",
        "gcr.Full",
        "gcr-public.Full",
        "licence.Full",
        "tenancies.Update",
        "tenancy-settings.Read",
        "territories.Read",
        "users.Full",
        "users-passwords.Full",
        "customer-number.Update",
      ],
      isOwner: true,
      pendingName: "Platform Root",
      tenancyName: "IAMCloud",
      entityName: "IAMCloud",
    },
    {
      entityId: "41563070-723e-4d0f-b957-1f4fde21c7f9",
      entityType: EntityTypes.CUSTOMER,
      tenancyId: "6f60e153-5656-40ec-beba-57f8c64b46cb",
      adminRole: "IACAdministrator" as AdminRoles.IACADMINISTRATOR,
      status: AdminStatuses.ACCEPTED,
      permissions: [
        "admins.Full",
        "agreements.Full",
        "agreements-admin.Full",
        "agreements-notes.Full",
        "applications.Full",
        "authentication-style.Full",
        "cdm.Create",
        "cdm.Read",
        "cdm.Update",
        "customers.Full",
        "domains.Full",
        "entities.Update",
        "gcr.Full",
        "gcr-public.Full",
        "licence.Full",
        "tenancies.Update",
        "tenancy-settings.Read",
        "territories.Read",
        "users.Full",
        "users-passwords.Full",
        "customer-number.Update",
      ],
      isOwner: true,
      pendingName: "Platform Root",
      tenancyName: "Test Identity App",
      entityName: "Test Identity App",
    },
    {
      entityId: "30e3cbe2-4fee-4e10-a239-26e6ac45436a",
      entityType: EntityTypes.CUSTOMER,
      tenancyId: "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55",
      adminRole: "IACAdministrator" as AdminRoles.IACADMINISTRATOR,
      status: AdminStatuses.ACCEPTED,
      permissions: [
        "admins.Full",
        "agreements.Full",
        "agreements-admin.Full",
        "agreements-notes.Full",
        "applications.Full",
        "authentication-style.Full",
        "cdm.Create",
        "cdm.Read",
        "cdm.Update",
        "customers.Full",
        "domains.Full",
        "entities.Update",
        "gcr.Full",
        "gcr-public.Full",
        "licence.Full",
        "tenancies.Update",
        "tenancy-settings.Read",
        "territories.Read",
        "users.Full",
        "users-passwords.Full",
        "customer-number.Update",
      ],
      isOwner: true,
      pendingName: "Platform Root",
      tenancyName: "Happy Place",
      entityName: "Happy Place",
    },
    {
      entityId: "42563070-723e-4d0f-b957-1f4fde21c7f9",
      entityType: EntityTypes.DISTRIBUTOR,
      tenancyId: "3225f4b0-1fa0-ea11-9cd8-84fdd17eeb36",
      adminRole: "IACAdministrator" as AdminRoles.IACADMINISTRATOR,
      status: AdminStatuses.ACCEPTED,
      permissions: [
        "admins.Full",
        "agreements.Full",
        "agreements-admin.Full",
        "agreements-notes.Full",
        "applications.Full",
        "authentication-style.Full",
        "cdm.Create",
        "cdm.Read",
        "cdm.Update",
        "customers.Full",
        "domains.Full",
        "entities.Update",
        "gcr.Full",
        "gcr-public.Full",
        "licence.Full",
        "tenancies.Update",
        "tenancy-settings.Read",
        "territories.Read",
        "users.Full",
        "users-passwords.Full",
        "customer-number.Update",
      ],
      isOwner: true,
      pendingName: "Platform Root",
      tenancyName: "MCAS CDM Lab",
      entityName: "MCAS CDM Lab",
    },
    {
      entityId: "934361f1-7173-4af5-4e76-08d95686e30a",
      entityType: EntityTypes.CUSTOMER,
      tenancyId: "c2a6f73f-0a89-4470-62aa-08d95686e337",
      adminRole: "IACAdministrator" as AdminRoles.IACADMINISTRATOR,
      status: AdminStatuses.ACCEPTED,
      permissions: [
        "admins.Full",
        "agreements.Full",
        "agreements-admin.Full",
        "agreements-notes.Full",
        "applications.Full",
        "authentication-style.Full",
        "cdm.Create",
        "cdm.Read",
        "cdm.Update",
        "customers.Full",
        "domains.Full",
        "entities.Update",
        "gcr.Full",
        "gcr-public.Full",
        "licence.Full",
        "tenancies.Update",
        "tenancy-settings.Read",
        "territories.Read",
        "users.Full",
        "users-passwords.Full",
        "customer-number.Update",
      ],
      isOwner: false,
      pendingName: "Platform Root",
      tenancyName: "Independant Tenancy",
      entityName: "Independent Test Entity",
    },
    {
      entityId: "712ae9d5-ec82-49e7-4e77-08d95686e30a",
      entityType: EntityTypes.CUSTOMER,
      tenancyId: "259faa76-e11d-4fdc-62ab-08d95686e337",
      adminRole: "IACAdministrator" as AdminRoles.IACADMINISTRATOR,
      status: AdminStatuses.ACCEPTED,
      permissions: [
        "admins.Full",
        "agreements.Full",
        "agreements-admin.Full",
        "agreements-notes.Full",
        "applications.Full",
        "authentication-style.Full",
        "cdm.Create",
        "cdm.Read",
        "cdm.Update",
        "customers.Full",
        "domains.Full",
        "entities.Update",
        "gcr.Full",
        "gcr-public.Full",
        "licence.Full",
        "tenancies.Update",
        "tenancy-settings.Read",
        "territories.Read",
        "users.Full",
        "users-passwords.Full",
        "customer-number.Update",
      ],
      isOwner: false,
      pendingName: "Platform Root",
      tenancyName: "Child Tenancy of CharlieSoft",
      entityName: "Child Entity of CharlieSoft",
    },
    {
      entityId: "30a6f1c4-a393-4556-a5fc-08d9833e5264",
      entityType: EntityTypes.CUSTOMER,
      tenancyId: "8a5cf45e-b2d3-43f6-20c2-08d9833740db",
      adminRole: "IACAdministrator" as AdminRoles.IACADMINISTRATOR,
      status: AdminStatuses.ACCEPTED,
      permissions: [
        "admins.Full",
        "agreements.Full",
        "agreements-admin.Full",
        "agreements-notes.Full",
        "applications.Full",
        "authentication-style.Full",
        "cdm.Create",
        "cdm.Read",
        "cdm.Update",
        "customers.Full",
        "domains.Full",
        "entities.Update",
        "gcr.Full",
        "gcr-public.Full",
        "licence.Full",
        "tenancies.Update",
        "tenancy-settings.Read",
        "territories.Read",
        "users.Full",
        "users-passwords.Full",
        "customer-number.Update",
      ],
      isOwner: false,
      pendingName: "Platform Root",
      tenancyName: "CDM Policy Tenancy EUR",
      entityName: "CDMPolicyTestEntityEUR",
    },
    {
      entityId: "de7cca6f-a648-42ed-ad17-08d984163dda",
      entityType: EntityTypes.CUSTOMER,
      tenancyId: "615077c7-2c7f-4a85-cba7-08d984163e01",
      adminRole: "IACAdministrator" as AdminRoles.IACADMINISTRATOR,
      status: AdminStatuses.ACCEPTED,
      permissions: [
        "admins.Full",
        "agreements.Full",
        "agreements-admin.Full",
        "agreements-notes.Full",
        "applications.Full",
        "authentication-style.Full",
        "cdm.Create",
        "cdm.Read",
        "cdm.Update",
        "customers.Full",
        "domains.Full",
        "entities.Update",
        "gcr.Full",
        "gcr-public.Full",
        "licence.Full",
        "tenancies.Update",
        "tenancy-settings.Read",
        "territories.Read",
        "users.Full",
        "users-passwords.Full",
        "customer-number.Update",
      ],
      isOwner: false,
      pendingName: "Platform Root",
      tenancyName: "CDM Policy Tenancy USA",
      entityName: "CDMPolicyTestEntityUSA",
    },
    {
      entityId: "63f397fd-a597-462f-89b4-08d9ae6734b7",
      entityType: EntityTypes.CUSTOMER,
      tenancyId: "adc23c73-2765-430e-c2df-08d9ae673530",
      adminRole: "IACAdministrator" as AdminRoles.IACADMINISTRATOR,
      status: AdminStatuses.ACCEPTED,
      permissions: [
        "admins.Full",
        "agreements.Full",
        "agreements-admin.Full",
        "agreements-notes.Full",
        "applications.Full",
        "authentication-style.Full",
        "cdm.Create",
        "cdm.Read",
        "cdm.Update",
        "customers.Full",
        "domains.Full",
        "entities.Update",
        "gcr.Full",
        "gcr-public.Full",
        "licence.Full",
        "tenancies.Update",
        "tenancy-settings.Read",
        "territories.Read",
        "users.Full",
        "users-passwords.Full",
        "customer-number.Update",
      ],
      isOwner: false,
      pendingName: "Platform Root",
      tenancyName: "CDM Application Componet Configuration Test",
      entityName: "CDMAppComConfT",
    },
    {
      entityId: "0ef36ece-05de-46d2-f890-08da00ef161c",
      entityType: EntityTypes.CUSTOMER,
      tenancyId: "2e38f7de-4b53-43d7-25a7-08da00ef1629",
      adminRole: "IACAdministrator" as AdminRoles.IACADMINISTRATOR,
      status: AdminStatuses.ACCEPTED,
      permissions: [
        "admins.Full",
        "agreements.Full",
        "agreements-admin.Full",
        "agreements-notes.Full",
        "applications.Full",
        "authentication-style.Full",
        "cdm.Create",
        "cdm.Read",
        "cdm.Update",
        "customers.Full",
        "domains.Full",
        "entities.Update",
        "gcr.Full",
        "gcr-public.Full",
        "licence.Full",
        "tenancies.Update",
        "tenancy-settings.Read",
        "territories.Read",
        "users.Full",
        "users-passwords.Full",
        "customer-number.Update",
      ],
      isOwner: false,
      pendingName: "Platform Root",
      tenancyName: "defaultTenancyFriendlyNameN1iHa",
      entityName: "entityNameN1iHa",
    },
  ],
}

export const noAdminRightsOrMultipleTennancies = {
  value: [
    {
      entityId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
      entityType: EntityTypes.IAMCLOUD,
      tenancyId: "3225f4b0-1fa0-ea11-9cd8-84fdd17eeb35",
      adminRole: AdminRoles.HELPDESK,
      status: AdminStatuses.PENDING,
      permissions: [],
      isOwner: false,
      pendingName: "Sea Bass",
      tenancyName: "IAMCloud",
      entityName: "IAMCloud",
    },
  ],
}

export const noAdminRightsWithMultipleTennancies = {
  value: [
    {
      entityId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
      entityType: EntityTypes.IAMCLOUD,
      tenancyId: "3225f4b0-1fa0-ea11-9cd8-84fdd17eeb35",
      adminRole: AdminRoles.HELPDESK,
      status: AdminStatuses.ACCEPTED,
      permissions: [],
      isOwner: true,
      pendingName: "Platform Root",
      tenancyName: "IAMCloud",
      entityName: "IAMCloud",
    },
    {
      entityId: "41563070-723e-4d0f-b957-1f4fde21c7f9",
      entityType: EntityTypes.CUSTOMER,
      tenancyId: "6f60e153-5656-40ec-beba-57f8c64b46cb",
      adminRole: AdminRoles.LEGAL,
      status: AdminStatuses.ACCEPTED,
      permissions: [],
      isOwner: true,
      pendingName: "Platform Root",
      tenancyName: "Test Identity App",
      entityName: "Test Identity App",
    },
  ],
}
