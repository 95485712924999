import constate from "constate"
import { useCallback, useState } from "react"
import { v4 as uuid } from "uuid"

import { AdminDetails, emptyAdminDetails } from "features/admins"

type ManageAdminsState = {
  adminToAdd: AdminDetails | null
}

type ManageAdminsActions = {
  cancelAddingAdmin: () => void
  addNewAdmin: () => void
}

export const useManageAdminsState = (): ManageAdminsState & ManageAdminsActions => {
  const [adminToAdd, setAdminToAdd] = useState<AdminDetails | null>(null)

  return {
    adminToAdd,
    cancelAddingAdmin: useCallback(() => {
      setAdminToAdd(null)
    }, []),
    addNewAdmin: useCallback(() => {
      setAdminToAdd({ id: uuid(), ...emptyAdminDetails })
    }, []),
  }
}

export const [ManageAdminsProvider, useManageAdminsContext] = constate(useManageAdminsState)
