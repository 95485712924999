import { currencies } from "api/mocks/currencies"

export const globalCurrency = currencies[0]

export enum Positions {
  beginning = "Beginning",
  ending = "Ending",
}

export const symbolPosition = (data: number): string =>
  globalCurrency.position === Positions.beginning
    ? `${globalCurrency.symbol} ${detectDelimiter(data)}`
    : `${detectDelimiter(data)} ${globalCurrency.symbol}`

export const detectDelimiter = (data: number): string => String(data).split(".").join(globalCurrency.delimiter)
