export const notes = [
  {
    id: "0f0038bd-801d-4646-9a35-fe63c57be5b2",
    date: "12/04/2021",
    noteTaker: "Yessi",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Habitant morbi tristique senectus et netus et malesuada fames. Et pharetra pharetra massa massa ultricies mi quis hendrerit. Leo in vitae turpis massa sed elementum. Platea dictumst vestibulum rhoncus est pellentesque elit. A arcu cursus vitae congue mauris rhoncus aenean vel elit. Morbi tristique senectus et netus et malesuada. Libero volutpat sed cras ornare arcu dui. Nunc sed blandit libero volutpat. Hendrerit gravida rutrum quisque non tellus orci ac auctor augue. Morbi tristique senectus et netus et. Eu consequat ac felis donec et odio pellentesque diam. Eget egestas purus viverra accumsan in nisl. Neque volutpat ac tincidunt vitae. Vel orci porta non pulvinar neque laoreet suspendisse. Amet commodo nulla facilisi nullam vehicula ipsum. Vitae elementum curabitur vitae nunc sed velit dignissim. Pharetra diam sit amet nisl suscipit adipiscing bibendum est ultricies. Vulputate ut pharetra sit amet aliquam id diam maecenas ultricies. Augue eget arcu dictum varius.",
  },
  {
    id: "29585092-a3bd-4001-9a91-4a2b4e98754c",
    date: "17/03/2021",
    noteTaker: "Yessi",
    note: "and it was a Roaring Success (as in Roaring Drunk) I mean we had Long lines",
  },
  {
    id: "1577bc86-5f9b-49e4-ba2e-da8da51a1f49",
    date: "15/03/2021",
    noteTaker: "Chava",
    note: "Alcohol based exposures through inadvertently consuming hand sanitizer, have been observed to",
  },
  {
    id: "65f1ddc9-25d2-4e96-857b-0d8780c15695",
    date: "14/12/2020",
    noteTaker: "Yessi",
    note: "produce more negative side effects for children than non-alcohol based.",
  },
  {
    id: "84a13ee8-0906-4911-b307-af508460538d",
    date: "08/09/2020",
    noteTaker: "Chava",
    note: "However rare side effects observed among children can be metabolic acidosis,",
  },
  {
    id: "db8a31e8-0174-43b2-b5d6-05d1159051e9",
    date: "15/04/2020",
    noteTaker: "Chava",
    note: "coma, respiratory depression, and hypoglycemia-",
  },
]
