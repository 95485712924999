import { Button, Flex, Input, Select, Switch, Textarea, VStack, useDisclosure } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as z from "zod"

import { HydrateStoreFrontModal } from "./HydrateStoreFrontModal"
import { StoreTypes } from "../storeTypeButton/StoryTypeButton"
import { api } from "api"
import { FormCountrySelect, FormField } from "common/components"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { TENANCY_ID } from "common/constants"
import { Statuses } from "common/statuses"
import { formStyles } from "common/theme"
import { numbersNlettersNdash, numbersNlettersNspecialCharacters, onlyLetters } from "common/validation"
import { route } from "features/app/routes"

type FormValues = {
  name: string
  description: string
  type: string
  countryId: number
  addressLine1: string
  addressLine2: string
  city: string
  region: string
  postCode: string
  status: string
  currencyId: string
  languageId: string
  isDefault: boolean
}

const PhysicalStoreChanges: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: tenancyCurrencies } = api.useTenancyCurrencies(TENANCY_ID, { expand: "currency" })
  const { data: tenancyLanguages } = api.useTenancyLanguages(TENANCY_ID, { expand: "language" })
  const { onOpen: onHydrateOpen, onClose: onHydrateClose, isOpen: isHydrateOpen } = useDisclosure()

  const [name, setName] = useState<string | undefined>("")
  const { data: storeFront } = api.useStoreFront(TENANCY_ID, params.id || "")

  const createStoreFrontMutation = api.useCreateStoreFrontsMutation(
    {
      onSuccess: () => navigate(route.storeFronts.path),
    },
    TENANCY_ID
  )
  const updateStoreFrontMutation = api.useUpdateStoreFrontsMutation(
    {
      onSuccess: () => navigate(route.storeFronts.path),
    },
    TENANCY_ID,
    params.id || ""
  )

  const schema = z.object({
    name: z
      .string()
      .max(30)
      .nonempty({ message: t("validation.required", { field: "name" }) })
      .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") }),
    description: z.string().optional(),
    type: z.string().nonempty({ message: t("validation.select", { field: "type" }) }),
    countryId: z.number().positive({ message: t("validation.select", { field: "country" }) }),
    addressLine1: z
      .string()
      .nonempty({ message: t("validation.required", { field: "address line 1" }) })
      .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") }),
    addressLine2: z
      .string()
      .regex(numbersNlettersNspecialCharacters, { message: t("validation.lettersNnumbersNspecialCharacters") })
      .optional(),
    city: z
      .string()
      .nonempty({ message: t("validation.required", { field: "city" }) })
      .regex(onlyLetters, { message: t("validation.letters") }),
    region: z
      .string()
      .nonempty({ message: t("validation.required", { field: "region" }) })
      .regex(onlyLetters, { message: t("validation.letters") }),
    postCode: z
      .string()
      .nonempty({ message: t("validation.required", { field: "post code" }) })
      .max(10)
      .regex(numbersNlettersNdash, { message: t("validation.lettersNnumbersNdash") }),
    status: z.string().nonempty({ message: t("validation.select", { field: "status" }) }),
    currencyId: z.string().nonempty({ message: t("validation.select", { field: "currency" }) }),
    languageId: z.string().nonempty({ message: t("validation.select", { field: "language" }) }),
    isDefault: z.boolean().optional(),
  })

  const {
    formState: { errors, isDirty, isValid },
    watch,
    getValues,
    setValue,
    register,
    reset,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: "all",
    defaultValues: {
      name: "",
      description: "",
      type: StoreTypes.Physical,
      countryId: 0,
      addressLine1: "",
      addressLine2: "",
      city: "",
      region: "",
      postCode: "",
      status: "",
      currencyId: "",
      languageId: "",
      isDefault: false,
    },
  })

  const watchCountryId = watch("countryId")
  const watchIsDefault = watch("isDefault")
  const isInvalid = !isDirty || !isValid
  const formFieldProps = { errors, register }

  const statuses: string[] = [Statuses.Active, Statuses.Inactive]

  const changesStore = (): void => {
    const formValues = getValues()
    const address = JSON.stringify({
      addressLine1: formValues.addressLine1,
      addressLine2: formValues.addressLine2,
      city: formValues.city,
      region: formValues.region,
      postCode: formValues.postCode,
    })
    const object = {
      name: formValues.name,
      description: formValues.description,
      type: StoreTypes.Physical,
      countryId: +formValues.countryId,
      address,
      status: formValues.status,
      isDefault: formValues.isDefault,
      currencyId: formValues.currencyId,
      languageId: formValues.languageId,
    }
    params.id ? updateStoreFrontMutation.mutate(object) : createStoreFrontMutation.mutate(object)
  }

  useEffect(() => {
    if (params.id && storeFront) {
      const address = JSON.parse(storeFront.address)
      setName(storeFront.name)
      reset({
        name: storeFront.name,
        description: storeFront.description,
        type: storeFront.type,
        countryId: storeFront.countryId || 0,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        region: address.region,
        postCode: address.postCode,
        status: storeFront.status,
        currencyId: storeFront.currencyId,
        languageId: storeFront.languageId,
        isDefault: storeFront.isDefault,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, storeFront])

  return (
    <VStack align="start" data-testid="changes-store-container">
      <PageHeader
        title={
          params.id ? `${t("editItem", { name })} ${t("storeFront")}` : t("addNew", { item: t("storeFront_other") })
        }
      />
      <Flex data-testid="changes-store-form" direction="column" pl={20} pr={4} py={4} width="100%">
        <FormField<FormValues>
          label={t("name")}
          name="name"
          isRequired
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Input data-testid="store-front-name-field" maxLength={30} placeholder={t("storeFronts.placeholder.name")} />
        </FormField>
        <FormField<FormValues>
          label={t("storeFronts.description")}
          name="description"
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Textarea
            data-testid="store-front-description-field"
            placeholder={t("storeFronts.placeholder.description")}
          />
        </FormField>
        <FormField<FormValues>
          label={t("storeFronts.type")}
          name="type"
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input
            data-testid="store-front-type-field"
            value={getValues().type === StoreTypes.Physical ? t("storeFronts.physical") : t("storeFronts.virtual")}
            readOnly
          />
        </FormField>
        <FormField<FormValues>
          errors={errors}
          label={t("country")}
          name="countryId"
          stackProps={{ ...formStyles.control }}
          isRequired
        >
          <FormCountrySelect
            data-testid="store-front-country-select"
            value={watchCountryId.toString()}
            onChange={(val) => setValue("countryId", +val, { shouldDirty: true, shouldValidate: true })}
          />
        </FormField>
        <FormField<FormValues>
          label={t("addressLine", { number: 1 })}
          name="addressLine1"
          isRequired
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Input data-testid="store-front-address-line1-field" />
        </FormField>
        <FormField<FormValues>
          label={t("addressLine", { number: 2 })}
          name="addressLine2"
          {...formFieldProps}
          stackProps={{ ...formStyles.control, width: "40%" }}
        >
          <Input data-testid="store-front-address-line2-field" />
        </FormField>
        <FormField<FormValues> label="City" name="city" isRequired {...formFieldProps} stackProps={formStyles.control}>
          <Input data-testid="store-front-city-field" />
        </FormField>
        <FormField<FormValues>
          label={t("region_label")}
          name="region"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input data-testid="store-front-region-field" />
        </FormField>
        <FormField<FormValues>
          label={t("post_code")}
          name="postCode"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input data-testid="store-front-post-code-field" maxLength={10} />
        </FormField>
        <FormField<FormValues>
          label={t("status_label")}
          name="status"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Select data-testid="store-front-status-select">
            <option value="" disabled>
              {t("select")}
            </option>
            {statuses.map((item) => (
              <option key={item} value={item}>
                {t(`statuses.${item}`)}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField<FormValues>
          label={t("storeFronts.currency")}
          name="currencyId"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Select data-testid="store-front-currency-select">
            <option value="" disabled>
              {t("select")}
            </option>
            {tenancyCurrencies?.value?.map(({ currencyId, currency: { localName } }) => (
              <option key={currencyId} value={currencyId}>
                {localName}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField<FormValues>
          label={t("storeFronts.language")}
          name="languageId"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Select data-testid="store-front-language-select">
            <option value="" disabled>
              {t("select")}
            </option>
            {tenancyLanguages?.value?.map(({ languageId, language: { name } }) => (
              <option key={languageId} value={languageId}>
                {name}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField<FormValues>
          label={t("storeFronts.defaultProducts")}
          name="isDefault"
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Switch data-testid="store-front-default-products-switch" isChecked={watchIsDefault} />
        </FormField>
        <Flex align="center" gridGap="12" justify="flex-start" py={4}>
          <Button
            data-testid="store-front-done-button"
            disabled={isInvalid}
            variant="primary"
            width="5rem"
            onClick={changesStore}
          >
            {t("done")}
          </Button>
          <Button data-testid="store-front-hydrate-button" onClick={onHydrateOpen}>
            {t("storeFronts.hydrateStoreFront")}
          </Button>
          <Button
            data-testid="store-front-cancel-button"
            variant="secondary"
            width="5rem"
            onClick={() => navigate(route.storeFronts.path)}
          >
            {t("cancel")}
          </Button>
        </Flex>
      </Flex>
      <HydrateStoreFrontModal isOpen={isHydrateOpen} onClose={onHydrateClose} />
    </VStack>
  )
}

export { PhysicalStoreChanges }
