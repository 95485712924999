import { faker } from "@faker-js/faker"

import { allEntities, entitiesByType } from "./entities"
import {
  AdminRoles,
  AdminStatuses,
  AdminTenancyPermission,
  Entity,
  HomeIndicator,
  Tenancy,
  TenancyStatuses,
} from "../generated"
import { OWNER_ADMIN_ID } from "api/bifrost-mock/constants"

faker.seed(0)

const entityToTenancy = (entity: Entity): Tenancy => ({
  id: entity.defaultTenancyId!,
  friendlyName: entity.name,
  legalName: entity.name,
  status: TenancyStatuses.ACTIVE,
  territoryId: entity.territoryId,
  entityId: entity.id!,
  countryId: 1,
})

export const tenancies: Tenancy[] = allEntities.map(entityToTenancy)

const entityToTenancyLink = (entity: Entity, overrides: Partial<AdminTenancyPermission>): AdminTenancyPermission => ({
  tenancyId: entity.defaultTenancyId!,
  tenancyName: entity.name,
  entityName: entity.name,
  entityId: entity.id,
  status: AdminStatuses.ACCEPTED,
  isOwner: true,
  ...overrides,
})

export const tenancyLinks: AdminTenancyPermission[] = [
  entityToTenancyLink(entitiesByType.root, {
    adminRole: "IACAdministrator" as AdminRoles.IACADMINISTRATOR,
  }),
  entityToTenancyLink(entitiesByType.distributor, {
    adminRole: AdminRoles.IDX_ADMINISTRATOR,
    status: AdminStatuses.PENDING,
    tenancyId: entitiesByType.root.defaultTenancyId!,
  }),
  entityToTenancyLink(entitiesByType.partner, {
    adminRole: AdminRoles.LEGAL,
    status: AdminStatuses.REJECTED,
    isOwner: false,
    tenancyId: entitiesByType.root.defaultTenancyId!,
  }),
  entityToTenancyLink(entitiesByType.consumer, {
    adminRole: AdminRoles.HELPDESK,
    tenancyId: entitiesByType.root.defaultTenancyId!,
  }),
]

export const tenanciesAdmins = tenancies.map(
  (tenancy, i) =>
    ({
      id: faker.datatype.uuid(),
      tenancyId: tenancy.id,
      adminId: i % 2 === 0 ? OWNER_ADMIN_ID : faker.datatype.uuid(),
      role: "IACAdministrator" as AdminRoles.IACADMINISTRATOR,
      status: AdminStatuses.ACCEPTED,
      deletedTS: null,
      homeIndicator: HomeIndicator.IAMCLOUD,
      pendingName: "Pending",
    } as const)
)
