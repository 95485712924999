import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

type OutOfStockModalProps = {
  isOpen: boolean
  onClose: () => void
  onClick: () => void
}

const OutOfStockModal: FC<OutOfStockModalProps> = ({ isOpen, onClose, onClick }) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} size="2xl" isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={8}>
        <ModalHeader color="text-01">{t("outOfStockModalTitle")}</ModalHeader>
        <ModalCloseButton
          borderColor="interactive-05"
          borderRadius={50}
          borderWidth={1}
          color="interactive-05"
          size="sm"
        />
        <ModalBody>
          <Text color="black" fontSize={20}>
            {t("outOfStockModalContent")}
          </Text>
        </ModalBody>
        <ModalFooter alignItems="center" justifyContent="flex-start">
          <Button fontWeight="normal" mr="3rem" size="sm" variant="primary" onClick={onClick}>
            {t("outOfStockButtonYes")}
          </Button>
          <Button fontWeight="normal" size="sm" variant="primary" onClick={onClose}>
            {t("outOfStockButtonNo")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export { OutOfStockModal }
