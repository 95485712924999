import { initLanguage } from "./initLanguage"

export const initTenancyLanguage = {
  id: "",
  languageId: "",
  tenancyId: "",
  isEnabled: false,
  isSystem: false,
  isDefault: false,
  language: {
    ...initLanguage,
  },
}
