import { SalesOrderStatus } from "../generated"

export const initOrder = {
  orderId: "",
  orderNumber: 0,
  id: "",
  tenancyId: "",
  customerId: "",
  storeFrontId: "",
  deliveryDate: "",
  currencyId: "",
  orderDate: "",
  orderLines: [],
  status: SalesOrderStatus.PENDING,
  subtotal: 0,
  tax: 0,
  deposit: 0,
  discount: 0,
  orderDiscount: 0,
  discountType: "Amount",
  paymentType: "",
  paidAmount: 0,
  sellerName: "",
  notes: "",
  change: 0,
  total: 0,
}
