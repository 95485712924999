import { useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { UnitOfMeasuresTable } from "./unitMeasuresTable/UnitOfMeasuresTable"
import { UnitOfMeasure, api, mockData } from "api"
import { ConfirmModal, PageHeader, PageLayout } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"
import { route } from "features/app/routes"

const UNIT_OF_MEASURES_TABLE = "UNIT_OF_MEASURES_TABLE"

const UnitOfMeasureView: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [unitMeasure, setUnitMeasure] = useState<UnitOfMeasure>(mockData.initUnitOfMeasure)
  const [unitsMeasure, setUnitsMeasure] = useState<UnitOfMeasure[] | undefined>([])
  const deleteUnitOfMeasureMutation = api.useDeleteUnitOfMeasureMutation(
    { onSuccess: () => onClose() },
    TENANCY_ID,
    unitMeasure.id
  )
  const [storedPageSize, setStoredPageSize] = useStoredPageSize(UNIT_OF_MEASURES_TABLE)

  const [options, setOptions] = useState({
    skip: 0,
    top: storedPageSize ?? DEFAULT_ITEMS_PER_PAGE,
  })

  const { data, isLoading } = api.useUnitOfMeasures(TENANCY_ID, {
    top: options.top,
    skip: options.skip,
    count: true,
  })

  const refetchUnitOfMeasures = async (top: number, skip: number) => {
    setOptions({
      ...options,
      top,
      skip,
    })
    setStoredPageSize(top)
  }

  const deleteUnit = (): void => deleteUnitOfMeasureMutation.mutate()

  useEffect(() => {
    if (data?.value) {
      setUnitsMeasure(data.value)
    }
  }, [data])

  useEffect(() => {
    if (search.length === 0) {
      setUnitsMeasure(data?.value)
    } else {
      const findMeasure = data?.value?.filter(
        (item) =>
          item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
          item.shortName?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      setUnitsMeasure(findMeasure)
    }
    // eslint-disable-next-line
  }, [search])

  return (
    <>
      <PageHeader
        buttonTitle={t("unitOfMeasure.addNewUnit")}
        link="/inventory"
        title={t("unitOfMeasure.unitOfMeasures")}
        isArrow
        onClick={() => navigate(route.addUnitOfMeasure.path)}
      />
      <PageLayout
        confirmModal={
          <ConfirmModal
            isOpen={isOpen}
            name={unitMeasure.name}
            type="unit"
            isDelete
            onClick={deleteUnit}
            onClose={onClose}
          />
        }
        setSearch={setSearch}
        table={
          <UnitOfMeasuresTable
            itemsPerPage={options.top}
            loading={isLoading}
            refetch={refetchUnitOfMeasures}
            setUnitMeasure={setUnitMeasure}
            units={unitsMeasure || []}
            onOpen={onOpen}
          />
        }
      />
    </>
  )
}

export { UnitOfMeasureView }
