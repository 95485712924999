import { differenceInMinutes } from "date-fns"

import { ProductAPI } from "api"

const TIME_TO_SAVE_PREVIOUS_VALUES_IN_MINUTES = 180
const DEFAULT_ADD_PRODUCT_VALUES = "DEFAULT_ADD_PRODUCT_VALUES"

type DefaultAddProductValuesType = {
  values: Partial<ProductAPI>
  timestamp: string
}

export const getDefaultAddProductValues = (): Partial<ProductAPI> => {
  const defaultAddProductValues: DefaultAddProductValuesType = JSON.parse(
    localStorage.getItem(DEFAULT_ADD_PRODUCT_VALUES) as string
  )
  if (defaultAddProductValues) {
    const areValuesAvailable =
      differenceInMinutes(new Date(), new Date(defaultAddProductValues.timestamp)) <
      TIME_TO_SAVE_PREVIOUS_VALUES_IN_MINUTES

    return areValuesAvailable ? defaultAddProductValues.values : {}
  }

  return {}
}

export const setDefaultAddProductValues = (productAPI: Partial<ProductAPI>) => {
  localStorage.setItem(
    DEFAULT_ADD_PRODUCT_VALUES,
    JSON.stringify({
      values: {
        supplierId: productAPI.supplierId,
        countryOfOriginId: productAPI.countryOfOriginId,
        productCategoryId: productAPI.productCategoryId,
        unitOfMeasureId: productAPI.unitOfMeasureId,
        inventoryType: productAPI.inventoryType,
        sellingTaxCategoryId: productAPI.sellingTaxCategoryId,
        storeFronts: productAPI.storeFronts,
      },
      timestamp: new Date().toString(),
    } as DefaultAddProductValuesType)
  )
}
