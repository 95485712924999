import { storeFronts } from "./storeFronts"
import { SalesOrderStatus } from "../generated"

export const ordersDashboard = [
  {
    orderId: 23457,
    storeFront: storeFronts[0],
    orderDate: "16/06/21 20:37",
    orderValue: 30.0,
    status: SalesOrderStatus.PENDING,
  },
  {
    orderId: 23456,
    storeFront: storeFronts[0],
    orderDate: "16/06/21 20:15",
    orderValue: 5.0,
    status: SalesOrderStatus.PAID,
  },
  {
    orderId: 23455,
    storeFront: storeFronts[0],
    orderDate: "16/06/21 19:37",
    orderValue: 25.56,
    status: SalesOrderStatus.PENDING,
  },
  {
    orderId: 23454,
    storeFront: storeFronts[1],
    orderDate: "16/06/21 19:07",
    orderValue: 18.78,
    status: SalesOrderStatus.PAID,
  },
  {
    orderId: 23453,
    storeFront: storeFronts[0],
    orderDate: "16/06/21 17:27",
    orderValue: 79.99,
    status: SalesOrderStatus.PENDING,
  },
  {
    orderId: 23452,
    storeFront: storeFronts[1],
    orderDate: "16/06/21 17:01",
    orderValue: 50.0,
    status: SalesOrderStatus.PENDING,
  },
  {
    orderId: 23451,
    storeFront: storeFronts[1],
    orderDate: "16/06/21 16:28",
    orderValue: 3.0,
    status: SalesOrderStatus.CANCELED,
  },
]
