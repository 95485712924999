import { Box, Button, Input } from "@chakra-ui/react"
import { ChangeEvent, FC, KeyboardEvent, useState } from "react"

import { isReturnKey } from "common/utils"

type SearchForProductsProps = {
  onSearch: (search: string) => void
  titleButton: string
}

const SearchForProducts: FC<SearchForProductsProps> = ({ onSearch, titleButton }) => {
  const [searchInput, setSearchInput] = useState("")

  return (
    <>
      <Box data-testid="searchContainer" flex={1}>
        <Input
          background="white"
          borderBottomRightRadius="none"
          borderColor="#e5e5e5"
          borderRightWidth={0}
          borderTopRightRadius="none"
          boxShadow="input"
          data-testid="searchInput"
          flex={1}
          placeholder="Enter a search term"
          value={searchInput}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) => setSearchInput(target.value)}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (isReturnKey(e)) onSearch(searchInput)
          }}
        />
      </Box>
      <Button
        borderRadius="0 5px 5px 0"
        data-testid="searchButton"
        variant="secondary"
        width="100px"
        onClick={() => onSearch(searchInput)}
      >
        {titleButton}
      </Button>
    </>
  )
}

export type { SearchForProductsProps }

export { SearchForProducts }
