import { PopToast } from "@iac/components.notification"
import { useTranslation } from "@iac/translations.i18n-instance"
import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { useEntityOfUser } from "."
import { Load } from "./types"
import { Admin, AdminRoles, ApiError, EntitiesService, Entity, TenancyLink, TenancyLinks } from "../generated"
import { AdminAndTenancyAdmin, AdminsService, TenancyAdmin } from "api/handmade"
import { isRootEntity } from "common/utils"
import { useAuth } from "features/auth"
import { useManagedCustomer } from "features/customers"

// export const useAdmin = () => {
//   // TODO Add a single hook for loading and saving a single Admin/TenancyAdmin
// }

const filterByIntersection = (data: { [key: string]: TenancyAdmin[] | null }): TenancyAdmin[] => {
  let keyForBaseSet: string | null = null
  let dataToFilter: TenancyAdmin[] | null = null

  Object.keys(data).forEach((keyForSet: string) => {
    if (data[keyForSet]) {
      if (!keyForBaseSet) {
        keyForBaseSet = keyForSet
        dataToFilter = data[keyForSet]
      } else {
        if (dataToFilter && data[keyForSet]) {
          const idsToFilterBy = data[keyForSet]?.map((admin) => admin.id)

          dataToFilter = dataToFilter.filter((admin) => !idsToFilterBy?.includes(admin.id))
        }
      }
    }
  })

  return dataToFilter ?? []
}

// Admins and TenancyAdmins are effectively the same, Admins is used in edge cases where the tenancy is not relevant.
// A query for multiple admins is always a TenancyAdmins query.
export const useAdmins = (
  filterBy: { entityOfUser?: boolean; managedCustomer?: boolean } = {
    entityOfUser: true,
    managedCustomer: false,
  },
  excludeRootAdmins = false,
  excludeSelf = false
): Load<TenancyAdmin[]> => {
  const { isAuthenticated } = useAuth()
  const { data: entityOfUser } = useEntityOfUser()
  const managedCustomer = useManagedCustomer()
  const { user: loggedInUser } = useAuth()

  // Normalise ids
  const entityOfUserTenancyId = entityOfUser?.defaultTenancyId ?? ""
  const managedCustomerTenancyId = managedCustomer?.defaultTenancyId ?? ""
  const loggedInUserId = loggedInUser?.profile.admin ? (loggedInUser?.profile.admin as UUID) : ""

  // Fetch all necessary data
  const { data: dataForEntityOfUser, isInitialLoading: usersEntityIsLoading } = useQuery(
    ["admins", entityOfUserTenancyId],
    () => AdminsService.tenanciesGetAdminsAsync({ id: entityOfUserTenancyId }),
    {
      enabled: isAuthenticated && !!filterBy?.entityOfUser && !!entityOfUserTenancyId,
    }
  )
  const { data: dataForManagedCustomer, isInitialLoading: managedCustomerIsLoading } = useQuery(
    ["admins", managedCustomerTenancyId],
    () => AdminsService.tenanciesGetAdminsAsync({ id: managedCustomerTenancyId }),
    {
      enabled: isAuthenticated && !!filterBy?.managedCustomer && !!managedCustomerTenancyId,
    }
  )
  const { data: loggedInUserData, isInitialLoading: loggedInUserIsLoading } = useQuery(
    ["admins", loggedInUserId],
    () => AdminsService.adminsGetAsync({ id: loggedInUserId }),
    {
      enabled: isAuthenticated && excludeSelf,
    }
  )
  const { data: entitiesData, isInitialLoading: entitiesIsLoading } = useQuery(
    ["entities"],
    () => EntitiesService.entitiesGetAllAsync({ orderby: "name" }),
    {
      enabled: isAuthenticated && excludeRootAdmins,
    }
  )

  // Apply necessary filters
  let filteredData = filterByIntersection({
    entityOfUser: filterBy.entityOfUser ? dataForEntityOfUser?.value ?? [] : null,
    managedCustomer: filterBy.managedCustomer ? dataForManagedCustomer?.value ?? [] : null,
  })
  if (excludeRootAdmins) {
    const rootEntity = entitiesData?.value?.find((entity) => isRootEntity(entity))
    filteredData = filteredData.filter((admin) => admin.entityId && admin.entityId !== rootEntity?.id)
  }
  if (excludeSelf) {
    filteredData = filteredData.filter((admin) => admin.entityId && admin.entityId !== loggedInUserData?.id)
  }

  return {
    data: filteredData,
    isLoading: usersEntityIsLoading || managedCustomerIsLoading || entitiesIsLoading || loggedInUserIsLoading,
  }
}

export const useTenancyPermissionsForCurrentUser = () => {
  const { cloudVaultId, isAuthenticated, user } = useAuth()
  const userId = cloudVaultId ? (cloudVaultId as UUID) : ""

  const { data, isInitialLoading, error, refetch } = useQuery(
    ["tenancyPermissions", userId],
    () => AdminsService.adminsGetMeAsync({}),
    {
      enabled: isAuthenticated,
      keepPreviousData: true,
      // 30 seconds, this stale time could be wrong.
      staleTime: 30000,
    }
  )

  // error is returned by useQuery as "unknown" by default.
  const typedError = error as ApiError | undefined

  return {
    data: data?.value ?? [],
    isLoading: isInitialLoading,
    error: typedError,
    refetch,
  }
}

/*
 * TODO Replace all hooks below this point with a single hook for each data type to return.
 */

export type AdminWithTenancies = Admin & {
  readonly "@odata.context"?: string
  tenancyLinks?: TenancyLink[]
}

export const useAdmin = ({ id, expand }: { id: UUID; expand?: string }): UseQueryResult<AdminWithTenancies, ApiError> =>
  useQuery(["admins", id, expand], () => AdminsService.adminsGetAsync({ id, expand }), {
    enabled: !!id,
  })

export const useUserAdmin = (): UseQueryResult<AdminWithTenancies, ApiError> => {
  const { cloudVaultId } = useAuth()

  return useAdmin({ id: cloudVaultId ?? "" })
}

export const useManageCustomerAdmin = (enabled = false): UseQueryResult<AdminWithTenancies, ApiError> => {
  const { cloudVaultId, user, isAuthenticated } = useAuth()
  const id = cloudVaultId as string

  return useQuery(["admins", id], () => AdminsService.adminsGetAsync({ id }), {
    enabled: enabled && !!id && isAuthenticated,
  })
}

export const useTenancyAdmins = (id: UUID | null | undefined) => {
  const { isAuthenticated } = useAuth()
  const { t } = useTranslation()

  return useQuery(["tenancies", "admins", id], () => AdminsService.tenanciesGetAdminsAsync({ id: id! }), {
    enabled: isAuthenticated && !!id,
    onError: ({ body: { error } }: ApiError) => {
      PopToast(t("Error getting admins"), `${error.message} ${error.correlationId}`, "error")
    },
  })
}

export const useSuggestedAdmins = () => {
  const { data: entityOfUser } = useEntityOfUser()

  return useTenancyAdmins(entityOfUser?.defaultTenancyId ?? "").data?.value ?? []
}

export const useSuggestedTenancyAdmins = () => {
  const { defaultTenancyId } = useManagedCustomer()

  const suggestedAdmins = useSuggestedAdmins()
  const tenancyAdminIds = (useTenancyAdmins(defaultTenancyId).data?.value ?? []).map(({ id }: TenancyAdmin) => id)

  return suggestedAdmins.filter(({ id }) => !tenancyAdminIds.includes(id))
}

export const useLinkAdminMutation = () => {
  const queryClient = useQueryClient()
  const { defaultTenancyId: tenancyId } = useManagedCustomer()
  const { t } = useTranslation()

  return useMutation(
    ({ adminId, role }: { adminId: UUID; role: AdminRoles }) =>
      AdminsService.adminsCreateTenancyLinks({
        id: adminId,
        requestBody: {
          links: [{ tenancyId: tenancyId!, role }],
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tenancies"])
        queryClient.invalidateQueries(["admins"])
      },
      onError: (error: ApiError) => {
        PopToast(t("Tenancy link error"), error.body.error.message, "error")
      },
    }
  )
}

export const useDeleteAdminMutation = (admin: AdminAndTenancyAdmin) => {
  const queryClient = useQueryClient()
  const { defaultTenancyId: id } = useManagedCustomer()
  const { t } = useTranslation()

  return useMutation(() => AdminsService.tenanciesDeleteAdminAsync({ id: id!, childId: admin.id! }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["tenancies"])
      queryClient.invalidateQueries(["admins"])
    },
    onError: (error: ApiError) => {
      PopToast(t("Admin delete error"), error.body.error.message, "error")
    },
  })
}

export const useSaveAdminMutation = ({ onSuccess }: { onSuccess: () => void }) => {
  const queryClient = useQueryClient()
  const { defaultTenancyId: id } = useManagedCustomer()
  const { t } = useTranslation()

  return useMutation(
    ({ isNew, ...admin }: AdminAndTenancyAdmin) =>
      isNew
        ? AdminsService.tenanciesPostAdminAsync({ id: id!, requestBody: admin })
        : AdminsService.tenanciesPatchAdminAsync({
            id: id!,
            childId: admin.id!,
            requestBody: { role: admin.role } as Admin,
          }),
    {
      onSuccess: () => {
        onSuccess()

        queryClient.invalidateQueries(["tenancies"])
        queryClient.invalidateQueries(["admins"])
      },
      onError: (error: ApiError) => {
        PopToast(t("Admin save error"), error.body.error.message, "error")
      },
    }
  )
}

export const useUpdateAdminRoleMutation = (admin: Admin) => {
  const queryClient = useQueryClient()
  const { defaultTenancyId } = useManagedCustomer()

  return useMutation(
    ({ role, tenancyId }: { role: AdminRoles; tenancyId?: UUID }) =>
      AdminsService.tenanciesPatchAdminAsync({
        id: tenancyId ?? defaultTenancyId!,
        childId: admin.id!,
        requestBody: { role } as Admin,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tenancies"])
        queryClient.invalidateQueries(["admins"])
      },
    }
  )
}

export const useMakeOwnerMutation = (admin: AdminAndTenancyAdmin) => {
  const queryClient = useQueryClient()
  const managedCustomer = useManagedCustomer()

  return useMutation(
    () =>
      EntitiesService.entitiesPatchAsync({
        id: managedCustomer.id!,
        requestBody: {
          ownerAdminId: admin.id!,
        } as Entity,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tenancies"])
        queryClient.invalidateQueries(["admins"])
      },
    }
  )
}

export const useUpdateUserTenancyLinksMutation = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(
    (tenancyLinks: TenancyLinks) =>
      AdminsService.adminsUpdateMeAsync({
        requestBody: tenancyLinks,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tenancies"])
        queryClient.invalidateQueries(["admins"])
      },
      onError: (error: ApiError) => {
        PopToast(t("Update user tenancy error"), error.body.error.message, "error")
      },
    }
  )
}
