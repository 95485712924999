export const customers = [
  {
    id: "a04e4e29-42d8-4f35-a913-0245f57d24fr",
    parentId: "",
    name: "May Megahed",
    email: "may.megahed@iamcloud.com",
    phone: "447441428372",
    companyName: "",
    companyType: "",
    taxRegistrationNumber: "",
    address: "Carrer del Joncar, 29, 08005 Barcelona",
    countryId: 1,
  },
  {
    id: "a04e4e29-42d8-4f35-a913-0245f57er4b8",
    parentId: "",
    name: "Anna Milton",
    email: "anna.milton@iamcloud.com",
    phone: "447520665374",
    companyName: "",
    companyType: "",
    taxRegistrationNumber: "",
    address: "Calle Bureta, 7, 28042 Madrid",
    countryId: 1,
  },
  {
    id: "a04e4e29-42d8-4f35-a913-0245f57d24sd",
    parentId: "",
    name: "Mariluz Lopez",
    email: "marliluz.lopez@iamcloud.com",
    phone: "447441425864",
    companyName: "",
    companyType: "",
    taxRegistrationNumber: "",
    address: "Avinguda Diagonal, 617, 08029 Barcelona",
    countryId: 1,
  },
  {
    id: "a0ry4e29-42d8-4f35-a913-0245f57d24b8",
    parentId: "",
    name: "Jorge Castillo",
    email: "jorge.castillo@iamcloud.com",
    phone: "447441428390",
    companyName: "Typeform SL",
    companyType: "",
    taxRegistrationNumber: "",
    address: "Carrer de la Princesa, 41, 08003 Barcelona",
    countryId: 1,
  },
  {
    id: "a0ry4e29-42d8-4f35-a913-0245f57d24b8",
    parentId: "",
    name: "David Bisbal",
    email: "david.bisbal@iamcloud.com",
    phone: "447441428390",
    companyName: "",
    companyType: "",
    taxRegistrationNumber: "",
    address: "Carrer de València, 616, 08026 Barcelona",
    countryId: 1,
  },
  {
    id: "a04e4e29-42d8-4f35-a913-0245f57d24fw",
    parentId: "",
    name: "May Megahed",
    email: "may.megahed@iamcloud.com",
    phone: "447441428372",
    companyName: "HayaBCN",
    companyType: "",
    taxRegistrationNumber: "",
    address: "Carrer del Joncar, 29, 08005 Barcelona",
    countryId: 1,
  },
  {
    id: "a0ry4e29-42d8-4f35-a913-0245f57d24b8",
    parentId: "",
    name: "Madga Delmar",
    email: "madga.delmar@iamcloud.com",
    phone: "447441428390",
    companyName: "",
    companyType: "",
    taxRegistrationNumber: "",
    address: "Carrer del Poeta Antonino Chocomeli, 1, 46015.....",
    countryId: 1,
  },
]
