import { Box, Button, Flex, FormControl, FormLabel, InputGroup, InputLeftElement, Textarea } from "@chakra-ui/react"
import { CrossIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import React, { useContext, useEffect, useState } from "react"
import "./ProductForm.css"

import { DiscountInput } from "../DiscountInput/DiscountInput"
import { ProductModel, mockData } from "api"
import { DiscountTypes } from "common/discountTypes"
import { generateOrder, getDiscount } from "features/inShop/inShopUtils"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

const MAX_DISCOUNT_PERCENTAGE = "100"

export const ProductForm = () => {
  const { t } = useTranslation()
  const [openNote, setOpenNote] = useState(false)
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const { state, dispatch } = useContext(ProductsContext)
  const {
    makeOrder: {
      orderProduct,
      order: { orderLines: products },
    },
  } = state
  const [product, setProduct] = useState<ProductModel>(mockData.initProduct)
  const maxDiscountAmount = +(product.retailPrice + product.tax).toFixed(2)

  useEffect(() => {
    const product = products.find((item) => item.id === orderProduct.id) as ProductModel
    setProduct(product)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  // const inputStyle = {
  //   background: "white",
  //   color: "text.02",
  //   fontSize: 12,
  //   borderRadius: 5,
  //   padding: 0,
  //   width: "100%",
  // }

  // const showPicker = (): void => {
  //   setOverlay(true)
  // }

  // const chooseDate = (day: Date): void => {
  //   setOverlay(false)
  //   setProduct({ ...product, deliveryDate: format(day, formatDate) })
  //   const orderProducts = products.map((item) =>
  //     item.id === product.id ? { ...product, deliveryDate: format(day, formatDate) } : item
  //   )
  //   dispatch({ type: Types.SetOrder, payload: { order: { ...state.makeOrder.order, orderLines: orderProducts } } })
  // }

  const onDiscountChange = (event: string) => {
    if (event.includes("-")) return
    if (product.discountType === DiscountTypes.Amount) {
      if (+event > maxDiscountAmount) {
        setProduct({ ...product, productDiscount: maxDiscountAmount.toString() })
      } else {
        setProduct({ ...product, productDiscount: event })
      }
    } else {
      if (+event >= +MAX_DISCOUNT_PERCENTAGE) {
        setProduct({ ...product, productDiscount: MAX_DISCOUNT_PERCENTAGE })
      } else {
        setProduct({ ...product, productDiscount: event })
      }
    }
  }

  const onDiscountBlur = () => {
    const discount = getDiscount(product)
    const changed = { ...product, discount }
    const stateProducts = products.map((item) => (item.id === product.id ? changed : item))
    const generatedOrder = generateOrder(stateProducts, mockData.initOrder, state)
    dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: changed } })
    dispatch({ type: Types.SetOrder, payload: { order: generatedOrder } })
  }

  const onTypeSelect = (type: string) => {
    const prod = { ...product, discountType: type, productDiscount: "0" }
    const discountProd = { ...prod, discount: getDiscount(prod) }

    const stateProducts = products.map((item) => (item.id === product.id ? discountProd : item))
    const generatedOrder = generateOrder(stateProducts, mockData.initOrder, state)
    dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: discountProd } })
    dispatch({ type: Types.SetOrder, payload: { order: generatedOrder } })
    setIsOpenSelect(false)
  }

  return (
    <Box
      bg="white"
      borderRadius={10}
      color="text.02"
      data-testid="form"
      fontWeight="normal"
      margin="0 auto 2rem"
      padding={4}
      pos="relative"
      width="100%"
      zIndex={0}
    >
      <Box>
        <FormControl style={formControlStyle}>
          <FormLabel color="text.02" fontSize={16} fontWeight="normal" margin={0}>
            {t("deliveryDate")}
          </FormLabel>
          <InputGroup borderColor="inherit" borderRadius={5} borderWidth={1} maxWidth="12rem" zIndex={0}>
            <InputLeftElement
              // children={<CalendarIcon color="#6A7888" />}
              height="100%"
              pointerEvents="none"
              zIndex={0}
            />
            {/*<DayPickerInput*/}
            {/*  dayPickerProps={{ disabledDays: { before: new Date() } }}*/}
            {/*  format={formatDate}*/}
            {/*  placeholder="01/01/2021 12:00:00"*/}
            {/*  showOverlay={overlay}*/}
            {/*  style={inputStyle}*/}
            {/*  value={product.deliveryDate}*/}
            {/*  onDayChange={chooseDate}*/}
            {/*  onDayPickerHide={() => setOverlay(false)}*/}
            {/*  onDayPickerShow={showPicker}*/}
            {/*/>*/}
          </InputGroup>
        </FormControl>
        <FormControl style={{ ...formControlStyle, marginBottom: "1rem" }}>
          <FormLabel color="text.02" fontSize={16} fontWeight="normal" margin={0}>
            {t("discount")}
          </FormLabel>
          <Flex maxWidth="10rem">
            <DiscountInput
              discountType={product.discountType}
              isOpenSelectState={[isOpenSelect, setIsOpenSelect]}
              value={product.productDiscount}
              onDiscountBlur={onDiscountBlur}
              onDiscountChange={onDiscountChange}
              onTypeSelect={onTypeSelect}
            />
          </Flex>
        </FormControl>
        {openNote && (
          <FormControl bg="white" borderRadius={10} height="100%" left={0} position="absolute" top={0} zIndex={1}>
            <CrossIcon
              _hover={{ cursor: "pointer" }}
              data-testid="close-note-button"
              position="absolute"
              right={5}
              top={5}
              zIndex={2}
              onClick={() => setOpenNote(false)}
            />
            <Textarea
              bg="white"
              borderRadius={10}
              color="#959595"
              data-testid="add-note-field"
              height="100%"
              padding={4}
              placeholder={t("textarea.placeholderFormNote")}
              resize="none"
              value={product.notes}
              variant="filled"
              zIndex={1}
              autoFocus
              onChange={(event) => setProduct({ ...product, notes: event.currentTarget.value })}
            />
          </FormControl>
        )}
        <Button
          borderColor="interactive.01"
          color="interactive.01"
          data-testid="note-button"
          mt="3rem"
          variant="outline"
          width="100%"
          zIndex={0}
          onClick={() => setOpenNote(!openNote)}
        >
          {product.notes.length > 0 ? `${t("editItem", { name: t("note") })}` : `${t("addItem", { name: t("note") })}`}
        </Button>
      </Box>
    </Box>
  )
}

const formControlStyle = {
  marginBottom: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  zIndex: 0,
}
