import { initCategory } from "./initCategory"
import { initStoreFont } from "./initStoreFont"
import { initSupplier } from "./initSupplier"
import { initUnitOfMeasure } from "./initUnitOfMeasure"

export const initCatalogProduct = {
  id: "",
  name: "",
  description: "",
  shortName: "",
  image: "",
  supplier: initSupplier,
  supplierSKU: "",
  countryOfOrigin: "0",
  hsCode: "",
  category: initCategory,
  iva: { id: "", name: "" },
  purchaseCostIncludesTax: false,
  rrpIncludesTax: false,

  unitOfMeasure: initUnitOfMeasure,
  availability: { id: "", name: "" },
  initialStock: 0,
  initialCostOfGoods: 0,
  carryingCost: 0,
  rrp: 0,
  variants: [],
  officialLicense: false,
  storeFronts: [initStoreFont],

  supplierBarcode: "",
  initialStockPerVariant: 0,
  unitCostPerVariant: 0,
  bufferStockPerVariant: 0,
  unitRrpPerVariant: 0,

  stockLevel: 0,
  status: "",
}
