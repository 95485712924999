import { Tag, Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { format } from "date-fns"
import { FC, ReactNode, useContext, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row, usePagination, useSortBy, useTable } from "react-table"

import { Order, SalesOrderStatus } from "api"
import { Pagination, Table, TableItemMenu } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"
import { ProductsContext } from "features/inShop/product/ProductState/context"

type SalesOrdersTableProps = {
  loading: boolean
  onOpen: () => void
  salesOrders: Order[]
  setOrder: (order: Order) => void
}

const SALES_ORDERS_TABLE = "SALES_ORDERS_TABLE"

const SalesOrdersTable: FC<SalesOrdersTableProps> = ({ setOrder, salesOrders, loading, onOpen }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [storedPageSize, setStoredPageSize] = useStoredPageSize(SALES_ORDERS_TABLE)

  const dateFormat = "MM/dd/yyyy kk:mm"
  const {
    state: {
      globalEntities: {
        tenancyCurrency: {
          currency: { symbol },
        },
      },
    },
  } = useContext(ProductsContext)

  const NameCell: FC<{ order: Order }> = ({ order }) => (
    <TableItemMenu
      deleteClick={() => {
        setOrder(order)
        onOpen()
      }}
      editClick={() => {}}
      value={String(order.orderNumber)}
      isShowEdit
      onClick={() => navigate(order.id)}
    />
  )

  const columnData: Column<Order>[] = [
    {
      Header: t("orderNumber") as string,
      accessor: "id",
      Cell: ({ row }) => <NameCell order={row.original} />,
    },
    {
      Header: t("orderDate") as string,
      accessor: "orderDate",
      Cell: ({ row: { original: order } }) => <Text>{format(new Date(order.orderDate), dateFormat)}</Text>,
    },
    {
      Header: t("orderValue") as string,
      accessor: "total",
      Cell: ({ row: { original: order } }) => <Text>{`${order.total.toFixed(2)} ${symbol} `}</Text>,
    },
    {
      Header: t("deliveryDate") as string,
      accessor: "deliveryDate",
      Cell: ({ row: { original: order } }) => (
        <Text>{order.deliveryDate ? format(new Date(order.deliveryDate), dateFormat) : "N/A"}</Text>
      ),
    },
    {
      Header: t("storeFront") as string,
      accessor: "storeFront",
      Cell: ({ row: { original: order } }) => <Text>{order.storeFront?.name}</Text>,
    },
    {
      Header: t("status_label") as string,
      accessor: "status",
      Cell: ({ row }) => (
        <Tag
          background={
            row.original.status === SalesOrderStatus.PAID
              ? "success.02"
              : row.original.status === SalesOrderStatus.CANCELED
              ? "danger.02"
              : "warning.02"
          }
          borderColor={
            row.original.status === SalesOrderStatus.PAID
              ? "success.03"
              : row.original.status === SalesOrderStatus.CANCELED
              ? "danger.03"
              : "warning.03"
          }
          borderRadius={2}
          color="text.01"
          fontSize={12}
        >
          {t(`salesOrderStatus.${row.original.status}`)}
        </Tag>
      ),
      disableSortBy: true,
    },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    pageOptions,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns: useMemo(() => columnData, []),
      data: useMemo(() => salesOrders, [salesOrders]),
      initialState: {
        pageSize: storedPageSize ?? DEFAULT_ITEMS_PER_PAGE,
      },
    },
    useSortBy,
    usePagination
  )

  /* eslint-disable react/jsx-key */
  const renderRow = (row: Row<Order>): ReactNode => {
    prepareRow(row)

    return (
      <Table.TR {...row.getRowProps()} _hover={{ cursor: "pointer", background: "#aee5ff" }}>
        {row.cells.map((cell) => {
          if (cell.column.Header === "Name") {
            return <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
          }

          return <Table.TD {...cell.getCellProps()}>{cell.render("Cell")}</Table.TD>
        })}
      </Table.TR>
    )
  }

  return (
    <Table {...getTableProps()} width="100%">
      <Table.Header>
        {headerGroups.map((headerGroup) => (
          <Table.TR bg="white" color="#36495E" fontSize="lg" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Table.TH textTransform="initial" {...column.getHeaderProps()}>
                {column.render("Header")}
              </Table.TH>
            ))}
          </Table.TR>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {loading ? (
          <Table.Skeleton columnWidths={[32, 16, 32, 64]} rowCount={DEFAULT_ITEMS_PER_PAGE} />
        ) : (
          <>{page.map(renderRow)}</>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.TR bg="#fafafa">
          <Table.TD colSpan={columnData.length}>
            <Pagination
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={(size) => {
                setPageSize(size)
                setStoredPageSize(size)
              }}
              totalPages={pageOptions.length}
              onPageChange={gotoPage}
            />
          </Table.TD>
        </Table.TR>
      </Table.Footer>
    </Table>
  )
  /* eslint-enable react/jsx-key */
}

export { SalesOrdersTable }
