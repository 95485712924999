import { Grid, GridItem, Text } from "@chakra-ui/react"
import { AddIcon, MinimiseIcon } from "@iac/components.icons.react"
import { FC, useContext, useEffect, useState } from "react"

import { Order, ProductModel, api, mockData } from "api"
import { TENANCY_ID } from "common/constants"
import { symbolPosition } from "common/utils/currency.utils"
import { confirmOrder } from "features/inShop/inShopUtils"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

type ExchangeProductItemProps = {
  object: ProductModel
}

const ExchangeProductItem: FC<ExchangeProductItemProps> = ({ object }) => {
  const { state, dispatch } = useContext(ProductsContext)
  const { exchange, makeOrder } = state
  const [product, setProduct] = useState<ProductModel>(object)
  const [isAdded, setIsAdded] = useState(false)
  const [order, setOrder] = useState<Order>(mockData.initOrder)
  const { data } = api.useSalesOrders(TENANCY_ID)

  const changeCount = (type: string): void => {
    const orderProd = order?.orderLines.find((item) => item.id === product.id)
    if (!isAdded && (!makeOrder.orderProduct.id || makeOrder.orderProduct.id !== product.id)) {
      orderProd?.id && dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: orderProd } })
      setIsAdded(true)
    }
    if (type === "add") {
      setProduct((prev: ProductModel): ProductModel => {
        if (prev.quantity === orderProd?.quantity) {
          return product
        }
        const prod = { ...product, count: prev.quantity + 1 }
        makeExchangeOrder(prod)
        const changedProducts = makeOrder.order.orderLines.map((item) => (item.id === prod.id ? prod : item))
        dispatch({ type: Types.SetOrder, payload: { order: { ...makeOrder.order, orderLines: changedProducts } } })
        confirmOrder(prod, state, dispatch)

        return prod
      })
    } else if (type === "minus") {
      setProduct((prev: ProductModel): ProductModel => {
        if (prev.quantity === 0) {
          return product
        }
        const prod = { ...product, count: prev.quantity - 1 }
        makeExchangeOrder(prod)
        const changedProducts = makeOrder.order.orderLines.map((item) => (item.id === prod.id ? prod : item))
        dispatch({ type: Types.SetOrder, payload: { order: { ...makeOrder.order, orderLines: changedProducts } } })
        confirmOrder(prod, state, dispatch)

        return prod
      })
    }
  }

  const makeExchangeOrder = (product: ProductModel): void => {
    const mockProd = order?.orderLines.find((item) => item.id === product.id)
    const count = (mockProd && mockProd.quantity - product.quantity) || 0
    if (count === 0) {
      const filteredProducts = exchange.exchangeOrder.orderLines.filter((item) => item.id !== product.id)
      dispatch({
        type: Types.SetExchangeOrder,
        payload: { exchangeOrder: { ...exchange.exchangeOrder, orderLines: filteredProducts } },
      })
    }
    if (mockProd && exchange.exchangeOrder.orderLines.length) {
      const existProduct = exchange.exchangeOrder.orderLines.find((item) => item.id === product.id)
      !existProduct &&
        dispatch({
          type: Types.SetExchangeOrder,
          payload: {
            exchangeOrder: {
              ...exchange.exchangeOrder,
              orderLines: [...exchange.exchangeOrder.orderLines, { ...product, quantity: count }],
            },
          },
        })
    }
    if (mockProd && !exchange.exchangeOrder.orderLines.length) {
      dispatch({
        type: Types.SetExchangeOrder,
        payload: {
          exchangeOrder: {
            ...exchange.exchangeOrder,
            orderLines: [{ ...product, quantity: count }],
          },
        },
      })
    }
  }

  useEffect(() => {
    const mockOrder = data?.value?.find((item: Order) => item.orderId === makeOrder.order.orderId)
    mockOrder && setOrder(mockOrder)
    if (JSON.stringify(order) === JSON.stringify(state.makeOrder.order)) {
      dispatch({ type: Types.IsOrderChanged, payload: { isOrderChanged: false } })
    } else {
      dispatch({ type: Types.IsOrderChanged, payload: { isOrderChanged: true } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeOrder.order, order])

  return (
    <Grid
      _hover={{
        background: "rgb(246, 246, 246)",
        cursor: "pointer",
      }}
      //align="center"
      data-testid="product"
      style={{
        background: "transparent",
        borderBottom: `1px solid #979797`,
        padding: "8px 30px 8px 15px",
        color: "#36495E",
        fontWeight: "bold",
        fontSize: 24,
        width: "100%",
      }}
      templateColumns="4fr 1fr 1fr 1fr 1fr"
      onClick={() => {
        dispatch({ type: Types.SetOpenExchange, payload: { openExchangeProduct: true } })
        dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: object } })
      }}
    >
      <GridItem
      //align="left"
      >
        <Text>{product.name}</Text>
      </GridItem>
      <GridItem alignItems="center" display="flex" justifyContent="center" onClick={(event) => event.stopPropagation()}>
        <MinimiseIcon
          _hover={{ cursor: "pointer" }}
          aria-label="remove-count"
          background="#007abd"
          borderRadius={3}
          color="white"
          fontSize={25}
          onClick={() => {
            dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: object } })
            changeCount("minus")
          }}
        />
        <GridItem px={4}>{product.quantity}</GridItem>
        <AddIcon
          _hover={{ cursor: "pointer" }}
          aria-label="add-count"
          background="#007abd"
          borderRadius={3}
          color="white"
          fontSize={25}
          onClick={() => {
            dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: object } })
            changeCount("add")
          }}
        />
      </GridItem>
      <GridItem>{symbolPosition(product.deposit)}</GridItem>
      <GridItem>
        {symbolPosition(
          Number(
            ((product.retailPrice + product.tax) * product.quantity - product.discount - product.deposit).toFixed(2)
          )
        )}
      </GridItem>
      <GridItem>{symbolPosition(0)}</GridItem>
    </Grid>
  )
}

export { ExchangeProductItem }
