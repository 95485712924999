import { Stack, StackProps } from "@chakra-ui/react"
import { FC, ReactNode } from "react"

type HeaderProps = StackProps & {
  icon?: ReactNode
}

const Header: FC<HeaderProps> = ({ icon, children, ...stackProps }) => (
  <Stack
    align="center"
    bg="elevation.01"
    borderBottomWidth="1px"
    borderColor="ui.08"
    borderTopRadius="md"
    direction="row"
    p={3}
    spacing={4}
    {...stackProps}
  >
    {icon && <Stack>{icon}</Stack>}
    <Stack justify="center" my={-1} spacing={0}>
      {children}
    </Stack>
  </Stack>
)

const Body: FC<StackProps> = (stackProps) => <Stack flex={1} p={3} {...stackProps} />

const Footer: FC<StackProps> = ({ children, ...stackProps }) => (
  <Stack borderBottomRadius="md" borderColor="ui.08" borderTopWidth="1px" p={3}>
    <Stack borderBottomRadius="md" direction="row" flex={1} justify="flex-end" m={-3} px={3} py={2} {...stackProps}>
      {children}
    </Stack>
  </Stack>
)

export type CardProps = StackProps & { editStatus?: EditStatus }

type CardType = FC<CardProps> & {
  Header: FC<HeaderProps>
  Body: FC<StackProps>
  Footer: FC<StackProps>
}

const Component: CardType = ({ editStatus = "read-only", ...stackProps }) => (
  <Stack
    bg="white"
    borderColor="ui.08"
    borderRadius="md"
    borderWidth="1px"
    boxShadow="sm"
    flex={1}
    maxW={440}
    minH={320}
    minW={340}
    opacity={editStatus === "editing-other" ? 0.75 : 1}
    role="group"
    spacing={0}
    {...stackProps}
  />
)

Component.Header = Header
Component.Body = Body
Component.Footer = Footer

export { Component as Card }
