import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { Supplier, SuppliersService } from "../generated"
import { ODataOptions } from "../handmade"

export const useSuppliers = (parentId: UUID, options?: ODataOptions) => {
  const { data, isInitialLoading, error } = useQuery(
    ["suppliers", options],
    () => SuppliersService.suppliersGetAllAsync({ parentId, ...options }),
    {
      cacheTime: 0,
    }
  )

  return {
    data: data?.value ?? [],
    isLoading: isInitialLoading,
    error,
  }
}

export const useSupplier = (parentId: UUID, id: UUID) =>
  useQuery(["suppliers", parentId, id], () => SuppliersService.suppliersGetAsync({ parentId, id }), {
    enabled: !!id,
    cacheTime: 0,
  })

export const useCreateSupplierMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (supplier: Partial<Supplier>) => SuppliersService.suppliersPostAsync({ parentId, requestBody: supplier }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["suppliers"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useUpdateSupplierMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (supplier: Partial<Supplier>) => SuppliersService.suppliersPatchAsync({ parentId, id, requestBody: supplier }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["suppliers"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useDeleteSupplierMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(() => SuppliersService.suppliersDeleteAsync({ parentId, id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["suppliers"])
      onSuccess()
    },
    onError: () => {},
  })
}
