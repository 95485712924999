import type { Customer } from "../models/Customer"
import { request as __request } from '../core/request'
import { trackPromise } from "react-promise-tracker"

export class CustomersService {

  /**
   * Gets all Customers.
   * ## Requires:
   * ### customers.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async customersGetAllAsync({
    tenancyId,
    expand,
    top,
    skip,
    count = false,
  }: {
    tenancyId: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
    /** Limits the number of items returned from a collection. **/
    top?: number
    /** Excludes the specified number of items of the queried collection from the result. **/
    skip?: number
    /** Indicates whether the total count of items within a collection are returned in the result. **/
    count?: boolean
  }): Promise<{
    readonly '@odata.context'?: string
    readonly value?: Array<Customer>
    readonly count: number
  }> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/tenancies/${tenancyId}/isa/customers`,
      query: {
        $expand: expand,
        $top: top,
        $skip: skip,
        $count: count,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }))
    return { ...result.body, count: result.body["@odata.count"] }
  }

  /**
   * Search Customers.
   * ## Requires:
   * ### customers.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async customersSearchAsync({
    searchTerm,
    tenancyId,
    expand,
  }: {
    searchTerm: string
    tenancyId: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<{
    readonly '@odata.context'?: string
    readonly value?: Array<Customer>
  }> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/tenancies/${tenancyId}/isa/customers`,
      query: {
        $expand: expand,
        $filter: `startswith(email, '${searchTerm}') or contains(phone, '${searchTerm}') or nationalId eq '${searchTerm}'`,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }))
    return result.body
  }

  /**
   * Create Customer.
   * ## Requires:
   * ### customers.Create
   * @returns any Success
   * @throws ApiError
   */
  public static async customersPostAsync({
    tenancyId,
    requestBody,
  }: {
    tenancyId: string
    requestBody?: Partial<Customer>
  }): Promise<({
    readonly '@odata.context'?: string,
  } & Customer)> {
    const result = await trackPromise(__request({
      method: 'POST',
      path: `/api/tenancies/${tenancyId}/isa/customers`,
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }))
    return result.body
  }

  /**
   * Gets Customer.
   * ## Requires:
   * ### customers.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async customersGetAsync({
    tenancyId,
    id,
    select,
    expand,
  }: {
    tenancyId: string
    id: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<{
    readonly '@odata.context'?: string
  } & Customer> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/tenancies/${tenancyId}/isa/customers/${id}`,
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }))
    return result.body
  }

  /**
   * Update Customer.
   * ## Requires:
   * ### customers.Update
   * @returns any Success
   * @throws ApiError
   */
  public static async customersPatchAsync({
    tenancyId,
    id,
    requestBody,
  }: {
    tenancyId: string
    id: string
    requestBody?: Customer
  }): Promise<({
    readonly '@odata.context'?: string
  } & Customer)> {
    const result = await trackPromise(__request({
      method: 'PATCH',
      path: `/api/tenancies/${tenancyId}/isa/customers/${id}`,
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        409: `Conflict`,
        500: `Internal server error`,
      },
    }))
    return result.body
  }

  /**
   * Delete the Customer.
   * ## Requires:
   * ### customers.Delete
   * @returns any Success
   * @throws ApiError
   */
  public static async customersDeleteAsync({
    id,
    tenancyId,
  }: {
    id: string
    tenancyId: string
  }): Promise<({
    readonly '@odata.context'?: string
  } & Customer)> {
    const result = await trackPromise(__request({
      method: 'DELETE',
      path: `/api/tenancies/${tenancyId}/isa/customers/${id}`,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }))
    return result.body;
  }

}