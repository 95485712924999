import { Box, Button, Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, SetStateAction, useEffect, useRef } from "react"

import { NotificationCard } from "./NotificationCard/NotificationCard"
import { EmptyNotificationsIcon } from "common/theme"

type NotificationsProps = {
  notifications: { id: string; title: string; text: string; type: string }[]
  setShowNotifications: Dispatch<SetStateAction<boolean>>
}

const Notifications: FC<NotificationsProps> = ({ setShowNotifications, notifications }) => {
  const { t } = useTranslation()
  const rootEl = useRef(null)
  const buttonColor = useColorModeValue("#004971", "#3395ca")
  const emptyNotificationsHeader = useColorModeValue("#2f2f2f", "#fcfcfc")
  const emptyNotificationsText = useColorModeValue("#595959", "#c9c9c9")

  useEffect(() => {
    // @ts-ignore
    const onClick = (e) => rootEl.current.contains(e.target) || setShowNotifications(false)
    document.addEventListener("click", onClick)

    return () => document.removeEventListener("click", onClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootEl])

  return (
    <Stack
      _before={{
        content: '""',
        position: "absolute",
        right: "5px",
        top: "-16px",
        borderWidth: "8px",
        borderColor: "transparent",
        borderBottomWidth: "8px",
        borderBottomStyle: "solid",
        borderBottomColor: useColorModeValue("#ffffff", "#000d14"),
      }}
      borderColor={useColorModeValue("#e5e5e5", "#323232")}
      borderRadius={4}
      borderStyle="solid"
      borderWidth="1px"
      boxShadow="0px 2px 3px rgba(55, 99, 122, 0.15)"
      height="375px"
      position="absolute"
      ref={rootEl}
      right="-5px"
      top="150%"
      width="320px"
      zIndex={2}
    >
      <Flex
        align="center"
        background={useColorModeValue("#ffffff", "#000d14")}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        boxShadow="0px 3px 5px rgba(55, 99, 122, 0.15)"
        justify="space-between"
        p={4}
        position="relative"
      >
        <Text color={useColorModeValue("#2f2f2f", "#fcfcfc")} fontSize={18} fontWeight={700}>
          {t("notifications.notifications")}
        </Text>
        {notifications.length > 0 && (
          <Button color={buttonColor} mr={0} size="sm" variant="text">
            {t("notifications.clearAll")}
          </Button>
        )}
      </Flex>
      <Box
        background={useColorModeValue("#fafafa", "#061720")}
        borderBottomRadius={4}
        css={{
          "&::-webkit-scrollbar": {
            width: "6px",
            background: useColorModeValue("#e5e5e5", "#323232"),
          },
          "&::-webkit-scrollbar-track": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: useColorModeValue("#e5e5e5", "#323232"),
            background: useColorModeValue("#b2b2b2", "#656565"),
            borderRadius: "10px",
          },
        }}
        height="100%"
        marginTop="0 !important"
        overflowY="auto"
        p={2}
      >
        {!notifications.length ? (
          <Flex align="center" direction="column" height="80%" justify="center">
            <EmptyNotificationsIcon fontSize={100} />
            <Text color={emptyNotificationsHeader} fontSize={14}>
              {t("notifications.youDontHaveNotifications")},
            </Text>
            <Text color={emptyNotificationsText} fontSize={12}>
              {t("notifications.weLetYouKnowWhenYouDo")}
            </Text>
          </Flex>
        ) : (
          notifications.map(({ id, text, type, title }) => (
            <NotificationCard key={id} text={text} title={title} type={type} />
          ))
        )}
      </Box>
    </Stack>
  )
}

export { Notifications }

export type { NotificationsProps }
