import { Button, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

type IsDefaultCurrencyModalProps = {
  isOpen: boolean
  onClose: () => void
  name: string
  setIsDefault: (isDefault: boolean) => void
}

const IsDefaultCurrencyModal: FC<IsDefaultCurrencyModalProps> = ({ onClose, isOpen, name, setIsDefault }) => {
  const { t } = useTranslation()
  const setValue = (value: boolean) => {
    setIsDefault(value)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} size="3xl" isCentered onClose={onClose}>
      <ModalContent>
        <ModalHeader>{t("currencies.currencyChanges.modal.question", { name })}</ModalHeader>
        <ModalCloseButton
          borderColor="text.01"
          borderRadius={50}
          borderWidth={1}
          data-testid="close-button"
          size="sm"
        />
        <ModalFooter alignItems="center" display="flex" justifyContent="flex-start">
          <Button data-testid="yes-button" mr={6} variant="primary" onClick={() => setValue(true)}>
            {t("yes")}
          </Button>
          <Button data-testid="no-button" variant="secondary" onClick={() => setValue(false)}>
            {t("no")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export type { IsDefaultCurrencyModalProps }

export { IsDefaultCurrencyModal }
