import { useMemo } from "react"

const STORED_TABLE_PAGE_SIZES = "STORED_TABLE_PAGE_SIZES"

export const useStoredPageSize = (tableName: string): [number, (pageSize: number) => void] => {
  const storedPageSize = useMemo(
    () => JSON.parse(localStorage.getItem(STORED_TABLE_PAGE_SIZES) as string)?.[tableName],
    [tableName]
  )

  const setStoredPageSize = (pageSize: number) => {
    const pageSizes = JSON.parse(localStorage.getItem(STORED_TABLE_PAGE_SIZES) as string)
    localStorage.setItem(STORED_TABLE_PAGE_SIZES, JSON.stringify({ ...pageSizes, [tableName]: pageSize }))
  }

  return [storedPageSize, setStoredPageSize]
}
