import { Box, Flex, Image, Text, VStack } from "@chakra-ui/react"
import { FC, useContext, useEffect, useState } from "react"

import { ProductModel } from "api"
import { NotImplemented } from "common/components/NotImplemented/NotImplemented"
import { ProductForm } from "common/components/ProductForm/ProductForm"
import { DefaultImageIcon, FavoriteIcon } from "common/theme"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

type ProductDetailsProps = {
  product: ProductModel
}

const ProductDetails: FC<ProductDetailsProps> = ({ product }) => {
  const [mainImage, setMainImage] = useState("")
  const { state, dispatch } = useContext(ProductsContext)
  const {
    globalEntities: { tenancyCurrency },
  } = state

  useEffect(() => {
    const isProduct = state.makeOrder.order.orderLines.find((item: ProductModel) => item.id === product.id)
    if (isProduct) {
      dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: isProduct } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (product.images.length > 0) {
      const image = product.images[0].url
      image && setMainImage(image)
    } else {
      setMainImage("")
    }
  }, [product])

  return (
    <VStack bg="customer.root.background" borderRadius="lg" mb={4} ml={1} mr={5} mt={6} p={6} width="400px">
      <Flex align="center" direction="column" flex={1} width="100%">
        <Flex align="center" justify="center" mb={4} position="relative" width="100%">
          <Text color="text.02" fontSize="2xl" fontWeight="semibold">
            {product.name}
          </Text>
          <NotImplemented>
            <Box pos="absolute" right="-10px">
              <FavoriteIcon cursor="pointer" fontSize={25} />
            </Box>
          </NotImplemented>
        </Flex>
        {mainImage ? (
          <Image borderRadius="20px" boxSize={64} src={mainImage} />
        ) : (
          <DefaultImageIcon boxSize={64} height="inherit" />
        )}
        <Flex align="center" direction="column" fontSize="lg" mt={4}>
          <Text color="text.02" fontWeight="semibold">
            {tenancyCurrency.currency?.symbol}
            {(product.retailPrice + product.tax).toFixed(2)}
          </Text>
          <Text color="text.02" fontWeight="bold">
            {product.inventoryType}
          </Text>
        </Flex>
      </Flex>
      <ProductForm />
    </VStack>
  )
}

export { ProductDetails }
