import { v4 as uuid } from "uuid"

import { ProductAPI, VariantOptionValue } from "api"
import { Statuses } from "common/statuses"

export type restType = {
  ProductId: string
  ProductVariantOptions: { variantTypeName: string; variantTypeOptionId: string }[]
}

export type AllVariants = {
  id: UUID
  tenancyId: UUID
  name: string
  description: string
  variantOptionValues: {
    id: string
    variantOptionId: string
    value: string
    color: string
    imageId: string
    description: string
    status: string
    translations?: {
      languageId: string
      value: string
    }[]
  }[]
}

export const getAllTypes = (
  allVariants: AllVariants[]
): {
  variantTypeOptionId: string
  variantTypeName: string
  value: string
}[] =>
  allVariants
    .map((item) =>
      item.variantOptionValues.map((option) => ({
        variantTypeOptionId: option.id,
        variantTypeName: item.name,
        value: option.value,
      }))
    )
    .reduce((a, b) => a.concat(b))

export const groupBy = (
  xs:
    | { value: string; variantTypeName: string; variantTypeOptionId: string }[]
    | { id: string; group: string; text: string }[],
  key: string
) =>
  // @ts-ignore
  xs.reduce(function (rv, x) {
    // eslint-disable-next-line fp/no-mutation,fp/no-mutating-methods
    ;(rv[x[key]] = rv[x[key]] || []).push(x)

    return rv
  }, {})

// @ts-ignore
const getRes = (grouped, indexes): restType => {
  const res: restType = {
    ProductId: uuid(),
    ProductVariantOptions: [],
  }
  // eslint-disable-next-line fp/no-let,fp/no-loops
  for (let [key, value] of Object.entries(grouped)) {
    // @ts-ignore
    if (value[indexes.filter((x) => x.key === key)[0].index]) {
      // eslint-disable-next-line fp/no-mutating-methods
      res.ProductVariantOptions.push({
        variantTypeName: key,
        // @ts-ignore
        variantTypeOptionId: value[indexes.filter((x) => x.key === key)[0].index].text,
      })
    }
  }

  return res
}

export const getGrouping = (
  items: { id: string; group: string; text: string }[],
  allVariants: AllVariants[]
): restType[] => {
  const all = getAllTypes(allVariants)

  // eslint-disable-next-line fp/no-let
  let itemsFiltered = []
  // eslint-disable-next-line fp/no-let,fp/no-loops,fp/no-mutation
  for (let i = 0; i < all.length; i++) {
    const find = items.find((item) => item.group === all[i].variantTypeName)
    if (!find) continue
    // eslint-disable-next-line fp/no-mutating-methods
    itemsFiltered.push(all[i])
  }
  const result = []
  const allTypes = groupBy(itemsFiltered, "variantTypeName")
  const grouped = groupBy(items, "group")
  const indexes = []

  // eslint-disable-next-line fp/no-let,fp/no-loops
  for (let [key] of Object.entries(allTypes)) {
    // eslint-disable-next-line fp/no-mutating-methods
    indexes.push({ key, index: 0 })
  }
  // eslint-disable-next-line fp/no-let
  let finished = false
  // eslint-disable-next-line fp/no-loops
  while (!finished) {
    // eslint-disable-next-line fp/no-mutating-methods
    result.push(getRes(grouped, indexes))
    // eslint-disable-next-line fp/no-let,fp/no-loops,fp/no-mutation
    for (let i = 0; i < indexes.length; i++) {
      if (grouped[indexes[i].key]) {
        if (indexes[i].index === grouped[indexes[i].key].length - 1) {
          if (i === indexes.length - 1) {
            // eslint-disable-next-line fp/no-mutation
            finished = true
            break
          }
          // eslint-disable-next-line fp/no-mutation
          indexes[i].index = 0
        } else {
          // eslint-disable-next-line fp/no-mutation
          ++indexes[i].index
          break
        }
      } else {
        // eslint-disable-next-line fp/no-mutation
        finished = true
        break
      }
    }
  }

  return result
}

export const createVariants = (items: restType[], allVariants: AllVariants[], productAPI: ProductAPI) =>
  items.map((item) => {
    const attributes = item.ProductVariantOptions.map((attribute) => ({
      name: attribute.variantTypeName,
      value: attribute.variantTypeOptionId,
    }))
    const allOptions = allVariants.map((item) => item.variantOptionValues).reduce((a, b) => a.concat(b))

    const opts = item.ProductVariantOptions.map((option) => {
      const opt = allOptions.find((item) => item.value === option.variantTypeOptionId) as VariantOptionValue

      return { ...option, id: opt?.id || "" }
    })

    const ids = opts.map((item) => ({ variantOptionValueId: item.id }))
    const str = attributes.map((item) => item.value.slice(0, 1)).join("-")
    const storeFronts = productAPI.storeFronts.map((item) => ({ ...item, retailPrice: 0 }))

    return {
      id: item.ProductId,
      receiptDisplayName: `${productAPI.shortName}-${str}`,
      sku: "",
      supplierSku: productAPI.supplierSku,
      supplierBarcode: productAPI.supplierBarcode,
      cost: 0,
      status: Statuses.Active,
      notes: "",
      variantOptionValues: [...ids],
      storeFronts,
    }
  })

export const generateMixedNames = (items: restType[]) => {
  const attributes = items.map((item) => ({
    productId: item.ProductId,
    attributes: item.ProductVariantOptions.map((option) => ({
      name: option.variantTypeName,
      value: option.variantTypeOptionId,
    })),
  }))

  return attributes.map((attribute) => {
    const values = attribute.attributes.map((item) => item.value)

    return { name: values.reduce((x, y) => `${x}/${y}`), productId: attribute.productId }
  })
}
