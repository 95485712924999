export const initProductVariant = {
  id: "",
  productId: "",
  receiptDisplayName: "",
  sku: "",
  status: "Active",
  supplierSku: "",
  supplierBarcode: "",
  cost: 0,
  stock: 0,
  notes: "",
  images: [],
  variantOptionValues: [],
  storeFronts: [],
}
