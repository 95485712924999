import {
  Box,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { AddIcon, CrossIcon, RemoveIcon } from "@iac/components.icons.react"
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { OutOfStockModal } from "../OutOfStockModal/OutOfStockModal"
import { ProductModel } from "api"
// import { CrossIcon, OrderInfoIcon } from "@iac/components.icons.react"
import { changeCount } from "features/inShop/inShopUtils"
import { InitialStateType, ProductStateDispatcher, ProductsContext } from "features/inShop/product/ProductState/context"

type ProductProps = {
  onClick: () => void
  deleteProduct: (id: string) => void
  product: ProductModel
  setProduct: Dispatch<SetStateAction<ProductModel>>
  getCount: (product: ProductModel, state: InitialStateType, dispatch: ProductStateDispatcher) => void
}

const ProductItem: FC<ProductProps> = ({ product, setProduct, getCount, onClick, deleteProduct }) => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [count, setCount] = useState(0)
  const { state, dispatch } = useContext(ProductsContext)
  const {
    globalEntities: {
      tenancyCurrency: {
        currency: { symbol },
      },
    },
  } = state
  const [forVariant, setForVariant] = useState({
    productId: "",
    variantId: "",
  })

  const goToVariantPage = () => {
    navigate(`/inventory/products/info/${forVariant.productId}/${forVariant.variantId}`)
    onClose()
  }

  const startOrder = (): void => {
    if (count === 0) {
      setCount(1)
      getCount({ ...product, quantity: 1 }, state, dispatch)
    }
    setProduct(product)
    onClick()
  }

  useEffect(() => setCount(product.quantity), [product.quantity])

  const changeProductCount = (value: number): void => {
    setForVariant({ productId: product.productId, variantId: product.id })
    changeCount(value, setCount, product, state, dispatch, onOpen)
  }

  return (
    <Grid
      _hover={{
        background: "rgb(246, 246, 246)",
        cursor: "pointer",
      }}
      background={product.isActive ? "info.02" : "transparent"}
      color="text.01"
      data-testid="product"
      fontSize={16}
      px={6}
      py={4}
      templateColumns="3fr 2fr 2fr 2fr 2fr 0.5fr"
      width="100%"
      onClick={startOrder}
    >
      <GridItem alignItems="center" display="flex" justifyContent="flex-start">
        <Text>{product.receiptDisplayName}</Text>
      </GridItem>
      <GridItem
        alignItems="center"
        display="flex"
        justifyContent="flex-end"
        onClick={(event) => event.stopPropagation()}
      >
        {(product.deliveryDate || product.notes) && (
          <Flex mr={2}>
            {/*<OrderInfoIcon color="interactive.01" />*/}
            <CrossIcon color="interactive.01" />
          </Flex>
        )}
        <InputGroup width="7rem">
          <InputLeftAddon
            bg="transparent"
            children={<RemoveIcon />}
            data-testid="delete-item-button"
            paddingX={1}
            onClick={() => changeProductCount(count - 1)}
          />
          <Input px={4} textAlign="center" value={count} onChange={(e) => changeProductCount(+e.target.value)} />
          <InputRightAddon
            bg="transparent"
            children={<AddIcon />}
            data-testid="add-item-button"
            paddingX={1}
            onClick={() => changeProductCount(count + 1)}
          />
        </InputGroup>
      </GridItem>
      <GridItem alignItems="center" display="flex" justifyContent="flex-end">
        {(product.retailPrice + product.tax).toFixed(2)} {symbol}
      </GridItem>
      <GridItem alignItems="center" display="flex" justifyContent="flex-end">
        {((product.retailPrice + product.tax) * product.quantity).toFixed(2)} {symbol}
      </GridItem>
      <GridItem
        alignItems="center"
        display="flex"
        justifyContent="flex-end"
        onClick={(event) => event.stopPropagation()}
      >
        <Text>
          {((product.retailPrice + product.tax) * product.quantity - product.deposit - product.discount).toFixed(2)}{" "}
          {symbol}
        </Text>
      </GridItem>
      <GridItem alignItems="center" display="flex" justifyContent="flex-end">
        {count > 0 && (
          <Box
            borderColor="ui.02"
            borderRadius={4}
            borderWidth={1}
            data-testid="remove-product-button"
            display="inline-flex"
            padding={2}
            onClick={(event) => {
              event.stopPropagation()
              deleteProduct(product.id)
            }}
          >
            <CrossIcon
              _hover={{ cursor: "pointer" }}
              aria-label="delete-product"
              color="interactive.05"
              fontSize={14}
            />
          </Box>
        )}
      </GridItem>
      <OutOfStockModal isOpen={isOpen} onClick={goToVariantPage} onClose={onClose} />
    </Grid>
  )
}

export { ProductItem }
