import { Button, Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useContext } from "react"
import { useNavigate } from "react-router-dom"

import { ProductsContext } from "./product/ProductState/context"
import { Types } from "./product/ProductState/reducers"
import { getLocalStorageStoreFrontIdInShop, setLocalStorageStoreFrontIdInShop } from "./storeFrontLocalSrotage"
import { StoreFront, api } from "api"
import { FormRichSelect } from "common/components"
import { TENANCY_ID } from "common/constants"

const InShopView: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(ProductsContext)
  const { data: storeFrontsData } = api.useStoreFronts(TENANCY_ID)
  const localStorageStoreFrontInShop = getLocalStorageStoreFrontIdInShop()

  const onStoreFrontChange = (id: string) => {
    setLocalStorageStoreFrontIdInShop(id)
    dispatch({ type: Types.SetOrder, payload: { order: { ...state.makeOrder.order, storeFrontId: id } } })
    dispatch({ type: Types.SetStoreFrontId, payload: { storeFrontId: id } })
  }

  const StartOrderButtons = () => (
    <>
      <Button
        _focus={{ boxShadow: "none" }}
        _hover={{ transform: "scale(1.1)" }}
        data-testid="start-order-button"
        style={{ ...buttonStyles, marginBottom: "10px" }}
        variant="primary"
        onClick={() => navigate("product")}
      >
        {t("pressToStart")}
      </Button>
      <Button
        _focus={{ boxShadow: "none" }}
        _hover={{ transform: "scale(1.1)" }}
        data-testid="scan-to-start-button"
        style={{ ...buttonStyles, color: "interactive.01", display: "flex", flexDirection: "column" }}
        variant="ghost"
      >
        <Text>{t("or")}</Text>
        <Text>{t("scanToStart")}</Text>
      </Button>
    </>
  )

  const StoreFrontSelect = () => (
    <Flex align="center" direction="column" gap={4}>
      <Text fontSize="xl">{t("selectStorefront")}</Text>
      <FormRichSelect
        data-testid="store-front-select"
        options={
          storeFrontsData?.value?.map((store: StoreFront) => ({ label: store.name, value: String(store.id) })) ?? []
        }
        value={localStorageStoreFrontInShop}
        onChange={(id) => onStoreFrontChange(id)}
      />
    </Flex>
  )

  return (
    <Flex align="center" direction="column" height="100%" justify="center">
      {localStorageStoreFrontInShop ? <StartOrderButtons /> : <StoreFrontSelect />}
    </Flex>
  )
}

const buttonStyles = {
  fontSize: 18,
  padding: ".9rem 1.3rem",
  width: "auto",
  height: "auto",
  outline: "none",
}

export { InShopView }
