import { Button, Flex, Grid, GridItem, Text } from "@chakra-ui/react"
import { ChevronLeftIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useContext, useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"

import { ViewProductItem } from "./ViewProductItem"
import { PriceBreakdownBox } from "../PriceBreakdownBox/PriceBreakdownBox"
import { ProductModel } from "api"
import { PaymentKeyboard } from "common/components/PaymentType/PaymentKeyboard/PaymentKeyboard"
import { PaymentType } from "common/components/PaymentType/PaymentType"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

const ViewOrder: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state, dispatch } = useContext(ProductsContext)
  const {
    makeOrder: { order },
    bool: { isPayment, isPaymentCash },
  } = state
  const [showButtons, setShowButtons] = useState(true)
  const [receiptButtons, setReceiptButtons] = useState(false)
  const [isOrderCreatingError, setIsOrderCreatingError] = useState(false)

  const onError = () => {
    setIsOrderCreatingError(true)
    setReceiptButtons(false)
    setShowButtons(true)
    dispatch({ type: Types.IsPaymentCash, payload: { isPaymentCash: false } })
  }

  useEffect(() => {
    dispatch({ type: Types.IsPayment, payload: { isPayment: true } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!order.orderLines.length) {
    return <Navigate to="/inShop/product" />
  }

  return (
    <Flex height="100%" overflowY="auto">
      <Flex align="flex-start" direction="column" flex={1} justify="flex-end">
        {isPaymentCash ? (
          <Flex align="center" justify="flex-end" overflowY="auto" pb={16} pl={4} pr={6} width="100%">
            <PaymentKeyboard setReceiptButtons={setReceiptButtons} onError={onError} />
          </Flex>
        ) : (
          <>
            <Flex align="center" fontSize={24} justify="space-between" padding={5}>
              <Flex align="center" height="40px">
                {showButtons && (
                  <Button
                    borderColor="info.01"
                    borderWidth={1}
                    data-testid="back-to-order-button"
                    mr={4}
                    padding={3}
                    variant="ghost"
                    onClick={() => navigate("/inShop/product")}
                  >
                    <ChevronLeftIcon color="info.01" fontSize={20} />
                  </Button>
                )}
                <Text fontWeight="bold">{t("orderSummary")}</Text>
              </Flex>
            </Flex>
            <Grid
              color="text.02"
              fontSize={14}
              padding="8px 30px 8px 5px"
              templateColumns="0.5fr 4fr 1fr 1fr 2fr"
              width="100%"
            >
              <GridItem />
              <GridItem />
              <GridItem>
                <Text>{t("quantity")}</Text>
              </GridItem>
              <GridItem textAlign="right">
                <Text>{t("total")}</Text>
              </GridItem>
              <GridItem textAlign="right">
                <Text>{t("due")}</Text>
              </GridItem>
            </Grid>
            <Flex align="flex-start" direction="column" flexGrow={1} overflowY="auto" width="100%">
              {order.orderLines.map((item: ProductModel) => (
                <ViewProductItem key={item.id} product={item} />
              ))}
            </Flex>
            <PriceBreakdownBox isOrderSummaryVariant />
          </>
        )}
      </Flex>
      {isPayment && (
        <PaymentType
          isOrderCreatingErrorState={[isOrderCreatingError, setIsOrderCreatingError]}
          receiptButtonsState={[receiptButtons, setReceiptButtons]}
          showButtonsState={[showButtons, setShowButtons]}
        />
      )}
    </Flex>
  )
}

export { ViewOrder }
