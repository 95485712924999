import { Button, Flex, Heading, Stack, Text, useTheme } from "@chakra-ui/react"
import { AddIcon, FilterIcon } from "@iac/components.icons.react"
import { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { PerVariantTable } from "./PerVariantTable"
import { CatalogProduct, mockData } from "api"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { route } from "features/app/routes"
import { SearchForProducts } from "features/inventory/products/searchForProducts/SearchForProducts"

export const StockPagePerVariant: FC = () => {
  const { colors } = useTheme()
  const params = useParams()
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const [product, setProduct] = useState<CatalogProduct>(mockData.initCatalogProduct)

  const stockPerVariants = [
    {
      id: "1c916e03-98cc-4b22-a297-c189a0e453b4",
      variant: "Large/Blue",
      onHand: 157,
      reserved: 5,
      available: 152,
      backOrder: 0,
      incoming: 389,
      leadTime: 15,
      safetyStock: 136,
      reorderPoint: 136,
      status: "Active",
    },
    {
      id: "b3142aa5-639b-4678-baec-56fbe264605a",
      variant: "Large/Red",
      onHand: 102,
      reserved: 28,
      available: 74,
      backOrder: 0,
      incoming: 389,
      leadTime: 15,
      safetyStock: 4500,
      reorderPoint: 4500,
      status: "Active",
    },
    {
      id: "53a98677-34de-456f-874d-17fbb484495e",
      variant: "Large/Yellow",
      onHand: 69,
      reserved: 12,
      available: 57,
      backOrder: 0,
      incoming: 389,
      leadTime: 15,
      safetyStock: 4500,
      reorderPoint: 4500,
      status: "Active",
    },
    {
      id: "f4578ca7-58c0-4e1f-8328-787fab6fd329",
      variant: "Medium/Blue",
      onHand: 76,
      reserved: 35,
      available: 41,
      backOrder: 0,
      incoming: 389,
      leadTime: 15,
      safetyStock: 4500,
      reorderPoint: 4500,
      status: "Active",
    },
    {
      id: "68e436b3-6a3f-43e2-b3c3-0fec207f39d2",
      variant: "Medium/Red",
      onHand: 104,
      reserved: 16,
      available: 88,
      backOrder: 0,
      incoming: 389,
      leadTime: 15,
      safetyStock: 4500,
      reorderPoint: 4500,
      status: "Active",
    },
    {
      id: "97a7942f-2945-4b55-93d7-3683ee2ba532",
      variant: "Medium/Yellow",
      onHand: 97,
      reserved: 33,
      available: 64,
      backOrder: 0,
      incoming: 389,
      leadTime: 15,
      safetyStock: 4500,
      reorderPoint: 4500,
      status: "Active",
    },
    {
      id: "33907ffc-382f-475e-8a58-a8c9fda9f604",
      variant: "Small/Blue",
      onHand: 125,
      reserved: 3,
      available: 122,
      backOrder: 0,
      incoming: 389,
      leadTime: 15,
      safetyStock: 4500,
      reorderPoint: 4500,
      status: "Active",
    },
    {
      id: "3be1a0f6-d2fd-401d-a508-fc520fe584a9",
      variant: "Small/Red",
      onHand: 88,
      reserved: 19,
      available: 69,
      backOrder: 0,
      incoming: 389,
      leadTime: 15,
      safetyStock: 4500,
      reorderPoint: 4500,
      status: "Active",
    },
    {
      id: "dd1cbe49-c91b-41e4-8224-30783cc49043",
      variant: "Small/Yellow",
      onHand: 190,
      reserved: 27,
      available: 163,
      backOrder: 0,
      incoming: 389,
      leadTime: 15,
      safetyStock: 4500,
      reorderPoint: 4500,
      status: "Active",
    },
  ]

  useEffect(() => {
    setSearch(search)
    const { id } = params
    if (id) {
      const findProduct = mockData.catalogProduct.catalogProducts.find((item) => item.id === id) as CatalogProduct
      setProduct(findProduct)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack data-testid="stockPagePerVariantContainer">
      <PageHeader
        buttonTitle="Back"
        title={product.name}
        variant="secondary"
        width="150px"
        onClick={() => navigate(`${route.productInfo.path}/${product.id}`)}
      />
      <Flex direction="column" p={4}>
        <Heading data-testid="stockPerVariantTableTitle" mb={6} size="md">
          Stock per variant
        </Heading>
        <Flex direction="column" width="100%">
          <Flex align="center" justify="space-between" mb={6}>
            <Flex width="50%">
              <SearchForProducts titleButton="Search" onSearch={setSearch} />
            </Flex>
            <Button color={colors.primary.blue} variant="ghost">
              <FilterIcon fontSize={22} />
              <Text ml={4}>Filter</Text>
            </Button>
          </Flex>
          <PerVariantTable variants={stockPerVariants} />
        </Flex>
      </Flex>
      <Flex align="flex-start" direction="column" p="2rem 1rem">
        <Button
          data-testid="addNewOrderButton"
          mb={4}
          mr={6}
          variant="primary"
          onClick={() => navigate(route.newOrder.path)}
        >
          <AddIcon border={`1px solid ${colors.white}`} borderRadius="100%" mr={3} /> Add New Order
        </Button>
        <Button
          borderColor={colors.primary.blue}
          color={colors.primary.blue}
          data-testid="viewStockPerOrderButton"
          variant="outline"
          onClick={() => navigate(`${route.productStockPage.path}/${product.id}`)}
        >
          View Stock per Order
        </Button>
      </Flex>
    </Stack>
  )
}
