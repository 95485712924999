import { FormControl, FormLabel, Input, Tooltip } from "@chakra-ui/react"
import { ChangeEvent, FC } from "react"

import { InfoIcon } from "common/theme"

export type CommonFormControlProps = {
  name?: string | null
  label?: string
  inputWidth?: string
  controlWidth?: string
  value?: string | null
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  isRequired?: boolean
  isInfo?: boolean
  tooltipLabel?: string
  placeholder?: string
  readOnly?: boolean
  marginBottom?: string | number
}

export const CommonFormControl: FC<CommonFormControlProps> = ({
  value,
  controlWidth = "20%",
  inputWidth = "100%",
  name,
  label,
  onChange,
  children,
  isRequired = false,
  isInfo = false,
  tooltipLabel = "",
  placeholder = "",
  readOnly = false,
  marginBottom = "30px",
}) => {
  const formStyles = {
    control: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      width: controlWidth,
      marginBottom,
    },
    label: {
      display: "flex",
      margin: "0 0 .5rem 0",
    },
    input: {
      width: inputWidth,
    },
  }

  return (
    <FormControl
      data-testid="control-container"
      isRequired={isRequired}
      style={{ ...formStyles.control, flexDirection: "column" }}
    >
      <FormLabel data-testid="form-label" style={{ ...formStyles.label, alignItems: "center" }}>
        {label}
        {isInfo && (
          <Tooltip bg="black" color="white" colorScheme="black" label={tooltipLabel} placement="right" hasArrow>
            <InfoIcon _hover={{ cursor: "pointer" }} color="info.01" data-testid="tooltip" ml={2} />
          </Tooltip>
        )}
      </FormLabel>
      {children ? (
        children
      ) : (
        <Input
          data-testid="form-input"
          name={name || ""}
          placeholder={placeholder}
          readOnly={readOnly}
          style={formStyles.input}
          value={value || ""}
          onChange={onChange}
        />
      )}
    </FormControl>
  )
}
