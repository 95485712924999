export const initSupplier = {
  id: "",
  tenancyId: "",
  name: "",
  email: "",
  phone: "",
  companyName: "",
  taxRegistrationNumber: "",
  address: "",
  priceIncludesTax: false,
  applyResellerTax: false,
  countryId: 0,
  defaultProductCountryOfOriginId: 0,
  buyingTaxCategoryId: "",
  defaultProductCategoryId: "",
  status: "",
}
