import { i18nInit } from "@iac/translations.i18n-instance"
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Log } from "oidc-client-ts"
import { StrictMode } from "react"
import { render } from "react-dom"
import { AuthProvider } from "react-oidc-context"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import { reactPlugin } from "./appInsights"
import { ErrorPage } from "./common/components"
import { App } from "./features/app/App/App"
import { CommandProvider } from "./features/commands"
import { ProductsProvider } from "./features/inShop/product/ProductState/context"
import { ThemeProvider } from "common/theme/ThemeProvider"
import { configureOpenAPI } from "configureOpenAPI"
import { Healthcheck, OnlineStatusProvider } from "features/app"
import { routes } from "features/app/routes"
import { AUTH_PROVIDER_CONFIG, AuthRoute, OIDC_PATH, PrivateRoutes } from "features/auth"
import { LandingPageRoute } from "features/auth/components/LandingPageRoute"
import { i18nInitOptions } from "features/i18n"
import { isDevelopment } from "isDevelopment"

// Enable authentication client logging in development mode
if (isDevelopment()) {
  Log.setLogger(console)
  Log.setLevel(Log.DEBUG)
}

const onScan: any = require("onscan.js")

i18nInit(i18nInitOptions)

configureOpenAPI()

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    onScan: any
  }
}

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })

window.onScan = onScan

render(
  <StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <AuthProvider {...AUTH_PROVIDER_CONFIG}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ProductsProvider>
            <CommandProvider>
              <ThemeProvider>
                <OnlineStatusProvider>
                  <BrowserRouter>
                    <Routes>
                      <Route element={<App />} path="/">
                        {/* Public Routes used for OIDC/IDS  */}
                        <Route element={<AuthRoute.LoginCallback />} path={OIDC_PATH.LOGIN_CALLBACK} />
                        <Route element={<AuthRoute.LogoutCallback />} path={OIDC_PATH.LOGOUT_CALLBACK} />
                        <Route element={<AuthRoute.SilentRefreshCallback />} path={OIDC_PATH.SILENT_REFRESH_CALLBACK} />
                        {/* Private Routes */}
                        <Route element={<PrivateRoutes />}>
                          <Route element={<LandingPageRoute />} index />
                          {routes.map((props) => (
                            <Route key={props.path} {...props} />
                          ))}
                        </Route>
                        {/* Base Routes */}
                        <Route element={ErrorPage.PageNotFoundError} path="*" />
                      </Route>
                      <Route element={<Healthcheck />} path="/health" />
                    </Routes>
                  </BrowserRouter>
                </OnlineStatusProvider>
                {/*<SpinnerCircle />*/}
              </ThemeProvider>
            </CommandProvider>
          </ProductsProvider>
        </QueryClientProvider>
      </AuthProvider>
    </AppInsightsContext.Provider>
  </StrictMode>,

  document.getElementById("root")
)
