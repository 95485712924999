import React from "react"
import { RouteProps } from "react-router-dom"

import { inventoryRoutes } from "./inventoryRoutes"
import { purchasesRoutes } from "./purchasesRoutes"
import { salesRoutes } from "./salesRoutes"
import { settingsRoutes } from "./settingsRoutes"
import { DownloadAppPage } from "../inShop/DownloadAppPage/DownloadAppPage"
import { InventoryView } from "../inventory"
import { PurchasesView } from "../purchases"
import { SalesView } from "../sales"
import { SettingsView } from "../settings"
import { InShopIcon, InventoryIcon, PurchasesIcon, SalesIcon, SettingsIcon } from "common/theme"
import { ExchangeView } from "features/exchange/Exchange.view"
import { ExchangePayment } from "features/exchange/ExchangePayment/ExchangePayment"
import { InShopView } from "features/inShop/InShop.view"
import { ProductView } from "features/inShop/product/Product.view"
import { ViewOrder } from "features/inShop/ViewOrder/ViewOrder"
import { RefundView } from "features/refund/Refund.view"
import { RefundPayment } from "features/refund/RefundPayment/RefundPayment"
import { RemainingView } from "features/remaining/Remaining.view"
import { RemainingPayment } from "features/remaining/RemainingPayment/RemainingPayment"
import { PasswordView } from "features/usersAndRoles/password/Password.view"
import { RolesView } from "features/usersAndRoles/roles/Roles.view"
import { UsersView } from "features/usersAndRoles/users/Users.view"
import { isInShopAccessible } from "isInShopAccessible"

export type RouteConfig = RouteProps & {
  /* i18n translation key */
  name?: string
  icon?: React.ReactNode
  path: string
}

export type RouteConfigObject = { [key: string]: RouteConfig }

/*
  CLEANUP: this was structured as config objects orignally so the side nav and Ackbar could use the route config
  however, it seems as though it would make more sense to declare the routes using react-router v6's <Routes /> components,
  and any feature-level routes should be in their respective folders and imported here
*/

const topLevelRoutes: RouteConfigObject = {
  inShop: {
    path: "inShop",
    name: "inShop",
    element: isInShopAccessible ? <InShopView /> : <DownloadAppPage />,

    icon: InShopIcon,
  },
  sales: {
    path: "sales",
    name: "sales",
    element: <SalesView />,
    icon: SalesIcon,
  },
  purchases: {
    path: "purchase",
    name: "purchase",
    element: <PurchasesView />,
    icon: PurchasesIcon,
  },
  inventory: {
    path: "inventory",
    name: "inventory",
    element: <InventoryView />,
    icon: InventoryIcon,
  },
  settings: {
    path: "settings",
    name: "settings",
    element: <SettingsView />,
    icon: SettingsIcon,
  },
}

const nestedRoutes: RouteConfigObject = {
  product: {
    path: "inShop/product",
    name: "isShop.product",
    element: isInShopAccessible ? <ProductView /> : <DownloadAppPage />,
  },
  order: {
    path: "/inShop/viewOrder",
    name: "inShop.viewOrder",
    element: isInShopAccessible ? <ViewOrder /> : <DownloadAppPage />,
  },
  refund: {
    path: "/refund",
    name: "refund",
    element: <RefundView />,
  },
  refundPayment: {
    path: "/refundPayment",
    name: "refundPayment",
    element: <RefundPayment />,
  },
  exchange: {
    path: "/exchange",
    name: "exchange",
    element: <ExchangeView />,
  },
  exchangePayment: {
    path: "/exchangePayment",
    name: "exchangePayment",
    element: <ExchangePayment />,
  },
  remaining: {
    path: "/remaining",
    name: "remaining",
    element: <RemainingView />,
  },
  remainingPayment: {
    path: "/remainingPayment",
    name: "remainingPayment",
    element: <RemainingPayment />,
  },
  users: {
    path: "/usersAndRoles/users",
    name: "usersAndRoles.users",
    element: <UsersView />,
  },
  passwords: {
    path: "/usersAndRoles/passwords",
    name: "usersAndRoles.passwords",
    element: <PasswordView />,
  },
  // profile: {
  //   path: "/profile",
  //   name: "profile",
  //   element: <ProfileView />,
  // },
  roles: {
    path: "/usersAndRoles/roles",
    name: "usersAndRoles.roles",
    element: <RolesView />,
  },
}

export const route: RouteConfigObject = {
  ...topLevelRoutes,
  ...nestedRoutes,
  ...inventoryRoutes,
  ...salesRoutes,
  ...purchasesRoutes,
  ...settingsRoutes,
} as const

export const routes: RouteConfig[] = Object.values(route)
