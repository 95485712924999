import { ComponentWithAs, IconProps, SimpleGrid } from "@chakra-ui/react"
import { FC } from "react"

import { ISACard } from "common/components/ISACard/ISACard"
import { PageHeader } from "common/components/PageHeader/PageHeader"

type MenuLayoutProps = {
  pageTitle: string
  cards: { link: string; title: string; icon: ComponentWithAs<"svg", IconProps> }[]
}

const MenuLayout: FC<MenuLayoutProps> = ({ pageTitle, cards }) => (
  <>
    <PageHeader title={pageTitle} width="100%" />
    <SimpleGrid columns={4} data-testid="menu-layout" mt={4} px={20} spacing={10}>
      {cards.map(({ title, link, icon }, index) => {
        const Icon = icon

        return (
          <ISACard key={title} data-test-id="card-button" link={link} title={title}>
            <Icon color="info.01" fontSize={60} />
          </ISACard>
        )
      })}
    </SimpleGrid>
  </>
)

export { MenuLayout }
