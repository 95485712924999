import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { flatten, intersection } from "ramda"
import { useAuth } from "react-oidc-context"
import { useParams } from "react-router-dom"

import { useAdmin, useUserAdmin } from "./admins.hook.mutable"
import { useCustomers } from "./entities"
import { AdminRoles, AdminTenancyPermission, AdminsService, TenanciesService } from "../generated"
import { api } from "api"
import { PermissionType } from "features/auth"

export const useTenancy = (id: UUID) => {
  const { isAuthenticated } = useAuth()

  return useQuery(["tenancies", id], () => TenanciesService.tenanciesGetAsync({ id }), {
    enabled: isAuthenticated,
    refetchOnMount: false,
  })
}

export const useSuggestedTenancies = (adminId: UUID) => {
  const { data: tenancies } = api.useTenancyPermissionsForCurrentUser()
  const { data: userAdmin } = useUserAdmin()
  const { data: managedAdmin } = useAdmin({ id: adminId, expand: "tenancyLinks" })

  const permissions: PermissionType[] = ["admins.Full", "admins.Create"]
  const hasPermission = (tenancy?: AdminTenancyPermission) =>
    intersection(permissions, flatten([tenancy?.permissions])).length > 0

  const claimsTenancyIds = flatten([tenancies])
    .filter(hasPermission)
    .map((tenancy) => tenancy?.tenancyId)

  const userAdminTenancyIds = (userAdmin?.tenancyLinks ?? []).map((t) => t.tenancyId)
  const managedAdminTenancyIds = (managedAdmin?.tenancyLinks ?? []).map((t) => t.tenancyId)

  const { data: suggestedEntities } = useCustomers()

  return (
    suggestedEntities?.value?.filter(
      ({ defaultTenancyId }) =>
        userAdminTenancyIds.includes(defaultTenancyId!) &&
        !managedAdminTenancyIds.includes(defaultTenancyId!) &&
        claimsTenancyIds.includes(defaultTenancyId!)
    ) ?? []
  )
}

export const useLinkTenancyMutation = (adminId: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (links: { tenancyId: UUID; role: AdminRoles }[]) =>
      AdminsService.adminsCreateTenancyLinks({
        id: adminId,
        requestBody: {
          links,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tenancies"])
        queryClient.invalidateQueries(["admins"])
      },
      onError: () => {
        // display error notification
      },
    }
  )
}

export const useDeleteTenancyAdminsMutation = () => {
  const queryClient = useQueryClient()
  const { adminId: childId = "" } = useParams<"adminId">()

  return useMutation((tenancyId: string) => AdminsService.tenanciesDeleteAdminAsync({ id: tenancyId, childId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["tenancies"])
      queryClient.invalidateQueries(["admins"])
    },
    onError: () => {
      // display error notification
    },
  })
}
