import type { ProductImage } from "../models/ProductImage"
import { request as __request } from "../core/request"
import { trackPromise } from "react-promise-tracker"
import { Image } from "../models/Image"

export class ProductImagesService {
  /**
   * Gets all Product Images.
   * ## Requires:
   * ### productImages.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async productImagesGetAllAsync({
    select,
    expand,
    parentId,
    tenancyId,
  }: {
    parentId: string
    tenancyId: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<ProductImage>
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/${parentId}/images`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Create Product Image.
   * ## Requires:
   * ### productImages.Create
   * @returns any Success
   * @throws ApiError
   */
  public static async productImagesPostAsync({
    parentId,
    tenancyId,
    requestBody,
  }: {
    tenancyId: string
    parentId: string
    requestBody?: ProductImage
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & ProductImage
  > {
    const result = await trackPromise(
      __request({
        method: "POST",
        path: `/api/tenancies/${tenancyId}/isa/products/${parentId}/images`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Gets Product Image.
   * ## Requires:
   * ### productImages.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async productImagesGetAsync({
    id,
    parentId,
    tenancyId,
    select,
    expand,
  }: {
    parentId: string
    tenancyId: string
    id: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<{
    readonly "@odata.context"?: string
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${tenancyId}/isa/products/${parentId}/images/${id}`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Update Product Image.
   * ## Requires:
   * ### productImages.Update
   * @returns any Success
   * @throws ApiError
   */
  public static async productImagesPatchAsync({
    tenancyId,
    parentId,
    requestBody,
  }: {
    tenancyId: string
    parentId: string
    requestBody?: Image[]
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & ProductImage
  > {
    const result = await trackPromise(
      __request({
        method: "PATCH",
        path: `/api/tenancies/${tenancyId}/isa/products/${parentId}/images`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          409: `Conflict`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Delete the Product Image.
   * ## Requires:
   * ### productImages.Delete
   * @returns any Success
   * @throws ApiError
   */
  public static async productImagesDeleteAsync({
    id,
    parentId,
    tenancyId,
  }: {
    id: string
    parentId: string
    tenancyId: string
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & ProductImage
  > {
    const result = await trackPromise(
      __request({
        method: "DELETE",
        path: `/api/tenancies/${tenancyId}/isa/products/${parentId}/images/${id}`,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }
}
