import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
} from "@chakra-ui/react"
import { AddIcon } from "@iac/components.icons.react"
import { FC } from "react"

import { VariantsTable } from "../VariantsTable"

type VariantType = {
  id: string
  variant: string
  supplierSku: string
  orderQty: number
  rrp: number
}

type SidebarVariantsProps = {
  onClose: () => void
  isOpen: boolean
  variants: VariantType[]
}

const SidebarVariants: FC<SidebarVariantsProps> = ({ isOpen, variants, onClose }) => (
  <>
    <Drawer isOpen={isOpen} placement="right" size="xl" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent width="60%">
        <DrawerCloseButton />
        <DrawerHeader>Variants</DrawerHeader>

        <DrawerBody mt="5rem">
          <Flex align="center" justify="flex-end" p={4} width="100%">
            <Button border="none" color="#0073b3" variant="outline">
              <AddIcon border="1px solid #0073b3" borderRadius="100%" color="#0073b3" mr={3} /> Add Variant
            </Button>
          </Flex>
          <VariantsTable variants={variants} />
        </DrawerBody>

        <DrawerFooter alignItems="center" display="flex" justifyContent="flex-start">
          <Button variant="primary" width={150} onClick={onClose}>
            Done
          </Button>
          <Button ml={4} variant="secondary" width={150} onClick={onClose}>
            Cancel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  </>
)

export { SidebarVariants }
