import { format } from "date-fns"

export const DELAY = {
  FETCH: 300,
  SPINNER: 100,
}

export const API_BASE = "https://devstable-bifrost.iamcloud.net/api"

export const SALES_EMAIL = "sales@iamcloud.com"

export const SUPPORT_DOMAIN = "support.iamcloud.com"

export const SUPPORT_LINK = `https://${SUPPORT_DOMAIN}`

export const SUPPORT_EMAIL = "support@iamcloud.com"

export const SUPPORT_SEND_EMAIL = `mailto:${SUPPORT_EMAIL}`

export const KNOWLEDGE_CENTER_LINK = "https://iamcloud.mcoutput.com/cdm/Default.htm"

export const CDM_TRIAL_PERIOD_DAYS = "30"

export const TERRITORY_ID = {
  EU: "626c729f-17a0-ea11-9cd8-84fdd17eeb35",
  US: "636c729f-17a0-ea11-9cd8-84fdd17eeb35",
  UK: "646c729f-17a0-ea11-9cd8-84fdd17eeb35",
} as const

export type DatacentreRegion = keyof typeof TERRITORY_ID

export const DEFAULT_ITEMS_PER_PAGE = 10

export const ITEMS_PER_PAGE_SCALES = [10, 20, 30, 40, 50]

export const STATUSES = ["success", "info", "warning", "danger"] as const

export type Status = (typeof STATUSES)[number]

/**
 * Formats as: 1st Jan 2000
 */
export const formatDate1 = (date: Date) => format(date, "do MMM yyyy")

/**
 * Formats as: 1/1/2000
 */
export const formatDate2 = (date: Date) => format(date, "d/M/y")

export const isoFormat = "yyyy-MM-dd"

export const TENANCY_ID = "b15cfb0f-d55a-48ba-8f9d-c2f014a8eb55"

export const COUNTRY_ID = 724

export const cdmReleaseTag = "#CDMRelease"

/**
 * Formats as: 1st Jan 2000
 */
export const formatDateLong = (date: Date) => format(date, "do MMM yyyy")

/**
 * Formats as: 1/1/2000
 */
export const formatDateShort = (date: Date) => format(date, "d/M/y")

/**
 * Formats as: 15:47
 */
export const formatTime = (date: Date) => format(date, "H:MM")

export const delay = async (time: number): Promise<ReturnType<typeof setTimeout>> =>
  new Promise((resolve) => {
    const timeout = setTimeout(() => {
      resolve(timeout)
    }, time)
  })
