import type { Supplier } from "../models/Supplier"
import { request as __request } from "../core/request"
import { trackPromise } from "react-promise-tracker"

export class SuppliersService {
  /**
   * Gets all Suppliers.
   * ## Requires:
   * ### suppliers.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async suppliersGetAllAsync({
    select,
    expand,
    filter,
    parentId,
  }: {
    parentId: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
    filter?: string
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<Supplier>
  }> {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${parentId}/isa/suppliers`,
        query: {
          $select: select,
          $expand: expand,
          $filter: filter,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Create Supplier.
   * ## Requires:
   * ### suppliers.Create
   * @returns any Success
   * @throws ApiError
   */
  public static async suppliersPostAsync({
    parentId,
    requestBody,
  }: {
    parentId: string
    requestBody?: Partial<Supplier>
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & Supplier
  > {
    const result = await trackPromise(
      __request({
        method: "POST",
        path: `/api/tenancies/${parentId}/isa/suppliers`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Gets Supplier.
   * ## Requires:
   * ### suppliers.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async suppliersGetAsync({
    id,
    parentId,
    select,
    expand,
  }: {
    parentId: string
    id: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & Supplier
  > {
    const result = await trackPromise(
      __request({
        method: "GET",
        path: `/api/tenancies/${parentId}/isa/suppliers/${id}`,
        query: {
          $select: select,
          $expand: expand,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Update Suppliers.
   * ## Requires:
   * ### suppliers.Update
   * @returns any Success
   * @throws ApiError
   */
  public static async suppliersPatchAsync({
    parentId,
    id,
    requestBody,
  }: {
    parentId: string
    id: string
    requestBody?: Partial<Supplier>
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & Supplier
  > {
    const result = await trackPromise(
      __request({
        method: "PATCH",
        path: `/api/tenancies/${parentId}/isa/suppliers/${id}`,
        body: requestBody,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          409: `Conflict`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }

  /**
   * Delete the Supplier.
   * ## Requires:
   * ### suppliers.Delete
   * @returns any Success
   * @throws ApiError
   */
  public static async suppliersDeleteAsync({ id, parentId }: { id: string; parentId: string }): Promise<
    {
      readonly "@odata.context"?: string
    } & Supplier
  > {
    const result = await trackPromise(
      __request({
        method: "DELETE",
        path: `/api/tenancies/${parentId}/isa/suppliers/${id}`,
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return result.body
  }
}
