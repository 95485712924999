import { PermissionType } from "features/auth"

const ADMINS_HAS_APP_PERMISSIONS: PermissionType[] = [
  "admins.Create",
  "admins.Delete",
  "admins.Full",
  "admins.Read",
  "admins.Update",
]

export { ADMINS_HAS_APP_PERMISSIONS }
