import { Box, Flex, NumberInput, NumberInputField, Text } from "@chakra-ui/react"
import { Dispatch, FC, SetStateAction, useContext } from "react"

import { DiscountTypes } from "common/discountTypes"
import { ProductsContext } from "features/inShop/product/ProductState/context"

type DiscountInputProps = {
  value: string
  discountType: string
  onDiscountBlur: () => void
  onDiscountChange: (event: string) => void
  onTypeSelect: (type: string) => void
  isOpenSelectState: [boolean, Dispatch<SetStateAction<boolean>>]
}

export const DiscountInput: FC<DiscountInputProps> = ({
  discountType,
  onDiscountBlur,
  onDiscountChange,
  onTypeSelect,
  value,
  isOpenSelectState,
}) => {
  const {
    state: {
      globalEntities: {
        tenancyCurrency: {
          currency: { symbol },
        },
      },
    },
  } = useContext(ProductsContext)
  const [isOpenSelect, setIsOpenSelect] = isOpenSelectState

  const boxStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    borderColor: "#cac5c6",
    borderWidth: 1,
    borderLeftRadius: 5,
    fontSize: 14,
    borderRightWidth: 1,
    borderRightColor: "CFD3D8",
  }

  return (
    <>
      <Box
        pos="relative"
        {...boxStyles}
        _hover={{ cursor: "pointer" }}
        height="40px"
        width="40%"
        zIndex={1}
        onClick={() => setIsOpenSelect(!isOpenSelect)}
      >
        <Text>{discountType === DiscountTypes.Amount ? symbol : "%"}</Text>
        {isOpenSelect && (
          <Box
            bg="white"
            borderRadius={5}
            borderWidth={1}
            data-testid="currency-select"
            pos="absolute"
            py="5px"
            top="41px"
            width="100%"
            zIndex={1}
          >
            <Flex
              _hover={{ cursor: "pointer", background: "#DBF3FF" }}
              data-testid="currency-select-button"
              justify="center"
              onClick={() => onTypeSelect(DiscountTypes.Amount)}
            >
              {symbol}
            </Flex>
            <Flex
              _hover={{ cursor: "pointer", background: "#DBF3FF" }}
              justify="center"
              onClick={() => onTypeSelect(DiscountTypes.Percentage)}
            >
              %
            </Flex>
          </Box>
        )}
      </Box>
      <NumberInput value={value} onBlur={onDiscountBlur} onChange={onDiscountChange}>
        <NumberInputField borderLeftRadius={0} borderLeftWidth={0} borderRightRadius={5} pr={4} textAlign="right" />
      </NumberInput>
    </>
  )
}
