import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { Customer, CustomersService } from "../generated"
import { ODataOptions } from "../handmade"

export const useCustomersAll = (tenancyId: UUID, options?: ODataOptions) =>
  useQuery(["customers", options], () => CustomersService.customersGetAllAsync({ tenancyId, ...options }), {
    enabled: !!tenancyId,
    cacheTime: 0,
  })

export const useSearchCustomers = (tenancyId: UUID, searchTerm: string, options?: ODataOptions) =>
  useQuery(
    ["searchCustomers", tenancyId, searchTerm, options],
    () => CustomersService.customersSearchAsync({ searchTerm, tenancyId, ...options }),
    {
      enabled: !!searchTerm,
    }
  )

export const useCustomerEntity = (tenancyId: UUID, id: UUID) =>
  useQuery(["customers", id], () => CustomersService.customersGetAsync({ tenancyId, id }), {
    enabled: !!id,
    cacheTime: 0,
  })

export const useCreateSalesCustomerMutation = (
  { onSuccess }: { onSuccess: (data: Customer) => void },
  tenancyId: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (customer: Partial<Customer>) => CustomersService.customersPostAsync({ tenancyId, requestBody: customer }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["customers"])
        onSuccess(data)
      },
      onError: () => {},
    }
  )
}

export const useUpdateSalesCustomerMutation = (
  { onSuccess }: { onSuccess: (data: Customer) => void },
  tenancyId: UUID,
  id: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (customer: Partial<Customer>) =>
      CustomersService.customersPatchAsync({ tenancyId, id, requestBody: customer as any }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["customers"])
        onSuccess(data)
      },
      onError: () => {},
    }
  )
}

export const useDeleteCustomerMutation = ({ onSuccess }: { onSuccess: () => void }, tenancyId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(() => CustomersService.customersDeleteAsync({ id, tenancyId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["customers"])
      onSuccess()
    },
    onError: () => {},
  })
}
