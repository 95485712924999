import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

import { MenuLayout } from "common/components"
import { CompanyInfoIcon, CurrencyIcon, LanguageIcon, SettingsIcon, StoreIcon } from "common/theme"

const SettingsView: FC = () => {
  const { t } = useTranslation()

  const cards = [
    { link: "companyInfo", title: t("companyInfo.companyInformation"), icon: CompanyInfoIcon },
    { link: "storeFronts", title: t("storeFronts.storeFront"), icon: StoreIcon },
    { link: "languages", title: t("languages.language"), icon: LanguageIcon },
    { link: "currencies", title: t("currencies.currency_other"), icon: CurrencyIcon },
    { link: "troubleshooting", title: t("troubleshooting.troubleshooting_title"), icon: SettingsIcon },
  ]

  return <MenuLayout cards={cards} pageTitle={t("configurations")} />
}

export { SettingsView }
