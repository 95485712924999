import { Box, Flex, useDisclosure } from "@chakra-ui/react"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { ShipmentMethodsTable } from "./ShipmentMethodsTable"
import { ShipmentMethod, mockData } from "api"
import { ConfirmModal } from "common/components/ConfirmModal/ConfirmModal"
import { PageHeader } from "common/components/PageHeader/PageHeader"
import { route } from "features/app/routes"
import { Search } from "features/inventory/search/Search"

const initShipmentMethod = {
  id: "",
  name: "",
  description: "",
}

const ShipmentMethodsView: FC = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)
  const [shipmentMethod, setShipmentMethod] = useState<ShipmentMethod>(initShipmentMethod)
  const [shipmentMethods, setShipmentMethods] = useState<ShipmentMethod[]>([])

  const deleteShipment = (): void => {
    const shipmentsArray: ShipmentMethod[] = JSON.parse(localStorage.getItem("shipments") as string)
    const filteredShipments = shipmentsArray.filter((item) => item.id !== shipmentMethod.id)
    localStorage.setItem("shipments", JSON.stringify(filteredShipments))
    setShipmentMethods(filteredShipments)
    onClose()
  }

  useEffect(() => {
    setLoading(true)
    const shipments = JSON.parse(localStorage.getItem("shipments") as string)
    if (!shipments || !shipments.length) {
      localStorage.setItem("shipments", JSON.stringify(mockData.shippingMethod.shipmentMethods))
      setShipmentMethods(mockData.shippingMethod.shipmentMethods)
      setLoading(false)
    } else {
      setShipmentMethods(shipments)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (search.length === 0) {
      const shipmentsArray = JSON.parse(localStorage.getItem("shipments") as string)
      setShipmentMethods(shipmentsArray)
    } else {
      const shipmentsArray: ShipmentMethod[] = JSON.parse(localStorage.getItem("shipments") as string)
      const findShipment = shipmentsArray.filter((item) => item.name.toLocaleLowerCase().includes(search))
      setShipmentMethods(findShipment)
    }
  }, [search])

  return (
    <Box p={4}>
      <PageHeader buttonTitle="Add New Shipment" title="Shipments" onClick={() => navigate(route.shipmentAdd.path)} />
      <Flex data-testid="shipments" direction="column" p={4}>
        <Search placeholder="Search for shipments" titleButton="Search shipments" onSearch={setSearch} />
        <Box p={4} />
        <ShipmentMethodsTable
          loading={loading}
          setShipmentMethod={setShipmentMethod}
          shipmentMethods={shipmentMethods}
          onOpen={onOpen}
        />
      </Flex>
      <ConfirmModal
        isOpen={isOpen}
        name={shipmentMethod.name || "this"}
        type="shipment method"
        isDelete
        onClick={deleteShipment}
        onClose={onClose}
      />
    </Box>
  )
}

export { initShipmentMethod, ShipmentMethodsView }
