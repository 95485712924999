import {
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, ReactElement } from "react"

import { FormButtons } from "../FormButtons/FormButtons"

type ImagesSelectModalLayoutProps = {
  onClose: () => void
  libraryPanel?: ReactElement
  uploadPanel?: ReactElement
  onDone: () => void
}

const ImagesSelectModalLayout: FC<ImagesSelectModalLayoutProps> = ({ onClose, libraryPanel, uploadPanel, onDone }) => {
  const { t } = useTranslation()

  return (
    <ModalContent height="655px" width="800px">
      <ModalHeader fontSize="2xl" ml={3} pb={0}>
        {t("imagesControl.choosePhoto")}
      </ModalHeader>
      <ModalBody>
        <Tabs height="100%">
          <TabList border="unset" color="Text03">
            <Tab
              _selected={{ color: "Interactive01", borderColor: "Interactive01" }}
              data-testid="upload-photos-tab"
              fontSize="lg"
              ml={3}
              mr={10}
              pb={1}
              px={0}
            >
              {t("imagesControl.uploadPhotos")}
            </Tab>
            {libraryPanel && (
              <Tab
                _selected={{ color: "Interactive01", borderColor: "Interactive01" }}
                data-testid="photo-library-tab"
                fontSize="lg"
                pb={1}
                px={0}
              >
                {t("imagesControl.photoLibrary")}
              </Tab>
            )}
          </TabList>

          <TabPanels>
            <TabPanel pb={0} px={0}>
              {uploadPanel}
            </TabPanel>
            {libraryPanel && <TabPanel px={0}>{libraryPanel}</TabPanel>}
          </TabPanels>
        </Tabs>
      </ModalBody>

      <ModalFooter justifyContent="flex-start" w="unset">
        <FormButtons buttonTestIdSuffix="-modal" cancel={onClose} disabled={false} done={onDone} />
      </ModalFooter>
    </ModalContent>
  )
}

export type { ImagesSelectModalLayoutProps }

export { ImagesSelectModalLayout }
