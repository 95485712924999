import React from "react"

import { RouteConfigObject } from "./routes"
import {
  AddProduct,
  AddProductCategory,
  ChangeProductVariant,
  EditProduct,
  EditProductCategory,
  EditSubCategory,
  NewPurchaseOrder,
  OptionValueChanges,
  ProductCategoryView,
  ProductInfo,
  ProductsView,
  StockPage,
  StockPagePerVariant,
  UnitOfMeasureView,
  UnitsOfMeasureChanges,
  VariantOptionChanges,
  VariantOptionValuesView,
  VariantOptionsView,
} from "../inventory"

export const inventoryRoutes: RouteConfigObject = {
  categories: {
    path: "/inventory/categories",
    name: "inventory.categories",
    element: <ProductCategoryView />,
  },
  addCategory: {
    path: "/inventory/categories/addCategory",
    name: "categories.addCategory",
    element: <AddProductCategory />,
  },
  editCategory: {
    path: "/inventory/categories/:id",
    name: "categories.editCategory",
    element: <EditProductCategory />,
  },
  editSubcategory: {
    path: "/inventory/categories/:categoryId/:id",
    name: "categories.editSubcategory",
    element: <EditSubCategory />,
  },
  variantOptions: {
    path: "/inventory/variantOptions",
    name: "inventory.variantOptions",
    element: <VariantOptionsView />,
  },
  addVariantOption: {
    path: "/inventory/variantOptions/addVariantOption",
    name: "variantOptions.addVariantOption",
    element: <VariantOptionChanges />,
  },
  editVariantOption: {
    path: "/inventory/variantOptions/:id",
    name: "variantOptions.editVariantOption",
    element: <VariantOptionChanges />,
  },
  variantOptionValues: {
    path: "/inventory/variantOptions/:optionId/variantOptionValues",
    name: "variantOption.variantOptionValues",
    element: <VariantOptionValuesView />,
  },
  addOptionalValue: {
    path: "/inventory/variantOptions/:optionId/variantOptionValues/addOptionalValue",
    name: "variantOptions.addOptionalValue",
    element: <OptionValueChanges />,
  },
  editOptionalValue: {
    path: "/inventory/variantOptions/:optionId/variantOptionValues/:id",
    name: "variantOptions.editOptionalValue",
    element: <OptionValueChanges />,
  },
  unitsOfMeasure: {
    path: "/inventory/unitsOfMeasure",
    name: "inventory.unitsOfMeasure",
    element: <UnitOfMeasureView />,
  },
  addUnitOfMeasure: {
    path: "/inventory/unitsOfMeasure/addUnitOfMeasure",
    name: "unitsOfMeasure.addUnitOfMeasure",
    element: <UnitsOfMeasureChanges />,
  },
  editUnitOfMeasure: {
    path: "/inventory/unitsOfMeasure/:id",
    name: "unitsOfMeasure.editUnitOfMeasure",
    element: <UnitsOfMeasureChanges />,
  },
  products: {
    path: "/inventory/products",
    name: "inventory.products",
    element: <ProductsView />,
  },
  productInfo: {
    path: "/inventory/products/info/:productId",
    name: "inventory.product",
    element: <ProductInfo />,
  },
  addProduct: {
    path: "/inventory/products/addProduct",
    name: "products.addProduct",
    element: <AddProduct />,
  },
  addProductViaSCode: {
    path: "/inventory/products/addProduct/:sCode",
    name: "products.addProduct",
    element: <AddProduct />,
  },
  editProduct: {
    path: "/inventory/products/:id",
    name: "products.editProduct",
    element: <EditProduct />,
  },
  addVariantForProductViaScanner: {
    path: "/inventory/products/addVariant",
    name: "products.addVariant",
    element: <ChangeProductVariant />,
  },
  addVariantForProduct: {
    path: "/inventory/products/info/:productId/addVariant",
    name: "products.addVariant",
    element: <ChangeProductVariant />,
  },
  editVariantForProduct: {
    path: "/inventory/products/info/:productId/:variantId",
    name: "products.editVariant",
    element: <ChangeProductVariant />,
  },
  productStockPage: {
    path: "/inventory/products/productStockPage",
    name: "products.productStockPage",
    element: <StockPage />,
  },
  productStockPerVariant: {
    path: "/inventory/products/productStockPerVariant",
    name: "products.productStockPerVariant",
    element: <StockPagePerVariant />,
  },
  newOrder: {
    path: "/inventory/products/newOrder",
    name: "products.newOrder",
    element: <NewPurchaseOrder />,
  },
}
