import { Button, Flex, Grid, GridItem, Text, useDisclosure } from "@chakra-ui/react"
import { CrossIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js"
import { format } from "date-fns"
import { FC, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { ProductsContext } from "./ProductState/context"
import { Types } from "./ProductState/reducers"
import { api, mockData } from "api"
import { ProductModel } from "api/generated"
import { ConfirmModal } from "common/components/ConfirmModal/ConfirmModal"
import { ProductItem } from "common/components/ProductItem/ProductItem"
import { TENANCY_ID } from "common/constants"
import { DiscountTypes } from "common/discountTypes"
import { CustomerDetail } from "features/customers/CustomerDetail/CustomerDetail"
import { deleteProduct, getCount, getInitProduct, resetState } from "features/inShop/inShopUtils"
import { PriceBreakdownBox } from "features/inShop/PriceBreakdownBox/PriceBreakdownBox"
import { ProductDetails } from "features/inShop/ProductDetails/ProductDetails"

const ProductView: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { state, dispatch } = useContext(ProductsContext)

  const {
    makeOrder: { order },
    bool,
  } = state
  const [product, setProduct] = useState<ProductModel>(mockData.initProduct)
  const { data: customerData } = api.useCustomerEntity(TENANCY_ID, order.customerId || "")
  const customerName = customerData?.name

  const appInsights = useAppInsightsContext()
  const trackCheckout = useTrackEvent(appInsights, "checkout", order.orderLines)
  const trackProductUpdate = useTrackEvent(appInsights, "Products Updated", order.orderLines)

  useEffect(() => {
    trackProductUpdate(order.orderLines)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.orderLines])

  // @ts-ignore
  const performCheckout = () => trackCheckout()
  // const trackComponent = useTrackMetric(appInsights, "Product")

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => performCheckout(), [])

  const updateActive = (products: ProductModel[], id: string): ProductModel[] =>
    products.map((item) => (item.id === id ? { ...item, isActive: true } : { ...item, isActive: false }))

  const openDetails = (product: ProductModel): void => {
    const isExistProduct = order.orderLines.find((item: ProductModel) => item.id === product.id)
    const resetActiveProducts = updateActive(order.orderLines, product.id)
    dispatch({ type: Types.SetOrder, payload: { order: { ...order, orderLines: resetActiveProducts } } })
    if (!isExistProduct) {
      const newProduct = {
        ...product,
        quantity: 1,
        deposit: 0,
        isActive: true,
        deliveryDate: format(Date.now(), "MM/dd/yyyy"),
      }

      dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: { ...newProduct } } })
      dispatch({ type: Types.OpenDetails, payload: { openDetails: true } })
    } else {
      dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: product } })
      dispatch({ type: Types.OpenDetails, payload: { openDetails: true } })
    }
  }

  useEffect(() => {
    if (order.orderLines.length > 0) {
      const searchableProduct = order.orderLines.find((item) => item.quantity === 0) as ProductModel
      if (searchableProduct) {
        const initialize = {
          ...getInitProduct(searchableProduct),
          quantity: 1,
          discountType: DiscountTypes.Amount,
          isActive: false,
        }
        getCount(initialize, state, dispatch)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.orderLines])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch({ type: Types.SetOrderType, payload: { typeOrder: "order" } }), [])

  useEffect(() => {
    if (!bool.openDetails) {
      dispatch({ type: Types.IsUpdate, payload: { isUpdate: true } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bool.openDetails])

  useEffect(() => {
    if (bool.isUpdate) {
      dispatch({ type: Types.IsUpdate, payload: { isUpdate: false } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.orderLines, bool.isUpdate])

  return (
    <Flex height="100%" overflowY="auto">
      <Flex align="flex-start" direction="column" flex={1}>
        <Flex align="center" fontSize={24} justify="space-between" padding="3rem 1.5rem" width="100%">
          <Flex align="center">
            <Button
              borderColor="info.01"
              borderWidth={1}
              data-testid="cancel-order-button"
              mr={10}
              padding={3}
              variant="ghost"
              onClick={onOpen}
            >
              <CrossIcon color="info.01" />
            </Button>
            <Text fontWeight="bold">{t("newOrder")}</Text>
          </Flex>
          <Flex align="center" fontWeight="bold" justify="flex-start">
            <Text>{t("customer")}:</Text>
            <Text color="text-02" fontSize={20} ml={6}>
              {customerName ?? t("newCustomer")}
            </Text>
          </Flex>
        </Flex>
        <Grid color="text.02" pb={6} px={6} templateColumns="3fr 2fr 2fr 2fr 2fr 0.5fr" width="100%">
          <GridItem alignItems="start">
            <Text>{t("productTitle")}</Text>
          </GridItem>
          <GridItem textAlign="end">
            <Text>{t("quantity")}</Text>
          </GridItem>
          <GridItem textAlign="end">
            <Text>{t("price")}</Text>
          </GridItem>
          <GridItem textAlign="end">
            <Text>{t("total")}</Text>
          </GridItem>
          <GridItem textAlign="end">
            <Text>{t("due")}</Text>
          </GridItem>
        </Grid>
        <Flex align="flex-start" direction="column" flexGrow={1} overflowY="auto" width="100%">
          {state.makeOrder.order.orderLines?.map((item) => (
            <ProductItem
              key={item.id}
              deleteProduct={() => deleteProduct(state.makeOrder.orderProduct, item.id, state, dispatch)}
              getCount={getCount}
              product={item}
              setProduct={setProduct}
              onClick={() => openDetails(item)}
            />
          ))}
        </Flex>
        <PriceBreakdownBox />
      </Flex>
      {state.bool.openDetails && <ProductDetails product={product} />}
      {state.bool.showCustomerDetail && <CustomerDetail onOpen={onOpen} />}
      <ConfirmModal
        isOpen={isOpen}
        type="order"
        onClick={() => {
          resetState(dispatch)
          navigate("/inShop")
        }}
        onClose={onClose}
      />
    </Flex>
  )
}

export { ProductView }
