import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { StoreFrontsService } from "../generated"
import { ODataOptions } from "../handmade"

export const useStoreFronts = (parentId: UUID, options?: ODataOptions) =>
  useQuery(["storeFronts", options], () => StoreFrontsService.storeFrontsGetAllAsync({ parentId, ...options }), {
    cacheTime: 0,
  })

export const useStoreFront = (parentId: UUID, id: UUID) =>
  useQuery(["storeFronts", parentId, id], () => StoreFrontsService.storeFrontsGetAsync({ parentId, id }), {
    enabled: !!id,
    cacheTime: 0,
  })

export const useCreateStoreFrontsMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (storeFront: {
      name: string
      description: string
      type: string
      address: string
      status: string
      currencyId: string
      languageId: string
      isDefault: boolean
      websiteType?: string
    }) => StoreFrontsService.storeFrontsPostAsync({ parentId, requestBody: storeFront as any }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["storeFronts"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useUpdateStoreFrontsMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (storeFront: {
      name: string
      description: string
      type: string
      address: string
      status: string
      currencyId: string
      languageId: string
      isDefault: boolean
      websiteType?: string
    }) => StoreFrontsService.storeFrontsPatchAsync({ parentId, id, requestBody: storeFront as any }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["storeFronts"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useDeleteStoreFrontsMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(() => StoreFrontsService.storeFrontsDeleteAsync({ parentId, id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["storeFronts"])
      onSuccess()
    },
    onError: () => {},
  })
}
