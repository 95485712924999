import { Box, Button, Flex, FormLabel, HStack, Stack, Switch, Textarea } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useState } from "react"

import { api } from "api"
import { PageHeader } from "common/components"
import { TENANCY_ID } from "common/constants"
import {
  getTroubleshooting,
  getTroubleshootingParsed,
  toggleEnableTroubleshooting,
} from "common/utils/troubleshooting.utils"

const TroubleshootingView: FC = () => {
  const { t } = useTranslation()
  const [isEnabled, setIsEnabled] = useState(!!getTroubleshooting())

  const sendLogMutation = api.useSendTroubleshootingLogs(TENANCY_ID)
  const formatRecord = (record: string): string =>
    record
      ? // eslint-disable-next-line fp/no-mutating-methods
        JSON.parse(record)
          .reverse()
          .map((rec: any) => JSON.stringify(rec))
          .join("\n")
      : ""
  const [record, setRecord] = useState(formatRecord(getTroubleshooting() ?? ""))

  const sendLogs = () => sendLogMutation.mutate(getTroubleshootingParsed())

  return (
    <>
      <PageHeader link="/settings" title={t("troubleshooting.troubleshooting_title")} isArrow />
      <Box pl={20} pr={10} py={4}>
        <Stack spacing={8}>
          <HStack justify="space-between">
            <Flex alignItems="center">
              <Switch
                data-testid="enable-logging-switch"
                id="enable-logging"
                isChecked={isEnabled}
                onChange={(event) => {
                  toggleEnableTroubleshooting(event.currentTarget.checked)
                  setIsEnabled(event.currentTarget.checked)
                  if (!event.currentTarget.checked) setRecord("")
                }}
              />
              <FormLabel htmlFor="enable-logging" mb="0" ml={4}>
                {t("troubleshooting.enableRealtimeLogging")}
              </FormLabel>
            </Flex>
            <HStack spacing={8}>
              <Button
                data-testid="clear-button"
                onClick={() => {
                  toggleEnableTroubleshooting(true)
                  setRecord("")
                }}
              >
                {t("clear")}
              </Button>
              <Button data-testid="send-button" disabled={!record} onClick={() => sendLogs()}>
                {t("troubleshooting.send")}
              </Button>
            </HStack>
          </HStack>
          <Box>
            <Textarea h="calc(100vh - 360px)" value={record} readOnly />
          </Box>
        </Stack>
      </Box>
    </>
  )
}

export { TroubleshootingView }
