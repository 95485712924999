import { useTranslation } from "@iac/translations.i18n-instance"
import constate from "constate"
import { adjust, assoc, findIndex, mergeDeepLeft as merge, without } from "ramda"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { v4 as uuid } from "uuid"

import { CustomerType } from "./../customer.utils"
import { determineAdminRole } from "./add-customer.utils"
import { Admin, AdminRoles, Country, Entity, api } from "api"
import { useAdmins } from "api/hooks"
import { emptyAdmin, generalRoles, updateAdminRolesForCustomer } from "features/admins"

export const MAX_ADMIN_COUNT = 10

const partnerManagerFilter = (customerType: CustomerType) => (role: AdminRoles) =>
  role !== AdminRoles.PARTNER_MANAGER || customerType !== "consumer"

export const useAddCustomerCustomerType = () => {
  const navigate = useNavigate()

  const params = useParams<"customerType">()
  const setCustomerType = (customerType: CustomerType) => navigate(`/customers/add/${customerType}`, { replace: true })

  return { customerType: params.customerType as CustomerType, setCustomerType }
}

const useAddCustomerForm = () => {
  const [parent, setParent] = useState<Entity | null>()
  const [parentSearch, _setParentSearch] = useState("")

  const [country, setCountry] = useState<Country | null>()
  const [countrySearch, _setCountrySearch] = useState("")

  const setParentSearch = (search = "") => {
    _setParentSearch(search)
    if (!search) setParent(null)
  }

  const setCountrySearch = (search = "") => {
    _setCountrySearch(search)
    if (!search) setCountry(null)
  }

  return {
    state: { parent, parentSearch, country, countrySearch },
    actions: { setParent, setParentSearch, setCountry, setCountrySearch },
  }
}

const useAddCustomerAdmins = () => {
  const [adminToEdit, setAdminToEdit] = useState<Admin | null>(null)
  const { data: creatorAdmin } = api.useUserAdmin()

  // set the creator admin to be the owner
  const [admins, _setAdmins] = useState<Admin[]>(creatorAdmin ? [{ ...creatorAdmin, isOwner: true }] : [])

  useEffect(() => {
    _setAdmins(creatorAdmin ? [{ ...creatorAdmin, isOwner: true }] : [])
  }, [creatorAdmin])

  const [suggestedAdminIds, setSuggestedAdminIds] = useState<UUID[]>([])

  const { data: suggestedAdmins } = useAdmins(
    {
      // Only include admins who belong to the same customer as the user, and of the customer being managed.
      entityOfUser: true,
      managedCustomer: true,
    },
    true, // Don't suggest admins who belong to the root customer.
    true // Don't suggest the creator admin.
  )

  const { customerType } = useAddCustomerCustomerType()

  // tile role options are general roles
  const roles = generalRoles.filter(partnerManagerFilter(customerType))

  const canAddAdmin = adminToEdit === null && admins.length < MAX_ADMIN_COUNT

  const setAdmins = (newAdmins: Admin[]) => {
    const hasOwner = newAdmins.find((a) => a.isOwner)
    const newAdminsWithOwner = hasOwner ? newAdmins : adjust(0, assoc("isOwner", true), newAdmins)

    _setAdmins(newAdminsWithOwner)
  }

  return {
    state: {
      admins: updateAdminRolesForCustomer(customerType, admins),
      adminToEdit,
      roles,
      canAddAdmin,
      suggestedAdmins,
      creatorAdmin,
    },
    actions: {
      addNewAdmin: () => setAdminToEdit({ ...emptyAdmin, id: "" }),

      addSuggestedAdmins: (adminsToAdd: Admin[], role: AdminRoles) => {
        setSuggestedAdminIds([...adminsToAdd.map((a) => a.id!), ...suggestedAdminIds])
        setAdmins([...admins, ...adminsToAdd.map((a) => ({ ...a, role }))])
      },

      removeSuggestedAdmin: (adminToRemove: Admin) => {},

      editAdmin: (admin: Admin) => setAdminToEdit(admin),

      cancelChanges: () => setAdminToEdit(null),

      makeOwner: ({ id }: Admin) =>
        setAdmins(
          admins.map((admin) => ({
            ...admin,
            role: determineAdminRole(admin.isOwner, admin.id === id, admin.role, customerType),
            isOwner: admin.id === id,
          }))
        ),

      saveAdmin: (admin: Admin) => {
        const i = findIndex((a) => a.id === admin?.id, admins)
        const adminExists = i > -1
        const newAdmins = adminExists
          ? adjust(i, merge(admin), admins)
          : ([...admins, { ...admin, id: uuid() }] as Admin[])

        setAdmins(newAdmins)
        setAdminToEdit(null)
      },

      deleteAdmin: (admin: Admin) => {
        setAdmins(without([admin], admins))
        setAdminToEdit(null)
        setSuggestedAdminIds(without([admin.id!], suggestedAdminIds))
      },
    },
  }
}

const useAddCustomerContext = () => {
  const { t } = useTranslation()
  const { customerType, setCustomerType } = useAddCustomerCustomerType()
  const customerTypeLabel = t(`customers.customerType.${customerType}`)

  const admins = useAddCustomerAdmins()
  const form = useAddCustomerForm()

  const setCustomerTypeAndResetParent = (customerType: CustomerType) => {
    setCustomerType(customerType)
    form.actions.setParentSearch("")
  }

  return {
    state: { ...admins.state, ...form.state, customerType, customerTypeLabel },
    actions: { ...admins.actions, ...form.actions, setCustomerType: setCustomerTypeAndResetParent },
  }
}

export const [AddCustomerProvider, useAddCustomerState, useAddCustomerActions] = constate(
  useAddCustomerContext,
  (ctx) => ctx.state,
  (ctx) => ctx.actions
)

export type AddCustomerState = ReturnType<typeof useAddCustomerState>
