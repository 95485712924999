import { VariantOptionValue } from "../generated"

export const initVariantOptionValue = {
  id: "",
  variantOptionId: "",
  value: "",
  color: "",
  imageId: "",
  description: "",
  status: "",
  translations: [],
  image: { id: "", url: "", thumbnailUrl: "" },
} as VariantOptionValue
