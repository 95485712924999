import {
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  Stack,
  StackProps,
  Tooltip,
  TooltipProps,
} from "@chakra-ui/react"
import { propOr } from "ramda"
import { PropsWithChildren, ReactElement, cloneElement } from "react"
import { DeepMap, FieldError, Path, UseFormRegister } from "react-hook-form"

export type FormFieldProps<FieldValues = any> = {
  name?: Path<FieldValues> | null
  label: string
  isDisabled?: boolean
  isRequired?: boolean
  errors: DeepMap<{ [x: string]: (string | number | readonly string[] | undefined) & (string | number) }, FieldError>
  register?: UseFormRegister<FieldValues>
  labelProps?: FormLabelProps
  stackProps?: StackProps
  tooltipProps?: TooltipProps
  formControlProps?: FormControlProps
}

export const FormField = <T,>({
  name,
  label,
  isDisabled,
  isRequired = false,
  errors,
  children,
  labelProps,
  tooltipProps,
  stackProps,
  formControlProps,
  register,
}: PropsWithChildren<FormFieldProps<T>>) => {
  // @ts-ignore: fix later
  const error = errors[name]
  const message: string = propOr("", "message", error)

  return (
    <FormControl
      {...formControlProps}
      data-testid="FormField"
      isDisabled={isDisabled}
      isInvalid={Boolean(error)}
      isRequired={isRequired}
    >
      <Stack {...stackProps}>
        <FormLabel htmlFor={name ?? undefined} my={0} {...labelProps}>
          {label}
        </FormLabel>
        <Tooltip
          bg="error.01"
          color="white"
          data-testid={"validation-error-" + name}
          flex={1}
          fontSize="xs"
          isOpen={Boolean(error)}
          label={message}
          p={0.5}
          pl={2}
          placement="right"
          pr={2}
          hasArrow
          {...tooltipProps}
        >
          {cloneElement(children as ReactElement, name && register ? register(name) : {})}
        </Tooltip>
      </Stack>
    </FormControl>
  )
}
