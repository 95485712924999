/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum HomeIndicator {
    HOME = 'Home',
    IAMCLOUD = 'IAMCloud',
    DISTRIBUTOR = 'Distributor',
    PARTNER = 'Partner',
    EXTERNAL = 'External',
    UNKNOWN = 'Unknown',
}