import { Button, Flex, NumberInput, NumberInputField, Stack, Tooltip, useMediaQuery } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react"

import { ClosedKeyboard } from "./ClosedKeyboard"
import { FastKeyboard } from "./FastKeyboard"
import { buttonStyle, sizes } from "./keyboardStyles"
import { SimpleKeyboard } from "./SimpleKeyboard"
import { SalesOrderStatus, api } from "api"
import { appInsights } from "appInsights"
import { TENANCY_ID } from "common/constants"
import { DiscountTypes } from "common/discountTypes"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

type PaymentKeyboardProps = {
  onError: () => void
  setReceiptButtons: Dispatch<SetStateAction<boolean>>
}

export const PaymentKeyboard: FC<PaymentKeyboardProps> = ({ onError, setReceiptButtons }) => {
  const { t } = useTranslation()
  const [amount, setAmount] = useState("")
  const [isError, setIsError] = useState(false)
  const [keyboardType, setKeyboardType] = useState("")
  const {
    state: {
      makeOrder: { order },
      globalEntities: {
        storeFrontId,
        tenancyCurrency: {
          currencyId,
          currency: { symbol },
        },
      },
    },
    dispatch,
  } = useContext(ProductsContext)
  const createSalesOrder = api.useCreateSalesOrderMutation(
    {
      onSuccess: (data) => {
        dispatch({ type: Types.SetOrder, payload: { order: { ...order, id: data.id || "" } } })
        window.location.href = `isa://order?type=cash-draw&storefrontId=${storeFrontId}`
        setReceiptButtons(true)
        dispatch({ type: Types.IsPaymentCash, payload: { isPaymentCash: false } })
        appInsights.stopTrackEvent("Create Order")
      },
    },
    {
      onError: () => onError(),
    },
    TENANCY_ID
  )
  const [isLongThan750] = useMediaQuery("(min-height: 750px)")

  const format = (value: string) => (amount.length ? `${symbol}${value}` : "")

  const completeOrder = (value?: number): void => {
    appInsights.startTrackEvent("Create Order")
    const date = new Date()
    const paidAmount = value || parseFloat(amount)
    const change = +(paidAmount - order.total).toFixed(2)
    if (change >= 0) {
      dispatch({ type: Types.SetOrder, payload: { order: { ...order, paidAmount, change } } })
      const orderLines = order.orderLines.map((item) => ({
        productVariantId: item.id,
        subtotal: item.subtotal,
        tax: item.tax,
        quantity: item.quantity,
        discount: item.discount,
        deposit: item.deposit,
        discountType: item.discountType,
        total: item.total,
        deliveryDate: item.deliveryDate || date.toISOString(),
        notes: item.notes,
      }))
      const createOrder = {
        ...order,
        customerId: order.customerId || undefined,
        orderDiscount: undefined,
        currencyId,
        discountType: DiscountTypes.Amount,
        orderId: undefined,
        orderDate: date.toISOString(),
        orderLines,
        storeFrontId,
        status: SalesOrderStatus.PAID,
        paidAmount,
        change,
      }
      createSalesOrder.mutate(createOrder)
    } else {
      setIsError(true)
    }
  }

  useEffect(() => {
    if (parseFloat(amount) >= order.total) {
      const change = +(parseFloat(amount) - order.total).toFixed(2)
      dispatch({ type: Types.SetOrder, payload: { order: { ...order, change, paidAmount: parseFloat(amount) } } })
    } else {
      dispatch({ type: Types.SetOrder, payload: { order: { ...order, change: 0, paidAmount: 0 } } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount])

  return (
    <Stack background="ui.07" borderRadius={20} margin="0 auto" padding={5}>
      <ClosedKeyboard
        createSalesOrder={(order) => createSalesOrder.mutate(order)}
        setAmount={setAmount}
        setIsError={setIsError}
      />
      <Flex align="center" justify="center" width="100%">
        <Tooltip
          bg="#e64433"
          color="white"
          data-testid="FormField__validationError"
          flex={1}
          isOpen={isError}
          label={t("amountShouldBeGreatThanTotal")}
          placement="right"
          hasArrow
        >
          <NumberInput mb={8} value={format(amount)} width="80%">
            <NumberInputField
              _placeholder={{ color: "text.03", textAlign: "left" }}
              background="ui.field"
              borderColor="ui.03"
              borderRadius={10}
              boxShadow="text-field"
              color="text.01"
              fontSize={24}
              height={isLongThan750 ? "76px" : "3rem"}
              placeholder={t("amount")}
              textAlign="right"
              readOnly
            />
          </NumberInput>
        </Tooltip>
      </Flex>
      <Flex width="100%">
        <FastKeyboard setAmount={setAmount} setIsError={setIsError} setKeyboardType={setKeyboardType} />
        <SimpleKeyboard
          amountState={[amount, setAmount]}
          keyboardTypeState={[keyboardType, setKeyboardType]}
          setIsError={setIsError}
        />
        <Flex direction="column">
          <Flex mb={4}>
            <Button
              {...sizes.lg}
              {...buttonStyle(isLongThan750)}
              onClick={() => {
                setAmount(String(order.total))
                setIsError(false)
                dispatch({ type: Types.SetOrder, payload: { order: { ...order, paidAmount: order.total, change: 0 } } })
                completeOrder(order.total)
              }}
            >
              {t("exact")}
            </Button>
          </Flex>
          <Flex height="100%" width="100%">
            <Button
              disabled={(isNaN(parseFloat(amount)) || parseFloat(amount)) < order.total}
              {...buttonStyle(true)}
              bg="success.03"
              data-testid="calculator-done-button"
              height="100%"
              width="100%"
              onClick={() => completeOrder()}
            >
              {t("done")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Stack>
  )
}
