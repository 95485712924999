import { Box, Badge as ChakraBadge, Flex, Menu, MenuButton, MenuListProps, Text, Tooltip } from "@chakra-ui/react"
import { ChevronDownIcon, DotIcon } from "@iac/components.icons.react"
import { FC } from "react"

import { STATUSES } from "common/constants"

export const BadgeSizes = ["xs", "sm", "md"] as const

export const BadgeVariants = ["plain", "ghost", ...STATUSES] as const

type Variant = (typeof BadgeVariants)[number]
type Size = (typeof BadgeSizes)[number]

const baseStyles = {
  alignItems: "center",
  borderWidth: "1px",
  color: "text.01",
  display: "flex",
  fontSize: "sm",
  fontWeight: "normal",
  lineHeight: "100%",
  px: 0,
  py: 0,
  textTransform: "none",
  width: "fit-content",
}

const sizeStyles: { [key in Size]: string } = {
  xs: "1.125rem",
  sm: "1.438rem",
  md: "1.75rem",
}

const ghostColour = "interactive.05"

const variantStyles = (variant: Variant) => {
  const isGhost: boolean = variant === "ghost"

  return {
    ...baseStyles,
    bg: isGhost ? "transparent" : `${variant}.02`,
    borderColor: isGhost ? ghostColour : `${variant}.03`,
    color: isGhost ? ghostColour : `${variant}.01`,
  } as const
}

type MenuItemSizes = [containerWidth: string, iconSize: string]

const menuItemSizes: { [key in Size]: MenuItemSizes } = {
  xs: ["1.438rem", "0.875rem"],
  sm: ["1.438rem", "0.875rem"],
  md: ["1.75rem", "1rem"],
}

const Marker = () => <DotIcon boxSize={2} ml={2} />

export type BadgeProps = {
  hasMarker?: boolean
  hint?: string
  size?: Size
  text: string
  variant?: Variant
}

export const Badge: FC<BadgeProps> = ({ hasMarker, hint, size = "md", text, variant = "info" }) => (
  <Tooltip label={hint} variant="info" hasArrow>
    <ChakraBadge sx={{ ...variantStyles(variant), minH: sizeStyles[size] }}>
      {hasMarker && <Marker />}
      <Text color={variant !== "ghost" ? "text.01" : ""} px={2}>
        {text}
      </Text>
    </ChakraBadge>
  </Tooltip>
)

export type BadgeMenuProps = BadgeProps & {
  menu: MenuListProps
  isReadOnly?: boolean
}

export const BadgeMenu: FC<BadgeMenuProps> = ({ isReadOnly = false, ...props }) => {
  if (isReadOnly) {
    return <Badge {...props} />
  }

  const { menu, text, size = "md", variant = "info", hasMarker = false } = props
  const [containerSize, iconSize] = menuItemSizes[size]

  return (
    <ChakraBadge
      className="badge__menu"
      size={size}
      sx={{
        ...variantStyles(variant),
        _hover: { borderColor: `${variant}.03` },
        minH: sizeStyles[size],
      }}
    >
      <Menu autoSelect={false} gutter={2}>
        <MenuButton flexDirection="column">
          <Box alignItems="center" display="flex" height="100%">
            {hasMarker && <Marker />}
            <Text color="text.01" px={2}>
              {text}
            </Text>

            <Flex
              alignItems="center"
              alignSelf="stretch"
              bg={`${variant}.03`}
              display="flex"
              justifyContent="center"
              width={containerSize}
            >
              <ChevronDownIcon boxSize={iconSize} color="text.01" />
            </Flex>
          </Box>
        </MenuButton>
        {menu}
      </Menu>
    </ChakraBadge>
  )
}
