import { TenancyLanguage } from "../models/TenancyLanguage"
import { request as __request } from "../core/request"
import { trackPromise } from "react-promise-tracker";

export class TenancyLanguagesService {
  /**
   * Gets all Tenancy Languages.
   * ## Requires:
   * ### tenancyLanguages.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async tenancyLanguagesGetAllAsync({
    select,
    expand,
    top,
    skip,
    count = false,
    parentId,
  }: {
    parentId: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
    /** Limits the number of items returned from a collection. **/
    top?: number
    /** Excludes the specified number of items of the queried collection from the result. **/
    skip?: number
    /** Indicates whether the total count of items within a collection are returned in the result. **/
    count?: boolean
  }): Promise<{
    readonly "@odata.context"?: string
    readonly value?: Array<TenancyLanguage>
    readonly count: number
  }> {
    const result = await trackPromise(__request({
      method: "GET",
      path: `/api/tenancies/${parentId}/isa/tenancylanguages`,
      query: {
        $select: select,
        $expand: expand,
        $top: top,
        $skip: skip,
        $count: count,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }))
    return { ...result.body, count: result.body["@odata.count"] }
  }

  /**
   * Update Tenancy Language.
   * ## Requires:
   * ### tenancyLanguages.Update
   * @returns any Success
   * @throws ApiError
   */
  public static async tenancyLanguagesPatchAsync({
    parentId,
    id,
    requestBody,
  }: {
    parentId: string
    id: string
    requestBody?: Partial<TenancyLanguage>
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & TenancyLanguage
  > {
    const result = await trackPromise(__request({
      method: "PATCH",
      path: `/api/tenancies/${parentId}/isa/tenancylanguages/${id}`,
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        409: `Conflict`,
        500: `Internal server error`,
      },
    }))
    return result.body
  }
}
