import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Textarea } from "@chakra-ui/react"
import { Dispatch, FC, SetStateAction } from "react"

type UpdateOrderModalProps = {
  isOpen: boolean
  notesState: [string, Dispatch<SetStateAction<string>>]
  updateProduct: () => void
}

const UpdateOrderModal: FC<UpdateOrderModalProps> = ({ isOpen, notesState, updateProduct }) => {
  const [notes, setNotes] = notesState

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      motionPreset="slideInRight"
      size="xl"
      isCentered
      onClose={updateProduct}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton data-testid="close-button" zIndex={2} />
        <ModalBody p={0}>
          <Flex width="100%">
            <Textarea
              data-testid="notes-field"
              width="100%"
              autoFocus
              onChange={(event) => setNotes(event.currentTarget.value)}
            >
              {notes}
            </Textarea>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export { UpdateOrderModal }
