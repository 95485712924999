import { Grid, GridItem, Image, Text } from "@chakra-ui/react"
import { FC, useContext, useEffect, useState } from "react"

import { ProductModel } from "api/generated"
import { DefaultImageIcon } from "common/theme"
import { ProductsContext } from "features/inShop/product/ProductState/context"

type ProductProps = {
  product: ProductModel
}

const ViewProductItem: FC<ProductProps> = ({ product }) => {
  const [image, setImage] = useState("")
  const { state } = useContext(ProductsContext)
  const {
    globalEntities: { tenancyCurrency },
  } = state

  useEffect(() => {
    const mainImage = product.images.find((item) => item.isMainImage)
    mainImage ? setImage(mainImage.thumbnailUrl) : setImage(product.images[0]?.thumbnailUrl ?? "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      //align="center"
      background="transparent"
      color="text-01"
      data-testid="product"
      fontSize={16}
      padding="8px 30px 8px 5px"
      templateColumns="0.7fr 4fr 1fr 1fr 2fr"
      width="100%"
    >
      <GridItem alignItems="center" display="flex" justifyContent="center">
        {image ? <Image margin="0 auto" maxWidth="30px" src={image} /> : <DefaultImageIcon />}
      </GridItem>
      <GridItem
      //align="left"
      >
        <Text>{product.name}</Text>
      </GridItem>
      <GridItem alignItems="center" display="flex" onClick={(event) => event.stopPropagation()}>
        <GridItem px={4} onClick={(event) => event.stopPropagation()}>
          {product.quantity}
        </GridItem>
      </GridItem>
      <GridItem textAlign="right">
        {((product.retailPrice + product.tax) * product.quantity).toFixed(2)} {tenancyCurrency.currency?.symbol}
      </GridItem>
      <GridItem textAlign="right">
        {((product.retailPrice + product.tax) * product.quantity).toFixed(2)} {tenancyCurrency.currency?.symbol}
      </GridItem>
    </Grid>
  )
}

export { ViewProductItem }
