import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { ProductCategoriesService } from "../generated"
import { ODataOptions } from "../handmade"

export const useProductCategories = (parentId: UUID, options?: ODataOptions) =>
  useQuery(
    ["productCategories"],
    () => ProductCategoriesService.productCategoriesGetAllAsync({ parentId, expand: "image", ...options }),
    { cacheTime: 0 }
  )

export const useProductCategory = (parentId: UUID, id: UUID) =>
  useQuery(
    ["productCategories", parentId, id],
    () => ProductCategoriesService.productCategoriesGetAsync({ parentId, id }),
    { cacheTime: 0 }
  )

export const useCreateProductCategoriesMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (productCategory: any) =>
      ProductCategoriesService.productCategoriesPostAsync({ parentId, requestBody: productCategory as any }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["productCategories"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useUpdateProductCategoriesMutation = (
  { onSuccess }: { onSuccess: () => void },
  parentId: UUID,
  id: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (productCategory: any) =>
      ProductCategoriesService.productCategoriesPatchAsync({ parentId, id, requestBody: productCategory as any }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["productCategories"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useDeleteProductCategoriesMutation = (
  { onSuccess }: { onSuccess: () => void },
  parentId: UUID,
  id: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(() => ProductCategoriesService.productCategoriesDeleteAsync({ parentId, id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["productCategories"])
      onSuccess()
    },
    onError: () => {},
  })
}
