import { Box, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { ProductCategoryTable } from "./productCategoryTable/ProductCategoryTable"
import { ProductSubcategoryTable } from "./productCategoryTable/ProductSubcategoryTable"
import { Category, api, mockData } from "api"
import { ConfirmModal, PageHeader, PageLayout, SearchPanel } from "common/components"
import { TENANCY_ID } from "common/constants"
import { route } from "features/app/routes"

const ProductCategoryView: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [type, setType] = useState("")
  const [search, setSearch] = useState("")
  const { onClose, isOpen, onOpen } = useDisclosure()
  const [filter, setFilter] = useState<Category>(mockData.initCategory)
  const [subcategories, setSubcategories] = useState<Category[]>([])
  const [category, setCategory] = useState<Category>(mockData.initCategory)
  const [selectCategory, setSelectCategory] = useState<Category>(mockData.initCategory)
  const [categories, setCategories] = useState<Category[]>([])
  const { data: productCategories, isLoading: categoriesLoading } = api.useProductCategories(TENANCY_ID)
  const deleteProductCategory = api.useDeleteProductCategoriesMutation(
    {
      onSuccess: () => {
        onClose()
        setFilter(mockData.initCategory)
      },
    },
    TENANCY_ID,
    category.id
  )

  const confirmDeleting = (item: Category, type: string): void => {
    onOpen()
    setType(type)
    setCategory(item)
  }

  const deleteItem = (): void => deleteProductCategory.mutate()

  const getMainCategories = () => {
    const mainCategories = productCategories?.value?.filter((item) => !item.parentCategoryId)
    setCategories(mainCategories || [])
  }

  useEffect(() => {
    if (productCategories?.value) {
      getMainCategories()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCategories?.value])

  useEffect(() => {
    if (search.length === 0) {
      getMainCategories()
    } else {
      setCategories(
        categories.filter((item: Category) => item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    setSelectCategory(filter)
    const subcategories = productCategories?.value?.filter((item) => item.parentCategoryId === filter.id)
    setSubcategories(subcategories || [])
    if (!filter.id) {
      getMainCategories()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  return (
    <Box>
      <PageHeader
        buttonTitle={t("addNewCategory")}
        link="/inventory"
        title={t("product_categories")}
        isArrow
        onClick={() => navigate(route.addCategory.path)}
      />
      <PageLayout
        confirmModal={
          <ConfirmModal
            isOpen={isOpen}
            name={category.name}
            type={type}
            isDelete
            onClick={deleteItem}
            onClose={onClose}
          />
        }
        search={
          <SearchPanel
            filter={{ id: filter.id, label: filter.name }}
            onRemove={() => setFilter(mockData.initCategory)}
            onSearch={setSearch}
          />
        }
        setSearch={() => {}}
        table={
          !filter.id ? (
            <ProductCategoryTable
              allCategories={productCategories?.value || []}
              categories={categories}
              deleteCategory={confirmDeleting}
              loading={categoriesLoading}
              setFilter={setFilter}
            />
          ) : (
            <ProductSubcategoryTable
              categoryId={selectCategory.id}
              deleteSubcategory={confirmDeleting}
              loading={categoriesLoading}
              subcategories={subcategories}
            />
          )
        }
      />
    </Box>
  )
}

export { ProductCategoryView }
