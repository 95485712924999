import { Category } from "../generated"

export const initCategory = {
  id: "",
  tenancyId: "",
  parentCategoryId: "",
  taxCategoryId: "",
  name: "",
  description: "",
  imageId: null,
  status: "",
  translations: [],
  image: { id: "", url: "", thumbnailUrl: "" },
} as Category
