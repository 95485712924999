export const currencies = [
  {
    id: "10b7a79e-f6b7-48e7-89f2-b848add75779",
    localName: "euro",
    iso: "EUR",
    symbol: "€",
    position: "Beginning",
    delimiter: ",",
    tenancyCurrencies: [],
  },
  {
    id: "1b37c760-4cbf-4666-9300-6228c20b695e",
    localName: "dollar",
    iso: "USD",
    symbol: "$",
    position: "Ending",
    delimiter: ".",
    tenancyCurrencies: [],
  },
]
