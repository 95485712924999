import { Box, forwardRef } from "@chakra-ui/react"
import { FormPhoneNumberInputProps, FormPhoneNumberInput as PhoneNumberInput } from "@iac/components.form"
import React, { useMemo } from "react"

import { api } from "api"

export const FormPhoneNumberInput = forwardRef<
  Omit<FormPhoneNumberInputProps, "countries"> & { dataTestId?: string },
  "div"
>(({ dataTestId, ...props }, ref) => {
  const { data: countriesData } = api.useSupportedCountries({ top: 500 })

  const countries = useMemo(
    () =>
      countriesData?.value?.map((c) => ({
        id: Number(c.id),
        name: c.name || "",
        isO3166Alpha2: c.isO3166Alpha2,
        flagUrl: c.flagUrl,
      })) ?? [],
    [countriesData]
  )

  return (
    <Box data-testid={dataTestId ? dataTestId + "-container" : undefined} ref={ref} width="100%">
      <PhoneNumberInput {...props} countries={countries || []} data-testid={dataTestId} />
    </Box>
  )
})
