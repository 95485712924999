import { EntityTypes } from "../"
import { rootAdmin } from "api/bifrost-mock/admins/mocks/data"
import { OWNER_ADMIN_ID as ownerAdminId } from "api/bifrost-mock/constants"
import { EntityWithChildren as Entity } from "api/handmade/models"
import { TERRITORY_ID } from "common/constants"
import { CustomerType } from "features/customers"

const parentId = "a04e4e29-42d8-4f35-a913-0245f57d24b8"
const territoryId = TERRITORY_ID.EU
const internalAccountsId = "{{internal accounts id}}"
const internalSupportId = "{{internal support id}}"

export const entities: Entity[] = [
  {
    id: parentId,
    name: "Alfa Computing",
    internalAccountsId,
    internalSupportId,
    parentId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
    entityType: "Distributor" as EntityTypes.DISTRIBUTOR,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a00e4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [
      {
        id: "a24e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "CharlieSoft",
        internalAccountsId,
        internalSupportId,
        parentId,
        entityType: "Partner" as EntityTypes.PARTNER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a02e4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
      {
        id: "a34e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Deltaworks",
        internalAccountsId,
        internalSupportId,
        parentId,
        entityType: "Partner" as EntityTypes.PARTNER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a03e4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
      {
        id: "b64e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "X-ray Support Ltd",
        internalAccountsId,
        internalSupportId,
        parentId,
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "b6d64aff-dfdf-406b-9417-f396732c6e16",
        territoryId,
      },
    ],
  },
  {
    id: "a14e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Bravo IT",
    internalAccountsId,
    internalSupportId,
    parentId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
    entityType: "Distributor" as EntityTypes.DISTRIBUTOR,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a01e4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [
      {
        id: "a44e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Echonet Solutions",
        internalAccountsId,
        internalSupportId,
        parentId: "a14e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Partner" as EntityTypes.PARTNER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: parentId,
        territoryId,
      },
      {
        id: "a54e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Foxtrot Computing",
        internalAccountsId,
        internalSupportId,
        parentId: "a14e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Partner" as EntityTypes.PARTNER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a05e4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
      {
        id: "b74e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Yankee Cloudware",
        internalAccountsId,
        internalSupportId,
        parentId: "a14e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "b7d64aff-dfdf-406b-9417-f396732c6e16",
        territoryId,
      },
    ],
  },
  {
    id: "a24e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "CharlieSoft",
    internalAccountsId,
    internalSupportId,
    parentId,
    entityType: "Partner" as EntityTypes.PARTNER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a02e4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [
      {
        id: "a64e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Golf Group",
        internalAccountsId,
        internalSupportId,
        parentId: "a24e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a06e4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
      {
        id: "aa4e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Kiloware",
        internalAccountsId,
        internalSupportId,
        parentId: "a24e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a0ae4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
      {
        id: "ae4e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Oscar Intelligence Solutions",
        internalAccountsId,
        internalSupportId,
        parentId: "a24e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a0ee4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
    ],
  },
  {
    id: "a34e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Deltaworks",
    internalAccountsId,
    internalSupportId,
    parentId,
    entityType: "Partner" as EntityTypes.PARTNER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a03e4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [
      {
        id: "a74e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Hotelligent Solutions",
        internalAccountsId,
        internalSupportId,
        parentId: "a34e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a07e4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
      {
        id: "af4e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Papaya Communications",
        internalAccountsId,
        internalSupportId,
        parentId: "a34e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a0fe4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
      {
        id: "b24e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "iTango",
        internalAccountsId,
        internalSupportId,
        parentId: "a34e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "b2d64aff-dfdf-406b-9417-f396732c6e16",
        territoryId,
      },
      {
        id: "b44e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Victorysoft",
        internalAccountsId,
        internalSupportId,
        parentId: "a34e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "b4d64aff-dfdf-406b-9417-f396732c6e16",
        territoryId,
      },
    ],
  },
  {
    id: "a44e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Echonet Solutions",
    internalAccountsId,
    internalSupportId,
    parentId: "a14e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Partner" as EntityTypes.PARTNER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: parentId,
    territoryId,
    children: [
      {
        id: "a84e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "India Outsourcing",
        internalAccountsId,
        internalSupportId,
        parentId: "a44e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a08e4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
      {
        id: "ac4e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Mike Roe Soft",
        internalAccountsId,
        internalSupportId,
        parentId: "a44e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a0ce4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
      {
        id: "b04e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Quebec Software Services",
        internalAccountsId,
        internalSupportId,
        parentId: "a44e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "b0d64aff-dfdf-406b-9417-f396732c6e16",
        territoryId,
      },
      {
        id: "b34e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Uniform Networks",
        internalAccountsId,
        internalSupportId,
        parentId: "a44e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "b3d64aff-dfdf-406b-9417-f396732c6e16",
        territoryId,
      },
    ],
  },
  {
    id: "a54e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Foxtrot Computing",
    internalAccountsId,
    internalSupportId,
    parentId: "a14e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Partner" as EntityTypes.PARTNER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a05e4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [
      {
        id: "a94e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Juliett Consulting",
        internalAccountsId,
        internalSupportId,
        parentId: "a54e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a09e4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
      {
        id: "ab4e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Limadev",
        internalAccountsId,
        internalSupportId,
        parentId: "a54e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a0be4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
      {
        id: "ad4e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "November Studios",
        internalAccountsId,
        internalSupportId,
        parentId: "a54e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "a0de4e29-42d8-4f35-a913-0245f57d24b8",
        territoryId,
      },
      {
        id: "b14e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Romeo IT",
        internalAccountsId,
        internalSupportId,
        parentId: "a54e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "b1d64aff-dfdf-406b-9417-f396732c6e16",
        territoryId,
      },
      {
        id: "b84e4e29-42d8-4f35-a913-0245f57d24b8",
        name: "Zulu IT",
        internalAccountsId,
        internalSupportId,
        parentId: "a54e4e29-42d8-4f35-a913-0245f57d24b8",
        entityType: "Customer" as EntityTypes.CUSTOMER,
        ownerAdminId,
        ownerAdmin: rootAdmin,
        defaultTenancyId: "b8d64aff-dfdf-406b-9417-f396732c6e16",
        territoryId,
      },
    ],
  },
  {
    id: "a64e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Golf Group",
    internalAccountsId,
    internalSupportId,
    parentId: "a24e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a06e4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [],
  },
  {
    id: "a74e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Hotelligent Solutions",
    internalAccountsId,
    internalSupportId,
    parentId: "a34e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a07e4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [],
  },
  {
    id: "a84e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "India Outsourcing",
    internalAccountsId,
    internalSupportId,
    parentId: "a44e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a08e4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [],
  },
  {
    id: "a94e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Juliett Consulting",
    internalAccountsId,
    internalSupportId,
    parentId: "a54e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a09e4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [],
  },
  {
    id: "aa4e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Kiloware",
    internalAccountsId,
    internalSupportId,
    parentId: "a24e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a0ae4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [],
  },
  {
    id: "ab4e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Limadev",
    internalAccountsId,
    internalSupportId,
    parentId: "a54e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a0be4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [],
  },
  {
    id: "ac4e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Mike Roe Soft",
    internalAccountsId,
    internalSupportId,
    parentId: "a44e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a0ce4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [],
  },
  {
    id: "ad4e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "November Studios",
    internalAccountsId,
    internalSupportId,
    parentId: "a54e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a0de4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [],
  },
  {
    id: "ae4e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Oscar Intelligence Solutions",
    internalAccountsId,
    internalSupportId,
    parentId: "a24e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a0ee4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [],
  },
  {
    id: "af4e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Papaya Communications",
    internalAccountsId,
    internalSupportId,
    parentId: "a34e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "a0fe4e29-42d8-4f35-a913-0245f57d24b8",
    territoryId,
    children: [],
  },
  {
    id: "b04e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Quebec Software Services",
    internalAccountsId,
    internalSupportId,
    parentId: "a44e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "b0d64aff-dfdf-406b-9417-f396732c6e16",
    territoryId,
    children: [],
  },
  {
    id: "b14e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Romeo IT",
    internalAccountsId,
    internalSupportId,
    parentId: "a54e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "b1d64aff-dfdf-406b-9417-f396732c6e16",
    territoryId,
    children: [],
  },
  {
    id: "b24e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "iTango",
    internalAccountsId,
    internalSupportId,
    parentId: "a34e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "b2d64aff-dfdf-406b-9417-f396732c6e16",
    territoryId,
    children: [],
  },
  {
    id: "b34e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Uniform Networks",
    internalAccountsId,
    internalSupportId,
    parentId: "a44e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "b3d64aff-dfdf-406b-9417-f396732c6e16",
    territoryId,
    children: [],
  },
  {
    id: "b44e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Victorysoft",
    internalAccountsId,
    internalSupportId,
    parentId: "a34e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "b4d64aff-dfdf-406b-9417-f396732c6e16",
    territoryId,
    children: [],
  },
  {
    id: "b54e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Coding & Whisky Solutions",
    internalAccountsId,
    internalSupportId,
    parentId: "7291dce2-1fa0-ea11-9cd8-84fdd17eeb35",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "b5d64aff-dfdf-406b-9417-f396732c6e16",
    territoryId: "626c729f-17a0-ea11-9cd8-84fdd17eeb35",
    children: [],
  },
  {
    id: "b64e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "X-ray Support Ltd",
    internalAccountsId,
    internalSupportId,
    parentId,
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "b6d64aff-dfdf-406b-9417-f396732c6e16",
    territoryId: "626c729f-17a0-ea11-9cd8-84fdd17eeb35",
    children: [],
  },
  {
    id: "b74e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Yankee Cloudware",
    internalAccountsId,
    internalSupportId,
    parentId: "a14e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "b7d64aff-dfdf-406b-9417-f396732c6e16",
    territoryId,
    children: [],
  },
  {
    id: "b84e4e29-42d8-4f35-a913-0245f57d24b8",
    name: "Zulu IT",
    internalAccountsId,
    internalSupportId,
    parentId: "a54e4e29-42d8-4f35-a913-0245f57d24b8",
    entityType: "Customer" as EntityTypes.CUSTOMER,
    ownerAdminId,
    ownerAdmin: rootAdmin,
    defaultTenancyId: "b8d64aff-dfdf-406b-9417-f396732c6e16",
    territoryId,
    children: [],
  },
]

export const root: Entity = {
  id: "004e4e29-42d8-4f35-a913-0245f57d24b8",
  name: "IAM Cloud",
  internalAccountsId,
  internalSupportId,
  parentId: "004e4e29-42d8-4f35-a913-0245f57d24b8",
  entityType: "IAMCloud" as EntityTypes.IAMCLOUD,
  ownerAdminId,
  ownerAdmin: rootAdmin,
  defaultTenancyId: "3225f4b0-1fa0-ea11-9cd8-84fdd17eeb35",
  territoryId,
  children: entities,
}

export const distributor = entities[0]

export const partner = entities[0].children![1] as Entity

export const consumer = entities[2].children![1] as Entity

export const allEntities = [root, ...entities]

export const entitiesByType: { [key in CustomerType]: Entity } = {
  root,
  distributor,
  partner,
  consumer,
}
