import type { Currency } from "../models/Currency";
import { request as __request } from '../core/request';
import { trackPromise } from "react-promise-tracker";

export class CurrenciesService {

  /**
   * Gets all currencies.
   * ## Requires:
   * ### currencies.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async currenciesGetAllAsync({
    select,
    expand,
  }: {
    /** Limits the properties returned in the result. **/
    select?: string,
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string,
  }): Promise<{
    readonly '@odata.context'?: string,
    readonly value?: Array<Currency>,
  }> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: '/api/currencies',
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }

  /**
   * Get Currency.
   * ## Requires:
   * ### currencies.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async currenciesGetAsync({
    id,
    select,
    expand,
  }: {
    id: string,
    /** Limits the properties returned in the result. **/
    select?: string,
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string,
  }): Promise<({
    readonly '@odata.context'?: string,
  }) & Currency> {
    const result = await trackPromise(__request({
      method: 'GET',
      path: `/api/currencies/${id}`,
      query: {
        '$select': select,
        '$expand': expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    }));
    return result.body;
  }
}