import { Button, Flex, useMediaQuery } from "@chakra-ui/react"
import { CrossIcon } from "@iac/components.icons.react"
import { Dispatch, FC, SetStateAction } from "react"

import { buttonStyle, sizes } from "./keyboardStyles"

type SimpleKeyboardProps = {
  amountState: [string, Dispatch<SetStateAction<string>>]
  keyboardTypeState: [string, Dispatch<SetStateAction<string>>]
  setIsError: Dispatch<SetStateAction<boolean>>
}

export const SimpleKeyboard: FC<SimpleKeyboardProps> = ({ amountState, keyboardTypeState, setIsError }) => {
  const [amount, setAmount] = amountState
  const [keyboardType, setKeyboardType] = keyboardTypeState
  const [isLongThan769] = useMediaQuery("(min-height: 750px)")
  const values = {
    first: [1, 2, 3],
    second: [4, 5, 6],
    third: [7, 8, 9],
  }

  const onPress = (value: number | string) => {
    setIsError(false)
    if (value === ".") {
      if (!amount.includes(".")) {
        const changed = `${amount}${value}`
        setAmount(changed)
      }
      if (+amount === 0) {
        setAmount("0.")
      }
      setKeyboardType("simple")
    } else {
      if (keyboardType === "fast") {
        const changed = !!amount ? parseFloat(amount) + Number(value) : Number(value)
        setAmount(String(changed))
        setKeyboardType("simple")
      } else {
        const changed = `${amount}${value}`
        setAmount(changed)
        setKeyboardType("simple")
      }
    }
  }

  const clearData = () => {
    const changed = String(amount).substring(0, String(amount).length - 1)
    setAmount(changed)
  }

  const getButton = (value: number) => (
    <Button key={value} {...sizes.lg} {...buttonStyle(isLongThan769)} mr={3} onClick={() => onPress(value)}>
      {value}
    </Button>
  )

  return (
    <Flex direction="column" ml={3}>
      <Flex mb={4}>{values.first.map((value) => getButton(value))}</Flex>
      <Flex mb={4}>{values.second.map((value) => getButton(value))}</Flex>
      <Flex mb={4}>{values.third.map((value) => getButton(value))}</Flex>
      <Flex>
        <Button {...sizes.lg} {...buttonStyle(isLongThan769)} mr={3} onClick={() => onPress(".")}>
          .
        </Button>
        <Button {...sizes.lg} {...buttonStyle(isLongThan769)} mr={3} onClick={() => onPress(0)}>
          0
        </Button>
        <Button
          {...sizes.lg}
          {...buttonStyle(isLongThan769)}
          mr={3}
          onClick={() => {
            clearData()
            setIsError(false)
          }}
        >
          <CrossIcon fontSize={40} />
        </Button>
      </Flex>
    </Flex>
  )
}
