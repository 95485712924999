import { Button, Flex } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useContext, useEffect } from "react"

import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

type CardType = {
  returnToPaymentType: () => void
  completeOrder: () => void
}

const Card: FC<CardType> = ({ completeOrder, returnToPaymentType }) => {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(ProductsContext)

  const buttonStyles = {
    fontSize: 30,
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15rem",
    borderRadius: 10,
    height: "12rem",
  }

  useEffect(() => {
    dispatch({
      type: Types.SetOrder,
      payload: { order: { ...state.makeOrder.order, paidAmount: state.makeOrder.order.total, change: 0 } },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex align="center" height="100%" justify="space-between" width="100%">
      <Button
        bg="white"
        boxShadow="box"
        data-testid="accept-payment-button"
        style={{ ...buttonStyles, marginRight: "3rem" }}
        variant="text"
        whiteSpace="pre-line"
        onClick={completeOrder}
      >
        {t("paymentAccepted")}
      </Button>
      <Button
        bg="white"
        boxShadow="box"
        color="danger.01"
        data-testid="decline-payment-button"
        style={{ ...buttonStyles }}
        variant="text"
        whiteSpace="pre-line"
        onClick={returnToPaymentType}
      >
        {t("paymentDeclined")}
      </Button>
    </Flex>
  )
}

export { Card }
