import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { VariantOptionsService, VariantTypeOption } from "../generated"
import { ODataOptions } from "../handmade"

export const useVariantOptions = (parentId: UUID, options?: ODataOptions) =>
  useQuery(
    ["variantOptions", options],
    () => VariantOptionsService.variantOptionsGetAllAsync({ parentId, ...options }),
    { cacheTime: 0 }
  )

export const useVariantOption = (parentId: UUID, id: UUID) =>
  useQuery(["variantOptions"], () => VariantOptionsService.variantOptionsGetAsync({ id, parentId }), { cacheTime: 0 })

export const useCreateVariantOptionMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (variantOption: Partial<VariantTypeOption>) =>
      VariantOptionsService.variantOptionsPostAsync({ parentId, requestBody: variantOption as any }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["variantOptions"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useUpdateVariantOptionMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (variantOption: Partial<VariantTypeOption>) =>
      VariantOptionsService.variantOptionsPatchAsync({ parentId, id, requestBody: variantOption as any }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["variantOptions"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useDeleteVariantOptionMutation = ({ onSuccess }: { onSuccess: () => void }, parentId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(() => VariantOptionsService.variantOptionsDeleteAsync({ id, parentId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["variantOptions"])
      onSuccess()
    },
    onError: () => {},
  })
}
