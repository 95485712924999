import { User as OidcClientUser, UserProfile } from "oidc-client-ts"
import { useCallback, useEffect, useState } from "react"
// We want to use the react-oidc-context here
// as we are wrapping it for other components to consume

import { useAuth } from "react-oidc-context"

import { TenancyLinkPermission } from "api/handmade/models/TenancyLinkPermission"

type User = OidcClientUser & {
  profile: UserProfile & { cloudVaultId?: string; homeEntityId?: string; homeDefaultTenancyId?: string }
}

type BaseAuthRequestOptions<T = void> = {
  onError?: (error: unknown) => void
  onSuccess?: (value: T) => void
}

// Some notes to the next developer that comes here hunting auth bugs:
// querySessionStatus fires off a load more requests, but errors are
// still just a string of the stack clearStaleState makes no difference
// Everything I try to do only gives us a string of the stack.
//
// Adding an error boundary didn't work, I think because they won't catch
// async errors (network requests).
//
// We need to treat all errors the same, whether it's 500 or 404, because
// all we get is a string of the stack, no way to determine what the error was
// events.addSilentRenewError doesn't actually get called when a silent renew
// error happens

/**
 * This is a wrapper around our OIDC Auth library, and should be used when calling any signin/signout methods
 * to ensure that any errors are caught. It also re-exports all of the OIDC context for conviencience so we don't
 * have to multiple calls to the useAuth hook.
 * It is intended to be a drop-in replacement for the react-oidc-context useAuth hook.
 **/
// eslint-disable-next-line sonarjs/cognitive-complexity
const useAuthWrapper = () => {
  const {
    signinRedirect: oidcSigninRedirect,
    signinSilent: oidcSigninSilent,
    signoutRedirect: oidcSignoutRedirect,
    isLoading,
    user,
    isAuthenticated,
    ...oidcContext
  } = useAuth()
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  const cloudVaultId =
    isAuthenticated && user && typeof user.profile.cloudVaultId === "string" ? user.profile.cloudVaultId : undefined

  const email = isAuthenticated && user && typeof user.profile.email === "string" ? user.profile.email : undefined

  const homeDefaultTenancyId =
    isAuthenticated && user && typeof user.profile.homeDefaultTenancyId === "string"
      ? user.profile.homeDefaultTenancyId
      : undefined

  const homeEntityId =
    isAuthenticated && user && typeof user.profile.homeEntityId === "string" ? user.profile.homeEntityId : undefined

  const name = isAuthenticated && user && typeof user.profile.name === "string" ? user.profile.name : undefined

  /**
   * if there is only one tenancyLink, react-oidc-context returns user.profile.tenancyLinks as an object not an array,
   * this logic ensures that an array is always returned.
   */
  const tenancyLinksObject = isAuthenticated && user ? user.profile.tenancyLinks : []
  const tenancyLinks: TenancyLinkPermission[] = Array.isArray(tenancyLinksObject)
    ? tenancyLinksObject
    : [tenancyLinksObject]

  const signinSilent = useCallback(
    async ({ onError, onSuccess }: BaseAuthRequestOptions<User | null> = {}) => {
      // eslint-disable-next-line fp/no-let
      let user = null

      try {
        // eslint-disable-next-line fp/no-mutation
        user = await oidcSigninSilent()
      } catch (error: unknown) {
        if (onError) {
          onError(error)
        }

        return
      }

      // It is important that this onSuccess is outside of the try/catch
      // otherwise errors thrown inside onSuccess are caught and handled
      // by onError, when they should be handled inside the onSuccess function
      // onError should only handle errors from oidcSigninSilent
      if (onSuccess) {
        onSuccess(user)
      }
    },
    [oidcSigninSilent]
  )

  const signinRedirect = useCallback(
    async ({ onError, onSuccess }: BaseAuthRequestOptions = {}) => {
      try {
        await oidcSigninRedirect()
      } catch (error: unknown) {
        if (onError) {
          onError(error)
        }

        return
      }

      // It is important that this onSuccess is outside of the try/catch
      // otherwise errors thrown inside onSuccess are caught and handled
      // by onError, when they should be handled inside the onSuccess function
      // onError should only handle errors from oidcSigninRedirect
      if (onSuccess) {
        onSuccess()
      }
    },
    [oidcSigninRedirect]
  )

  const signoutRedirect = useCallback(
    async ({ onError, onSuccess }: BaseAuthRequestOptions = {}) => {
      try {
        await oidcSignoutRedirect()
      } catch (error: unknown) {
        if (onError) {
          onError(error)
        }

        return
      }

      // It is important that this onSuccess is outside of the try/catch
      // otherwise errors thrown inside onSuccess are caught and handled
      // by onError, when they should be handled inside the onSuccess function
      // onError should only handle errors from oidcSignoutRedirect
      if (onSuccess) {
        onSuccess()
      }
    },
    [oidcSignoutRedirect]
  )

  useEffect(() => {
    if (!isInitialLoading) {
      return
    }

    if (!isLoading) {
      setIsInitialLoading(false)
    }
  }, [isInitialLoading, isLoading])

  return {
    ...oidcContext,
    isInitialLoading,
    isLoading,
    signinRedirect,
    signinSilent,
    signoutRedirect,
    isAuthenticated,
    user,
    email,
    cloudVaultId,
    homeDefaultTenancyId,
    homeEntityId,
    name,
    tenancyLinks,
  }
}

export { useAuthWrapper, useAuthWrapper as useAuth }

export type { BaseAuthRequestOptions, User }
