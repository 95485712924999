import { OWNER_ADMIN_ID } from "api/bifrost-mock/constants"
import { User } from "features/auth"

export const user: User = {
  id_token:
    "eyJhbGciOiJSUzI1NiIsImtpZCI6IkRDRTEwNUM5RThGQUEyNjM2NjdFMUE2REQxMDIxNjRFQkU2Mzk2NTVSUzI1NiIsInR5cCI6IkpXVCIsIng1dCI6IjNPRUZ5ZWo2b21ObWZocHQwUUlXVHI1amxsVSJ9.eyJuYmYiOjE2MDE2MzI2NTMsImV4cCI6MTYwMTYzMjk1MywiaXNzIjoiaHR0cHM6Ly9pYWNkMnVrcy1hdGgxLWlkczEtYXBwMS5henVyZXdlYnNpdGVzLm5ldCIsImF1ZCI6InBvcnRhbC1jbGllbnQiLCJpYXQiOjE2MDE2MzI2NTMsImF0X2hhc2giOiJNSzFhLTBwbXlLSXI2LXVET0hxak5BIiwic19oYXNoIjoiUW52clcteHltV3NzRjdCaE45enIzQSIsInNpZCI6IjRFMzcxOTg3NTUzQjI0NDJFQzJGNENFOUZFMEEyM0U2Iiwic3ViIjoiMSIsImF1dGhfdGltZSI6MTYwMTYzMjY1MSwiaWRwIjoibG9jYWwiLCJhbXIiOlsicHdkIl19.LNSbfxLe4hw1eDS64s1pckGfGiLHgrezQ2CYIr9L4BLPwykBZ6rKw-josID16HJx3O0lfNgnE5ADNNd4MjqiPVzDSU-T-0K-sUXM57GqV-aIsYbODtcoAxONjSEt7KgS1UZSlCMayrmpXbHM7Jw0bxW5dSiPK5BmkZd63s7i-rwU9dO5QyGLJ-CR9Ye2Lts5uMzDXxIc3L3UJYew32IToL432ixCIT8WegkweicgPGyFyLpYQ2rOpcUNwa3ODvT9lSErHYEJhSkaxKPTnlQiXbEGSUDBEctpVkTimhRtv_R1rbPhPS6UtDzw04OBG6_liKVcHbwQ-mO-QSB0pAoaWw",
  session_state: "0UzNO8pfND2Mj3SflJ4mo4bGN1shV3iNdWfGerXr0-k.969B4122B64B945BE1E68691571AB520",
  access_token:
    "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc2MTc1RjRCNEExQzhEODEyQjk4RkUyQjA1RkIxMEVENEI1OUU5RTdSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6ImRoZGZTMG9jallFcm1QNHJCZnNRN1V0WjZlYyJ9.eyJuYmYiOjE2MTQ1OTMwMzcsImV4cCI6MTYxNDU5MzkzNywiaXNzIjoiaHR0cHM6Ly9pYWNkMnVrcy1hdGgxLWlkczEtYXBwMS5henVyZXdlYnNpdGVzLm5ldCIsImF1ZCI6ImJpZnJvc3QiLCJjbGllbnRfaWQiOiJwb3J0YWwtY2xpZW50Iiwic3ViIjoiMSIsImF1dGhfdGltZSI6MTYxNDU5MzAzNSwiaWRwIjoibG9jYWwiLCJlbWFpbCI6ImFkbWluQGlhbWNsb3VkLmNvbSIsImFkbWluIjoiZjBkODZjYmQtYjkyZi00NDlhLTg1NzktNjZmYTEwYzZkNTcyIiwidGVycml0b3J5IjoiNjI2YzcyOWYtMTdhMC1lYTExLTljZDgtODRmZGQxN2VlYjM1IiwidmF1bHRJZCI6IjAwMDAwMDAwLTAwMDAtMDAwMC0wMDAwLTAwMDAwMDAwMDAwMCIsInBlcm1pc3Npb25zIjoiYWxsLkZ1bGwiLCJqdGkiOiJEQzhGN0RFQzMyMTNBNzMxMkM1RjZGNEVGQkM5RDhBQSIsInNpZCI6IjNBQjM0N0ZCMDQ2RjU1QUVFMEZFQzA3REFBNjM1MTgxIiwiaWF0IjoxNjE0NTkzMDM3LCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiYmlmcm9zdCJdLCJhbXIiOlsicHdkIl0sInRlbmFuY2llcyI6W3sidGVuYW5jeUlkIjoiMzIyNWY0YjAtMWZhMC1lYTExLTljZDgtODRmZGQxN2VlYjM1IiwicGVybWlzc2lvbnMiOlsiYWxsLkZ1bGwiXX0seyJ0ZW5hbmN5SWQiOiJhMDBlNGUyOS00MmQ4LTRmMzUtYTkxMy0wMjQ1ZjU3ZDI0YjgiLCJwZXJtaXNzaW9ucyI6WyJhbGwuRnVsbCJdfSx7InRlbmFuY3lJZCI6ImEwMmU0ZTI5LTQyZDgtNGYzNS1hOTEzLTAyNDVmNTdkMjRiOCIsInBlcm1pc3Npb25zIjpbImFsbC5GdWxsIl19LHsidGVuYW5jeUlkIjoiYTA2ZTRlMjktNDJkOC00ZjM1LWE5MTMtMDI0NWY1N2QyNGI4IiwicGVybWlzc2lvbnMiOlsiYWxsLkZ1bGwiXX1dLCJlbnRpdGllcyI6eyJlbnRpdHlJZCI6IjcyOTFkY2UyLTFmYTAtZWExMS05Y2Q4LTg0ZmRkMTdlZWIzNSIsImVudGl0eVR5cGVJZCI6MX19.oxT7KzUr3Q0zrOTT9J8qT6NQs0-mldVY-qLzCiG6WP2HbYi9mphIqBbALkcewzliRi9e9kv__ai1Gx2Kcy8JiSYGM0-p_voLBwd3P5mIqPaz1NQ76PGnQPYDpbBmrx49GMH6RDkTIG70nNRRb1_2fbHJ73UFBkvaRLCRYCVrwUO-NVlmQJl-L7ld-adTi2KQWv3hFyya1g0Qqe45JfSJ-cdUcenzXTMa6y4nxKD074vqdmsFHT3tkh2YqdJip_pfqVBSNJjs7OZan4GqREfMVHsqTowFIWuEaa0ISBk_kYOCvkQ0uA9NAmydpfRM5tW6hzmqXEGssJCVs5lYMdF73g",
  token_type: "Bearer",
  scope: "openid profile bifrost",
  expires_at: 9601633553,
  expires_in: 9601633553,
  expired: false,
  state: {},
  profile: {
    admin: OWNER_ADMIN_ID,
    preferred_username: "Bob Dobalina",
    name: "Robert Dobalina",
    s_hash: "QnvrW-xymWssF7BhN9zr3A",
    sid: "4E371987553B2442EC2F4CE9FE0A23E6",
    sub: "1",
    auth_time: 1601632651,
    iat: 1601633553,
    idp: "local",
    iss: "issue_identifier",
    aud: "audiences",
    amr: ["pwd"],
    exp: 9601633553,
  },
  toStorageString() {
    return "mockUser.toStorageString"
  },
  // eslint-disable-next-line fp/no-get-set
  get scopes() {
    return [""]
  },
}
