import { Button, Flex, Text, VStack } from "@chakra-ui/react"
import { FC, useContext, useState } from "react"

import { Customer, mockData } from "api"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

type CustomerDetailProps = {
  onOpen: () => void
}

const CustomerDetail: FC<CustomerDetailProps> = ({ onOpen }) => {
  const { state, dispatch } = useContext(ProductsContext)
  const [customer, setCustomer] = useState<Customer>(mockData.initCustomer)

  const buttonStyle = {
    width: "150px",
    height: "45px",
    fontSize: 20,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 10,
    zIndex: 0,
  }

  return (
    <VStack
      align="center"
      style={{
        background: "#36495E",
        width: "420px",
        color: "white",
        padding: "20px",
        fontWeight: "bold",
        fontSize: 18,
      }}
    >
      <Flex align="center" direction="column" flex={1} width="100%">
        <Flex alignItems="center" justifyContent="space-between" padding="10px 0" width="100%">
          <Text>Name</Text>
          <Text fontSize={20}>{customer.name}</Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" padding="10px 0" width="100%">
          <Text>Email</Text>
          <Text fontSize={20}>{customer.email}</Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" padding="10px 0" width="100%">
          <Text>Phone</Text>
          <Text fontSize={20}>{customer.phone}</Text>
        </Flex>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Button
          style={{
            ...buttonStyle,
            background: "transparent",
          }}
          onClick={() => {
            setCustomer(mockData.initCustomer)
            dispatch({
              type: Types.SetOrder,
              payload: {
                order: { ...state.makeOrder.order, customerId: "" },
              },
            })
            dispatch({ type: Types.ShowCustomerDetail, payload: { showCustomerDetail: false } })
            onOpen()
          }}
        >
          Change
        </Button>
        <Button
          style={{
            ...buttonStyle,
            border: "none",
            background: "#cc2b19",
          }}
          onClick={() => dispatch({ type: Types.ShowCustomerDetail, payload: { showCustomerDetail: false } })}
        >
          Close details
        </Button>
      </Flex>
    </VStack>
  )
}

export { CustomerDetail }
