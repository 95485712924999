import { MenuItem, MenuItemProps } from "@chakra-ui/react"
import { WarningSolidIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC } from "react"

type Props = Pick<MenuItemProps, "children" | "onClick">

const WarningMenuItem: FC<MenuItemProps> = ({ children, onClick }) => {
  const { t } = useTranslation()

  return (
    <MenuItem
      children={children}
      icon={<WarningSolidIcon aria-label={t("Warning icon")} color="warning.01" />}
      sx={{
        _focus: { bg: "warning.02" },
        _active: {
          bg: "warning.03",
        },
      }}
      onClick={onClick}
    />
  )
}

export { WarningMenuItem }

export type { Props as WarningMenuItemProps }
