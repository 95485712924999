export const initStoreFont = {
  id: "",
  parentId: "",
  name: "",
  description: "",
  type: "",
  address: "",
  status: "",
  isDefault: false,
  websiteType: "",
  deletedTS: "",
  currencyId: "",
  languageId: "",
}
