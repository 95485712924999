import { Box, Button, Image as ChakraImage, Flex, Input, Text } from "@chakra-ui/react"
import { CrossIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useRef, useState } from "react"

type ImagesSelectModalProps = {
  uploadedImages: string[]
  onImageUploaded: (image: string) => void
  isMultiple: boolean
  clearLibrary: () => void
  deleteImage: (index: number) => void
}

const MAX_FILE_SIZE = 1024 * 1024
const ALLOWED_DISPROPORTION_PERCENT = 5

const UploadPhotosPanel: FC<ImagesSelectModalProps> = ({
  uploadedImages,
  onImageUploaded,
  isMultiple,
  clearLibrary,
  deleteImage,
}) => {
  const { t } = useTranslation()
  const [isDropZoneHighlighted, setIsDropZoneHighlighted] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onFileUpload = (file: File) => {
    if (file.size > MAX_FILE_SIZE) return alert("Please select an image under 1MB")
    const reader = new FileReader()

    reader.onloadend = function () {
      const image = new Image()
      // eslint-disable-next-line fp/no-mutation
      image.src = reader.result as string
      // eslint-disable-next-line fp/no-mutation
      image.onload = function () {
        if (Math.abs(image.width - image.height) > (image.width / 100) * ALLOWED_DISPROPORTION_PERCENT)
          return alert("Please upload a square image (256x256, 512x512, etc.)")
        if (!isMultiple) clearLibrary()
        onImageUploaded(reader.result as string)
      }
    }
    reader.readAsDataURL(file)
  }

  return (
    <Flex
      borderRadius="5px"
      direction="column"
      justifyContent="flex-start"
      minH="430px"
      outline={isDropZoneHighlighted ? "4px dashed #00a7df" : "none"}
      onDragLeave={(e) => {
        e.preventDefault()
        setIsDropZoneHighlighted(false)
      }}
      onDragOver={(e) => {
        e.preventDefault()
        setIsDropZoneHighlighted(true)
      }}
      onDrop={(e) => {
        e.preventDefault()
        setIsDropZoneHighlighted(false)
        Array.from(e.dataTransfer.files).forEach(onFileUpload)
      }}
    >
      <Flex direction="row" gridGap={7} justifyContent="flex-start" minH="140px" mt={3} wrap="wrap">
        {!!uploadedImages.length &&
          uploadedImages.map((image, mapIndex) => (
            <Box key={mapIndex} pos="relative">
              <ChakraImage
                alt="Image Preview"
                boxSize="120px"
                data-testid="ImageUpload__preview"
                mt={2}
                src={image as string}
              />
              <CrossIcon
                bgColor="red.400"
                borderRadius="100%"
                color="white"
                cursor="pointer"
                height="28px"
                pos="absolute"
                right="-12px"
                top="-3px"
                width="28px"
                onClick={() => deleteImage(mapIndex)}
              />
            </Box>
          ))}
      </Flex>
      <Flex alignItems="center" direction="column" gridGap={3} justifyContent="center" mt={7}>
        <Text color="text.01" fontSize="lg" fontWeight="bold">
          {t("imagesControl.dragAndDropAnImageToUpload")}
        </Text>
        <Text mb={2}>{t("imagesControl.or")}</Text>
        <Button size="sm" variant="tertiary" onClick={() => fileInputRef.current?.click()}>
          {t("imagesControl.chooseFiles")}
        </Button>
      </Flex>

      <Input
        accept="image/*"
        data-testid="ImageUpload__input"
        disabled={false}
        display="none"
        ref={fileInputRef}
        type="file"
        multiple
        onChange={(e) => Array.from(e.target.files ?? []).forEach(onFileUpload)}
      />
    </Flex>
  )
}

export type { ImagesSelectModalProps }

export { UploadPhotosPanel }
