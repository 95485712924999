import { createRouteCommand } from "../commands/commands.utils"
import { Command } from "features/commands"
import { useCentralCommandApp } from "features/customers"

const useCentralCommandAckbarCommands = (): Command[] => {
  const { appName, hasApp } = useCentralCommandApp()

  // Other central command ackbar specific commands in addition to route commands
  // could be created and returned here.
  const commands: Command[] = [createRouteCommand({ path: "customers" }, appName)]

  return hasApp ? commands : []
}

export { useCentralCommandAckbarCommands }
