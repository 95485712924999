import ms from "ms"
import { useEffect, useState } from "react"

import { useAuth } from "features/auth"

const COUNTDOWN_SECONDS = 5
const COUNTDOWN_INTERVAL = ms("1s")

type Params = {
  countdownRunning: boolean
  countdownSeconds?: number
}

const useSignoutRedirectCountdown = ({
  countdownRunning,
  countdownSeconds = COUNTDOWN_SECONDS,
}: Params): number | null => {
  const { signoutRedirect } = useAuth()
  const [timeRemaining, setTimeRemaining] = useState<number | null>(countdownSeconds)

  useEffect(() => {
    if (!countdownRunning) {
      // reset the timer.
      setTimeRemaining(countdownSeconds)

      return
    }

    if (timeRemaining === 0) {
      setTimeRemaining(null)
      signoutRedirect()

      return
    }

    if (!timeRemaining) {
      return
    }

    const intervalId = setInterval(() => {
      setTimeRemaining(timeRemaining - 1)
    }, COUNTDOWN_INTERVAL)

    return () => clearInterval(intervalId)
  }, [countdownRunning, countdownSeconds, setTimeRemaining, signoutRedirect, timeRemaining])

  return timeRemaining
}

export type { Params as useSignoutRedirectCountdownParams }

export { useSignoutRedirectCountdown }
