import { AdminsService as GeneratedAdminService } from "api/generated"
import { request as __request } from "api/generated/core/request"
import { TenancyAdmin } from "api/handmade"

// FYI: isExternal will be removed from Admin and TenancyAdmin models
// TODO: Remove this AdminsService when UI can use new generated models
// @ts-ignore - Temporally overriding static methods as API incorrect
// eslint-disable-next-line fp/no-class
export class AdminsService extends GeneratedAdminService {
  /**
   * Gets the admins.
   * ## Requires:
   * ### admins.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async tenanciesGetAdminsAsync({
    id,
    select,
    expand,
    filter,
    orderby,
    top,
    skip,
    count = false,
  }: {
    id: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
    /** Restricts the set of items returned. For the allowed functions please refer to the Bifrost Swagger document https://bifrost.iamcloud.net/api/swagger/index.html **/
    filter?: string
    /** Specifies the order in which items are returned. The maximum number of expressions is 5. **/
    orderby?: string
    /** Limits the number of items returned from a collection. **/
    top?: number
    /** Excludes the specified number of items of the queried collection from the result. **/
    skip?: number
    /** Indicates whether the total count of items within a collection are returned in the result. **/
    count?: boolean
  }): Promise<{
    readonly "@odata.context"?: string
    readonly "@odata.count"?: number
    readonly value?: Array<TenancyAdmin>
  }> {
    const result = await __request({
      method: "GET",
      path: `/api/tenancies/${id}/admins`,
      query: {
        $select: select,
        $expand: expand,
        $filter: filter,
        $orderby: orderby,
        $top: top,
        $skip: skip,
        $count: count,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    })

    return result.body
  }

  /**
   * Gets the Admin.
   * ## Requires:
   * ### admins.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async tenanciesGetAdminAsync({
    id,
    childId,
    select,
    expand,
  }: {
    id: string
    childId: string
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
  }): Promise<
    {
      readonly "@odata.context"?: string
    } & TenancyAdmin
  > {
    const result = await __request({
      method: "GET",
      path: `/api/tenancies/${id}/admins/${childId}`,
      query: {
        $select: select,
        $expand: expand,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        406: `Not acceptable`,
        500: `Internal server error`,
      },
    })

    return result.body
  }
}
