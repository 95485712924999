import type { Country } from "../models/Country"
import { request as __request } from "../core/request"
import { trackPromise } from "react-promise-tracker"

export class CountriesService {

    /**
     * Get Country
     * ## Requires:
     * ### countries.Read
     * @returns any Success
     * @throws ApiError
     */
    public static async countriesGetCountry({
      isoCode,
      select,
      expand,
    }: {
        /** Country ISO code **/
        isoCode: string,
        /** Limits the properties returned in the result. **/
        select?: string,
        /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
        expand?: string,
    }): Promise<({
        readonly '@odata.context'?: string,
    } & Country)> {
        const result = await __request({
            method: 'GET',
            path: `/api/countries/${isoCode}`,
            query: {
                '$select': select,
                '$expand': expand,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

  /**
   * Get All Countries
   * ## Requires:
   * ### countries.Read
   * @returns any Success
   * @throws ApiError
   */
  public static async countriesGetAll({
    select,
    expand,
    filter,
    orderby,
    top,
    skip,
    count = false,
  }: {
    /** Limits the properties returned in the result. **/
    select?: string
    /** Indicates the related entities to be represented inline. The maximum depth is 2. **/
    expand?: string
    /** Restricts the set of items returned. For the allowed functions please refer to the Bifrost Swagger document https://bifrost.iamcloud.net/api/swagger/index.html **/
    filter?: string
    /** Specifies the order in which items are returned. The maximum number of expressions is 5. **/
    orderby?: string
    /** Limits the number of items returned from a collection. **/
    top?: number
    /** Excludes the specified number of items of the queried collection from the result. **/
    skip?: number
    /** Indicates whether the total count of items within a collection are returned in the result. **/
    count?: boolean
  }): Promise<{
    readonly "@odata.context"?: string
    readonly "@odata.count"?: number
    readonly value?: Array<Country>
  }> {
    const result = await trackPromise(
      __request({
        useBifrost: true,
        method: "GET",
        path: `/api/countries`,
        query: {
          $select: select,
          $expand: expand,
          $filter: filter,
          $orderby: orderby,
          $top: top,
          $skip: skip,
          $count: count,
        },
        errors: {
          400: `Bad request`,
          401: `Unauthorized`,
          403: `Forbidden`,
          406: `Not acceptable`,
          500: `Internal server error`,
        },
      })
    )
    return {
      ...result.body,
      value: result.body.value.map((country: Omit<Country, "id"> & { id: string }) => ({
        ...country,
        id: +country.id,
      })),
    }
  }
}
