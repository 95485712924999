import { Box, Flex } from "@chakra-ui/react"
import { Dispatch, FC, ReactElement, SetStateAction } from "react"

import { SearchPanel } from "../SearchPanel/SearchPanel"

type PageLayoutProps = {
  setSearch: Dispatch<SetStateAction<string>>
  search?: ReactElement
  table: ReactElement
  confirmModal?: ReactElement
}

export const PageLayout: FC<PageLayoutProps> = ({ setSearch, table, search, confirmModal }) => (
  <>
    <Box pl={20} pr={10} py={4}>
      <Flex align="center" justify="space-between" width="100%">
        {search ? search : <SearchPanel onSearch={setSearch} />}
      </Flex>
      <Flex direction="column" py={4}>
        {table}
      </Flex>
    </Box>
    {confirmModal}
  </>
)
