import { isDevelopment } from "isDevelopment"

/**
 * In development, environment values are pulled from 'process.env' whereas in other
 * environments it's loaded from 'window.BUILD_CONFIG',
 * which is obtained from the `public/runtime.config.js` which is included in the html <script /> tag
 *
 * The fallback '{}' exists so that in the build pipeline (where the 'process.env.NODE_ENV'
 * is 'production' because we are building a production version, but there is no 'window.BUILD_CONFIG'
 * present because it's not a deployment) this statement can successfully deconstruct.
 * Without it, a compilation error occurs.
 */
const environment = isDevelopment() ? process.env : (window as any).BUILD_CONFIG || {}

export const {
  REACT_APP_API_URL,
  REACT_APP_BIFROST_URL,
  REACT_APP_OIDC_AUTHORITY,
  REACT_APP_OIDC_CLIENT_ID,
  REACT_APP_OIDC_SCOPE,
  REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
} = environment as {
  [key: string]: string
}

export const REACT_APP_REGION: string = environment.REACT_APP_REGION || "UKS"

export const REACT_APP_ENABLED_FEATURE_FLAGS: string = environment.REACT_APP_ENABLED_FEATURE_FLAGS || ""
