import { Box, Flex } from "@chakra-ui/react"
import { FC } from "react"

import { PageHeader } from "common/components/PageHeader/PageHeader"
import { Search } from "features/inventory/search/Search"

const InvoiceView: FC = () => (
  <Box p={4}>
    <PageHeader buttonTitle="Add New Invoice" title="Invoices" onClick={() => {}} />
    <Flex data-testid="shipments" direction="column" p={4}>
      <Search placeholder="Search for invoices" titleButton="Search invoice" onSearch={() => {}} />
    </Flex>
  </Box>
)

export { InvoiceView }
