import { Grid, GridItem, Text } from "@chakra-ui/react"
import { FC, useContext } from "react"

import { ProductModel } from "api"
import { symbolPosition } from "common/utils/currency.utils"
import { ProductsContext } from "features/inShop/product/ProductState/context"
import { Types } from "features/inShop/product/ProductState/reducers"

type RemainingProductItemProps = {
  object: ProductModel
}

const RemainingProductItem: FC<RemainingProductItemProps> = ({ object }) => {
  const { dispatch } = useContext(ProductsContext)

  return (
    <Grid
      _hover={{
        background: "rgb(246, 246, 246)",
        cursor: "pointer",
      }}
      data-testid="product"
      style={{
        background: "transparent",
        borderBottomColor: "#979797",
        borderBottomWidth: 1,
        padding: "8px 30px 8px 15px",
        color: "#36495E",
        fontWeight: "bold",
        fontSize: 24,
        width: "100%",
      }}
      templateColumns="4fr 1fr 1fr 1fr 1fr"
      onClick={() => {
        dispatch({ type: Types.SetOpenRemaining, payload: { openRemainingProduct: true } })
        dispatch({ type: Types.SetOrderProduct, payload: { orderProduct: object } })
      }}
    >
      <GridItem
      //align="left"
      >
        <Text>{object.name}</Text>
      </GridItem>
      <GridItem alignItems="center" display="flex" justifyContent="center">
        <GridItem px={4}>{object.quantity}</GridItem>
      </GridItem>
      <GridItem>{symbolPosition(Number((object.quantity * object.retailPrice).toFixed(2)))}</GridItem>
      <GridItem>
        {symbolPosition(Number(((object.retailPrice + object.tax - object.deposit) * object.quantity).toFixed(2)))}
      </GridItem>
      <GridItem>{symbolPosition(0)}</GridItem>
    </Grid>
  )
}

export { RemainingProductItem }
