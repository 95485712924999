export const KEY_CODES = {
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ARROW_UP: 38,
  ESC: 27,
  RETURN: 13,
  SPACE: 32,
}

type GenericEvent = {
  keyCode?: number
  which?: number
}

export const getKeyCode = ({ keyCode, which }: GenericEvent): number | null => keyCode || which || null

export const isKey = (key: number) => (event: GenericEvent) => getKeyCode(event) === key

export const isReturnKey = isKey(KEY_CODES.RETURN)

export const isSpaceKey = isKey(KEY_CODES.SPACE)

export const isEscKey = isKey(KEY_CODES.ESC)

export const isArrowDownKey = isKey(KEY_CODES.ARROW_DOWN)

export const isArrowUpKey = isKey(KEY_CODES.ARROW_UP)
