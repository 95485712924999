import { useTranslation } from "@iac/translations.i18n-instance"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FC, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"

import { ErrorSplash } from "./../ErrorSplash"
import { markTenancyLinksAccepted } from "./storage.utils"
import { AdminStatuses, AdminsService, TenancyLink, TenancyLinks } from "api"
import { useManageCustomerAdmin } from "api/hooks"
import { Loading } from "common/components"
import { useAuth } from "features/auth"

const useUpdateUserTenancyLinksMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (tenancyLinks: TenancyLinks) =>
      AdminsService.adminsUpdateMeAsync({
        requestBody: tenancyLinks,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tenancies"])
        queryClient.invalidateQueries(["admins"])
      },
    }
  )
}

const AutoAccept: FC<{ tenancyLinks: TenancyLink[] }> = ({ tenancyLinks }) => {
  const { signinSilent, signoutRedirect, user } = useAuth()
  const { t } = useTranslation()
  const { refetch: fetchManageCustomerAdmin } = useManageCustomerAdmin()

  const [hasMutationError, setHasMutationError] = useState(false)

  useEffect(() => {
    /* This cleans up the state hook if AutoAccept fails */
    setHasMutationError(false)
  }, [])

  const tenancyLinkMutation = useUpdateUserTenancyLinksMutation()

  const pendingTenancyLinks = tenancyLinks.filter((t) => t.status === "Pending")

  const shouldAutoAccept = tenancyLinks.length === pendingTenancyLinks.length

  const shouldNavigate = !shouldAutoAccept && !hasMutationError

  if (shouldNavigate) {
    const path = (user?.state as string) ?? "/"

    return <Navigate to={path} replace />
  }

  const handleRetry = () => {
    signinSilent()
  }

  const handleSignout = () => {
    signoutRedirect()
  }

  if (!tenancyLinkMutation.isLoading) {
    // call mutation with pending tenancy links
    tenancyLinkMutation.mutate(
      {
        links: pendingTenancyLinks.map((tenancyLink: TenancyLink) => ({
          tenancyId: tenancyLink.tenancyId,
          status: AdminStatuses.ACCEPTED,
        })),
      },
      {
        onSuccess: () => {
          markTenancyLinksAccepted()

          signinSilent({
            onError: () => setHasMutationError(true),
            onSuccess: () => fetchManageCustomerAdmin(),
          })
        },
        onError: () => {
          setHasMutationError(true)
        },
      }
    )
  }

  if (hasMutationError) {
    return (
      <ErrorSplash
        emitRetry={handleRetry}
        emitSignout={handleSignout}
        errorMessage={t("There was a problem configuring your tenancies.")}
      />
    )
  }

  return <Loading />
}

export { AutoAccept }
