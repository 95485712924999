import { Button, Flex, Heading, Stack, Text, useDisclosure } from "@chakra-ui/react"
import { FunnelSolidIcon } from "@iac/components.icons.react"
import { format } from "date-fns"
import { ChangeEvent, FC, useEffect, useState } from "react"
// import DayPickerInput from "react-day-picker/DayPickerInput"
import { useNavigate } from "react-router-dom"

import { OrderProductsTable } from "./OrderProductsTable"
import { SidebarVariants } from "./SidebarVariatns/SidebarVariants"
import { SearchForProducts } from "../searchForProducts/SearchForProducts"
import { CatalogProduct, ShipmentMethod, Supplier, mockData } from "api"
import { CommonFormControl } from "common/components/CommonFormControl/CommonFormControl"
import { FormSelect } from "common/components/FormSelect/FormSelect"
import { PageHeader } from "common/components/PageHeader/PageHeader"
// import "./NewPurchaseOrder.css"
import { route } from "features/app/routes"

type PurchaseOrder = {
  id: string
  supplier: { id: string; name: string }
  poNumber: string
  poDate: string
  shippingTerm: { id: string; name: string }
  shippingMethod: { id: string; name: string }
  deliveryDate: string
  status: string
}

const NewPurchaseOrder: FC = () => {
  const navigate = useNavigate()
  const formatDate = "dd/MM/yyyy"
  const { onOpen, isOpen, onClose } = useDisclosure()
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>({
    id: "",
    supplier: mockData.initSupplier,
    poNumber: "",
    poDate: "",
    shippingTerm: { id: "", name: "" },
    shippingMethod: { id: "", name: "" },
    deliveryDate: "",
    status: "",
  })
  const [search, setSearch] = useState<string>("")
  const products = mockData.catalogProduct.catalogProducts.map((item) => ({
    id: item.id,
    name: item.name,
    supplierSku: item.supplierSKU,
    orderQty: 0,
    rrp: item.rrp,
  }))
  const [filter, setFilter] = useState<CatalogProduct>(mockData.initCatalogProduct)
  const [variants, setVariants] = useState<
    {
      id: string
      variant: string
      supplierSku: string
      orderQty: number
      rrp: number
    }[]
  >([])

  // const dayPickerStyles = {
  //   width: "60%",
  //   textAlign: "left",
  //   background: "inherit",
  //   borderWidth: 1,
  //   borderColor: "#e3dcde",
  //   fontSize: 16,
  //   paddingRight: 20,
  //   borderRadius: ".25rem",
  //   padding: 0,
  // }

  const statuses = ["Active", "Inactive"]

  const shippingMethods: ShipmentMethod[] =
    JSON.parse(localStorage.getItem("shipments") as string) || mockData.shippingMethod.shipmentMethods

  const setValue = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.currentTarget
    setPurchaseOrder({ ...purchaseOrder, [name]: value })
  }

  // const chooseDate = (day: Date, name: string): void => {
  //   setPurchaseOrder({ ...purchaseOrder, [name]: format(day, formatDate) })
  // }

  useEffect(() => {
    const date = format(Date.now(), formatDate)
    setPurchaseOrder({ ...purchaseOrder, poDate: date, deliveryDate: date })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSearch(search)
    if (filter.id) {
      const mappedVariants = filter.variants.map(({ id, variant, supplierSku }) => ({
        id,
        variant: variant ? variant : "",
        supplierSku,
        orderQty: 0,
        rrp: filter.rrp,
      }))
      setVariants(mappedVariants)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  return (
    <Stack data-testid="newOrderContainer" p={4}>
      <PageHeader
        buttonTitle="Back"
        title="New Purchase Order"
        variant="secondary"
        width="150px"
        onClick={() => navigate(-1)}
      />
      <Flex direction="column" p={4} width="70%">
        <FormSelect
          data={mockData.supplier.suppliers.map((item) => ({ id: item.id, name: item.name || "" }))}
          label="Supplier"
          name="supplier"
          value={purchaseOrder.supplier}
          onChange={(event) => {
            const supplier = mockData.supplier.suppliers.find(
              (item) => item.id === event.currentTarget.value
            ) as Supplier
            setPurchaseOrder({ ...purchaseOrder, supplier: { id: supplier.id, name: supplier.name || "" } })
          }}
        />
        <CommonFormControl label="PO Number" name="poNumber" value={purchaseOrder.poNumber} onChange={setValue} />
        <CommonFormControl label="PO Date">
          {/*<DayPickerInput*/}
          {/*  format={formatDate}*/}
          {/*  placeholder="MM/DD/YYYY"*/}
          {/*  style={dayPickerStyles}*/}
          {/*  value={purchaseOrder.poDate}*/}
          {/*  onDayChange={(day) => chooseDate(day, "poDate")}*/}
          {/*/>*/}
        </CommonFormControl>
        <FormSelect
          data={mockData.shippingTerm.shippingTerms}
          label="Shipping Term"
          name="shippingTerm"
          value={purchaseOrder.shippingTerm}
          onChange={(event) => {
            const shippingTerm = mockData.shippingTerm.shippingTerms.find(
              (item) => item.id === event.currentTarget.value
            ) as { id: string; name: string }
            setPurchaseOrder({ ...purchaseOrder, shippingTerm })
          }}
        />
        <FormSelect
          data={shippingMethods}
          label="Shipping Method"
          name="shippingMethod"
          value={purchaseOrder.shippingMethod}
          onChange={(event) => {
            const shippingMethod = shippingMethods.find(
              (item) => item.id === event.currentTarget.value
            ) as ShipmentMethod
            setPurchaseOrder({ ...purchaseOrder, shippingMethod })
          }}
        />
        <CommonFormControl label="Delivery Date">
          {/*<DayPickerInput*/}
          {/*  format={formatDate}*/}
          {/*  placeholder="MM/DD/YYYY"*/}
          {/*  style={dayPickerStyles}*/}
          {/*  value={purchaseOrder.deliveryDate}*/}
          {/*  onDayChange={(day) => chooseDate(day, "deliveryDate")}*/}
          {/*/>*/}
        </CommonFormControl>
        <FormSelect
          label="Status"
          name="status"
          stringData={statuses}
          value={{ id: purchaseOrder.status, name: purchaseOrder.status }}
          onChange={(event) => {
            const status = statuses.find((item) => item === event.currentTarget.value) as string
            setPurchaseOrder({ ...purchaseOrder, status })
          }}
        />
      </Flex>
      <Flex direction="column" p={4}>
        <Heading mb={6} size="md">
          Add Order
        </Heading>
        <Flex direction="column" width="100%">
          <Flex align="center" justify="space-between" mb={6}>
            <Flex width="60%">
              <SearchForProducts titleButton="Search" onSearch={setSearch} />
            </Flex>
            <Button color="#0073b3" variant="ghost">
              <FunnelSolidIcon fontSize={22} />
              <Text ml={4}>Filter</Text>
            </Button>
          </Flex>
          <OrderProductsTable products={products} setFilter={setFilter} onOpen={onOpen} />
          <SidebarVariants isOpen={isOpen} variants={variants} onClose={onClose} />
        </Flex>
      </Flex>
      <Flex p={4}>
        <Button variant="primary" width={150} onClick={() => navigate(route.products.path)}>
          Done
        </Button>
        <Button ml={4} variant="secondary" width={150}>
          Cancel
        </Button>
      </Flex>
    </Stack>
  )
}

export { NewPurchaseOrder }
