import { useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { OptionValuesTable } from "./variantOptionsTable/OptionValuesTable"
import { VariantOptionValue, VariantTypeOption, api, mockData } from "api"
import { ConfirmModal, PageHeader, PageLayout, SearchPanel } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"
import { route } from "features/app/routes"

const OPTION_VALUES_TABLE = "OPTION_VALUES_TABLE"

const VariantOptionValuesView: FC = () => {
  const params = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [optionValue, setOptionValue] = useState<VariantOptionValue>(mockData.initVariantOptionValue)
  const [optionValues, setOptionValues] = useState<VariantOptionValue[] | undefined>([])
  const [filter, setFilter] = useState<VariantTypeOption>(mockData.initVariantOption)
  const { data: variantOption } = api.useVariantOption(TENANCY_ID, params.optionId || "")
  const deleteVariantOptionValueMutation = api.useDeleteVariantOptionValueMutation(
    { onSuccess: () => onClose() },
    TENANCY_ID,
    params.optionId || "",
    optionValue.id
  )

  const [storedPageSize, setStoredPageSize] = useStoredPageSize(OPTION_VALUES_TABLE)

  const [options, setOptions] = useState({
    skip: 0,
    top: storedPageSize ?? DEFAULT_ITEMS_PER_PAGE,
  })

  const { data: optionValuesArray, isLoading } = api.useVariantOptionValues(TENANCY_ID, params.optionId || "", {
    top: options.top,
    skip: options.skip,
    count: true,
  })

  const refetchVariantOptionsValues = async (top: number, skip: number) => {
    setOptions({
      top,
      skip,
    })
    setStoredPageSize(top)
  }

  const deleteVariantOptionValue = (): void => deleteVariantOptionValueMutation.mutate()

  useEffect(() => {
    if (variantOption) {
      setFilter(variantOption)
    }
  }, [variantOption])

  useEffect(() => {
    if (optionValuesArray?.value && typeof optionValuesArray?.value !== "string") {
      setOptionValues(optionValuesArray?.value)
    }
  }, [optionValuesArray])

  useEffect(() => {
    if (typeof optionValuesArray?.value !== "string") {
      if (search.length === 0) {
        setOptionValues(optionValuesArray?.value)
      } else {
        const findOptions = optionValuesArray?.value?.filter((item: VariantOptionValue) =>
          item.value.toLocaleLowerCase().includes(search)
        )
        setOptionValues(findOptions)
      }
    }
  }, [search, optionValuesArray?.value])

  return (
    <>
      <PageHeader
        buttonTitle={t("variant_option.add_new_option_value")}
        link={route.variantOptions.path}
        title={t("variant_option.variantValue")}
        isArrow
        onClick={() => navigate(`${route.variantOptions.path}/${params.optionId}/variantOptionValues/addOptionalValue`)}
      />
      <PageLayout
        confirmModal={
          <ConfirmModal
            isOpen={isOpen}
            name={optionValue.value}
            type={t("variant_option.option_value")}
            isDelete
            onClick={deleteVariantOptionValue}
            onClose={onClose}
          />
        }
        search={
          <SearchPanel
            filter={{ id: filter.id, label: filter.name }}
            onRemove={() => {
              setFilter(mockData.initVariantOption)
              navigate(route.variantOptions.path)
            }}
            onSearch={setSearch}
          />
        }
        setSearch={() => {}}
        table={
          <OptionValuesTable
            isLoading={isLoading}
            itemsPerPage={options.top}
            optionValues={optionValues || []}
            refetch={refetchVariantOptionsValues}
            setOptionValue={setOptionValue}
            onOpen={onOpen}
          />
        }
      />
    </>
  )
}

export { VariantOptionValuesView }
