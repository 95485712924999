import { useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { ProductsTable } from "./productsTable/ProductsTable"
import { ProductAPI, api, mockData } from "api"
import { ConfirmModal, PageHeader, PageLayout } from "common/components"
import { DEFAULT_ITEMS_PER_PAGE, TENANCY_ID } from "common/constants"
import { useStoredPageSize } from "common/hooks/useStoredTablePageSize"
import { route } from "features/app/routes"

const PRODUCTS_TABLE = "PRODUCTS_TABLE"

const ProductsView: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [search, setSearch] = useState<string>("")
  const [product, setProduct] = useState<ProductAPI>(mockData.initProductAPI)
  const [products, setProducts] = useState<ProductAPI[] | undefined>([])
  const [productsCount, setProductsCount] = useState(1)

  const deleteProductMutation = api.useDeleteProductMutation({ onSuccess: () => onClose() }, TENANCY_ID, product.id)

  const [storedPageSize, setStoredPageSize] = useStoredPageSize(PRODUCTS_TABLE)

  const [options, setOptions] = useState({
    top: storedPageSize || DEFAULT_ITEMS_PER_PAGE,
    skip: 0,
  })

  const { data, isLoading } = api.useProducts(TENANCY_ID, {
    expand: "variants",
    top: options.top,
    skip: options.skip,
    count: true,
    filter: search,
  })

  const refetchProducts = async (top: number, skip: number) => {
    setStoredPageSize(top)
    setOptions({
      top,
      skip,
    })
  }

  const deleteProduct = (): void => deleteProductMutation.mutate()

  useEffect(() => {
    if (data?.value) {
      setProducts(data?.value)
      setProductsCount(data.count)
    }
  }, [data])

  return (
    <>
      <PageHeader
        buttonTitle={t("product.addNewProduct")}
        link="/inventory"
        title={t("product.product_other")}
        isArrow
        onClick={() => navigate(route.addProduct.path)}
      />
      <PageLayout
        confirmModal={
          <ConfirmModal
            isOpen={isOpen}
            name={product.name}
            type="product"
            isDelete
            onClick={deleteProduct}
            onClose={onClose}
          />
        }
        setSearch={setSearch}
        table={
          <ProductsTable
            itemsPerPage={options.top}
            loading={isLoading}
            pageCount={Math.ceil(productsCount / options.top)}
            products={products || []}
            refetch={refetchProducts}
            search={search}
            setProduct={setProduct}
            onOpen={onOpen}
          />
        }
      />
    </>
  )
}

export { ProductsView }
